import Box from "@mui/material/Box";
import HelpBoyPic from "assets/img/static/bg/help_boy_bg.png";
import { FaqData } from "parts/frequentlyQuestions/faqData";
import FrequentlyQuestionItem from "parts/frequentlyQuestions/frequentlyQuestionItem";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import React from "react";

const FAQ: React.FC = () => {
  return (
    <>
      <section>
        <MainContainer>
          <Box
            pt={5}
            className="resImg text-center"
            sx={{ width: "min(350px,100%)", margin: "auto" }}
          >
            <img src={HelpBoyPic} alt="Help FAQ" />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box pb={5}>
            <Box textAlign="center">
              <SectionTitle
                title="You got questions?"
                subTitle="We have answers"
              />
            </Box>
            <Box pt={5}>
              {FaqData?.map((item, i) => (
                <FrequentlyQuestionItem key={i} index={i} item={item} />
              ))}
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default FAQ;
