import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import RouteConstant from "navigation/constant";
import {
  AccountDetailsProps,
  BillDetailsProps,
  BookingDetailsProps,
  BuildBundleInterval,
  BuildBundleProps,
  IdentityTypeProps,
  PackageBundleDetailsProps,
  PhoneDetailsProps,
  PromotionDetailsProps,
} from "_interfaces/phoneBooking";

export type BookingStepInfoProps = {
  target: string;
  content: string;
  order?: number;
  stepTitle?: string;
  nextStepTitle?: string;
};

type BookingDataProps = {
  identityType?: IdentityTypeProps;
  phoneDetails: PhoneDetailsProps | null;
  packageBundleDetails: PackageBundleDetailsProps | null;
  buildBundleDetails?: BuildBundleProps | null;
  buildBundleMinimumRequirement: BuildBundleProps;
  accountDetails: AccountDetailsProps | null;
  bookingDetails: BookingDetailsProps | null;
  billDetails: BillDetailsProps | null;
  buildBundleIntervalId: BuildBundleInterval;
  //promotion
  promotionDetails: PromotionDetailsProps | null;
};

type BookingStatusProps = {
  identityType: boolean;
  phoneDetails: boolean;
  packageBundleDetails: boolean;
  buildBundleDetails: boolean;
  accountDetails: boolean;
  bookingDetails: boolean;
  billDetails: boolean;
};

type Props = {
  bookingData: BookingDataProps;
  bookingStatus: BookingStatusProps;
  totalSteps: number;
  stepIndex: number;
  steps: BookingStepInfoProps[];
  previousPath: string;
};

const initialState: Props = {
  bookingData: {
    identityType: undefined,
    phoneDetails: null,
    packageBundleDetails: null,
    buildBundleDetails: null,
    buildBundleMinimumRequirement: {
      dataMb: 0,
      onNetVoice: 0,
      offNetVoice: 0,
      smsOffNet: 0,
    },
    accountDetails: null,
    bookingDetails: null,
    billDetails: null,
    buildBundleIntervalId: 18,
    //promotion
    promotionDetails: null,
  },
  bookingStatus: {
    identityType: false,
    phoneDetails: false,
    packageBundleDetails: false,
    buildBundleDetails: false,
    accountDetails: false,
    bookingDetails: false,
    billDetails: false,
  },
  totalSteps: 5,
  stepIndex: 0,
  steps: [
    // don't set order in all. As we need only in these fields to use in "confirm-number" page
    // if the number is not selected but package selected, we will use this order to mutate the steps
    {
      target: "#next-step-for-phone-number-selection",
      content: "Choose your favorite number and continue with the booking",
      order: 1,
      stepTitle: "Select your phone number",
      nextStepTitle: "Select your package",
    },
    {
      target: "#next-step-for-package-bundle-selection",
      content: "Select the package that best meets your requirements.",
      order: 2,
      stepTitle: "Select your package",
      nextStepTitle: "Verify number",
    },
    {
      target: "#next-step-for-account-details",
      content: "Check the number to proceed.",
      stepTitle: "Verify number",
      nextStepTitle: "Account Details",
    },
    {
      target: "#next-step-for-checkout",
      content: "Provide your account details and proceed with the booking.",
      stepTitle: "Account Details",
      nextStepTitle: "Payment Details",
    },
    {
      target: "#last-step-for-payment",
      content:
        "Complete the process by entering your payment details and making the payment to secure your booking.",
      stepTitle: "Payment Details",
    },
  ],
  previousPath: "",
};

export const allowedPathsForBooking = [
  RouteConstant.SELECT_SERVICE,
  RouteConstant.PRODUCTS,
  RouteConstant.PRODUCT_BUILD_BUNDLE,
  RouteConstant.PRODUCT_BUNDLE_PLANS,
  RouteConstant.PRODUCT_PREMIUM_PLANS,
  RouteConstant.PRODUCT_TOURIST_PLANS,
  RouteConstant.CONFIRM_NUMBER,
  RouteConstant.CREATE_ACCOUNT,
  RouteConstant.CHECKOUT,
  RouteConstant.ORDER_STATUS,
];

const phoneBookingSlice = createSlice({
  name: "phoneBooking",
  initialState,
  reducers: {
    DispatchIdentityType(state, action: PayloadAction<IdentityTypeProps>) {
      if (action?.payload) {
        state.bookingData.identityType = action.payload;
        state.bookingStatus.identityType = true;
      } else {
        state.bookingData.identityType = undefined;
        state.bookingStatus.identityType = false;
      }
    },
    DispatchPhoneDetails(
      state,
      action: PayloadAction<PhoneDetailsProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.phoneDetails = action.payload;
        state.bookingStatus.phoneDetails = true;
      } else {
        state.bookingData.phoneDetails = null;
        state.bookingStatus.phoneDetails = false;
      }
    },
    DispatchPackageBundleDetails(
      state,
      action: PayloadAction<PackageBundleDetailsProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.packageBundleDetails = action.payload;
        state.bookingStatus.packageBundleDetails = true;

        // Make Build bundle to initial as only one type is allowed
        state.bookingData.buildBundleDetails = null;
        state.bookingStatus.buildBundleDetails = false;
      } else {
        state.bookingData.packageBundleDetails = null;
        state.bookingStatus.packageBundleDetails = false;
      }
    },
    DispatchBuildBundleDetails(
      state,
      action: PayloadAction<BuildBundleProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.buildBundleDetails = action.payload;
        state.bookingStatus.buildBundleDetails = true;

        // Make Package bundle to initial as only one type is allowed
        state.bookingData.packageBundleDetails = null;
        state.bookingStatus.packageBundleDetails = false;
      } else {
        state.bookingData.buildBundleDetails = null;
        state.bookingStatus.buildBundleDetails = false;
      }
    },
    DispatchBuildBundleIntervalId(
      state,
      action: PayloadAction<BuildBundleInterval>
    ) {
      if (action?.payload) {
        state.bookingData.buildBundleIntervalId = action?.payload;
      }
    },
    DispatchBuildBundleMinimumRequirement(
      state,
      action: PayloadAction<Partial<BuildBundleProps> | null>
    ) {
      if (action?.payload) {
        const { dataMb, onNetVoice, offNetVoice, smsOffNet } = action.payload;

        // Update only the properties that are present in the payload
        if (dataMb !== undefined) {
          state.bookingData.buildBundleMinimumRequirement.dataMb = dataMb;
        }
        if (onNetVoice !== undefined) {
          state.bookingData.buildBundleMinimumRequirement.onNetVoice =
            onNetVoice;
        }
        if (offNetVoice !== undefined) {
          state.bookingData.buildBundleMinimumRequirement.offNetVoice =
            offNetVoice;
        }
        if (smsOffNet !== undefined) {
          state.bookingData.buildBundleMinimumRequirement.smsOffNet = smsOffNet;
        }
      } else {
        state.bookingData.buildBundleMinimumRequirement =
          initialState.bookingData.buildBundleMinimumRequirement;
      }
    },

    DispatchAccountDetails(
      state,
      action: PayloadAction<AccountDetailsProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.accountDetails = action.payload;
        state.bookingStatus.accountDetails = true;
      } else {
        state.bookingData.accountDetails = null;
        state.bookingStatus.accountDetails = false;
      }
    },
    DispatchBookingDetails(
      state,
      action: PayloadAction<BookingDetailsProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.bookingDetails = action.payload;
        state.bookingStatus.bookingDetails = true;
      } else {
        state.bookingData.bookingDetails = null;
        state.bookingStatus.bookingDetails = false;
      }
    },
    DispatchBillDetails(state, action: PayloadAction<BillDetailsProps | null>) {
      if (action?.payload) {
        state.bookingData.billDetails = action.payload;
        state.bookingStatus.billDetails = true;
      } else {
        state.bookingData.billDetails = null;
        state.bookingStatus.billDetails = false;
      }
    },
    DispatchPromotionDetails(
      state,
      action: PayloadAction<PromotionDetailsProps | null>
    ) {
      if (action?.payload) {
        state.bookingData.promotionDetails = action.payload;
      } else {
        state.bookingData.promotionDetails = null;
      }
    },
    DispatchPromotionChargesDetails(
      state,
      action: PayloadAction<PromotionDetailsProps["charges"] | null>
    ) {
      if (state.bookingData.promotionDetails) {
        if (action?.payload) {
          state.bookingData.promotionDetails = {
            ...state.bookingData.promotionDetails,
            charges: action.payload,
          };
        } else {
          state.bookingData.promotionDetails = {
            ...state.bookingData.promotionDetails,
            charges: null,
          };
        }
      }
    },
    clearPhoneBookingData(state) {
      // Don't set initial value of Identity Type
      // as it causes side effects, on every bundle page ask to select identity type
      state.bookingData.phoneDetails = null;
      state.bookingData.packageBundleDetails = null;
      state.bookingData.buildBundleDetails = null;
      state.bookingData.accountDetails = null;
      state.bookingData.bookingDetails = null;
      state.bookingData.billDetails = null;
      state.bookingData.promotionDetails = null;

      state.bookingStatus.phoneDetails = false;
      state.bookingStatus.packageBundleDetails = false;
      state.bookingStatus.buildBundleDetails = false;
      state.bookingStatus.accountDetails = false;
      state.bookingStatus.bookingDetails = false;
      state.bookingStatus.billDetails = false;

      state.stepIndex = 0;
    },
    DispatchBookingStep(state, action: PayloadAction<number>) {
      state.stepIndex = action.payload;
    },
    DispatchSetPreviousPath(state, action: PayloadAction<string>) {
      if (action?.payload) {
        state.previousPath = action.payload;
      } else {
        state.previousPath = "";
      }
    },
  },
});

export const {
  DispatchIdentityType,
  DispatchPhoneDetails,
  DispatchPackageBundleDetails,
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchAccountDetails,
  DispatchBookingDetails,
  DispatchBillDetails,
  DispatchPromotionDetails,
  DispatchPromotionChargesDetails,
  clearPhoneBookingData,
  DispatchBookingStep,
  DispatchSetPreviousPath,
} = phoneBookingSlice.actions;

export default phoneBookingSlice.reducer;
