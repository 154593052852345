import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ForgotPasswordLayout from "component/auth/forgot-password";
import MainContainer from "parts/mainContainer";

const ForgotPassword = () => {
  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6}>
                <Box
                  p={{ xs: 3, sm: 5 }}
                  sx={{
                    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.15)",
                    borderRadius: 5,
                  }}
                >
                  <ForgotPasswordLayout />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default ForgotPassword;
