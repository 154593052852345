const theAgreement = `<h4><strong>The Agreement</strong></h4>
<p><span style="font-weight: 400;">These are the terms and conditions upon which you may use the Talkio Mobile Services (as defined herein) provided by Talkio Mobile (&ldquo;Conditions of Use&rdquo;) the acceptance of which constitutes a binding contract between yourself and Talkio Mobile. When you register as a subscriber by signing the Registration Form annexed hereto, you agree to the retention of your personal details (Know Your Customer details) provided on the registration form and to abide by these Conditions of Use. You therefore need to read and fully understand these Conditions of Use and if you do not agree with them, you must not proceed to register for and/or use Talkio Mobile Services.</span></p>`;

const definitions = `<h4><strong>Definitions</strong></h4>
<p><span style="font-weight: 400;">The following definitions relate to these Conditions of Use:</span></p>
<ul>
<li><strong>&ldquo;Agent(s)&rdquo;</strong><span style="font-weight: 400;"> means person(s) registered by Talkio Mobile to provide Talkio Mobile Services, details of which may be obtained from Talkio Mobile Service Centers.</span></li>
<li><strong>&ldquo;Agreement&rdquo;</strong><span style="font-weight: 400;"> means these Conditions of Use together with the Registration Form.</span></li>
<li><strong>&ldquo;Application(s) or Application software&rdquo;</strong><span style="font-weight: 400;"> means a program or a collection of programs designed to help customers perform specific tasks off the Talkio Mobile system as downloaded onto a mobile device by the customer.</span></li>
<li><strong>&ldquo;Authority(ies)&rdquo;</strong><span style="font-weight: 400;"> means the relevant regulatory bodies as the context may require.</span></li>
<li><strong>&ldquo;Charges&rdquo;</strong><span style="font-weight: 400;"> means the Tariffs and other charges payable under this Agreement for the Talkio Mobile Services.</span></li>
<li><strong>&ldquo;Conditions of Use&rdquo;</strong><span style="font-weight: 400;"> means these terms and conditions as may be varied by us from time to time.</span></li>
<li><strong>&ldquo;Credit Balance&rdquo;</strong><span style="font-weight: 400;"> means the amount of Mobile Money or airtime from time to time standing to the credit of your Talkio Mobile Account.</span></li>
<li><strong>&ldquo;Credit&rdquo;</strong><span style="font-weight: 400;"> means the movement of funds into your Talkio Mobile Account.</span></li>
<li><strong>&ldquo;Credit Transaction&rdquo;</strong><span style="font-weight: 400;"> means any transaction which results in your Account being credited with Mobile Money or Airtime as verified by Talkio Mobile.</span></li>
<li><strong>&ldquo;Customer&rdquo;</strong><span style="font-weight: 400;"> means a subscriber (you) and every other person in whose name an Account for the Talkio Mobile Services is registered.</span></li>
<li><strong>&ldquo;Customer Care Centre&rdquo;</strong><span style="font-weight: 400;"> means Talkio Mobile Customer Care Centre situated at locations advertised, or any regional Customer Care Centre.</span></li>
<li><strong>&ldquo;Debit&rdquo;</strong><span style="font-weight: 400;"> means the movement of funds or airtime out of your Talkio Mobile Account.</span></li>
<li><strong>&ldquo;Debit Transaction&rdquo;</strong><span style="font-weight: 400;"> means any transaction which results in a Debit from your Talkio Mobile Account as verified by Talkio Mobile on the Talkio Mobile System.</span></li>
<li><strong>&ldquo;Designated Payee&rdquo;</strong><span style="font-weight: 400;"> means any person (including yourself, another customer, an Agent or Authorized Retailer) who is designated by you by SMS or through the App, to be the recipient of funds / airtime from your Account.</span></li>
<li><strong>&ldquo;Funds&rdquo;</strong><span style="font-weight: 400;"> means Mobile Money.</span></li>
<li><strong>&ldquo;Goods and Services&rdquo;</strong><span style="font-weight: 400;"> means such goods and services as may be purchased from Authorised Retailers using the Talkio Mobile System.</span></li>
<li><strong>&ldquo;ID Number&rdquo;</strong><span style="font-weight: 400;"> means the number associated with the form of Government/ Regulator approved identification provided.</span></li>
<li><strong>&ldquo;Mobile Equipment&rdquo;</strong><span style="font-weight: 400;"> means your Mobile Phone and SIM Card or other equipment which when used together allows access to Talkio Mobile Services and, in each case, is approved for use by the relevant authority.</span></li>
<li><strong>&ldquo;Mobile Money&rdquo;</strong><span style="font-weight: 400;"> means the electronic money issued by Talkio Mobile and representing an entitlement to an equivalent amount of cash monies held by Us in respect of the purchase of such electronic value.</span></li>
<li><strong>&ldquo;Mobile Phone&rdquo;</strong><span style="font-weight: 400;"> means your mobile phone handset.</span></li>
<li><strong>&ldquo;Retailer&rdquo;</strong><span style="font-weight: 400;"> refers to a Merchant who accepts Mobile Money as payment for Goods and Services.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Talkio Mobile Account or Account&rdquo;</strong><span style="font-weight: 400;"> means an account activated in your name on the Talkio Mobile System upon completion of the registration, being the record maintained by us of the amount of Mobile Money or airtime from time to time held by you and represented by an equivalent amount of cash held by Us on your behalf.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Talkio Mobile Services&rdquo;</strong><span style="font-weight: 400;"> means all the products and services provided by Talkio Mobile to its customers including the issuance and redemption of funds and the transfer of funds between Customers on the basis of Transfer Instructions including the recording of all Transactions, verifying and confirming all Transactions concluded and updating Customer Account records.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Talkio Mobile System&rdquo;</strong><span style="font-weight: 400;"> means the proprietary cellular phone money transfer service which is marketed, managed, and operated exclusively by Talkio Mobile.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Talkio Mobile Website&rdquo;</strong><span style="font-weight: 400;"> refers to the website address</span><a href="http://www.talkio.com"> <span style="font-weight: 400;">www.talkio.com</span></a><span style="font-weight: 400;">.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Talkio Mobile&rdquo;</strong><span style="font-weight: 400;"> refers to the GSM Mobile Network Operator Talkio Mobile.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;MSISDN&rdquo;</strong><span style="font-weight: 400;"> means Mobile Station International Subscriber Directory Number issued to you with the SIM Card and corresponding identity number and PUK for accessing the Talkio Mobile network.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Network&rdquo;</strong><span style="font-weight: 400;"> means the Global System for Mobile telecommunication (&ldquo;GSM&rdquo;) system operated by Talkio Mobile.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Outlet Operator&rdquo;</strong><span style="font-weight: 400;"> means the assistant dealing with you at an Outlet.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Outlet&rdquo;</strong><span style="font-weight: 400;"> means any shop, unit or other retail premises operated by an Agent.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;PIN&rdquo;</strong><span style="font-weight: 400;"> means your personal identification number, being the secret code you choose to access and operate your Account.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Pre-paid Airtime&rdquo;</strong><span style="font-weight: 400;"> means mobile phone calling credit on Talkio Mobile&rsquo;s Network.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Registration Form&rdquo;</strong><span style="font-weight: 400;"> means the registration form containing registration details and acceptance of these conditions of use by you in the form annexed hereto.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;SIM or SIM Card&rdquo;</strong><span style="font-weight: 400;"> means the subscriber identity module which when used with the appropriate Mobile Equipment enables you to use the Talkio Mobile Services.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;SMS&rdquo;</strong><span style="font-weight: 400;"> means a short message service consisting of a text message transmitted from one Mobile Phone to another.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Start Key&rdquo;</strong><span style="font-weight: 400;"> means the one-time PIN sent to you on registration for the purpose of activating your Talkio Mobile Account.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Tariffs&rdquo;</strong><span style="font-weight: 400;"> means actual charges for use of Talkio Mobile Services as published on the Talkio Mobile Website.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Tariff Guide&rdquo;</strong><span style="font-weight: 400;"> means a catalogue published for the Tariffs payable for Talkio Mobile Services, as updated from time to time.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Transactions&rdquo;</strong><span style="font-weight: 400;"> means any of the transactions specified under these terms and conditions such as mobile money transaction, data usage, voice call, SMS, etc.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Transfer Instructions&rdquo;</strong><span style="font-weight: 400;"> means instructions given via SMS or the Application for the transfer of funds from one Customer to another.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Trustee&rdquo;</strong><span style="font-weight: 400;"> means Talkio Mobile which holds the aggregate of all Payments and sums equivalent to all transfers of Mobile Money into your Talkio Mobile Account from other Customers in trust for you in the Trustee Account.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;Trustee Account&rdquo;</strong><span style="font-weight: 400;"> means the Bank Account maintained by the Trustee into which all Payments are made and held by the Trustee on behalf of Customers.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;User Manual&rdquo;</strong><span style="font-weight: 400;"> means a document describing the Talkio Mobile System and its use.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;</strong><span style="font-weight: 400;"> means Talkio Mobile and (where applicable) Talkio Mobile as the Trustee.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>&ldquo;you&rdquo; or &ldquo;your&rdquo;</strong><span style="font-weight: 400;"> means the Customer (subscriber).</span></li>
</ul>`;

const applicationForAccount = `<h4><strong>Application for a Talkio Mobile Account</strong></h4>
<ol start="1">
    <li><strong>Application for a Talkio Mobile Account</strong>
        <ol>
            <li>You may register for Talkio Mobile Services at any Talkio Mobile authorized SIM Selling outlet or Agent.</li>
            <li>For registering as a Customer, you will be required to provide the following information for inclusion in the Registration Form to be signed by you: - Presentation of the original version of your Original National Identity Card (bearing your National Identity Number) for citizens or Original version of the Passport for non-citizens, Refugee Identity Card or Letters of Attestation for refugees and valid asylum seeker&rsquo;s certificate. - Your full name - Your physical address - Your date of birth - Your Nationality - Your mobile number - Your fingerprint - Your visa and work permit (for non-citizens) - Passport size photograph.</li>
            <li>All information provided must be complete and accurate in all respects.</li>
            <li>We may decline your application at our sole discretion.</li>
            <li>We may refuse to open a Talkio Mobile Account for you if we are not satisfied with proof of your identity.</li>
            <li>Upon registration with Talkio Mobile and upon activation of your Talkio Mobile Account by the input of the Start Key you will upon choosing a secret PIN, be entitled to use Talkio Mobile Services with immediate effect.</li>
            <li>If the registration details provided change, you are required to notify us within ten working days from the date of change.</li>
        </ol>
    </li>
</ol>`;

const talkioMobileService = `<h4><strong>Talkio Mobile Services</strong></h4>
<ol start="2">
    <li><strong>Talkio Mobile Services</strong>
        <ol>
            <li>Talkio Mobile Services are made available to you subject to these Conditions of Use: - 
                <ol>
                    <li>Although we will try to ensure that you are able to make full use of the Network to access Talkio Mobile Services within the coverage area, we do not guarantee that the Talkio Mobile Services will be available at all times and we will not be responsible or liable for any loss whatsoever or howsoever arising as a consequence of any non-availability of Talkio Mobile Services. Talkio Mobile Services are not fault-free and factors including (but not limited to) acts of God, geographical topography, weather conditions, planned maintenance or rectification work on the Talkio Mobile Network may interfere adversely with the quality and provision of Talkio Mobile Services.</li>
                    <li>In the event of damage to, loss, or theft of the SIM Card, you are obliged to inform us immediately of such damage, loss or theft. We will then disable such damaged, lost or stolen SIM Card so as to prevent further use of the Talkio Mobile Services until the same has been replaced. A replacement fee will be applicable and you will be responsible for all Charges and applicable fees.</li>
                    <li>You are responsible for the payment of all applicable Charges for the use of Talkio Mobile Services and any other costs incurred in accessing Talkio Mobile Services through the Network.</li>
                    <li>You shall not use and shall ensure that no other person uses the Talkio Mobile Services:
                        <ol>
                            <li>for storing, reproducing, transmitting, communicating or receiving any material which is offensive, abusive, indecent, obscene or menacing, a nuisance or a hoax, defamatory, or otherwise unlawful.</li>
                            <li>to cause annoyance, inconvenience or needless anxiety to any person or to commit a fraud or any other criminal offence.</li>
                            <li>contrary to any other instructions that we may give to you from time to time.</li>
                        </ol>
                    </li>
                    <li>We shall be entitled to terminate the Talkio Mobile Services immediately and without notice to you if we determine in our sole discretion that you have used the Talkio Mobile Services for any unauthorized purpose.</li>
                    <li>You must comply with any instructions that we may give you from time to time about the Talkio Mobile Services.</li>
                </ol>
            </li>
        </ol>
    </li>
</ol>`;

const useOfTalkioMobileService = `<h4><strong>Use of Talkio Mobile Services</strong></h4>
<ol start="3">
    <li><strong>Use of Talkio Mobile Services</strong>
        <ol>
            <li>Transactions may be effected using Talkio Mobile System and Talkio Mobile Services only:
                <ol>
                    <li>By you.</li>
                    <li>Using your Mobile Equipment and your PIN.</li>
                    <li>To or from any Mobile Phone which is not barred from receiving SMS.</li>
                </ol>
            </li>
            <li>The Talkio Mobile Services may only be used for lawful transactions.</li>
            <li>You are responsible for the safekeeping and proper use of your Mobile Equipment, and for keeping your PIN and Account details secret. You must take all reasonable precautions to prevent the loss, theft, or misuse of the Mobile Equipment and your Account. We will not be responsible for any loss arising from the misuse of your PIN and Account.</li>
            <li>Your Talkio Mobile Account will be credited when you purchase Mobile Money using cash at our Agents.</li>
            <li>Your Talkio Mobile Account will be debited when you make payments from your Account for purchases from designated merchants.</li>
            <li>You may also make transfers from your Account to other Customers, other banks, and designated payees using your PIN and Mobile Equipment or Application.</li>
            <li>The maximum and minimum transaction limits and maximum balance limits for your Talkio Mobile Account are set and communicated by Talkio Mobile. These limits are subject to change without notice.</li>
            <li>You are required to comply with all applicable laws and regulations of the jurisdictions from which you use the Talkio Mobile Services.</span></li>
        </ol>
    </li>
</ol>`;

const transaction = `<h4><strong>Transactions</strong></h4>
<ol start="4">
    <li><strong>Transactions</strong>
        <ol>
            <li> You are responsible for verifying the amount of any transaction and all details contained in any transaction before confirming the transaction.</li>
            <li> Once you have confirmed a transaction, the transaction is final and cannot be reversed.</li>
            <li> All transactions will be subject to our standard fees and charges applicable at the time the transaction is made.</li>
            <li> You may request a mini-statement or full statement of your Account from time to time. We may charge a fee for providing such statements.</li>
            <li> You must notify us of any transaction errors, discrepancies, or unauthorized transactions within 30 days of the date of the transaction. We will investigate and may rectify any errors, but will not be responsible for any loss or damage you suffer if you do not notify us within this period.</li>
            <li> If we determine that an error has been made, we will rectify the error as soon as possible and credit or debit your Account as necessary.</li>
            <li> We reserve the right to reverse any transaction which is subject to a dispute, or if we determine that the transaction was made fraudulently or in error.</li>
        </ol>
    </li>
</ol>`;

const securityPrivacy = `<h4><strong>Security and Privacy</strong></h4>
<ol start="5">
    <li><strong>Security and Privacy</strong>
        <ol>
            <li> You must keep your PIN and any other security details secret and secure at all times and must not disclose them to any third party.</li>
            <li> If you suspect that your PIN or other security details have been compromised, you must notify us immediately and change your PIN.</li>
            <li> We will take all reasonable precautions to keep your Account information confidential and secure. However, we will not be responsible for any unauthorized access to your Account or for any loss or damage you suffer as a result of such unauthorized access.</li>
            <li> We may disclose your Account information to third parties:
                <ol>
                    <li> If we are required to do so by law or by any regulatory authority.</li>
                    <li> To facilitate the provision of Talkio Mobile Services.</li>
                    <li> With your consent.</li>
                </ol>
            <li> We will not sell or rent your personal information to third parties for marketing purposes without your explicit consent.
            </li>
        </ol>
    </li>
</ol>`;

const terminationSuspension = `<h4><strong>Termination and Suspension</strong></h4>
<ol start="6">
    <li><strong>Termination and Suspension</strong>
        <ol>
            <li> We may terminate or suspend your Talkio Mobile Services and close your Account at any time and for any reason, including but not limited to:
                <ol>
                    <li> If you breach these Conditions of Use.</li>
                    <li> If we suspect that your Account is being used for illegal or fraudulent purposes.</li>
                    <li> If we are required to do so by law or by any regulatory authority.</li>
                    <li> If we determine that your Account has been inactive for a period of six months or more.</li>
                </ol>
            </li>
            <li> You may terminate your Talkio Mobile Services and close your Account at any time by giving us notice in writing.</li>
            <li> Upon termination of your Account, any remaining balance in your Account will be refunded to you after deducting any applicable fees and charges.</li>
        </ol>
    </li>
</ol>`;

const general = `<h4><strong>General</strong></h4>
<ol start="7">
    <li><strong>General</strong>
        <ol>
            <li> These Conditions of Use constitute the entire agreement between you and us in relation to Talkio Mobile Services and supersede any previous agreements or understandings.</li>
            <li> We may vary these Conditions of Use at any time by giving you notice. Your continued use of Talkio Mobile Services after such notice will constitute your acceptance of the changes.</li>
            <li> If any provision of these Conditions of Use is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.</li>
            <li> Our failure to enforce any right or provision in these Conditions of Use will not constitute a waiver of such right or provision.</li>
            <li> You may not transfer or assign any of your rights or obligations under these Conditions of Use without our prior written consent.</li>
            <li> These Conditions of Use are governed by the laws of the jurisdiction in which Talkio Mobile is registered. Any disputes arising out of or in connection with these Conditions of Use will be subject to the exclusive jurisdiction of the courts of that jurisdiction.</li>
        </ol>
    </li>
</ol>`;

export const termsConditionsData = [
  {
    title: "The Agreement",
    content: theAgreement,
  },
  {
    title: "Definitions",
    content: definitions,
  },
  {
    title: "Application for a Talkio Mobile Account",
    content: applicationForAccount,
  },
  {
    title: "Talkio Mobile Services",
    content: talkioMobileService,
  },
  {
    title: "Use of Talkio Mobile Services",
    content: useOfTalkioMobileService,
  },
  {
    title: "Transactions",
    content: transaction,
  },
  {
    title: "Security and Privacy",
    content: securityPrivacy,
  },
  {
    title: "Termination and Suspension",
    content: terminationSuspension,
  },
  {
    title: "General",
    content: general,
  },
];
