import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import PlanCard from "component/_common/plans/planCard";
import { filterNonNullValues } from "functions/helper";
import { PackageBundleGetAll } from "functions/http-requests/packageBundle";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toggleLoading } from "state/slice/loading";
import {
  DispatchBookingStep,
  DispatchPackageBundleDetails,
  DispatchSetPreviousPath,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  GetPackageBundleProps,
  PackageCategoryProps,
  PackageForProps,
} from "_interfaces/http-requests/packageBundle";
import { PackageBundleDetailsProps } from "_interfaces/phoneBooking";

interface FiltersProps {
  packageFor?: PackageForProps | "";
  category: PackageCategoryProps | "";
}

const BuildBundlePlans = () => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const paginationRef = useRef<HTMLDivElement | null>(null);

  const { bookingData, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [state, setState] = useState<PackageBundleDetailsProps[]>([]);

  const [filters, setFilters] = useState<FiltersProps>({
    packageFor: "premium",
    category: "",
  });

  const handleFilter = (name: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [name]: prev?.[name as keyof FiltersProps] === value ? "" : value,
    }));
  };

  const handleSelectPlan = (item: PackageBundleDetailsProps) => {
    if (item?._id) {
      Dispatch(DispatchPackageBundleDetails(item));
      Dispatch(DispatchBookingStep(stepIndex + 1));
      Dispatch(DispatchSetPreviousPath(pathname));

      const couponToken = searchParams.get("ct"); // Get couponToken from URL
      if (couponToken) {
        navigate(`${RouteConstant.CONFIRM_NUMBER}?ct=${couponToken}`);
      } else {
        navigate(RouteConstant.CONFIRM_NUMBER);
      }
    }
  };

  useEffect(() => {
    let fetchPlans: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      let PAYLOAD_DATA: GetPackageBundleProps["DATA"] = {
        pageNumber: 1,
        pageSize: 100,
        packageFor: filters?.packageFor || undefined,
        category:
          filters?.packageFor === "tourist"
            ? undefined
            : filters?.category || undefined,
      };
      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      PackageBundleGetAll({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const DATA = res?.data?.data;
          if (DATA?.length) {
            const categoryOrder = ["bronze", "silver", "gold"];
            setState(
              DATA?.map((item: any) => ({
                _id: item?._id || "",
                retailPrice: item?.retailPrice || "",
                // packageName: item?.packageName || "",
                category: item?.category || "",
                packageType: item?.packageType || "",
                validity: item?.validity || "",
                allNetMinutes: item?.allNetMinutes || "",
                onNetMinutes: item?.onNetMinutes || "",
                offNetMinutes: item?.offNetMinutes || "",
                allNetSMS: item?.allNetSMS || "",
                onNetSMS: item?.onNetSMS || "",
                offNetSMS: item?.offNetSMS || "",
                internationalCallMinutes: item?.internationalCallMinutes || "",
                packageFor: item?.packageFor || "",
                packageID: item?.packageID || "",
                dataPerDay: item?.dataPerDay || null,
                dataVolume: item?.dataVolume || null,
              }))?.sort((a: any, b: any) => {
                const indexA =
                  categoryOrder.indexOf(a.category) !== -1
                    ? categoryOrder.indexOf(a.category)
                    : categoryOrder.length;
                const indexB =
                  categoryOrder.indexOf(b.category) !== -1
                    ? categoryOrder.indexOf(b.category)
                    : categoryOrder.length;
                return indexA - indexB;
              })
            );
          } else {
            setState([]);
          }
        })
        .catch(() => {
          setState([]);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchPlans();

    return () => {
      fetchPlans = null;
    };
  }, [filters]);

  const packageForListItems = useMemo(
    () => [
      {
        id: "premium",
        label: "Premium",
      },
      {
        id: "tourist",
        label: "Tourist",
      },
    ],
    [bookingData]
  );

  const categoryListItems = [
    {
      id: "bronze",
      label: "Bronze",
    },
    {
      id: "silver",
      label: "Silver",
    },
    {
      id: "gold",
      label: "Gold",
    },
  ];

  return (
    <>
      <Box maxWidth={{ xs: "100%", sm: 600 }} margin="auto" textAlign="center">
        <Box py={2}>
          <Stack
            direction="row"
            flexWrap="wrap"
            spacing={1}
            justifyContent="center"
          >
            {packageForListItems?.map((item, i) => (
              <Button
                key={i}
                id={item?.id}
                variant={
                  filters?.packageFor === item?.id ? "contained" : "outlined"
                }
                onClick={() => handleFilter("packageFor", item.id)}
                color="inherit"
                // disabled={item?.disabled}
                sx={{
                  textTransform: "capitalize",
                  border: `1px solid ${alpha(palette.secondary.main, 0.3)}`,
                  fontSize: 12,
                  mb: `8px !important`,
                }}
              >
                {item?.label}
              </Button>
            ))}
          </Stack>
        </Box>

        <Box id="next-step-for-package-bundle-selection">
          <SectionTitle
            title=" Choose Your Plan"
            subTitle="Experience the best that Talkio has to offer"
          />
        </Box>
        <CustomJoyRide
          steps={steps}
          stepIndex={stepIndex}
          run={!!state?.length}
        />
      </Box>
      {filters?.packageFor === "premium" ? (
        <Box pt={2}>
          <Stack
            direction="row"
            flexWrap="wrap"
            spacing={1}
            justifyContent="center"
          >
            {categoryListItems?.map((item, i) => (
              <Button
                key={i}
                id={item?.id}
                variant={
                  filters?.category === item?.id ? "contained" : "outlined"
                }
                onClick={() => handleFilter("category", item.id)}
                color="inherit"
                sx={{
                  textTransform: "capitalize",
                  border: `1px solid ${alpha(palette.secondary.main, 0.3)}`,
                  fontSize: 12,
                  mb: `8px !important`,
                }}
              >
                {item?.label}
              </Button>
            ))}
          </Stack>
        </Box>
      ) : null}
      <Box position="relative">
        {state?.length ? (
          <>
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
                el: ".swiper-pagination",
              }}
              modules={[Pagination]}
              className="custom-pagination-swiper"
              centerInsufficientSlides
              freeMode
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                600: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                900: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1100: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
              }}
              style={{
                paddingTop: "24px",
                paddingBottom: "50px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
              onSlideChange={() => {
                const activeBullet = document.querySelector(
                  ".swiper-pagination-bullet-active"
                ) as HTMLElement | null;

                if (paginationRef.current && activeBullet) {
                  paginationRef.current.scrollLeft =
                    activeBullet.offsetLeft -
                    paginationRef.current.offsetWidth / 2 +
                    activeBullet.offsetWidth / 2;
                }
              }}
            >
              {state.map((item: PackageBundleDetailsProps, i: number) => (
                <SwiperSlide key={i}>
                  <PlanCard
                    data={item}
                    handleClick={() => handleSelectPlan(item)}
                    showValidityAsChip={
                      item?.packageFor?.toLowerCase() === "tourist"
                    }
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Box className="swiper-pagination" ref={paginationRef} />
          </>
        ) : (
          <Box pt={3}>
            <Typography fontSize={{ xs: 16, md: 24 }} textAlign="center">
              No Plan Found
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BuildBundlePlans;
