import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Box, Button, Grid, Typography } from "@mui/material";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { FC } from "react";
import { Link } from "react-router-dom";

const MidSection: FC = () => {
  const list = [
    {
      Icon: TouchAppIcon,
      description: `Click the "Join Us" button to choose your desired number.`,
    },
    {
      Icon: CardGiftcardIcon,
      description: `Choose a package from our bundle options or create your own custom bundle.`,
    },
    {
      Icon: CheckCircleIcon,
      description: `Confirm the package and number to proceed with the booking.`,
    },
    {
      Icon: AccountCircleIcon,
      description: `Enter your account details to create your account.`,
    },
    {
      Icon: PaymentIcon,
      description: `Confirm your details and proceed with the payment to complete your booking.`,
    },
  ];
  return (
    <>
      <Box
        pb={5}
        className="text-white text-center "
        sx={{
          maxWidth: 600,
          margin: "auto",
        }}
      >
        <SectionTitle title="Talkio Mobile process for reserving a phone number" />
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {list?.map(({ Icon, description }, i) => (
          <Grid item xs={6} md={4} lg={2.4} key={i}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Icon style={{ fontSize: "36px", color: "white" }} />

              <Box pt={3}>
                <Typography variant="body2" color="white">
                  {description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12} className="p-center">
          <Link to={RouteConstant.SELECT_SERVICE}>
            <Button
              variant="outlined"
              sx={{
                px: 6,
                color: "#fff",
                borderColor: "#fff",
                "&:hover": {
                  borderColor: "#fff",
                },
              }}
            >
              Join us
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default MidSection;
