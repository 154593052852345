import Box from "@mui/material/Box";
import { PromotionGetAll } from "functions/http-requests/promotion";
import moment from "moment";
import MainContainer from "parts/mainContainer";
import { useEffect, useState } from "react";
import {
  PromotionDataProps,
  showInPageProps,
} from "_interfaces/_common/promotion";
import MainBanner from "./mainBanner";
import NoticeSec from "./noticeSec";
import Offer from "./offer";
import PromotionalBanner from "./promotionalBanner";
import TermsConditions from "./termsConditions";

const PromotionLayout = () => {
  const [promotions, setPromotions] = useState<PromotionDataProps[] | null>(
    null
  );

  // GET PROMOTIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      PromotionGetAll({
        DATA: {
          showInPage: showInPageProps.promotionPage,
        },
        isCancellable: false,
      })
        .then((res) => {
          let DATA: PromotionDataProps[] = res?.data?.data;

          const itemCondition = (item: PromotionDataProps): boolean =>
            item?.showInPage?.includes(showInPageProps.promotionPage) &&
            moment(item?.expiryDate).isAfter(moment()) &&
            item?.isActive;

          if (
            Array.isArray(DATA) &&
            DATA?.length &&
            DATA?.some((item) => itemCondition(item))
          ) {
            DATA = DATA?.filter((item) => itemCondition(item));
            setPromotions(DATA);
          }
        })
        .catch((e) => {});
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <>
      <section>
        <MainContainer>
          <Box pt={5}>
            <MainBanner />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box py={8}>
            <Offer />
          </Box>
        </MainContainer>
      </section>

      {promotions?.some((item) => item?.UI?.theme === 1) ? (
        <section>
          <MainContainer>
            <PromotionalBanner
              data={promotions?.find((item) => item.UI.theme === 1)}
            />
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      <section>
        <MainContainer>
          <NoticeSec />
        </MainContainer>
      </section>

      <section>
        <MainContainer bgColor="#282626">
          <TermsConditions />
        </MainContainer>
      </section>
    </>
  );
};

export default PromotionLayout;
