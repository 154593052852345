const RouteConstant = {
  HOME: "/",
  GET_STARTED: "/get-started",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",

  SELECT_SERVICE: "/BookYourNumber",
  CONFIRM_NUMBER: "/confirm-number",
  CREATE_ACCOUNT: "/create-account",

  CHECKOUT: "/checkout",
  ORDER_STATUS: "/order-status",
  NEAREST_AGENT: "/nearest-agent",

  PROMOTION: "/promotion",

  // HELP *************************
  HELP: "/help",
  FAQ: "/help/faq",
  HOW_TO_PAY: "/help/how-to-pay",

  // Product
  PRODUCTS: "/products",
  PRODUCT_BUILD_BUNDLE: "/products/build-bundle",
  PRODUCT_PREMIUM_PLANS: "/products/premium-plans",
  PRODUCT_TOURIST_PLANS: "/products/tourist-plans",
  PRODUCT_BUNDLE_PLANS: "/products/bundle-plans",

  // AUTH
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgot-password",
  CHANGE_PASSWORD: "/auth/change-password",

  // Logged in pages
  UNAUTHORIZED: "/unauthorized",
  MY_ACCOUNT: "/my-account",
  MY_PROFILE: "/my-account/my-profile",
  MY_WALLET: "/my-account/my-wallet",
  BALANCE_SUMMARY: "/my-account/balance-summary",
  RECHARGE_HISTORY: "/my-account/recharge-history",

  RECHARGE_PLANS: "/recharge/plans",

  APP_DOWNLOAD: "/app-download",
};
export default RouteConstant;
