import React, { SVGProps } from "react";

const PackageIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={props?.width || "40"}
      height={props?.height || "39"}
      viewBox="0 0 40 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3846 39H9.57298C6.19108 39 3.10649 36.4164 2.54904 33.0949L0.0776669 18.4067C-0.312552 16.1186 0.802378 13.1846 2.64198 11.7268L15.5192 1.48547C18.0091 -0.507428 21.9299 -0.488956 24.4384 1.50394L37.3156 11.7268C39.1367 13.1846 40.2516 16.1186 39.8799 18.4067L37.4085 33.0949C36.8511 36.3611 33.7108 39 30.3846 39ZM19.9602 2.79554C18.9753 2.79554 17.9905 3.09095 17.2658 3.66298L4.38862 13.9041C3.32945 14.753 2.60476 16.6353 2.82774 17.9639L5.29917 32.6523C5.63364 34.5898 7.58472 36.2321 9.57298 36.2321H30.3846C32.3729 36.2321 34.324 34.5896 34.6584 32.6336L37.1298 17.9454C37.3528 16.6168 36.6097 14.716 35.5691 13.8857L22.6917 3.66298C21.9484 3.09095 20.9636 2.79554 19.9602 2.79554Z"
        fill="white"
      />
      <path
        d="M23.8564 25.2049C23.5102 25.2049 23.1824 25.0805 22.9091 24.8317C21.1418 23.1793 18.846 23.1793 17.0605 24.8317C16.5139 25.347 15.6574 25.3293 15.129 24.7962C14.6007 24.2631 14.6189 23.4278 15.1655 22.9125C17.9897 20.265 21.9435 20.265 24.7858 22.9125C25.3324 23.4278 25.3504 24.2631 24.8221 24.7962C24.5852 25.0627 24.2208 25.2049 23.8564 25.2049Z"
        fill="white"
      />
      <path
        d="M27.6606 21.3905C27.3165 21.3905 26.9724 21.2603 26.7188 20.9995C25.8676 20.1425 24.9077 19.4531 23.8934 18.9501C21.3578 17.7019 18.5867 17.7019 16.0692 18.9501C15.055 19.4531 14.1132 20.1425 13.2438 20.9995C12.7186 21.5398 11.8492 21.5398 11.324 20.9809C10.7988 20.422 10.8169 19.5462 11.3421 19.0059C12.4107 17.9254 13.6061 17.0685 14.892 16.4351C18.1702 14.8143 21.7925 14.8143 25.0525 16.4351C26.3385 17.0685 27.5338 17.9254 28.6024 19.0059C29.1457 19.5462 29.1457 20.422 28.6205 20.9809C28.3669 21.2417 28.0228 21.3905 27.6606 21.3905Z"
        fill="white"
      />
      <path
        d="M19.9757 29.4878C19.4822 29.4878 19.0078 29.3207 18.6282 29.0033C17.8881 28.3516 17.8881 27.299 18.6282 26.6473C19.3684 25.9956 20.5828 25.9956 21.3229 26.6473C22.063 27.299 22.063 28.3516 21.3229 29.0033C20.9433 29.3207 20.4691 29.4878 19.9757 29.4878Z"
        fill="white"
      />
    </svg>
  );
};

export default PackageIcon;
