import { GET_APP_LATEST_VERSION, SERVICE_URL } from "config/endpoint";
import { HTTP } from "functions/http";

export const GetAPPLatestVersion = () => {
  return HTTP({
    Method: "GET",
    baseURL: SERVICE_URL,
    Url: GET_APP_LATEST_VERSION,
    isCancellable: false,
  });
};
