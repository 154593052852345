import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PlanCardThree from "component/_common/plans/planCardThree";
import { formatPriceWithDelimiter } from "functions/helper";
import { PackageBundleGetAll } from "functions/http-requests/packageBundle";
import RouteConstant from "navigation/constant";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GetPackageBundleProps,
  PackageForProps,
} from "_interfaces/http-requests/packageBundle";

const formatPlanDescription = (item: any) => {
  const dataVolume = item?.dataVolume?.isUnlimited
    ? "Unlimited DATA"
    : item?.dataVolume?.quantity
    ? `${item?.dataVolume?.quantity} ${item?.dataVolume?.dataUnit}`
    : null;

  const onNetMinutes =
    item?.onNetMinutes > 0 ? `${item?.onNetMinutes} Onnet Mins` : null;
  const offNetMinutes =
    item?.offNetMinutes > 0 ? `${item?.offNetMinutes} Offnet Mins` : null;
  const onNetSMS = item?.onNetSMS > 0 ? `${item?.onNetSMS} Onnet SMS` : null;
  const offNetSMS =
    item?.offNetSMS > 0 ? `${item?.offNetSMS} Offnet SMS` : null;
  const intlMins =
    item?.internationalCallMinutes > 0
      ? `${item?.internationalCallMinutes} Intl Mins`
      : null;

  // Collect all the parts except the price
  const details = [
    dataVolume,
    onNetMinutes,
    offNetMinutes,
    onNetSMS,
    offNetSMS,
    intlMins,
  ]
    .filter(Boolean) // Filter out null or undefined values
    .join(" + "); // Join the non-null values with " + "

  // Add price separately, without leading ' + '
  const price = item?.retailPrice
    ? ` @ UGX ${formatPriceWithDelimiter(item?.retailPrice, false)}`
    : "";

  // Concatenate the details and price, ensuring no ' + ' before the price
  return details + price;
};

const ExcitingPlans = () => {
  const navigate = useNavigate();

  const [listItems, setListItems] = useState<
    {
      data: {
        chipLabel: string;
        planName: string;
        list: {
          heading?: string;
          desc?: string;
          component?: React.ReactNode;
        }[];
      };
      handleClick: () => void;
      handleShowMore: () => void;
    }[]
  >([
    {
      data: {
        chipLabel: "Be Premium",
        planName: "Premium",
        list: [],
      },
      handleClick: () => {
        navigate(RouteConstant.PRODUCT_PREMIUM_PLANS);
      },
      handleShowMore: () => {},
    },
    {
      data: {
        chipLabel: "Fit for travel",
        planName: "Tourist",
        list: [],
      },
      handleClick: () => {
        navigate(RouteConstant.PRODUCT_TOURIST_PLANS);
      },
      handleShowMore: () => {},
    },
    {
      data: {
        chipLabel: "Get what your need",
        planName: "Bundles",
        list: [
          {
            heading: "Freedom Bundles",
            component: (
              <Box fontSize={14}>
                <Link
                  to={`${RouteConstant.PRODUCT_BUNDLE_PLANS}?type=freedom`}
                  className="fc-primary-var"
                >
                  Learn More
                </Link>
              </Box>
            ),
          },
          {
            heading: "Weekly Bundles",
            component: (
              <Box fontSize={14}>
                <Link
                  to={`${RouteConstant.PRODUCT_BUNDLE_PLANS}?type=weekly`}
                  className="fc-primary-var"
                >
                  Learn More
                </Link>
              </Box>
            ),
          },
          {
            heading: "Monthly Bundles",
            component: (
              <Box fontSize={14}>
                <Link
                  to={`${RouteConstant.PRODUCT_BUNDLE_PLANS}?type=monthly`}
                  className="fc-primary-var"
                >
                  Learn More
                </Link>
              </Box>
            ),
          },
          {
            heading: "Build Your Bundle",
            component: (
              <Box fontSize={14}>
                <Link
                  to={RouteConstant.PRODUCT_BUILD_BUNDLE}
                  className="fc-primary-var"
                >
                  Learn More
                </Link>
              </Box>
            ),
          },
        ],
      },
      handleClick: () => {
        navigate(RouteConstant.PRODUCTS);
      },
      handleShowMore: () => {},
    },
  ]);

  // Reusable function to fetch and set plans
  const fetchPlansByType = (
    packageFor: PackageForProps,
    sortingKey: string,
    sortingOrder: string[],
    mapHeading: (item: any) => string
  ) => {
    const PAYLOAD_DATA: GetPackageBundleProps["DATA"] = {
      pageNumber: 1,
      pageSize: 100,
      packageFor,
    };

    PackageBundleGetAll({
      DATA: PAYLOAD_DATA,
      isCancellable: false,
    })
      .then((res) => {
        const DATA = res?.data?.data;
        if (DATA?.length) {
          const filteredData: any[] = [];

          // Collect the first item of each sorting category (category or validity)
          sortingOrder.forEach((order) => {
            const firstItem = DATA.find(
              (item: any) => item?.[sortingKey]?.toString() === order
            );
            if (firstItem) filteredData.push(firstItem);
          });

          setListItems((prev) =>
            prev?.map((item) => {
              if (item?.data?.planName?.toLowerCase() === packageFor) {
                return {
                  ...item,
                  data: {
                    ...item.data,
                    list: filteredData
                      ?.sort((a: any, b: any) => {
                        const indexA =
                          sortingOrder.indexOf(a?.[sortingKey]?.toString()) !==
                          -1
                            ? sortingOrder.indexOf(a?.[sortingKey]?.toString())
                            : sortingOrder.length;
                        const indexB =
                          sortingOrder.indexOf(b?.[sortingKey]?.toString()) !==
                          -1
                            ? sortingOrder.indexOf(b?.[sortingKey]?.toString())
                            : sortingOrder.length;
                        return indexA - indexB;
                      })
                      ?.map((item: any) => ({
                        heading: mapHeading(item),
                        desc: item?.description || formatPlanDescription(item),
                      })),
                  },
                };
              }
              return item;
            })
          );
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  // Fetch Premium Plan
  useEffect(() => {
    fetchPlansByType(
      "premium",
      "category",
      ["bronze", "silver", "gold"],
      (item) => item?.category
    );
  }, []);

  // Fetch Tourist Plan
  useEffect(() => {
    fetchPlansByType(
      "tourist",
      "validity",
      ["7", "30", "90"],
      (item) => `${item?.validity?.toString()} Days`
    );
  }, []);

  return (
    <>
      <Typography
        variant="h2"
        fontSize={{ xs: 24, sm: 36, lg: 42 }}
        fontWeight={700}
        className="fc-black"
      >
        Our Exciting{" "}
        <Box component="span" className="fc-primary">
          Plans
        </Box>
      </Typography>
      <Box pt={3}>
        <Grid container spacing={5}>
          {listItems?.map(({ data, handleClick }, i) => (
            <Fragment key={i}>
              {data?.list?.length ? (
                <Grid item xs={12} sm={6} md={4}>
                  <PlanCardThree data={data} handleClick={handleClick} />
                </Grid>
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ExcitingPlans;
