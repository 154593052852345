import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type userState = {
  isLoggedIn: boolean;
  userInfo: {
    signature: string;
    token: string;
    userId: string;
    name: string;
    email: string;
    phone: string;
    role: string;
  };
};

const initialState: userState = {
  isLoggedIn: false,
  userInfo: {
    signature: "",
    token: "",
    userId: "",
    name: "",
    email: "",
    phone: "",
    role: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action?: PayloadAction<any>) => {
      state.isLoggedIn = true;
      if (action?.payload?.signature)
        state.userInfo.signature = action.payload.signature;
      if (action?.payload?.token) state.userInfo.token = action.payload.token;
      if (action?.payload?._id) state.userInfo.userId = action.payload._id;
      if (action?.payload?.name) state.userInfo.name = action.payload.name;
      if (action?.payload?.email) state.userInfo.email = action.payload.email;
      if (action?.payload?.phone) state.userInfo.phone = action.payload.phone;
      if (action?.payload?.role) state.userInfo.role = action.payload.role;
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      state.userInfo = initialState.userInfo;
    },
  },
});

export const { setUser, setLogout } = userSlice.actions;

export default userSlice.reducer;
