import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import XIcon from "@mui/icons-material/X";
import { Stack, StackOwnProps } from "@mui/material";
import TikTokIcon from "component/_common/icons/tiktok";
import React, { Fragment } from "react";

interface Props {
  color?: string;
  justifyContent?: StackOwnProps["justifyContent"];
}

const SocialLinks: React.FC<Props> = ({ color, justifyContent }) => {
  const socialLinks: { link: string; Icon?: React.ElementType }[] = [
    { link: "https://wa.me/message/WUIJB2FCORJYJ1", Icon: WhatsAppIcon },
    {
      link: "https://www.linkedin.com/company/talkiomobile/",
      Icon: LinkedInIcon,
    },
    { link: "https://twitter.com/TalkioMobile", Icon: XIcon },
    { link: "https://instagram.com/talkioMobile", Icon: InstagramIcon },
    { link: "https://facebook.com/TalkioMobile", Icon: FacebookIcon },
    { link: "https://tiktok.com/@talkioMobile", Icon: TikTokIcon },
  ];

  return (
    <Stack
      direction="row"
      justifyContent={justifyContent || "flex-end"}
      spacing={1}
    >
      {socialLinks?.map(({ link, Icon }, i) => (
        <Fragment key={i}>
          {Icon ? (
            <a href={link} target="_blank">
              <Icon
                style={{ color: color || "white", fontSize: 30, width: 30 }}
              />
            </a>
          ) : (
            <></>
          )}
        </Fragment>
      ))}
    </Stack>
  );
};

export default SocialLinks;
