interface Props {
  agreeTerms?: string;
}

const defaultData: Props = {
  agreeTerms: "",
};

export class CheckoutErrorModel {
  agreeTerms?: string;

  constructor(data: Props = defaultData) {
    this.agreeTerms = data.agreeTerms || "";
  }
}
