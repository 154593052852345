interface Props {
  momoPhoneNumber?: string;
  airtelMOMOPhone?: string;
}

const defaultData: Props = {
  momoPhoneNumber: "",
  airtelMOMOPhone: "",
};

export class PaymentMethodErrorModel {
  momoPhoneNumber?: string;
  airtelMOMOPhone?: string;

  constructor(data: Props = defaultData) {
    this.momoPhoneNumber = data.momoPhoneNumber || "";
    this.airtelMOMOPhone = data.airtelMOMOPhone || "";
  }
}
