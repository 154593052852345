import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  PackageTypeProps,
  PackageValidityProps,
} from "_interfaces/http-requests/packageBundle";
import { BundlePlansFiltersProps } from "_interfaces/products/bundle-plans";

interface Props {
  filters: BundlePlansFiltersProps;
  setFilters: React.Dispatch<React.SetStateAction<BundlePlansFiltersProps>>;
}
const AllBundlePlansFilter: React.FC<Props> = ({ filters, setFilters }) => {
  const { palette } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilter = (name: string, value: string, isArray?: boolean) => {
    setFilters((prev) => {
      const currentValue = prev?.[name as keyof BundlePlansFiltersProps];

      const bundleType = searchParams.get("type");
      if (bundleType) {
        searchParams.delete("type");
        setSearchParams(searchParams);
      }

      if (isArray && Array.isArray(currentValue)) {
        if (value === "combo") {
          // If "combo" is selected, set the array to only include "combo"
          return {
            ...prev,
            [name]: ["combo"],
            unlimited: undefined,
          };
        } else {
          // If a value other than "combo" is selected and "combo" is in the array, remove "combo"
          const newValues = currentValue.includes("combo")
            ? currentValue.filter((item) => item !== "combo")
            : currentValue;

          return {
            ...prev,
            [name]: newValues.includes(value as any)
              ? newValues.filter((item) => item !== value)
              : [...newValues, value],
            unlimited: undefined,
          };
        }
      } else {
        return {
          ...prev,
          [name]: isArray ? [value] : currentValue === value ? "" : value,
          unlimited: undefined,
        };
      }
    });
  };

  useEffect(() => {
    let bundleType = searchParams.get("type");

    if (bundleType?.toLowerCase() === "freedom") {
      setFilters((prev) => ({ ...prev, unlimited: true }));
    } else if (bundleType?.toLowerCase() === "weekly") {
      setFilters((prev) => ({ ...prev, packageValidity: "7" }));
    } else if (bundleType?.toLowerCase() === "monthly") {
      setFilters((prev) => ({ ...prev, packageValidity: "30" }));
    }
  }, [searchParams]);

  const planListItems: { id: PackageTypeProps; label: string }[] = [
    { id: "data", label: "Data" },
    { id: "call", label: "Voice" },
    { id: "sms", label: "SMS" },
    { id: "combo", label: "Combo" },
  ];

  const validityListItems: { id: PackageValidityProps; label: string }[] = [
    { id: "1", label: "D" },
    { id: "7", label: "W" },
    { id: "30", label: "M" },
  ];

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            {planListItems?.map((item, i) => (
              <Button
                key={i}
                onClick={() => handleFilter("packageType", item.id, true)}
                variant={
                  filters?.packageType?.includes(item.id) ? "contained" : "text"
                }
                color="inherit"
                size="small"
                sx={{
                  fontSize: 14,
                  backgroundColor: filters?.packageType?.includes(item?.id)
                    ? palette.primaryVar.main
                    : "",
                  color: filters?.packageType?.includes(item?.id)
                    ? palette.primaryVar.contrastText
                    : "",
                  "&:hover": {
                    backgroundColor: filters?.packageType?.includes(item?.id)
                      ? palette.primaryVar.main
                      : "",
                  },
                }}
              >
                {item?.label}
              </Button>
            ))}
            <Button
              onClick={() => {
                setFilters((prev) => ({
                  ...prev,
                  unlimited: true,
                  packageType: undefined,
                  packageValidity: undefined,
                }));
              }}
              variant={filters?.unlimited ? "contained" : "text"}
              color="inherit"
              size="small"
              sx={{
                fontSize: 14,
                backgroundColor: filters?.unlimited
                  ? palette.primaryVar.main
                  : "",
                color: filters?.unlimited
                  ? palette.primaryVar.contrastText
                  : "",
                "&:hover": {
                  backgroundColor: filters?.unlimited
                    ? palette.primaryVar.main
                    : "",
                },
              }}
            >
              Freedom Plans
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack
            direction="row"
            flexWrap="wrap"
            spacing={1}
            justifyContent={{ xs: "center", md: "flex-end" }}
          >
            {validityListItems?.map((item, i) => (
              <Button
                key={i}
                onClick={() => handleFilter("packageValidity", item.id)}
                variant={
                  filters?.packageValidity === item?.id ? "contained" : "text"
                }
                color="inherit"
                size="small"
                sx={{
                  fontSize: 14,
                  ml: 1,
                  minWidth: "unset",
                  padding: 0,
                  width: 38,
                  height: 38,
                  borderRadius: "50%",
                  backgroundColor:
                    filters?.packageValidity === item?.id
                      ? palette.primaryVar.main
                      : "",
                  color:
                    filters?.packageValidity === item?.id
                      ? palette.primaryVar.contrastText
                      : "",
                  "&:hover": {
                    backgroundColor:
                      filters?.packageValidity === item?.id
                        ? palette.primaryVar.main
                        : "",
                  },
                }}
              >
                {item?.label}
              </Button>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AllBundlePlansFilter;
