import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { PhoneBookingApplyProps } from "_interfaces/http-requests/phoneBooking";
import { CreateAccountModel } from "_models/data/data.createAccount.model";
import { CreateAccountErrorModel } from "_models/errors/error.createAccount.model";
import Summary from "assets/img/static/icon/summary.png";
import CustomJoyRide from "component/_common/customJoyRide";
import InputAutoComplete from "component/_common/forms/inputAutoComplete";
import SummaryNumber from "component/_common/plans/summaryNumber";
import SummaryPlan from "component/_common/plans/summaryPlan";
import { AppStatusCode } from "config/appStatusCode";
import { filterNonNullValues } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { PhoneBookingApply } from "functions/http-requests/phoneBooking";
import useDisableBackButton from "hooks/useDisableBackButton";
import usePreventPageRefresh from "hooks/usePreventPageRefresh";
import RouteConstant from "navigation/constant";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import {
  DispatchAccountDetails,
  DispatchBillDetails,
  DispatchBookingDetails,
  DispatchBookingStep,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { validateAccountDetails } from "./formValidator";
import SectionTitle from "parts/sectionTitle";
import InputText from "component/_common/forms/inputText";
import MainContainer from "parts/mainContainer";

interface Props {}

const CreateAccountLayout: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useDisableBackButton();
  usePreventPageRefresh();

  const { bookingData, bookingStatus, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const isBookingDataAvailable: boolean = useMemo(
    () =>
      (bookingStatus?.packageBundleDetails ||
        bookingStatus?.buildBundleDetails) &&
      bookingStatus?.phoneDetails,
    [bookingStatus]
  );

  const [state, setState] = useState<CreateAccountModel>(
    new CreateAccountModel()
  );
  const [errors, setErrors] = useState<CreateAccountErrorModel>(
    new CreateAccountErrorModel()
  );

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setState((prev) => ({
      ...prev,
      [name]: value,
      searchResult: null,
    }));
  };

  const handleAutoComplete = (
    e: React.SyntheticEvent | null,
    value: any,
    name: string,
    multiple?: boolean
  ) => {
    setState((prev) => ({
      ...prev,
      [name]: multiple
        ? value?.map(
            (e: { value: string | number; id: string | number }) =>
              e?.value || e?.id
          )
        : value?.value || value?.id,
    }));
  };

  const handleCreateBooking = () => {
    Dispatch(toggleLoading(true));

    const couponToken = searchParams.get("ct"); // Get couponToken from URL

    let PAYLOAD_DATA: PhoneBookingApplyProps["DATA"] = {
      MSISDN: bookingData?.phoneDetails?.MSISDN,
      packageId: bookingStatus?.packageBundleDetails
        ? bookingData?.packageBundleDetails?._id
        : undefined,
      buildBundle:
        bookingStatus?.buildBundleDetails &&
        !bookingData?.promotionDetails?.promotionId
          ? {
              ...(bookingData?.buildBundleDetails || {}),
              intervalId: bookingData?.buildBundleIntervalId,
            }
          : undefined,
      name: state.name,
      email: state.email,
      phoneNumber: state.phoneNumber,
      identityType: state.identityType || undefined,
      nationalIdIdNumber:
        state?.identityType === "citizen" ? state.nationalIdIdNumber : "",
      refugeeDocumentNumber:
        state?.identityType === "refugee" ? state.refugeeDocumentNumber : "",
      passportNumber:
        state?.identityType === "tourist" ? state.passportNumber : "",
      promotionId: bookingData?.promotionDetails?.promotionId || undefined,
      couponToken:
        couponToken && !bookingData?.promotionDetails?.promotionId
          ? couponToken
          : undefined,
    };

    PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

    PhoneBookingApply({ DATA: PAYLOAD_DATA })
      .then((res) => {
        const data = res?.data;
        const bookingData = data?.data?.booking;
        const billData = data?.data?.bill;
        const discountedInfo = data?.data?.discountedInfo;
        if (
          data?.statusCode === AppStatusCode.api_success &&
          bookingData?._id &&
          billData?._id
        ) {
          Dispatch(
            DispatchAccountDetails({
              name: state?.name || "",
              email: state?.email || "",
              phoneNumber: state?.phoneNumber || "",
            })
          );
          Dispatch(
            DispatchBookingDetails({
              _id: bookingData?._id,
              bookingID: bookingData?.bookingID,
              promotionId: bookingData?.promotion,
            })
          );
          Dispatch(
            DispatchBillDetails({
              _id: billData?._id,
              billID: billData?.billID,
              subTotalSimCharges: billData?.subTotalSimCharges,
              simChargesId: billData?.simChargesId,
              subTotalPackageCharges: billData?.subTotalPackageCharges,
              subTotalPackageChargesId: billData?.subTotalPackageChargesId,
              totalCharges: billData?.totalCharges,
              bundleBundleCharges: billData?.bundleBundleCharges,
              isCouponApplied: billData?.isCouponApplied || false,
              couponId: billData?.couponId,
              totalWithoutDiscount: billData?.totalWithoutDiscount,
              discountAmount: billData?.discountAmount,
            })
          );
          Dispatch(DispatchBookingStep(stepIndex + 1));
          navigate(RouteConstant.CHECKOUT);
        } else {
          if (
            PAYLOAD_DATA?.promotionId &&
            !billData?.isCouponApplied &&
            discountedInfo?.message
          ) {
            Dispatch(
              setAlert({
                type: data?.level,
                message: discountedInfo?.message || "Something went wrong!",
              })
            );
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
          }
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateAccountDetails(state);

    if (ValidateStep?.valid) {
      handleCreateBooking();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  useEffect(() => {
    if (
      !bookingStatus?.phoneDetails &&
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails
    ) {
      setIsLoaded(true);
      setCounter(3);
      return;
    }

    setIsLoaded(true);
  }, [bookingStatus]);

  useEffect(() => {
    if (bookingData?.accountDetails) {
      setState((prev) => ({
        ...prev,
        ...bookingData.accountDetails,
      }));
    }
  }, [bookingData]);

  useEffect(() => {
    if (!isBookingDataAvailable && counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            return prevCounter - 1;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isBookingDataAvailable, counter]);

  useEffect(() => {
    if (counter === 0 && isLoaded && !isBookingDataAvailable) {
      navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
    }
  }, [counter, navigate, isLoaded, isBookingDataAvailable]);

  const inputList = useMemo(
    () => [
      {
        type: "autocomplete",
        name: "identityType",
        label: "Select identity type",
        options: [
          { id: "citizen", value: "citizen", title: "Citizen", isActive: true },
          { id: "refugee", value: "refugee", title: "Refugee", isActive: true },
          { id: "tourist", value: "tourist", title: "Tourist", isActive: true },
        ],
        enabled: true,
      },
      { type: "text", name: "name", label: "Enter your name", enabled: true },
      {
        type: "email",
        name: "email",
        label: "Enter your email",
        enabled: true,
      },
      {
        type: "number",
        name: "phoneNumber",
        label: "Enter your phone number",
        enabled: true,
      },
      {
        type: "text",
        name: "nationalIdIdNumber",
        label: "Enter National ID number",
        enabled: state?.identityType === "citizen",
      },
      {
        type: "text",
        name: "refugeeDocumentNumber",
        label: "Enter Refugee Document number",
        enabled: state?.identityType === "refugee",
      },
      {
        type: "text",
        name: "passportNumber",
        label: "Enter Passport number",
        enabled: state?.identityType === "tourist",
      },
    ],
    [state.identityType]
  );

  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            {isLoaded ? (
              <>
                {!isBookingDataAvailable ? (
                  <>
                    {counter > 0 ? (
                      <Alert severity="error" className="fm-poppins">
                        <AlertTitle>Package or Number Not Selected</AlertTitle>
                        You will be automatically redirected to the package
                        bundle selection page in {counter} seconds.
                        <br /> Alternatively, you can click{" "}
                        <Link
                          to={RouteConstant.PRODUCT_BUILD_BUNDLE}
                          style={{ textDecoration: "underline" }}
                        >
                          here
                        </Link>{" "}
                        to go to the page immediately.
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      spacing={{ xs: 3, md: 5 }}
                      justifyContent="center"
                    >
                      <Grid item xs={12} lg={5}>
                        <Box className="text-center mx-auto mb-3">
                          <SectionTitle
                            title="Summary"
                            className="fm-inter"
                            sx={{
                              fontSize: {
                                xs: 24,
                                md: 48,
                              },
                              fontWeight: 700,
                            }}
                          />
                        </Box>
                        <SummaryNumber
                          phoneDetails={bookingData.phoneDetails}
                        />

                        <SummaryPlan
                          packageBundleDetails={
                            bookingData?.packageBundleDetails
                          }
                          buildBundleDetails={bookingData?.buildBundleDetails}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <Box component="form" onSubmit={handleSubmit}>
                          <Box pb={3} textAlign="center">
                            <Typography
                              variant="caption"
                              className="fm-rale"
                              pb={1}
                            >
                              Hello!
                            </Typography>
                            <h1
                              className="heading-2"
                              id="next-step-for-checkout"
                            >
                              It's nice to{" "}
                              <b className="fc-primary fw-bold"> meet you!</b>
                            </h1>
                            <CustomJoyRide
                              steps={steps}
                              stepIndex={stepIndex}
                              run={isBookingDataAvailable}
                            />
                          </Box>
                          <Grid container spacing={3}>
                            {inputList?.map((item, i) => (
                              <Fragment key={i}>
                                {item?.enabled ? (
                                  <Grid item xs={12}>
                                    {item?.type === "text" ||
                                    item?.type === "number" ||
                                    item?.type === "email" ? (
                                      <InputText
                                        variant="standard"
                                        color="secondary"
                                        type={item?.type}
                                        label={item?.label}
                                        name={item?.name}
                                        value={
                                          state?.[
                                            item?.name as keyof CreateAccountModel
                                          ]
                                        }
                                        error={
                                          !!errors?.[
                                            item?.name as keyof CreateAccountErrorModel
                                          ]
                                        }
                                        errorText={
                                          errors?.[
                                            item?.name as keyof CreateAccountErrorModel
                                          ]
                                        }
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                      />
                                    ) : item?.type === "autocomplete" ? (
                                      <InputAutoComplete
                                        name={item?.name}
                                        options={item?.options || []}
                                        label={item?.label}
                                        onChange={(e, v, m) =>
                                          handleAutoComplete(e, v, item.name, m)
                                        }
                                        value={
                                          item?.options &&
                                          item?.options.length &&
                                          state?.[
                                            item.name as keyof CreateAccountModel
                                          ]
                                        }
                                        errorText={
                                          errors[
                                            item.name as keyof CreateAccountErrorModel
                                          ]
                                        }
                                        onFocus={handleFocus}
                                        multiple={false}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Fragment>
                            ))}
                          </Grid>
                          <Box pt={5} textAlign="center">
                            <Button variant="contained" type="submit">
                              Continue
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default CreateAccountLayout;
