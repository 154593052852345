import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import { useSelector } from "react-redux";
import { RootState } from "state/store";

interface Props {
  handleOpen: () => void;
  setReSelectingNumber: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReselectNumber: React.FC<Props> = ({ handleOpen }) => {
  const { bookingData, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  return (
    <>
      <Typography variant="caption">Select a number</Typography>
      <Typography>
        Find a number you{" "}
        <Box component="span" color="#FF0000" fontWeight={600}>
          love
        </Box>
      </Typography>
      <Box py={5}>
        <Button
          id="next-step-for-phone-number-selection"
          onClick={handleOpen}
          variant="contained"
          sx={{
            backgroundColor: "#000",
            "&:hover": {
              backgroundColor: "#000",
            },
          }}
        >
          Choose A Number
        </Button>

        <CustomJoyRide
          steps={[...steps]
            ?.filter((item) => item?.order)
            .sort(
              (a, b) =>
                (b.order ?? Number.MAX_VALUE) - (a.order ?? Number.MAX_VALUE)
            )}
          stepIndex={stepIndex}
          run={!bookingData?.phoneDetails?._id}
        />
      </Box>
    </>
  );
};

export default ReselectNumber;
