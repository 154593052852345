import { Box, Typography } from "@mui/material";
import SectionTitle from "parts/sectionTitle";

const TermsConditions = () => {
  const termdesc = [
    {
      desc: "This promotion is open to all Talkio customers who book a new number through our website and will run for a limited time.",
    },
    {
      desc: "Various winners will be randomly selected and announced every week & overall winner will be announced after hitting 10,000 bookings at the end of the campaign period.",
    },
    {
      desc: "Terms and conditions apply. Talkio Mobile is regulated by the Uganda Communications Commission.",
    },
    {
      desc: "Talkio Mobile – Let’s Connect",
    },
  ];
  return (
    <>
      <Box py={8} color="white" textAlign="center" maxWidth={600} mx="auto">
        <SectionTitle title="Terms & Conditions" />

        <Box pt={3}>
          {termdesc.map((item, i, arr) => (
            <Box key={i} pb={i + 1 !== arr.length ? 3 : 0}>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 400,
                  wordBreak: "break-word",
                }}
              >
                {item?.desc}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default TermsConditions;
