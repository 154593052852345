import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  title: string;
  subTitle?: string;
  sx?: SxProps<Theme>;
  className?: string;
}
const SectionTitle: React.FC<Props> = ({ title, subTitle, sx, className }) => {
  return (
    <>
      <Typography
        variant="h2"
        fontSize={{ xs: 24, md: 30 }}
        fontWeight={600}
        sx={{
          fontSize: {
            xs: 30,
            sm: 42,
          },
          fontWeight: 400,
          ...(sx || {}),
        }}
        className={`${className} `}
      >
        <Box dangerouslySetInnerHTML={{ __html: title }} />
      </Typography>

      {subTitle ? <Typography>{subTitle}</Typography> : null}
    </>
  );
};

export default SectionTitle;
