import { RechargeAirtimeModel } from "_models/data/recharge/data.rechargeAirtime.model";
import { RechargeWalletModel } from "_models/data/recharge/data.rechargeWallet.model";
import { CreateWalletModel } from "_models/data/wallet/data.createWallet.model";
import { WalletPinChangeModel } from "_models/data/wallet/data.walletPinChange.model";
import { isValidAmount, isValidPhone, isValidPin } from "functions/validators";

export const validateCreateWallet = (DATA: CreateWalletModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.PIN?.trim()) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "PIN is required",
    });
  }

  if (DATA.PIN?.trim() && !isValidPin(DATA.PIN)) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "Invalid PIN",
    });
  }

  return { valid, errors };
};

export const validateWalletPinChange = (DATA: WalletPinChangeModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.PIN?.trim()) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "PIN is required",
    });
  }

  if (DATA.PIN?.trim() && !isValidPin(DATA.PIN)) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "Invalid PIN",
    });
  }

  return { valid, errors };
};

export const validateWalletRecharge = (DATA: RechargeWalletModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.amount?.trim()) {
    valid = false;
    errors.push({
      name: "amount",
      error: "amount is required",
    });
  }

  if (DATA.amount?.trim() && !isValidAmount(DATA.amount)) {
    valid = false;
    errors.push({
      name: "amount",
      error: "Invalid amount",
    });
  }

  return { valid, errors };
};

export const validateAirtimeRecharge = (DATA: RechargeAirtimeModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.phoneNumber?.trim()) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Phone number is required",
    });
  }

  if (DATA.phoneNumber?.trim() && !isValidPhone(DATA.phoneNumber)) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Invalid phone number",
    });
  }

  if (!DATA.amount?.trim()) {
    valid = false;
    errors.push({
      name: "amount",
      error: "amount is required",
    });
  }

  if (DATA.amount?.trim() && !isValidAmount(DATA.amount)) {
    valid = false;
    errors.push({
      name: "amount",
      error: "Invalid amount",
    });
  }

  return { valid, errors };
};
