import Box from "@mui/material/Box";
import {
  PromotionDataProps,
  showInPageProps,
} from "_interfaces/_common/promotion";
import { BuildBundleProps } from "_interfaces/phoneBooking";
import BuildBundleLayout from "component/products/build-bundle";
import BuildBundlePlans from "component/products/build-bundle/buildBundlePlans";
import { HTTP_ERROR } from "functions/http";
import {
  PromotionGetAll,
  PromotionGetById,
} from "functions/http-requests/promotion";
import moment from "moment";
import BuildBundleBanner from "parts/banners/buildBundleBanner";
import LimitedTimeOfferBanner from "parts/banners/limitedTimeofferBanner";
import FrequentlyQuestions from "parts/frequentlyQuestions";
import MainContainer from "parts/mainContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
const ProductBuildBundle = () => {
  const Dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { bookingStatus, bookingData } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [promotions, setPromotions] = useState<PromotionDataProps[] | null>(
    null
  );

  // GET PROMOTIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      PromotionGetAll({
        DATA: {
          showInPage: showInPageProps["products-build-bundle"],
        },
      })
        .then((res) => {
          let DATA: PromotionDataProps[] = res?.data?.data;

          const itemCondition = (item: PromotionDataProps): boolean =>
            item?.showInPage.includes(
              showInPageProps["products-build-bundle"]
            ) &&
            moment(item.expiryDate).isAfter(moment()) &&
            item?.isActive;

          if (
            Array.isArray(DATA) &&
            DATA?.length &&
            DATA?.some((item) => itemCondition(item))
          ) {
            DATA = DATA?.filter((item) => itemCondition(item));
            setPromotions(DATA);
          }
        })
        .catch((e) => {});
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  // GET PROMOTION BY ID
  useEffect(() => {
    const promotionId = searchParams.get("promotion");

    if (promotionId) {
      let fetchList: (() => void) | null = () => {
        Dispatch(toggleLoading(true));
        PromotionGetById(promotionId)
          .then((res) => {
            let DATA: PromotionDataProps = res?.data?.data;

            if (DATA?._id) {
              let intervalId = DATA?.buildBundleData?.intervalId || undefined;

              let dataMb = DATA?.buildBundleData?.dataMb || 0;
              let onNetVoice = DATA?.buildBundleData?.onNetVoice || 0;
              let offNetVoice = DATA.buildBundleData?.offNetVoice || 0;
              let smsOffNet = DATA.buildBundleData?.smsOffNet || 0;

              if (dataMb) {
                dataMb =
                  DATA?.rechargeDataUnit === "gb" ? dataMb * 1024 : dataMb;
              }

              let newData: BuildBundleProps = {
                ...bookingData.buildBundleMinimumRequirement,
                dataMb,
                onNetVoice,
                offNetVoice,
                smsOffNet,
              };

              if (bookingData?.buildBundleDetails) {
                newData = {
                  ...bookingData.buildBundleDetails,
                  dataMb,
                  onNetVoice,
                  offNetVoice,
                  smsOffNet,
                };
              }

              Dispatch(DispatchBuildBundleDetails(newData));

              if (intervalId) {
                Dispatch(DispatchBuildBundleIntervalId(intervalId));
              }

              Dispatch(
                DispatchPromotionDetails({
                  promotionId: DATA._id,
                  amount: DATA.amount || 0,
                  dataMb,
                  onNetVoice,
                  offNetVoice,
                  smsOffNet,
                })
              );
              Dispatch(
                DispatchBuildBundleMinimumRequirement({
                  dataMb,
                  onNetVoice,
                  offNetVoice,
                  smsOffNet,
                })
              );
            } else {
              Dispatch(
                setAlert({
                  type: "error",
                  message: res?.data?.message || "Promotion not found",
                })
              );
            }
          })
          .catch((error) => {
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          })
          .finally(() => Dispatch(toggleLoading(false)));
      };

      fetchList();
      return () => {
        fetchList = null;
      };
    } else {
      Dispatch(DispatchPromotionDetails(null));
    }
  }, [searchParams, Dispatch]);

  useEffect(() => {
    if (bookingStatus?.bookingDetails || bookingStatus?.billDetails) {
      Dispatch(clearPhoneBookingData());
      return;
    }
    if (bookingStatus?.phoneDetails) {
      Dispatch(DispatchBookingStep(1));
      return;
    }
    if (bookingData?.promotionDetails) return;
    Dispatch(clearPhoneBookingData());
  }, [Dispatch, bookingStatus]);

  return (
    <>
      <section>
        <MainContainer>
          <Box pt={5}>
            <BuildBundleLayout />
          </Box>
        </MainContainer>
      </section>

      {/* <SpecialOfferBanner /> */}

      <section>
        <MainContainer>
          <Box py={5}>
            <BuildBundlePlans />
          </Box>
        </MainContainer>
      </section>

      {promotions?.some((item) => item.UI.theme === 1) ? (
        <section>
          <MainContainer>
            <LimitedTimeOfferBanner
              data={promotions?.find((item) => item.UI.theme === 1)}
            />
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      {promotions?.some((item) => item.UI.theme === 2) ? (
        <section>
          <MainContainer>
            <Box pt={5}>
              <BuildBundleBanner
                isImageFirst
                data={promotions?.find((item) => item.UI.theme === 2)}
              />
            </Box>
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      <section>
        <MainContainer>
          <Box py={5}>
            <FrequentlyQuestions />
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default ProductBuildBundle;
