import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import PromotionBannerImage from "assets/img/static/bg/promotion-banner.jpg";
import { formatPriceWithDelimiter } from "functions/helper";
import { GetCountForIphoneLottery } from "functions/http-requests/promotion";
import RouteConstant from "navigation/constant";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleLoading } from "state/slice/loading";

const MainBanner = () => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();

  const [countForLottery, setCountForLottery] = useState(0);

  // GET Lottery Count
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      GetCountForIphoneLottery()
        .then((res) => {
          let DATA = res?.data?.data;
          setCountForLottery(DATA);
        })
        .catch((e) => {})
        .finally(() => Dispatch(toggleLoading(false)));
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#1F1E1E",
        borderRadius: 8,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} lg={9}>
          <Box position="relative">
            <Box component="img" src={PromotionBannerImage} width="100%" />

            <Box
              pt={2}
              sx={{
                position: {
                  xs: "unset",
                  lg: "absolute",
                },
                px: {
                  xs: "9%",
                  lg: 0,
                },
                width: "100%",
                top: "80%",
                left: "9%",
                display: "flex",
                alignItems: "center",
                gap: 1,
                maxWidth: {
                  xs: 400,
                  lg: 280,
                },
                a: {
                  flex: 1,
                },
                button: {
                  fontSize: {
                    xs: 14,
                    lg: 16,
                  },
                  py: {
                    xs: 1,
                    lg: 1.5,
                  },
                  flex: 1,
                  borderRadius: 1.2,
                  px: 0,
                },
              }}
            >
              <Link to={RouteConstant.SELECT_SERVICE}>
                <Button variant="contained" color="primary" fullWidth>
                  Book Now
                </Button>
              </Link>
              <Link to={RouteConstant.PRODUCTS}>
                <Button
                  variant="text"
                  color="inherit"
                  fullWidth
                  sx={{
                    color: "rgba(255,255,255,0.5)",
                    "&:hover": { color: "#fff" },
                  }}
                >
                  Explore Talkio
                </Button>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box
            sx={{
              pb: {
                xs: "9%",
                lg: 0,
              },
            }}
          >
            <Box
              sx={{
                borderLeft: {
                  lg: `solid 1px ${palette.grey[800]}`,
                },
                borderTop: {
                  xs: `solid 1px ${palette.grey[800]}`,
                  lg: 0,
                },
                pt: 3,
                mt: 3,
              }}
            >
              <Grid
                container
                spacing={3}
                color={"#fff"}
                alignItems="center"
                textAlign={{
                  xs: "center",
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{ textTransform: "uppercase", letterSpacing: "2px" }}
                  >
                    Live Counter
                  </Typography>
                  <Box pt={3}>
                    <Typography
                      sx={{
                        fontSize: "54px",
                        lineHeight: 1,
                        letterSpacing: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {formatPriceWithDelimiter(countForLottery)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      pb: {
                        xs: 0,
                        lg: 3,
                      },
                    }}
                  >
                    <Typography fontSize={14} fontWeight={400}>
                      Target Bookings <br />
                      {/* <Box sx={{ fontSize: 10, fontWeight: 400 }} color="gray">
                        T&Cs Apply
                      </Box> */}
                    </Typography>
                    <Box
                      sx={{
                        borderLeft: `solid 1px ${palette.grey[800]}`,
                        display: "flex",
                        alignItems: "center",
                        pl: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        10,000
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MainBanner;
