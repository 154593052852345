import Box from "@mui/material/Box";
import Container, { ContainerOwnProps } from "@mui/material/Container";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";

interface Props {
  maxWidth?: ContainerOwnProps["maxWidth"];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  disableDefaultBg?: boolean;
  disablePadding?: boolean;
  bgColor?: string;
  className?: string;
}

const MainContainer: React.FC<Props> = ({
  maxWidth,
  children,
  sx,
  disableDefaultBg = false,
  disablePadding = false,
  bgColor,
  className,
}) => {
  return (
    <Container
      maxWidth={maxWidth || "xl"}
      sx={{
        px: {
          xs: 0,
          sm: 0,
        },
        ...(sx || {}),
      }}
    >
      <Box
        sx={{
          px: {
            xs: 0,
            sm: disableDefaultBg ? 0 : 3,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor:
              !bgColor && !disableDefaultBg
                ? "#FFFFFF"
                : bgColor
                ? bgColor
                : disableDefaultBg
                ? "transparent"
                : "transparent",
            px: {
              xs: 2,
              sm: disableDefaultBg || disablePadding ? 0 : 5,
              md: disableDefaultBg || disablePadding ? 0 : 8,
            },
          }}
          className={className || ""}
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export default MainContainer;
