import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FilterPlansProps } from "_interfaces/_common/filterPlans";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import {
  PackageCategoryProps,
  PackageForProps,
  PackageTypeProps,
} from "_interfaces/http-requests/packageBundle";

interface Props {
  filters: FilterPlansProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterPlansProps>>;
}

interface ListItemProps {
  id: PackageForProps | PackageCategoryProps | PackageTypeProps;
  label: string;
}

const FilterPlans: React.FC<Props> = ({ filters, setFilters }) => {
  const [openedFilterSections, setOpenedFilterSections] = useState<
    (keyof FilterPlansProps)[]
  >(["packageFor", "category", "packageType"]);

  const handleToggleFilterSections = (type: keyof FilterPlansProps) => {
    setOpenedFilterSections((prev) =>
      prev.includes(type)
        ? prev.filter((section) => section !== type)
        : [...prev, type]
    );
  };

  const handleFilter = (
    type: keyof FilterPlansProps,
    value: string,
    isArray?: boolean
  ) => {
    setFilters((prev) => {
      const currentValue = prev?.[type as keyof FilterPlansProps];

      if (isArray && Array.isArray(currentValue)) {
        return {
          ...prev,
          [type]: currentValue.includes(value as any)
            ? currentValue.filter((item) => item !== value)
            : [...currentValue, value],
        };
      } else {
        return {
          ...prev,
          [type]: isArray ? [value] : currentValue === value ? "" : value,
        };
      }
    });
  };

  const packageForListItems: ListItemProps[] = [
    {
      id: "normal",
      label: "Normal",
    },
    {
      id: "premium",
      label: "Premium",
    },
    {
      id: "tourist",
      label: "Tourist",
    },
  ];

  // const categoryListItems: ListItemProps[] = [
  //   {
  //     id: "bronze",
  //     label: "Bronze",
  //   },
  //   {
  //     id: "silver",
  //     label: "Silver",
  //   },
  //   {
  //     id: "gold",
  //     label: "Gold",
  //   },
  // ];

  const packageTypeListItems: ListItemProps[] = [
    { id: "data", label: "Data" },
    { id: "call", label: "Voice" },
    { id: "sms", label: "SMS" },
    { id: "combo", label: "Combo" },
  ];

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "100px",
          boxShadow: {
            xs: "none",
            md: "0 .25rem 1rem rgba(0,0,0,.08)",
          },
          borderRadius: 3,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          sx={{
            padding: 3,
            height: {
              xs: "100dvh",
              md: "calc(100dvh - 120px)",
            },
            overflow: "auto",
            msOverflowStyle: "none" /* Internet Explorer 10+ */,
            scrollbarWidth: "none" /* Firefox */,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            ">div:not(:last-child)": {
              pb: 3,
              borderBottom: "1px solid rgba(0,0,0,.08)",
            },
          }}
        >
          <RenderButtonGroup
            handleFilter={handleFilter}
            filters={filters}
            type={"packageFor"}
            listItems={packageForListItems}
            label="Category"
            openedFilterSections={openedFilterSections}
            handleToggleFilterSections={handleToggleFilterSections}
          />

          {/* <RenderButtonGroup
            handleFilter={handleFilter}
            filters={filters}
            type={"category"}
            listItems={categoryListItems}
            label="Category"
            openedFilterSections={openedFilterSections}
            handleToggleFilterSections={handleToggleFilterSections}
          /> */}

          <RenderButtonGroup
            handleFilter={handleFilter}
            filters={filters}
            type={"packageType"}
            listItems={packageTypeListItems}
            label="Package Type"
            openedFilterSections={openedFilterSections}
            handleToggleFilterSections={handleToggleFilterSections}
            isArrayFilter={true}
          />
        </Box>
      </Box>
    </>
  );
};

interface RenderButtonGroupProps {
  label: string;
  listItems: ListItemProps[];
  type: keyof FilterPlansProps;
  filters: FilterPlansProps;
  handleFilter: (
    type: keyof FilterPlansProps,
    value: string,
    isArray?: boolean
  ) => void;
  openedFilterSections: (keyof FilterPlansProps)[];
  handleToggleFilterSections: (type: keyof FilterPlansProps) => void;
  isArrayFilter?: boolean;
}
const RenderButtonGroup: React.FC<RenderButtonGroupProps> = ({
  label,
  listItems,
  type,
  filters,
  handleFilter,
  openedFilterSections,
  handleToggleFilterSections,
  isArrayFilter,
}) => {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize={12}>{label}: </Typography>
        <IconButton
          sx={{ minWidth: "unset" }}
          size="small"
          onClick={() => handleToggleFilterSections(type)}
        >
          <KeyboardArrowDownIcon
            fontSize="small"
            sx={{
              transition: "all 0.3s",
              transform: openedFilterSections?.includes(type)
                ? "rotate(180deg)"
                : "none",
            }}
          />
        </IconButton>
      </Box>
      {openedFilterSections?.includes(type) ? (
        <Stack direction="column">
          {listItems?.map((item, i) => (
            <Button
              key={i}
              id={item?.id}
              variant="text"
              onClick={() => handleFilter(type, item.id, isArrayFilter)}
              color="inherit"
              // disabled={item?.disabled}
              sx={{
                textTransform: "capitalize",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              startIcon={
                (
                  isArrayFilter
                    ? filters?.[type as keyof FilterPlansProps]?.includes(
                        item.id as any
                      )
                    : item?.id === filters?.[type as keyof FilterPlansProps]
                ) ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )
              }
            >
              {item?.label}
            </Button>
          ))}
        </Stack>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default FilterPlans;
