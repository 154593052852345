import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AgentIcon from "assets/icons/agent";
import IdStatusIcon from "assets/icons/idStatus";
import PackageIcon from "assets/icons/pacakge";
import Sim2Icon from "assets/icons/sim2";
import BgImage from "assets/img/static/bg/booking-step.jpeg";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { useNavigate } from "react-router-dom";

interface Props {
  handleOpenSearchNumber: () => void;
  handleOpenCheckStatus: () => void;
}

const BookingSteps: React.FC<Props> = ({
  handleOpenSearchNumber,
  handleOpenCheckStatus,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const steps = [
    {
      step: "Choose ID status",
      Icon: IdStatusIcon,
      handleClick: handleOpenCheckStatus,
    },
    {
      step: "Book your number",
      Icon: Sim2Icon,
      handleClick: handleOpenSearchNumber,
    },
    {
      step: "Select package",
      Icon: PackageIcon,
      handleClick: () => navigate(RouteConstant.PRODUCT_BUILD_BUNDLE),
    },
    { step: "Find the nearest Talkio agent", Icon: AgentIcon },
  ];
  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.205539) 22.84%, rgba(0, 0, 0, 0.185762) 54.58%, rgba(0, 0, 0, 0.9) 84.12%), url(${BgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: 250,
          height: "100%",
          py: 3,
          px: { xs: 1, md: 3 },
          borderRadius: "36px",
          overflow: "hidden",
        }}
      >
        <SectionTitle
          title="Book your Number"
          sx={{
            fontSize: {
              xs: 20,
              md: 36,
            },
            fontWeight: 700,
            lineHeight: 1.5,
          }}
          className="fc-white"
        />
        <SectionTitle
          title="Let's Connect!"
          sx={{
            fontSize: {
              xs: 18,
              md: 24,
            },
            fontWeight: 400,
            lineHeight: 1.5,
          }}
          className="fc-white"
        />

        <Box pt={5}>
          <Grid
            container
            spacing={{ xs: 1, sm: 3 }}
            justifyContent="space-between"
          >
            {steps?.map(({ step, Icon, handleClick }, i) => (
              <Grid item xs={6} sm="auto" key={i}>
                <Box
                  width={{ xs: 90, sm: 90 }}
                  height={{ xs: 90, sm: 90 }}
                  borderRadius="50%"
                  border={3}
                  borderColor={palette.primary.main}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin="auto"
                  sx={{
                    background: "rgba(255,255,255, 0.17)",
                    cursor: handleClick ? "pointer" : "default",
                  }}
                  onClick={handleClick}
                >
                  <Icon width={48} height={48} color="white" />
                </Box>
                <Box pt={3} width={{ xs: 120, sm: 148 }} margin="auto">
                  <Typography
                    className="fc-white"
                    fontSize={14}
                    fontWeight={400}
                    textAlign="center"
                    onClick={handleClick}
                    sx={{
                      cursor: handleClick ? "pointer" : "default",
                    }}
                  >
                    {i + 1}. {step}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BookingSteps;
