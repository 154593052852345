import React, { SVGProps } from "react";

const IdStatusIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={props?.width || "333"}
      height={props?.height || "297"}
      viewBox="0 0 333 297"
      fill={props?.color || "#000"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m121.4 48.5c0-6.3-0.1-12.3 0-18.4 0-7.5-0.7-6.4 6.4-6.4 1.4 0 2.8 0 4.5 0v-2.5c0-6.1 0-12.2-0.1-18.3 0-1.9 0.5-2.6 2.6-2.6 21.2 0.1 42.4 0.1 63.6 0.1 2.6 0 2.7 0.1 2.7 2.7 0 6.8 0 13.5 0 20.6 2.7 0 5.3 0 7.8 0 2.8 0 3.1 0.3 3.1 3q0 9.6 0 19.3v2.5c0.8 0 1.4 0.1 2 0.1q57 0 114 0c4.2 0 5 0.8 5 5.1q0 118.8 0 237.5c0 4.6-0.7 5.4-5.2 5.4q-161.1 0-322.2 0c-4.4 0-5.2-0.8-5.2-5.2q0-118.9 0-237.8c0-4.2 0.7-5 5-5q56.8 0 113.5-0.1zm-102.3 229.4h295.2v-210.7h-295.2zm137.2-32.7h-110.5c-0.1-0.2-0.3-0.3-0.3-0.4-0.7-14.4 0.2-28.3 8.2-41.1 12.3-19.6 35.4-29.7 58.3-25.1 22.3 4.5 39.8 22.7 43.6 45.3 0.4 2.8 0.6 5.6 0.7 8.4 0.1 4.2 0 8.5 0 12.9zm-90.4-111.4c0.3-19.7 16.1-34.9 35.9-34.6 19.3 0.2 34.3 16.2 34 36.1-0.3 18.6-16.4 34-35.3 33.7-19.4-0.3-34.9-16.1-34.6-35.2zm169.2-38.7q26.5 0 53 0c3.4 0 4.6 1.1 4.6 4.5 0 3.6 0 7.1 0 10.6-0.1 2.9-1.2 4-4 4.1-0.7 0.1-1.4 0-2.1 0q-52.2 0-104.4 0.1c-3.9 0-4.9-1-4.9-4.9q0-5.1 0.1-10.2c0-3.1 1.1-4.2 4.4-4.2q26.6 0 53.3 0zm-0.1 64.3q-26.5 0-53 0c-3.6 0-4.7-1.1-4.7-4.7q0-5.3 0.1-10.6c0-2.7 1.1-3.9 3.8-4 0.9-0.1 1.8 0 2.8 0q51.9 0 103.9 0c3.7 0 4.8 1 4.8 4.8q0 5.1 0 10.1c0 3.3-1.2 4.4-4.6 4.4q-26.6 0-53.1 0zm-0.1 45h-52.6c-4 0-4.9-1-4.9-4.9q-0.1-4.8-0.1-9.7c0.1-3.7 1-4.7 4.7-4.7q34.8 0 69.6 0 18.1 0 36.2 0c3.9 0 4.9 1 4.9 4.7q0 5.1 0 10.2c0 3.2-1.2 4.4-4.4 4.4q-26.7 0-53.4 0zm0.2 25.7q26.3 0 52.6 0c4.1 0.1 5 0.9 5 5q0 5 0 10c0 3.1-1.2 4.3-4.2 4.3q-53.4 0-106.7 0c-3.4 0-4.4-1.1-4.4-4.5q-0.1-4.7 0-9.5c0-4.6 0.7-5.3 5.3-5.3q26.2 0 52.4 0z" />
    </svg>
  );
};

export default IdStatusIcon;
