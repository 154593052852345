import Box from "@mui/material/Box";
import BannerImage from "assets/img/static/bg/premium-banner.jpg";
import AllPremiumPlans from "component/products/premium-plans/allPremiumPlans";
import PremiumInfo from "component/products/premium-plans/premiumInfo";
import WhyPremium from "component/products/premium-plans/whyPremium";
import BuildOwnBundle from "component/_common/plans/buildOwnBundle";
import InnerPageTopBanner3 from "parts/banners/innerPageTopBanner/innerPageTopBanner3";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";

const ProductPremiumPlans = () => {
  const Dispatch = useDispatch();

  const { bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  useEffect(() => {
    if (bookingStatus?.bookingDetails || bookingStatus?.billDetails) {
      Dispatch(clearPhoneBookingData());
      return;
    }
    if (bookingStatus?.phoneDetails) {
      Dispatch(DispatchBookingStep(1));
      return;
    }

    Dispatch(clearPhoneBookingData());
  }, [Dispatch, bookingStatus]);

  return (
    <>
      <InnerPageTopBanner3
        bannerImage={BannerImage}
        upperTitle="Premium Plans"
        title="Crafted Just For You!"
      />

      <section>
        <MainContainer>
          <Box pt={5}>
            <AllPremiumPlans />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box py={5}>
            <Box textAlign="center" className="fc-black">
              <SectionTitle
                title="Not seeing a plan that suits you?"
                subTitle="Don’t worry, build one that fits your needs."
              />
            </Box>

            <Box pt={3}>
              <BuildOwnBundle />
            </Box>
          </Box>
        </MainContainer>
      </section>

      {/* <SpecialOfferBanner /> */}

      <section>
        <MainContainer>
          <Box pb={5}>
            <WhyPremium />
          </Box>
        </MainContainer>
      </section>

      <PremiumInfo />
    </>
  );
};

export default ProductPremiumPlans;
