import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type RechargeDataProps = {
  phoneNumber: string;
  bundleId: string;
};

type Props = {
  rechargeData: RechargeDataProps;
};

const initialState: Props = {
  rechargeData: {
    phoneNumber: "",
    bundleId: "",
  },
};

const rechargePlanSlice = createSlice({
  name: "rechargePlan",
  initialState,
  reducers: {
    DispatchRechargeData(
      state,
      action: PayloadAction<Partial<RechargeDataProps> | null>
    ) {
      if (action?.payload) {
        if (action?.payload?.phoneNumber) {
          state.rechargeData.phoneNumber = action.payload.phoneNumber;
        }
        if (action?.payload?.bundleId) {
          state.rechargeData.bundleId = action.payload?.bundleId;
        }
      } else {
        state.rechargeData = initialState.rechargeData;
      }
    },
  },
});

export const { DispatchRechargeData } = rechargePlanSlice.actions;

export default rechargePlanSlice.reducer;
