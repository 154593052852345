import { Box, Typography } from "@mui/material";
import BannerImage from "assets/img/static/bg/why-join-talkio-mobile.jpg";
import { FC } from "react";

const GetStartedDesc: FC = () => {
  return (
    <>
      <Box
        sx={{
          background: `url(${BannerImage}) no-repeat center center`,
          backgroundSize: "cover",
          borderRadius: "30px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          pl: {
            xs: 3,
            sm: 5,
          },
          py: {
            xs: 8,
            sm: 15,
          },
        }}
      >
        <Box className="fc-black" maxWidth={800}>
          <Typography variant="h4" color="primary" fontWeight="bold" mb={2}>
            Why join Talkio Mobile ?
          </Typography>
          <Typography color="grey.600" mb={2}>
            You get to enjoy these benefits, and more, just for being with us
          </Typography>
          <ul>
            <li>
              <Typography color="grey.600">
                No limits to the Talkio Mobile plan you can choose
              </Typography>
            </li>
            <li>
              <Typography color="grey.600">No hidden transfer fees</Typography>
            </li>
            <li>
              <Typography color="grey.600">Nationwide coverage</Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};

export default GetStartedDesc;
