import {
  AuthCheckProps,
  AuthLogOutProps,
  ChangePasswordProps,
  ChangePasswordRequestProps,
  ResendPasswordRequestOtpProps,
  UserLoginOtpSubmitProps,
  UserLoginPasswordSubmitProps,
  UserLoginRequestProps,
  VerifyOtpPasswordRequestProps,
} from "_interfaces/http-requests/auth";
import {
  AUTH_CHECK,
  AUTH_LOGOUT,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_REQUEST,
  RESEND_PASSWORD_REQUEST_OTP,
  USER_LOGIN_OTP_SUBMIT,
  USER_LOGIN_PASSWORD_SUBMIT,
  USER_LOGIN_REQUEST,
  VERIFY_OTP_PASSWORD_REQUEST,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const UserLoginRequest = ({ DATA }: UserLoginRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: USER_LOGIN_REQUEST,
    Data: DATA,
  });
};

export const UserLoginOtpSubmit = ({ DATA }: UserLoginOtpSubmitProps) => {
  return HTTP({
    Method: "POST",
    Url: USER_LOGIN_OTP_SUBMIT,
    Data: DATA,
  });
};

export const UserLoginPasswordSubmit = ({
  DATA,
}: UserLoginPasswordSubmitProps) => {
  return HTTP({
    Method: "POST",
    Url: USER_LOGIN_PASSWORD_SUBMIT,
    Data: DATA,
  });
};

export const AuthCheck = ({ DATA }: AuthCheckProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_CHECK,
    Data: DATA,
  });
};

export const AuthLogOut = ({ DATA }: AuthLogOutProps) => {
  return HTTP({
    Method: "POST",
    Url: AUTH_LOGOUT,
    Data: DATA,
  });
};

export const ChangePasswordRequest = ({ DATA }: ChangePasswordRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: CHANGE_PASSWORD_REQUEST,
    Data: DATA,
  });
};

export const ChangePassword = ({ DATA }: ChangePasswordProps) => {
  return HTTP({
    Method: "POST",
    Url: CHANGE_PASSWORD,
    Data: DATA,
  });
};

export const VerifyOtpPasswordRequest = ({
  DATA,
}: VerifyOtpPasswordRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: VERIFY_OTP_PASSWORD_REQUEST,
    Data: DATA,
  });
};

export const ResendPasswordRequestOtp = ({
  DATA,
}: ResendPasswordRequestOtpProps) => {
  return HTTP({
    Method: "POST",
    Url: RESEND_PASSWORD_REQUEST_OTP,
    Data: DATA,
  });
};
