import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { Channel, ClearDataOnLogout, getInitialName } from "functions/helper";
import { HTTP_ERROR } from "functions/http";
import { AuthLogOut } from "functions/http-requests/auth";
import RouteConstant from "navigation/constant";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { setLogout } from "state/slice/user";
import { RootState } from "state/store";

interface NavItem {
  label: string;
  path: string;
}

const MyAccountNavbar: React.FC = () => {
  const Dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isMediumDevice = useMediaQuery(breakpoints.up("md"));

  const { userInfo } = useSelector((state: RootState) => state.user);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    const signature = localStorage.getItem("signature");

    if (signature) {
      Dispatch(toggleLoading(true));

      AuthLogOut({
        DATA: {
          signature,
        },
      })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
          Dispatch(setLogout());
          Channel.postMessage("logout");
          ClearDataOnLogout();
        });
    }
  };

  useEffect(() => {
    Channel.onmessage = () => {
      ClearDataOnLogout();
      Channel.close();
    };
  }, []);

  const navList: NavItem[] = [
    { label: "Dashboard", path: RouteConstant?.MY_ACCOUNT },
    { label: "My Wallet", path: RouteConstant?.MY_WALLET },
    { label: "My Profile", path: RouteConstant?.MY_PROFILE },
    { label: "Balance Summary", path: RouteConstant?.BALANCE_SUMMARY },
    { label: "Recharge History", path: RouteConstant?.RECHARGE_HISTORY },
    // { label: "My Order", path: RouteConstant?.MY_ORDER },
    // { label: "Address", path: RouteConstant?.MY_ADDRESS },
    // { label: "Payment", path: RouteConstant?.PAYMENT },
    // { label: "Loyalty Program", path: "" },
    // { label: "Support", path: RouteConstant?.MY_SUPPORT },
    // { label: "Setting", path: "" },
  ];

  const RenderList = () => {
    return (
      <List
        disablePadding
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 0,
          width: "100%",
        }}
      >
        {navList?.map(({ label, path }: NavItem, i: number) => (
          <ListItemButton key={i} sx={{ p: 0, my: 0 }}>
            <NavLink
              to={path}
              end
              className={({ isActive }) =>
                isActive ? "c-bg-primary text-white" : "fc-grey"
              }
              style={{
                padding: 10,
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                fontWeight: 400,
                width: "100%",
                fontSize: "inherit",
                textAlign: "center",
                fontFamily: "inherit",
              }}
            >
              {label}
            </NavLink>
          </ListItemButton>
        ))}
      </List>
    );
  };

  const CustomButtons = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          p: 1,
        }}
      >
        <Button
          variant={isMediumDevice ? "text" : "outlined"}
          onClick={handleLogout}
          color="error"
          sx={{
            padding: "8px 20px",
          }}
        >
          Logout
        </Button>
      </Box>
    );
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <RenderList />
    </Box>
  );

  return (
    <>
      <Box
        pb={{ xs: 0, md: 5 }}
        textAlign="center"
        className="fm-poppins"
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: {
            xs: "row",
            md: "column",
          },
          // justifyContent: {
          //   xs: "space-between",
          //   md: "center",
          // },
          alignItems: { xs: "center", md: "unset" },
          maxWidth: {
            xs: "calc(100% - 60px)",
            md: "100%",
          },
        }}
      >
        <Box
          width={{ xs: 60, md: 120 }}
          height={{ xs: 60, md: 120 }}
          overflow="hidden"
          className="rounded-circle bg-light-grey m-auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontSize={{ xs: 40, md: 60 }}
            fontWeight={800}
            textTransform="uppercase"
            letterSpacing={5}
          >
            {getInitialName(userInfo.name)}
          </Typography>
        </Box>
        <Box
          pt={{ md: 2 }}
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: {
              xs: "flex-start",
              md: "center",
            },
            px: 1,
            maxWidth: {
              xs: "calc(100% - 60px)",
              md: "100%",
            },
          }}
        >
          <Typography
            fontSize={{ xs: 18, md: 24 }}
            className="text-truncate"
            maxWidth="100%"
          >
            {userInfo.name}
          </Typography>
          <Typography
            fontSize={{ xs: 12, md: 14 }}
            className="text-truncate"
            maxWidth="100%"
          >
            {userInfo.phone}
          </Typography>
        </Box>

        {isMediumDevice ? <CustomButtons /> : <></>}
      </Box>

      {/* Mobile Version -- Button */}
      {!isMediumDevice ? (
        <Box sx={{ display: { xs: "block", md: "none" } }} className="fc-grey">
          <IconButton
            size="medium"
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      ) : (
        <></>
      )}

      {/* Desktop version */}
      {isMediumDevice ? (
        <AppBar
          position="static"
          sx={{
            margin: { md: "0 auto" },
            borderRadius: 0,
            boxShadow: "none",
            display: { xs: "none", md: "block" },
          }}
          color="inherit"
        >
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <RenderList />
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <></>
      )}

      {/* Mobile Version  */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            minWidth: 240, // Minimum width
          },
        }}
      >
        <Box pt={5} />
        {drawer}
        <CustomButtons />
      </Drawer>
    </>
  );
};

export default MyAccountNavbar;
