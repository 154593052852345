import Grid from "@mui/material/Grid";
import BuildBundle from "component/_common/plans/buildBundle";
import BuildBundleCalculation from "component/_common/plans/buildBundleCalculation";
import {
  calculateBuildBundleReturn,
  calculateBuildBundleUnit,
} from "component/_common/plans/calculateBuildBundleCost";
import RouteConstant from "navigation/constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  DispatchBookingStep,
  DispatchBuildBundleDetails,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionChargesDetails,
  DispatchSetPreviousPath,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import {
  minimumDataForBuildBundle,
  minimumOffNetVoiceForBuildBundle,
  minimumOnNetVoiceForBuildBundle,
  minimumPriceForBuildBundle,
  minimumSmsForBuildBundle,
} from "_interfaces/http-requests/packageBundle";

interface Props {}
const BuildOwnBundle: React.FC<Props> = ({}) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { bookingData, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [bundleUnitState, setBundleUnitState] =
    useState<calculateBuildBundleUnit>({
      onNetVoice: 0,
      offNetVoice: 0,
      dataMb: 0,
      smsOffNet: 0,
    });

  const [bundleCalculation, setBundleCalculation] =
    useState<calculateBuildBundleReturn>({
      onNetPrice: 0,
      offNetPrice: 0,
      dataPrice: 0,
      smsPrice: 0,
      totalPrice: 0,
      original_totalPrice: 0,
    });

  const handleSelectBuildBundle = () => {
    if (bundleCalculation?.totalPrice >= minimumPriceForBuildBundle) {
      Dispatch(DispatchBuildBundleDetails(bundleUnitState));
      Dispatch(DispatchBookingStep(stepIndex + 1));
      Dispatch(DispatchSetPreviousPath(pathname));

      Dispatch(DispatchPromotionChargesDetails(bundleCalculation));

      const couponToken = searchParams.get("ct"); // Get couponToken from URL
      if (couponToken) {
        navigate(`${RouteConstant.CONFIRM_NUMBER}?ct=${couponToken}`);
      } else {
        navigate(RouteConstant.CONFIRM_NUMBER);
      }
    }
  };

  useEffect(() => {
    Dispatch(
      DispatchBuildBundleMinimumRequirement({
        dataMb: minimumDataForBuildBundle,
        smsOffNet: minimumSmsForBuildBundle,
        offNetVoice: minimumOffNetVoiceForBuildBundle,
        onNetVoice: minimumOnNetVoiceForBuildBundle,
      })
    );
  }, [
    Dispatch,
    minimumDataForBuildBundle,
    minimumSmsForBuildBundle,
    minimumOffNetVoiceForBuildBundle,
    minimumOnNetVoiceForBuildBundle,
  ]);

  useEffect(() => {
    setBundleUnitState((prevState) => ({
      ...prevState,
      dataMb: bookingData?.buildBundleDetails?.dataMb ?? prevState.dataMb,
      onNetVoice:
        bookingData?.buildBundleDetails?.onNetVoice ?? prevState.onNetVoice,
      offNetVoice:
        bookingData?.buildBundleDetails?.offNetVoice ?? prevState.offNetVoice,
      smsOffNet:
        bookingData?.buildBundleDetails?.smsOffNet ?? prevState.smsOffNet,
    }));
  }, [
    bookingData?.buildBundleDetails?.dataMb,
    bookingData?.buildBundleDetails?.onNetVoice,
    bookingData?.buildBundleDetails?.offNetVoice,
    bookingData?.buildBundleDetails?.smsOffNet,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <BuildBundle
            bundleUnitState={bundleUnitState}
            setBundleUnitState={setBundleUnitState}
            setBundleCalculation={setBundleCalculation}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BuildBundleCalculation
            bundleCalculation={bundleCalculation}
            bundleUnitState={bundleUnitState}
            handleContinueBuildBundle={handleSelectBuildBundle}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BuildOwnBundle;
