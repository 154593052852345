type Props = {
  token?: string;
  PIN?: string;
};

const defaultData: Props = {
  token: "",
  PIN: "",
};

export class WalletPinChangeErrorModel {
  token?: string;
  PIN?: string;

  constructor(data: Props = defaultData) {
    this.token = data.token || "";
    this.PIN = data.PIN || "";
  }
}
