import CreateAccountLayout from "component/create-account";

const CreateAccount = () => {
  return (
    <>
      <CreateAccountLayout />
    </>
  );
};

export default CreateAccount;
