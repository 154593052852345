import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { MyAccountDetailsProps } from "_interfaces/myAccount";
import { GetCustomerInfo } from "functions/http-requests/customer";
import moment from "moment";
import MyAccountLayout from "parts/myAccountLayout";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";

const MyProfile = () => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<MyAccountDetailsProps | null>(null);

  useEffect(() => {
    Dispatch(toggleLoading(true));
    let fetchInfo: (() => void) | null = () => {
      GetCustomerInfo()
        .then((res) => {
          const DATA = res?.data?.data?.[0];
          if (DATA) {
            setState(DATA);
          }
        })
        .catch((error) => {})
        .finally(() => Dispatch(toggleLoading(false)));
    };

    fetchInfo();

    return () => {
      fetchInfo = null;
    };
  }, [Dispatch]);

  const rows = [
    {
      field: "nameAsDocument",
      headerName: "Full Name",
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
    },
    {
      field: "alterNativeNumber",
      headerName: "Alternative Number",
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      renderCell: (value: any) => {
        return value ? moment(value).format("Do MMM YYYY") : "";
      },
    },
  ];

  return (
    <MyAccountLayout>
      <SectionTitle title="My Profile" />
      <Box pt={5} className="fm-poppins">
        <TableContainer>
          <Table className="table table-bordered">
            <TableBody>
              {rows?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ fontWeight: 600 }} width="20%">
                    {row?.headerName}
                  </TableCell>
                  <TableCell>
                    {state?.[row.field as keyof MyAccountDetailsProps] ? (
                      <>
                        {row?.renderCell
                          ? row.renderCell(
                              state?.[row.field as keyof MyAccountDetailsProps]
                            )
                          : state?.[row.field as keyof MyAccountDetailsProps]}
                      </>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MyAccountLayout>
  );
};

export default MyProfile;
