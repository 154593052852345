import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { PaymentMethodDataProps } from "_interfaces/_common/payment";
import { WalletRechargeRequestProps } from "_interfaces/http-requests/recharge";
import { RechargeWalletModel } from "_models/data/recharge/data.rechargeWallet.model";
import { RechargeWalletErrorModel } from "_models/errors/recharge/error.rechargeWallet.model";
import PaymentMethod from "component/_common/payment/paymentMethod";
import { paymentOptionsList } from "component/_common/payment/paymentOptionsList";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  RechargeRequestUpdate,
  WalletRechargeRequest,
} from "functions/http-requests/recharge";
import { isValidAmount } from "functions/validators";
import React, { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { validateWalletRecharge } from "./formValidator";
import InputText from "component/_common/forms/inputText";

interface WalletRechargeProps {
  handleRefresh: () => void;
  handleClose: () => void;
}

const WalletRecharge: React.FC<WalletRechargeProps> = ({
  handleRefresh,
  handleClose,
}) => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<RechargeWalletModel>(
    new RechargeWalletModel()
  );
  const [errors, setErrors] = useState<RechargeWalletErrorModel>(
    new RechargeWalletErrorModel()
  );

  const [openPaymentOption, setOpenPaymentOption] = useState<boolean>(false);

  const handleTogglePaymentOption = () =>
    setOpenPaymentOption(!openPaymentOption);

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    if (errors?.[name as keyof RechargeWalletErrorModel]) {
      setErrors((prev) => ({ ...prev, amount: "" }));
      return;
    }
    if (value && name === "amount" && !isValidAmount(value)) {
      setErrors((prev) => ({ ...prev, amount: "Invalid amount" }));
      return;
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  let handleRechargeRequest = (DATA: PaymentMethodDataProps) => {
    if (
      DATA?.paymentBy &&
      DATA?.paymentBy !== "wallet" &&
      DATA?.paymentMethod &&
      state?.amount
    ) {
      let PAYLOAD_DATA: WalletRechargeRequestProps["DATA"] = {
        amount: Number(state.amount),
        paymentMethod: DATA.paymentMethod,
        paymentBy: DATA.paymentBy,
        momoPhoneNumber: DATA.momoPhoneNumber || undefined,
        airtelMOMOPhone: DATA.airtelMOMOPhone || undefined,
      };

      Dispatch(toggleLoading(true));
      WalletRechargeRequest({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          const rechargeId = data?.data?.rechargeId;

          if (data?.statusCode === AppStatusCode.api_success && rechargeId) {
            handleRechargeRequestUpdate(rechargeId);
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          Dispatch(toggleLoading(false));
        });
    }
  };

  const handleCancelProcess = () => {
    handleRefresh();
    Dispatch(toggleLoading(false));
    handleTogglePaymentOption();
    handleClose();
    setState(new RechargeWalletModel());
  };

  const handleRechargeRequestUpdate = (ID: string) => {
    RechargeRequestUpdate(ID)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const STATUS = data?.data?.status?.toLowerCase();

          if (STATUS === "success") {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message,
              })
            );
            handleCancelProcess();
          } else if (STATUS === "pending") {
            // Call the function again after 4 seconds if status is pending
            setTimeout(() => {
              handleRechargeRequestUpdate(ID);
            }, 4000);
          }
        } else {
          Dispatch(
            setAlert({
              type: data?.level,
              message: data?.message || "Something went wrong!",
            })
          );
          handleCancelProcess();
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        handleCancelProcess();
      });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    let ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateWalletRecharge(state);

    if (!ValidateStep?.valid) {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
      return;
    }

    handleTogglePaymentOption();
  };

  const inputList = [
    {
      type: "number",
      name: "amount",
      label: "Enter amount",
    },
  ];

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            margin: "auto",
            textAlign: "center",
            maxWidth: 400,
            p: 3,
          }}
        >
          <Typography
            variant="h2"
            fontSize={36}
            fontWeight={700}
            textAlign="center"
            sx={{ textTransform: "capitalize" }}
          >
            Add Balance
          </Typography>

          <Typography>Enter amount</Typography>

          <Box pt={5}>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {inputList?.map((item, i) => (
                  <Fragment key={i}>
                    <Grid item xs={12}>
                      <InputText
                        type={item?.type}
                        label={item?.label}
                        name={item?.name}
                        value={state?.[item?.name as keyof RechargeWalletModel]}
                        error={
                          !!errors?.[
                            item?.name as keyof RechargeWalletErrorModel
                          ]
                        }
                        errorText={
                          errors?.[item?.name as keyof RechargeWalletErrorModel]
                        }
                        onChange={handleChange}
                        onFocus={() => handleFocus(item?.name)}
                      />
                    </Grid>
                  </Fragment>
                ))}

                <Grid item xs={12}>
                  <Box
                    pt={5}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={2}
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={handleClose}
                      sx={{
                        padding: "8px 20px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        padding: "8px 20px",
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      {/* PAYMENT METHOD */}
      <Dialog
        open={openPaymentOption}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            ">.MuiDialogContent-root": {
              msOverflowStyle: "none" /* Internet Explorer 10+ */,
              scrollbarWidth: "none" /* Firefox */,
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
          }}
        >
          <PaymentMethod
            handleMakePaymentRequest={handleRechargeRequest}
            togglePaymentOption={handleTogglePaymentOption}
            paymentOptions={paymentOptionsList.filter(
              (item) => item.paymentBy !== "wallet"
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WalletRecharge;
