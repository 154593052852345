import Box from "@mui/material/Box";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import { privacyPolicyData } from "./data";

const PrivacyPolicy = () => {
  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <SectionTitle title="Privacy Policy" />

            <Box pt={3}>
              <Box dangerouslySetInnerHTML={{ __html: privacyPolicyData }} />
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default PrivacyPolicy;
