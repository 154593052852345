import CallIcon from "@mui/icons-material/Call";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import ImportExport from "@mui/icons-material/ImportExport";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import InfoCard from "component/_common/cards/infoCards";
import { formatDataUsage } from "functions/helper";
import React from "react";
import { BuildBundleProps } from "_interfaces/phoneBooking";

interface Props {
  data?: BuildBundleProps | null;
}

const BuildBundleCard: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className="fm-poppins"
      >
        <CardContent
          sx={{
            padding: "0 !important",
          }}
        >
          <Box>
            <InfoCard
              Icon={ImportExport}
              heading={data?.dataMb ? formatDataUsage(data?.dataMb) : "0"}
              desc="DATA"
            />

            <InfoCard
              Icon={CallIcon}
              heading={data?.onNetVoice || "0"}
              desc="On Net Voice"
            />

            <InfoCard
              Icon={CallIcon}
              heading={data?.offNetVoice || "0"}
              desc="All Net Voice"
            />

            <InfoCard
              Icon={DraftsOutlinedIcon}
              heading={data?.smsOffNet || "0"}
              desc="SMS"
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default BuildBundleCard;
