import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Sim3Icon from "assets/icons/sim3";
import Logo from "assets/img/static/logo.png";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { Link } from "react-router-dom";

const SwitchToTalkio = () => {
  return (
    <>
      <Box
        sx={{
          background: `radial-gradient(83.91% 86.46% at 13.06% 16.09%, #242424 0%, #070707 100%)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
          p: 3,
          borderRadius: "36px",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        className="fc-white"
      >
        <Box position="absolute" top={24} right={24}>
          <Sim3Icon width={80} height={135} />
        </Box>
        <Box pt={12}>
          <SectionTitle
            title="Switch to"
            className="fc-white"
            sx={{ fontSize: { xs: 20, sm: 24 }, fontWeight: 500 }}
          />
          <Box width={180}>
            <img src={Logo} alt="logo" style={{ width: "100%" }} />
          </Box>
          <Box
            pt={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={18}>
              Select a plan & book your <br /> SIM today
            </Typography>
            <Box>
              <Link to={RouteConstant.PRODUCTS}>
                <IconButton color="inherit">
                  <ArrowOutwardIcon fontSize="medium" />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SwitchToTalkio;
