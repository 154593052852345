import React, { useMemo } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { alpha, styled, Theme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { useLocation } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { BookingStepInfoProps } from "state/slice/phoneBooking";
import CancelPhoneBooking from "../phoneBooking/cancelPhoneBooking";

// Styled components
const StepperContainer = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: "fixed",
  top: 5,
  right: 5,
  width: "auto",
  background: theme.palette.background.paper,
  borderRadius: 8,
  padding: theme.spacing(1),
  zIndex: 99999,
  boxShadow: `0 4px 12px 0 ${alpha(theme.palette.common.black, 0.15)}`,
  display: "flex",
  alignItems: "center",
}));

const StepIndicator = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: 60,
  height: 60,
});

const ProgressCircle = styled(CircularProgress)(
  ({ theme }: { theme: Theme }) => ({
    position: "absolute",
    color: theme.palette.primary.main,
  })
);

const BackgroundCircle = styled(CircularProgress)(
  ({ theme }: { theme: Theme }) => ({
    position: "absolute",
    color: theme.palette.grey[300],
  })
);

const StepContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
});

// Custom Stepper Component
const CustomStepper: React.FC = () => {
  const { pathname } = useLocation();
  const { bookingData, steps, stepIndex, totalSteps } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  // Calculate progress percentage
  const percentage = useMemo(
    () => (stepIndex / totalSteps) * 100,
    [stepIndex, totalSteps]
  );

  // Get current step
  const currentStep = useMemo(() => {
    if (pathname === RouteConstant.CONFIRM_NUMBER && bookingData) {
      if (!bookingData?.phoneDetails?._id) {
        const stepForNumber = steps?.find(
          (item) => item?.target === "#next-step-for-phone-number-selection"
        );
        const stepForPackageBundle = steps?.find(
          (item) => item?.target === "#next-step-for-package-bundle-selection"
        );
        return {
          stepTitle: stepForNumber?.stepTitle,
          nextStepTitle: stepForPackageBundle?.nextStepTitle,
        };
      }
    }
    return steps[stepIndex] || {};
  }, [steps, stepIndex, pathname, bookingData]);

  // Define default values to avoid type issues
  const { stepTitle = "", nextStepTitle = "" } =
    currentStep as BookingStepInfoProps;

  return (
    <>
      {(stepIndex > 0 || pathname === RouteConstant.SELECT_SERVICE) && (
        <StepperContainer>
          <StepIndicator>
            {/* Background circle for gray border */}
            <BackgroundCircle
              variant="determinate"
              value={100}
              size={60}
              thickness={4}
            />
            {/* Foreground circle for primary color */}
            <ProgressCircle
              variant="determinate"
              value={percentage}
              size={60}
              thickness={4}
            />
            <Typography variant="body2">{`${
              stepIndex + 1
            }/${totalSteps}`}</Typography>
          </StepIndicator>

          <Box ml={2}>
            <StepContent>
              {stepTitle && (
                <Typography
                  variant="h6"
                  fontSize={{ xs: 14, sm: 16 }}
                  lineHeight={1}
                >
                  {stepTitle}
                </Typography>
              )}
              {nextStepTitle && (
                <Typography variant="subtitle1" fontSize={12}>
                  Next step: {nextStepTitle}
                </Typography>
              )}
              <Box>
                <CancelPhoneBooking />
              </Box>
            </StepContent>
          </Box>
        </StepperContainer>
      )}
    </>
  );
};

export default CustomStepper;
