export const privacyPolicyData = `<p><strong>Privacy Notice</strong></p>
<p><span style="font-weight: 400;">We respect your right to privacy, it is very important to us, and we take the protection of your privacy and ensuring the security of your Personal Data very seriously. We are committed to the fair and transparent Processing of your Personal Data. This Privacy Notice describes how we process your Personal Data, explains your rights in relation to the Personal Data we process, and states our commitment when Processing your Personal Data in a compliant, ethical, and secure manner.</span></p>
<p><strong>Who are we?</strong><span style="font-weight: 400;"> We are Talkio Mobile.</span></p>
<p><span style="font-weight: 400;">In this privacy notice &ldquo;Talkio&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; and &ldquo;us&rdquo; refers to Talkio Mobile.</span></p>
<p><strong>Who does this notice apply to?</strong><span style="font-weight: 400;"> This notice applies to individuals that are:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Any person(s) who contracts with us for the provision of products and services, or who purchases a product from us (&ldquo;customers&rdquo;), including potential customers to the extent Talkio processes your Personal Data;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Any person(s) who uses our website, mobile applications or any products or services (&ldquo;users&rdquo;);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">All suppliers, contractors, and service providers of Talkio (&ldquo;suppliers&rdquo;) including potential suppliers to the extent Talkio processes your Personal Data;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Resellers such as Talkio distributors and agents as well as partners (&ldquo;resellers&rdquo;) including potential resellers to the extent Talkio processes your Personal Data; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Any other persons (save for employees and job applicants) who share Personal Data with Talkio including beneficiaries, trustees, or donors to Talkio trusts or charitable causes; Talkio guests (&ldquo;other Data Subjects&rdquo;).</span></li>
</ul>
<p><strong>Important terms you need to understand when reading this notice:</strong></p>
<p><strong>Data Subject</strong><span style="font-weight: 400;"> Means an identified or identifiable natural person. An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person. It shall also include any additional persons afforded data privacy rights and protection of Personal Data in terms of Applicable Data Privacy Law(s).</span></p>
<p><strong>Local Regulatory Requirements</strong><span style="font-weight: 400;"> Legal, statutory, regulatory, license conditions rules, guidelines, Ministerial/National Security orders or directives, and Directives relating to Public safety (where applicable) and Data Sovereignty*-related requirements with which Talkio is required to comply by applicable authorities in the jurisdictions in which Talkio operates.</span></p>
<p><span style="font-weight: 400;">*Data Sovereignty relates to the laws and governance structures that Personal Data is subject to, due to the geographical location of where it is processed.</span></p>
<p><strong>Personal Data</strong><span style="font-weight: 400;"> Means any information relating to a Data Subject from which the Data subject can be identified. Examples of &ldquo;Personal Data&rdquo; includes, but is not limited to, the following:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">a name, date of birth, nationality, marital status;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">educational level or occupation of the person;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">any identifying number, symbol;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">contact information (e.g., e-mail address, postal address, telephone number);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">location data or physical address;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">online identifier or other assignment to the person;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">the biometric information of the Data Subject;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">the personal opinions, views, or preferences of the Data Subject;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">correspondence sent by the Data Subject that is implicitly or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">the views or opinions of another person about the Data Subject;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">the name of the Data Subject if it appears with other Personal Data relating to the Data Subject or if the disclosure of the name itself would reveal information about the Data Subject; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Sensitive Personal Data.</span></li>
</ul>
<p><strong>Processing</strong><span style="font-weight: 400;"> Any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, including collection, receipt, recording, organisation, structuring, collation, storage; adaptation or alteration, updating, retrieval, consultation, use, dissemination, disclosure by means of transmission; or otherwise making available, alignment or combination, merging, restriction, erasure, destruction, and/or degradation.</span></p>
<p><strong>Sensitive Personal Data</strong><span style="font-weight: 400;"> A sub-set of Personal Data which is considered more sensitive than other categories of Personal Data. Sensitive Personal Data includes but is not limited to Personal Data revealing a Data Subject&rsquo;s racial or ethnic origin; political opinions or persuasions; religious or philosophical beliefs; trade union membership; criminal behaviour relating to the alleged commission of a crime or proceedings relating to the alleged commission of a crime; genetic data; biometric data; data concerning health; and/or data concerning a Data Subject&rsquo;s sex life or sexual orientation.</span></p>
<p><strong>What Personal Data does Talkio collect and use?</strong><span style="font-weight: 400;"> To run our business, we may be required to collect, use, store and otherwise process your Personal Data. The Personal Data that we may collect and process can vary depending on the Talkio products and services that you use, contract for or that you supply to us; how you may be using or delivering those products and services; and the means by which you may be interacting with us.</span></p>
<p><span style="font-weight: 400;">We will only collect the Personal Data of yours that is adequate, relevant, and limited to what is needed in relation to the specific purposes for which we are collecting it, as described in this privacy notice.</span></p>
<p><span style="font-weight: 400;">The Personal Data collected and processed by us may include:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><strong>Account and contact information:</strong><span style="font-weight: 400;"> This may include your name, contact information (email address, postal address, residential address, telephone number), date of birth, identifying numbers (identity document number, passport numbers and/or other national ID numbers as required); billing information; financial information; any other information required to comply with regulatory requirements;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Location information:</strong><span style="font-weight: 400;"> We collect information regarding your physical location / geolocation when you use your device, our products, or services, and they connect with cellphone towers, Wi-Fi routers, Bluetooth, beacons, GPS satellites and/or other Talkio technologies or infrastructure;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Network performance:</strong><span style="font-weight: 400;"> We collect information which inform our network performance such as your use of our products, services, or equipment;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Financial information:</strong><span style="font-weight: 400;"> This may be your bank account or credit card details; financial history (including information to determine your creditworthiness);</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Usage of your devices, our services, and products:</strong><span style="font-weight: 400;"> Data usage (including browsing history); telephone records (including information regarding your incoming and outgoing telephone calls); text message records; video streaming (including your selected packages and usage); purchase activities; and other similar information;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Web browsing and application usage:</strong><span style="font-weight: 400;"> We collect your Personal Data when you visit our website or use our mobile applications. This may include your IP address, browser type; operating system; mobile carrier; your ISP; URLs of sites from which you arrive or leave the website and mobile applications and sites that have our platform technology embedded; time you spend on our website or mobile applications; links or advertisements you see; purchases made through our website; first party cookies and other web tracking technologies. We may also collect information identifying or related to equipment accessing our networks including the software, configuration, equipment types and other unique identifiers (such as your username and password). Third party cookies will also be collected from our selected marketing technology partner(s), third party websites and applications.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Supplier, reseller, contract, and service provider information:</strong><span style="font-weight: 400;"> Which may include name, contact information, billing information, background checks, financial history, employment history, tax information, information related to your performance; any other information which may be required in terms of regulatory requirements applicable to Talkio and/or our policies;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Multimedia data:</strong><span style="font-weight: 400;"> Such as unaltered photographs, audio recordings or video recordings;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Social media information:</strong><span style="font-weight: 400;"> We collect your information related to your interaction with our social media accounts, content, and marketing campaigns (including your details when you enter into a competition or interact with an advertisement; your comments, your reactions (i.e., &lsquo;likes&rsquo;), personal data available from your public profile and information you have allowed social media platforms to share with us in terms of your privacy preferences on that platform; and your social media handles;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Biometric information:</strong><span style="font-weight: 400;"> Such as images of you, fingerprints, voice recording or voiceprint, scan of your face in order to identify you;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Electronic identification data:</strong><span style="font-weight: 400;"> Such as IP address, online identifiers/cookies, image recording i.e. CCTV;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Demographic information:</strong><span style="font-weight: 400;"> We may collect information related to your gender, race or ethnic origin, age, education, financial status;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Views, opinions, and interests:</strong><span style="font-weight: 400;"> We collect information regarding your views, opinions, and preferences (for example when you participate in a survey or when you provide feedback regarding our products or services or online-based behavior) as well as your interests. In the case of suppliers and resellers, we will obtain the views and opinions of others regarding your performance (e.g., references).</span></li>
</ul>
<p><strong>Where does Talkio collect my Personal Data from?</strong><span style="font-weight: 400;"> In most circumstances, we collect Personal Data directly from you, for instance, when you buy or use any of our products or services, visit our website, interact with our customer services departments.</span></p>
<p><span style="font-weight: 400;">In some instances, however, we will also collect your Personal Data indirectly from third parties, such as:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Credit check or vetting agencies;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Social media platforms;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Marketing agencies;</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Other entities affiliated with Talkio Mobile (e.g., based on the usage of their applications, websites, and sharing of Personal Data);</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Resellers in respect of the Personal Data of customers, users, and suppliers; and</span></li>
<li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Third parties providing supplier and reseller references.</span></li>
</ul>
<p><strong>For what purposes does Talkio use your Personal Data?</strong><span style="font-weight: 400;"> Talkio will use your Personal Data for purposes of:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><strong>Verification:</strong><span style="font-weight: 400;"> We process Personal Data necessary to verify your identity when you are purchasing our products and services, when you contact us, or when we contact you;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Credit checks:</strong><span style="font-weight: 400;"> We perform credit checks, including using the services of a credit bureau, where you are applying for a contract for products and/or services with us, and to assess your application;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Contracting and processing orders:</strong><span style="font-weight: 400;"> We process your Personal Data in order to conclude a contract, to process your request to provide products and services, and to keep you updated on the status of your request. This includes registering you as a customer or user; to process any contracts, applications, or similar agreements; and to process your orders for products and services and keep you updated on the status of your orders;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Payment processing and financial transactions:</strong><span style="font-weight: 400;"> We process Personal Data to process your payments, send you invoices, bill you, and process your payments and other financial transactions. We also process Personal Data to collect and recover any money owed to us;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Marketing and customer engagement:</strong><span style="font-weight: 400;"> We will use Personal Data for marketing purposes to send you news, offers and marketing information about products and services that may be of interest to you (i.e., to market our products and services to you); for market research, analytics, and developing statistics; and to provide you with information related to our products, services, and other offers, promotions, contests, and competitions. We will also process Personal Data to enable customer engagement, including to address complaints, compliments, and queries;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Customer care:</strong><span style="font-weight: 400;"> To enable you to contact our customer care representatives and to maintain a record of your interactions with us;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Customer account management:</strong><span style="font-weight: 400;"> This may include maintaining, updating, and managing your customer account;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Loyalty and reward programs:</strong><span style="font-weight: 400;"> We will process Personal Data to enable your participation in our loyalty and rewards programs, to allocate and process loyalty rewards, points, and credits;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Improving and personalising our products, services, and interactions with you:</strong><span style="font-weight: 400;"> We will process Personal Data to evaluate, analyse, and improve our products and services; to create new products and services; and to personalise our products, services, and interactions with you. This may include, for example, processing Personal Data to make product and service recommendations to you and to personalise the content of our communications to you. We may also process your Personal Data for the purposes of research, analytics, reporting, and auditing our products and services.</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Managing our business operations:</strong><span style="font-weight: 400;"> For example, processing your Personal Data to assess risks and for operational, financial, and legal management purposes;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Management of our networks:</strong><span style="font-weight: 400;"> We will process Personal Data to evaluate, manage, and improve our networks and infrastructure. We will also process Personal Data to manage traffic on our networks and to manage our network security;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Supplier and reseller administration:</strong><span style="font-weight: 400;"> Including but not limited to, payment processing, contract management, vetting of suppliers and resellers, and payment administration;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Legal obligations:</strong><span style="font-weight: 400;"> To comply with legal and regulatory requirements, including reporting to national and international regulatory and enforcement bodies and to comply with law enforcement and crime prevention requirements;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Exercising and protecting our rights:</strong><span style="font-weight: 400;"> We will process Personal Data to exercise and protect our legal rights including to take action against those that seek to violate or abuse our rights, our property, our employees, and our customers. This will include the processing of Personal Data to prevent, detect and prosecute crime, fraud, and corruption.</span></li>
</ul>
<p><strong>How do we protect your Personal Data?</strong><span style="font-weight: 400;"> Talkio implements and maintains appropriate technical, physical, and organisational measures to protect your Personal Data against accidental, unlawful, or unauthorised destruction, loss, alteration, disclosure, or access. Our security controls are designed to maintain data confidentiality, integrity, and an appropriate level of availability. We regularly test our systems and other assets for security vulnerabilities.</span></p>
<p><strong>Who does Talkio share your Personal Data with?</strong><span style="font-weight: 400;"> We may need to share your Personal Data to the following recipients:</span></p>
<ul>
<li style="font-weight: 400;" aria-level="1"><strong>Talkio Mobile entities and affiliates:</strong><span style="font-weight: 400;"> We will share your Personal Data with other entities affiliated with Talkio where required in order to provide our products and services to you and for our internal administration and management purposes. Our affiliates will be bound to maintain the confidentiality of your Personal Data;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Resellers, suppliers, and partners:</strong><span style="font-weight: 400;"> We may share your Personal Data with our resellers, suppliers, and partners in order to provide you with our products and services and to manage our business. For example, we will share your Personal Data with our resellers and distributors to manage our relationship with them, and with our suppliers to enable them to provide services to us;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Government authorities and law enforcement bodies:</strong><span style="font-weight: 400;"> Where required, we will share Personal Data with law enforcement and other authorities in the jurisdictions in which we operate in order to comply with legal and regulatory requirements;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Professional advisors:</strong><span style="font-weight: 400;"> We will share your Personal Data with our professional advisors, such as lawyers and auditors, where necessary to protect our legal rights and manage our business operations;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Third-party service providers:</strong><span style="font-weight: 400;"> We will share your Personal Data with our third-party service providers who provide us with support services. For example, we will share your Personal Data with third-party providers who assist us with payment processing, IT services, and marketing services. These third-party service providers are required to process your Personal Data in a secure and confidential manner and only in accordance with our instructions;</span></li>
<li style="font-weight: 400;" aria-level="1"><strong>Other entities in connection with business transactions:</strong><span style="font-weight: 400;"> We may share your Personal Data with third parties in connection with business transactions, such as mergers, acquisitions, or asset sales. In such circumstances, the recipient of the Personal Data will be bound by this privacy notice or an equivalent confidentiality and security requirement.</span></li>
</ul>
<p><strong>How long do we retain your Personal Data?</strong><span style="font-weight: 400;"> We will retain your Personal Data for as long as it is necessary to fulfil the purposes for which it was collected, as set out in this privacy notice, unless a longer retention period is required or permitted by law. We will also retain your Personal Data for the duration of any period necessary to establish, exercise, or defend any legal rights.</span></p>
<p><strong>Your rights in relation to your Personal Data</strong><span style="font-weight: 400;"> You have certain rights in relation to your Personal Data. These rights may include the right to access your Personal Data, the right to correct your Personal Data, the right to delete your Personal Data, the right to restrict the processing of your Personal Data, the right to object to the processing of your Personal Data, and the right to data portability. To exercise your rights, please contact us using the contact details provided below. We will respond to your request in accordance with applicable data privacy laws.</span></p>
<p><strong>Changes to this Privacy Notice</strong><span style="font-weight: 400;"> We may update this privacy notice from time to time to reflect changes in our processing of Personal Data or changes in applicable data privacy laws. We will notify you of any significant changes to this privacy notice by posting the updated notice on our website or by other means of communication.</span></p>
<p><strong>Contact us</strong><span style="font-weight: 400;"> If you have any questions or concerns about this privacy notice or our processing of your Personal Data, please contact us at:</span></p>
<p><strong>Email:</strong><span style="font-weight: 400;"> info@talkio.com&nbsp;</span></p>`;
