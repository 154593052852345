import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SelectServiceTextImage from "assets/img/static/bg/select-service-text.png";
import CustomJoyRide from "component/_common/customJoyRide";
import SearchNumber from "component/_common/searchNumber";
import useDisableBackButton from "hooks/useDisableBackButton";
import usePreventPageRefresh from "hooks/usePreventPageRefresh";
import MainContainer from "parts/mainContainer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import SelectServiceCurveImage from "./selectServiceCurveImage.svg";

const SelectService: React.FC = () => {
  const Dispatch = useDispatch();
  useDisableBackButton();
  usePreventPageRefresh();

  const { steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [openSearchNumber, setOpenSearchNumber] = useState<boolean>(false);

  const handleOpenSearchNumber = () => setOpenSearchNumber(true);
  const handleCloseSearchNumber = () => setOpenSearchNumber(false);

  const list = [
    { title: "It’s easy to get started" },
    { title: "Choose your number" },
    { title: "Choose your preferred product" },
  ];

  useEffect(() => {
    Dispatch(DispatchBookingStep(0));
    Dispatch(clearPhoneBookingData());
  }, [Dispatch]);

  return (
    <>
      <section>
        <MainContainer disablePadding>
          <Box
            sx={{
              backgroundColor: alpha("#E0E0E0", 0.8),
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${SelectServiceTextImage})`,
                backgroundSize: "50% 100%",
                backgroundRepeat: "repeat-y",
                backgroundPosition: "top right",
              }}
            >
              <Box width="100%" position="relative" zIndex={1}>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "80%",
                    },
                    height: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "top left",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    backgroundColor: {
                      xs: "#FFF",
                      lg: "unset",
                    },
                    backgroundImage: {
                      xs: "none",
                      lg: `url(${SelectServiceCurveImage})`,
                    },
                  }}
                />
                <Box
                  py={10}
                  px={{
                    xs: 2,
                    sm: 5,
                    md: 8,
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <h1 className="heading-1 fw-bold mb-4">
                        Book Your Number to Enjoy
                        <br />
                        <span className="c-text-primary">Talkio Services</span>
                      </h1>
                      <Typography variant="body2" lineHeight={1.5}>
                        Stay connected effortlessly with Talkio’s range of
                        services,
                        <br /> including fast data, clear voice calls, and
                        unlimited SMS options.
                      </Typography>

                      <Box py={3}>
                        <List disablePadding>
                          {list?.map((item, i) => (
                            <ListItem
                              key={i}
                              sx={{
                                padding: "4px 0",
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: 40 }}>
                                <CheckCircleOutlinedIcon color="primary" />
                              </ListItemIcon>
                              <ListItemText primary={item?.title} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>

                      <Button
                        variant="outlined"
                        endIcon={<KeyboardArrowRightIcon />}
                        color="secondary"
                        onClick={handleOpenSearchNumber}
                        id="next-step-for-phone-number-selection"
                      >
                        Select
                      </Button>
                      <CustomJoyRide
                        steps={steps}
                        stepIndex={stepIndex}
                        run={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </MainContainer>
      </section>

      <Dialog
        open={openSearchNumber}
        onClose={handleCloseSearchNumber}
        maxWidth="lg"
        fullWidth
      >
        <SearchNumber onClose={handleCloseSearchNumber} />
      </Dialog>
    </>
  );
};

export default SelectService;
