export const minimumPriceForBuildBundle: number = 10000;
export const minimumDataForBuildBundle: number = 3 * 1024;
export const minimumSmsForBuildBundle: number = 0;
export const minimumOffNetVoiceForBuildBundle: number = 10;
export const minimumOnNetVoiceForBuildBundle: number = 0;

export type GetPackageBundleProps = {
  DATA: {
    //   searchKey?: string;
    //   isActive?: boolean;
    pageSize?: number;
    pageNumber?: number;
    category?: PackageCategoryProps;
    packageType?: PackageTypeProps[];
    packageFor?: PackageForProps;
    packageValidity?: PackageValidityProps;
    unlimited?: boolean;
  };
  isCancellable?: boolean;
};

export type PackageForProps = "normal" | "premium" | "tourist";
export type PackageCategoryProps = "normal" | "silver" | "gold" | "bronze";
export type PackageDiscountForProps = "all" | "agent" | "whole_seller";
export type PackageTypeProps =
  | "all"
  | "data"
  | "call"
  | "sms"
  | "combo"
  | "freedom";
export type PackageValidityProps = "1" | "7" | "30";

export type PackageBundleGetBuildBundleCalculationProps = {
  DATA: {
    onNetVoice: number | string;
    offNetVoice: number | string;
    dataMb: number | string;
    smsOffNet: number | string;
  };
};

export type PackageBundleGetBuildBundleRatesProps = {
  PARAMS?: {
    intervalId?: string;
  };
};
