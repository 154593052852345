const emailRegex: RegExp =
  /^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*\.[a-zA-Z]{2,}$/;
const phoneRegex: RegExp = /^\+?\d{1,3}[-.\s]?\d{3,14}$/;

// DETECT CONTACT TYPE
export const detectContactType = (contact: string): string | null => {
  if (emailRegex.test(contact)) {
    return "email";
  }
  if (phoneRegex.test(contact)) {
    return "phone";
  }
  return null;
};

// IS VALID EMAIL
export const isValidEmail = (val: string): boolean => {
  /* eslint-disable */
  const reg: RegExp =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return reg.test(val);
};

// IS VALID PHONE
export const isValidPhone = (val: string): boolean => {
  const reg: RegExp = /^\+?\d{1,3}[-.\s]?\d{8,14}$/;
  return reg.test(val);

  // // Remove any non-digit characters from the input
  // const cleanedNumber = val.replace(/\D/g, "");

  // const validFirstDigit = (digit: string) => {
  //   // Check if the first digit is within the valid range for Uganda
  //   const validFirstDigits = ["7", "3", "4", "2"];
  //   const firstDigit = digit.charAt(0);
  //   if (validFirstDigits.includes(firstDigit)) {
  //     return true;
  //   }
  //   return false;
  // };

  // // Check if the cleaned number has the correct length for Ugandan phone numbers
  // if (cleanedNumber.length === 9 && /^\d+$/.test(cleanedNumber)) {
  //   return validFirstDigit(cleanedNumber);
  // }

  // // Check if the number starts with the country code for Uganda
  // if (cleanedNumber.startsWith("256")) {
  //   // Validate number with country code
  //   const subscriberNumber = cleanedNumber.slice(3);
  //   if (subscriberNumber.length === 9 && /^\d+$/.test(subscriberNumber)) {
  //     return validFirstDigit(subscriberNumber);
  //   }
  // }

  // // Check if the number starts with '0'
  // if (cleanedNumber.startsWith("0")) {
  //   // Validate number without country code
  //   const subscriberNumber = cleanedNumber.slice(1);
  //   if (subscriberNumber.length === 9 && /^\d+$/.test(subscriberNumber)) {
  //     return validFirstDigit(subscriberNumber);
  //   }
  // }

  // // If the number doesn't match any of the valid formats, return false
  // return false;
};

// Is VALID MSISDN
export const isValidMsisdn = (val: string): boolean => {
  // const reg: RegExp = /^\+?\d{1,3}[-.\s]?\d{8,14}$/;
  // return reg.test(val);

  // Remove any non-digit characters from the input
  const cleanedNumber = val.replace(/\D/g, "");

  const validFirstDigit = (digit: string) => {
    // Check if the first digit is within the valid range for Uganda
    const validFirstDigits = ["7", "3", "4", "2"];
    const firstDigit = digit.charAt(0);
    if (validFirstDigits.includes(firstDigit)) {
      return true;
    }
    return false;
  };

  // Check if the number starts with the country code for Uganda
  if (cleanedNumber.startsWith("256")) {
    // Validate number with country code
    const subscriberNumber = cleanedNumber.slice(3);
    if (subscriberNumber.length === 9 && /^\d+$/.test(subscriberNumber)) {
      // return validFirstDigit(subscriberNumber);
      return true;
    }
  }

  // If the number doesn't match any of the valid formats, return false
  return false;
};

// IS VALID PASSWORD
export const isValidPassword = (password: string): boolean => {
  const strongPasswordRegex: RegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  return strongPasswordRegex.test(password);
};

// IS VALID LONGITUDE
export const isValidLongitude = (longitude: number): boolean => {
  if (Number(longitude) <= 180 && Number(longitude) >= -180) return true;
  else return false;
};

// IS VALID LATITUDE
export const isValidLatitude = (latitude: number): boolean => {
  if (Number(latitude) <= 90 && Number(latitude) >= -90) return true;
  else return false;
};

// IS VALID COORDINATE
export const isValidCoordinate = (coordinate: any[]): boolean => {
  if (!Array.isArray(coordinate)) return false;
  if (isValidLongitude(coordinate[0]) && isValidLatitude(coordinate[1])) {
    return true;
  } else return false;
};

// FILE UPLOAD TYPE VALIDATION
export const isValidFileType = (
  filename: string,
  fileTypes: string[]
): boolean => {
  let fileType: string[] = filename.split(".");
  let file: string = fileType[fileType.length - 1];
  return fileTypes.includes(file);
};

export const isValidPin = (pin: string): boolean => {
  const pinRegex: RegExp = /^\d{6}$/;
  return pinRegex.test(pin);
};

export const isValidAmount = (amount: string): boolean => {
  const amountRegex: RegExp = /^\d+(\.\d{1,2})?$/;
  return amountRegex.test(amount) && parseFloat(amount) > 0;
};
