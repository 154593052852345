import SimCardIcon from "@mui/icons-material/SimCard";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SummaryNumber from "component/_common/plans/summaryNumber";
import useDisableBackButton from "hooks/useDisableBackButton";
import usePreventPageRefresh from "hooks/usePreventPageRefresh";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearPhoneBookingData } from "state/slice/phoneBooking";
import { RootState } from "state/store";
import {
  BookingDetailsProps,
  BuildBundleProps,
  PackageBundleDetailsProps,
  PhoneDetailsProps,
} from "_interfaces/phoneBooking";

const OrderStatus: React.FC = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { palette } = useTheme();
  useDisableBackButton();
  usePreventPageRefresh();

  const { bookingData, bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const [bookingState, setBookingState] = useState<{
    phoneDetails: PhoneDetailsProps | null;
    packageBundleDetails: PackageBundleDetailsProps | null;
    buildBundleDetails: BuildBundleProps | null;
    bookingDetails: BookingDetailsProps | null;
  }>({
    phoneDetails: null,
    packageBundleDetails: null,
    buildBundleDetails: null,
    bookingDetails: null,
  });

  const isBookingDataAvailable: boolean = useMemo(
    () =>
      Boolean(
        (bookingState?.packageBundleDetails ||
          bookingState?.buildBundleDetails) &&
          bookingState?.phoneDetails &&
          bookingState?.bookingDetails
      ),
    [bookingState]
  );

  useEffect(() => {
    if (isBookingDataAvailable) {
      // This is necessary, as we clear the data from redux state after storing in local state
      return;
    }

    if (
      !bookingStatus?.phoneDetails &&
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails &&
      !bookingStatus?.bookingDetails
    ) {
      setIsLoaded(true);
      setCounter(3);
      return;
    }

    setBookingState((prev) => ({
      ...prev,
      phoneDetails: bookingData?.phoneDetails || null,
      packageBundleDetails: bookingData?.packageBundleDetails || null,
      buildBundleDetails: bookingData?.buildBundleDetails || null,
      bookingDetails: bookingData?.bookingDetails || null,
    }));

    setIsLoaded(true);
    Dispatch(clearPhoneBookingData());
  }, [bookingStatus, bookingData, Dispatch, isBookingDataAvailable]);

  useEffect(() => {
    if (!isBookingDataAvailable && counter > 0) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            return prevCounter - 1;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isBookingDataAvailable, counter]);

  useEffect(() => {
    if (counter === 0 && isLoaded && !isBookingDataAvailable) {
      navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
    }
  }, [counter, navigate, isLoaded, isBookingDataAvailable]);

  return (
    <>
      <section>
        <MainContainer>
          <Box py={5} maxWidth={600} margin="auto">
            {isLoaded ? (
              <>
                {!isBookingDataAvailable ? (
                  <>
                    {counter > 0 ? (
                      <Alert severity="error" className="fm-poppins">
                        <AlertTitle>Details not found</AlertTitle>
                        You will be automatically redirected to the package
                        bundle selection page in {counter} seconds.
                        <br /> Alternatively, you can click{" "}
                        <Link
                          to={RouteConstant.PRODUCT_BUILD_BUNDLE}
                          style={{ textDecoration: "underline" }}
                        >
                          here
                        </Link>{" "}
                        to go to the page immediately.
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <h1 className="heading-2 text-center">
                              Successfully Booked!
                            </h1>

                            <Box
                              display="flex"
                              justifyContent="center"
                              borderRadius={1}
                              alignItems="center"
                              p={{ xs: 2, sm: 4 }}
                              mt={4}
                              sx={{
                                backgroundColor: alpha(
                                  palette.success.main,
                                  0.15
                                ),
                                color: palette.success.main,
                              }}
                            >
                              <SimCardIcon sx={{ fontSize: 40, mr: 2 }} />
                              <Box>
                                <Typography variant="caption">
                                  Booking ID
                                </Typography>
                                <Typography variant="h5" className="fm-poppins">
                                  {bookingState?.bookingDetails?.bookingID}
                                </Typography>
                              </Box>
                            </Box>
                            {/* <Box pt={5}>
                            <Link to={RouteConstant.NEAREST_AGENT}>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ width: 250, mb: 3 }}
                              >
                                Find Nearest Agent
                              </Button>
                            </Link>
                          </Box> */}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryNumber
                          phoneDetails={bookingState?.phoneDetails}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default OrderStatus;
