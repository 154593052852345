import { isValidPhone } from "functions/validators";
import { RechargeNumberModel } from "_models/data/recharge/data.rechargeNumber.model";

export const validateRechargeNumber = (DATA: RechargeNumberModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.phoneNumber?.trim()) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Enter your phone number",
    });
  }

  if (DATA?.phoneNumber?.trim() && !isValidPhone(DATA.phoneNumber)) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Invalid phone number.",
    });
  }

  return { valid, errors };
};
