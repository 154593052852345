import DoneAllIcon from "@mui/icons-material/DoneAll";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title?: string;
  message?: string;
}
const DefaultBox: React.FC<Props> = ({ title, message }) => {
  return (
    <>
      <Box textAlign="center">
        {title ? <SectionTitle title={title} /> : <></>}
        {message ? (
          <Box dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <></>
        )}

        <Box pt={5}>
          <Link to={RouteConstant.LOGIN}>
            <Button variant="text">Back To Login</Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export const TokenNotFound = () => {
  return (
    <>
      <DefaultBox title="Oops!" message="We couldn't find Verification Token" />
    </>
  );
};

export const TokenNotVerified = () => {
  return (
    <>
      <DefaultBox
        title="Oops!"
        message="We couldn't verify token. <br /> Token is invalid or has been expired."
      />
    </>
  );
};

export const PasswordChangeSuccess = () => {
  const { palette } = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          p={5}
          sx={{
            backgroundColor: alpha(palette.success.main, 0.2),
            color: palette.success.main,
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoneAllIcon sx={{ fontSize: 48 }} />
        </Box>
        <Box pt={2}>
          <DefaultBox
            title="Password Changed"
            message="Password successfully changed."
          />
        </Box>
      </Box>
    </>
  );
};
