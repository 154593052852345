import {
  GET_RANDOM_NUMBER_FOR_BOOKING,
  SEARCH_PHONE_NUMBER,
} from "config/endpoint";
import { HTTP } from "functions/http";
import { GetRandomNumberForBookingProps } from "_interfaces/http-requests/MSISDN";

export const SearchPhoneNumber = (phoneNumber: string) => {
  return HTTP({
    Method: "GET",
    Url: SEARCH_PHONE_NUMBER(phoneNumber),
  });
};

export const GetRandomNumberForBooking = ({
  PARAMS,
}: GetRandomNumberForBookingProps) => {
  return HTTP({
    Method: "GET",
    Url: GET_RANDOM_NUMBER_FOR_BOOKING(PARAMS),
  });
};
