import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Summary from "assets/img/static/icon/summary.png";
import BuildBundleCard from "component/_common/plans/buildBundleCard";
import PlanCard from "component/_common/plans/planCard";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "state/store";

const ConfirmNumberSummary = () => {
  const navigate = useNavigate();

  const { bookingData, bookingStatus, previousPath } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const handleNavigatePackagePage = () => {
    if (previousPath) {
      navigate(previousPath);
    } else {
      navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
    }
  };

  return (
    <>
      <Box
        pb={{
          xs: 0,
          lg: 3,
        }}
      >
        <Box className="text-center mx-auto mb-3">
          <SectionTitle
            title="Summary"
            className="fm-inter"
            sx={{
              fontSize: {
                xs: 24,
                md: 48,
              },
              fontWeight: 700,
            }}
          />
        </Box>
        <Card
          sx={{
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 4,
          }}
        >
          <CardContent>
            <Box
              pb={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1" fontWeight={600}>
                Your Plan
              </Typography>

              <IconButton onClick={handleNavigatePackagePage}>
                <EditIcon sx={{ color: "black" }} />
              </IconButton>
            </Box>

            {bookingData?.packageBundleDetails?._id ? (
              <PlanCard data={bookingData?.packageBundleDetails} previewOnly />
            ) : bookingStatus?.buildBundleDetails ? (
              <>
                <BuildBundleCard data={bookingData?.buildBundleDetails} />
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ConfirmNumberSummary;
