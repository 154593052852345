import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {
  PaymentByProps,
  PaymentMethodsProps,
} from "_interfaces/_common/payment";
import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export interface PaymentOptionsListProps {
  label: string;
  paymentMethod: PaymentMethodsProps;
  paymentBy: PaymentByProps;
  Icon?: React.ElementType;
  disabled?: boolean;
}
export const paymentOptionsList: PaymentOptionsListProps[] = [
  {
    label: "MTN Momo",
    paymentMethod: "mobileMoney",
    paymentBy: "momo",
    // Icon: MonetizationOnOutlinedIcon,
    disabled: false,
  },
  {
    label: "Airtel Money",
    paymentMethod: "mobileMoney",
    paymentBy: "airtelMoney",
    // Icon: MonetizationOnOutlinedIcon,
    disabled: false,
  },
  // {
  //   label: "Wallet",
  //   Icon: AccountBalanceWalletIcon,
  //   paymentMethod: "mobileMoney",
  //   paymentBy: "wallet",
  //   disabled: true,
  // },
  // {
  //   label: "Installment",
  //   Icon: CalendarMonthOutlinedIcon,
  //   paymentMethod: "installment",
  //   paymentBy: "installment",
  //   disabled: true,
  // },
  // {
  //   label: "Credit / Debit Card",
  //   Icon: CreditCardIcon,
  //   paymentMethod: "creditCard",
  //   paymentBy: "creditCard",
  //   disabled: true,
  // },
  // { label: "Mobile Money", Icon: MonetizationOnOutlinedIcon },
  // { label: "Other", Icon: CircleOutlinedIcon },
];
