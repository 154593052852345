import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Pic1 from "assets/img/static/bg/get-started-1.jpg";
import Pic2 from "assets/img/static/bg/get-started-2.jpg";
import InputText from "component/_common/forms/inputText";
import RouteConstant from "navigation/constant";
import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DispatchAccountDetails } from "state/slice/phoneBooking";
import { CreateAccountModel } from "_models/data/data.createAccount.model";
import { CreateAccountErrorModel } from "_models/errors/error.createAccount.model";
import { validateGetStartedAccountDetails } from "./formValidator";

const GetStartedHeader: React.FC = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState<CreateAccountModel>(
    new CreateAccountModel()
  );
  const [errors, setErrors] = useState<CreateAccountErrorModel>(
    new CreateAccountErrorModel()
  );

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setState((prev) => ({
      ...prev,
      [name]: value,
      searchResult: null,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateGetStartedAccountDetails(state);

    if (ValidateStep?.valid) {
      Dispatch(
        DispatchAccountDetails({
          name: state?.name || "",
          email: state?.email || "",
          phoneNumber: state?.phoneNumber || "",
        })
      );
      navigate(RouteConstant.PRODUCTS);
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 3, sm: 5, lg: 15 }}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5}>
          <Typography
            fontFamily="Poppins"
            fontWeight="700"
            variant="h2"
            color="primary.main"
            fontSize={{
              xs: 36,
              lg: 60,
            }}
          >
            <span>Love</span> your <br /> mobile <br /> number ?
          </Typography>
          <Box pt={5} maxWidth={450}>
            <Box
              component="form"
              name="get-started-form"
              onSubmit={handleSubmit}
            >
              <InputText
                variant="outlined"
                placeholder="Enter your email"
                name="email"
                value={state?.email}
                error={!!errors?.email}
                errorText={errors?.email}
                onChange={handleChange}
                onFocus={() => handleFocus("email")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    position: "relative",
                    borderRadius: "40px",
                    paddingRight: 0,
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px",
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "40px",
                  },
                  "& .MuiInputBase-input": {
                    borderRadius: "40px",
                    paddingRight: 0,
                    padding: "10px 14px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        height: "100%",
                        margin: 0,
                        maxHeight: "unset",
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          borderRadius: "40px",
                          height: "100%",
                          zIndex: 1,
                          fontSize: 14,
                        }}
                      >
                        Get Started
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              "& .image_wrapper": {
                border: "4px solid #FFF",
                boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
                overflow: "hidden",
                maxHeight: 500,
                img: {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
              },
            }}
          >
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Box className="image_wrapper" sx={{ borderRadius: 220 }}>
                  <img src={Pic1} alt={"Get Started"} />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  className="image_wrapper"
                  mt={{ xs: 3, md: 10 }}
                  sx={{
                    borderTopLeftRadius: 220,
                    borderTopRightRadius: 220,
                  }}
                >
                  <img src={Pic2} alt={"Get Started"} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GetStartedHeader;
