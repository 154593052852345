import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SectionTitle from "parts/sectionTitle";

const WhyPremium = () => {
  const listItems = [
    {
      title: "Unlimited On-Net Calls",
      desc: "All plans include unlimited on-net calls, ensuring seamless communication across the Talkio network.",
    },
    {
      title: "Generous Data Bundles",
      desc: "Our plans provide ample data to meet the needs of heavy internet users, perfect for streaming, browsing, and staying connected online.",
    },
    {
      title: "Flexible Validity Periods",
      desc: "Options range from 30-day to 365-day plans, allowing you to select based on your needs, with the highest plan valid for an entire year.",
    },
  ];
  return (
    <>
      <Box textAlign="center" pb={5}>
        <SectionTitle
          title="Why do you need a premium plan?"
          className="fc-primary"
          sx={{
            textTransform: "none",
            fontSize: { xs: 24, sm: 30, lg: 36 },
          }}
        />
      </Box>

      <Grid container spacing={5}>
        {listItems?.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              sx={{
                height: "100%",
                border: "1px solid #E1E1E1",
                p: 3,
                borderRadius: 4,
              }}
            >
              <Typography
                fontSize={{ xs: 24, sm: 30 }}
                fontWeight={800}
                className="fc-black"
              >
                0{i + 1}
              </Typography>
              <Typography
                fontSize={{ xs: 18, sm: 20 }}
                fontWeight={500}
                className="fc-black"
                py={2}
              >
                {item?.title}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={500}
                sx={{
                  color: "#595959",
                }}
              >
                {item?.desc}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default WhyPremium;
