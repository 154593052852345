import Box from "@mui/material/Box";
import WorldMap from "assets/img/static/bg/worldMap.png";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import AllTouristPlans from "./allTouristPlans";

const TouristPlansLayout = () => {
  return (
    <>
      <Box position="relative" zIndex={1}>
        <Box
          sx={{
            background: `url(${WorldMap}) no-repeat top right`,
            backgroundSize: "contain",
            opacity: 0.08,
            position: "absolute",
            top: "5%",
            right: 0,
            width: "100%",
            height: "80%",
            maxHeight: "80vh",
            zIndex: -1,
          }}
        />
        <MainContainer>
          <Box pt={5}>
            <Box className="fc-black" maxWidth={800}>
              <SectionTitle
                title="Choose what fits your needs"
                subTitle="Enjoy competitive rates and seamless connectivity with our
                  Tourist Plans, ensuring you have all the voice and data you
                  need for your stay."
              />
            </Box>

            <AllTouristPlans />
          </Box>
        </MainContainer>
      </Box>
    </>
  );
};

export default TouristPlansLayout;
