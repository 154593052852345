import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  InputBaseProps,
  useTheme,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

interface Props extends InputBaseProps {
  label?: string;
  isHideShow?: boolean;
  errorText?: string;
}
const Password: React.FC<Props> = ({
  name,
  label,
  placeholder,
  autoComplete,
  isHideShow,
  onChange,
  onFocus,
  value,
  errorText,
  disabled,
}) => {
  const { palette } = useTheme();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      {/* {label ? (
        <FormLabel
          htmlFor={name}
          sx={{ color: palette.secondary.main, mb: "6px", display: "block" }}
        >
          {label}
        </FormLabel>
      ) : (
        <></>
      )} */}

      <TextField
        id={name}
        fullWidth
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        autoComplete={autoComplete}
        type={showPassword && value ? "text" : "password"}
        error={errorText ? true : false}
        helperText={errorText}
        disabled={disabled}
        InputProps={{
          endAdornment:
            isHideShow && value ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : (
              <></>
            ),
        }}
      />
    </>
  );
};

export default Password;
