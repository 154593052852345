import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { lighten, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { IsActiveCell } from "component/_common/customCell";
import { formatPriceWithDelimiter } from "functions/helper";
import React from "react";
import { WalletDetailsProps } from "_interfaces/wallet";

interface Props {
  data: WalletDetailsProps;
  handleRefresh: () => void;
}
const WalletCard: React.FC<Props> = ({ data, handleRefresh }) => {
  const { palette } = useTheme();

  return (
    <>
      <Box className="fc-grey">
        <Box
          sx={{
            boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
            borderRadius: 3,
            padding: 3,
          }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography fontWeight={400} fontSize={12}>
                Wallet ID
              </Typography>
              <Typography fontWeight={600} className="fc-black-secondary">
                {data?.walletID}
              </Typography>
            </Box>
            <Box
              flex="1 1 auto"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
              className="fc-black-secondary"
            >
              <Button
                onClick={handleRefresh}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<RefreshIcon />}
                sx={{
                  fontSize: 13,
                  fontWeight: 600,
                  p: "6px 12px",
                }}
              >
                Refresh
              </Button>
              <Chip
                label={<IsActiveCell status={data.status} />}
                sx={{
                  backgroundColor: data.isActive
                    ? lighten(palette.success.main, 0.85)
                    : lighten(palette.error.main, 0.85),
                  fontWeight: 600,
                }}
              />
            </Box>
          </Box>
          <Box pt={3} textAlign="center">
            <Typography
              fontSize={36}
              fontWeight={700}
              className="fc-black"
              lineHeight={1.2}
            >
              {data?.balance
                ? formatPriceWithDelimiter(data.balance, false)
                : 0}
            </Typography>
            <Typography fontWeight={400} fontSize={12}>
              Wallet Balance
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WalletCard;
