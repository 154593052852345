interface Props {
  identityType?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  nationalIdIdNumber?: string;
  refugeeDocumentNumber?: string;
  passportNumber?: string;
}

const defaultData: Props = {
  identityType: "",
  name: "",
  email: "",
  phoneNumber: "",
  nationalIdIdNumber: "",
  refugeeDocumentNumber: "",
  passportNumber: "",
};

export class CreateAccountErrorModel {
  identityType?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  nationalIdIdNumber?: string;
  refugeeDocumentNumber?: string;
  passportNumber?: string;

  constructor(data: Props = defaultData) {
    this.identityType = data.identityType || "";
    this.name = data.name || "";
    this.email = data.email || "";
    this.phoneNumber = data.phoneNumber || "";
    this.nationalIdIdNumber = data.nationalIdIdNumber || "";
    this.refugeeDocumentNumber = data.refugeeDocumentNumber || "";
    this.passportNumber = data.passportNumber || "";
  }
}
