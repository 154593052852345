import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BuildBundle from "component/_common/plans/buildBundle";
import BuildBundleCalculation from "component/_common/plans/buildBundleCalculation";
import {
  calculateBuildBundleReturn,
  calculateBuildBundleUnit,
} from "component/_common/plans/calculateBuildBundleCost";
import RouteConstant from "navigation/constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  DispatchBuildBundleDetails,
  DispatchBuildBundleMinimumRequirement,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";

interface Props {
  handleSelectPlan?: () => void;
  handleToggleBuildBundleRechargeOpen?: () => void;
}

const BuildBundleRecharge: React.FC<Props> = ({
  handleSelectPlan,
  handleToggleBuildBundleRechargeOpen,
}) => {
  const Dispatch = useDispatch();
  const { pathname } = useLocation();

  const { bookingData } = useSelector((state: RootState) => state.phoneBooking);

  const [isRecharge, setIsRecharge] = useState<boolean>(false);

  const [bundleUnitState, setBundleUnitState] =
    useState<calculateBuildBundleUnit>({
      onNetVoice: 0,
      offNetVoice: 0,
      dataMb: 0,
      smsOffNet: 0,
    });

  const [bundleCalculation, setBundleCalculation] =
    useState<calculateBuildBundleReturn>({
      onNetPrice: 0,
      offNetPrice: 0,
      dataPrice: 0,
      smsPrice: 0,
      totalPrice: 0,
      original_totalPrice: 0,
    });

  const handleSelectBuildBundle = () => {
    if (bundleCalculation?.totalPrice > 0) {
      Dispatch(DispatchBuildBundleDetails(bundleUnitState));
      if (handleSelectPlan) handleSelectPlan();
      if (handleToggleBuildBundleRechargeOpen)
        handleToggleBuildBundleRechargeOpen();
    }
  };

  useEffect(() => {
    Dispatch(
      DispatchBuildBundleMinimumRequirement({
        onNetVoice: 0,
        offNetVoice: 0,
        dataMb: 0,
        smsOffNet: 0,
      })
    );
  }, [Dispatch]);

  useEffect(() => {
    if (pathname === RouteConstant.RECHARGE_PLANS) {
      setIsRecharge(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (bookingData?.buildBundleDetails) {
      setBundleUnitState(bookingData.buildBundleDetails);
    }
  }, [bookingData]);
  return (
    <>
      <Box pb={5}>
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.02)",
            border: "1px solid rgba(0,0,0,0.1)",
            p: 3,
            borderRadius: 5,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pb={3}
          >
            <Typography
              variant="h2"
              fontSize={{ xs: 18, sm: 20, md: 24 }}
              fontWeight={700}
              className="fc-dark-secondary"
            >
              Recharge Build Bundle
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <BuildBundle
                bundleUnitState={bundleUnitState}
                setBundleUnitState={setBundleUnitState}
                setBundleCalculation={setBundleCalculation}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <BuildBundleCalculation
                bundleCalculation={bundleCalculation}
                bundleUnitState={bundleUnitState}
                handleContinueBuildBundle={handleSelectBuildBundle}
                isRecharge={isRecharge}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BuildBundleRecharge;
