type Props = {
  phoneNumber?: string;
  amount?: string;
};

const defaultData: Props = {
  phoneNumber: "",
  amount: "",
};

export class RechargeAirtimeErrorModel {
  phoneNumber?: string;
  amount?: string;

  constructor(data: Props = defaultData) {
    this.phoneNumber = data.phoneNumber || "";
    this.amount = data.amount || "";
  }
}
