import {
  PhoneBookingApplyCouponProps,
  PhoneBookingApplyProps,
  PhoneBookingMakePaymentRequestProps,
} from "_interfaces/http-requests/phoneBooking";
import {
  PHONE_BOOKING_APPLY,
  PHONE_BOOKING_APPLY_COUPON,
  PHONE_BOOKING_CHECK_PAYMENT_REQUEST,
  PHONE_BOOKING_GET_BY_ID,
  PHONE_BOOKING_MAKE_PAYMENT_REQUEST,
  PHONE_BOOKING_REMOVE_COUPON,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const PhoneBookingApply = ({ DATA }: PhoneBookingApplyProps) => {
  return HTTP({
    Method: "POST",
    Url: PHONE_BOOKING_APPLY,
    Data: DATA,
  });
};

export const PhoneBookingGetById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: PHONE_BOOKING_GET_BY_ID(ID),
  });
};

export const PhoneBookingMakePaymentRequest = ({
  DATA,
}: PhoneBookingMakePaymentRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: PHONE_BOOKING_MAKE_PAYMENT_REQUEST,
    Data: DATA,
  });
};

export const PhoneBookingCheckPaymentRequest = (ID: string) => {
  return HTTP({
    Method: "POST",
    Url: PHONE_BOOKING_CHECK_PAYMENT_REQUEST(ID),

    isCancellable: false,
  });
};

export const PhoneBookingApplyCoupon = ({
  DATA,
}: PhoneBookingApplyCouponProps) => {
  return HTTP({
    Method: "POST",
    Url: PHONE_BOOKING_APPLY_COUPON,
    Data: DATA,
  });
};

export const PhoneBookingRemoveCoupon = (ID: string) => {
  return HTTP({
    Method: "POST",
    Url: PHONE_BOOKING_REMOVE_COUPON(ID),
  });
};
