import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { WalletDetailsProps } from "_interfaces/wallet";
import WalletCreate from "component/my-account/my-wallet/walletCreate";
import {
  GetMyWallet,
  WalletChangePinRequest,
  WalletResendOtpForPinChange,
  WalletVerifyOtpForPinChange,
} from "functions/http-requests/wallet";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useState } from "react";
import WalletCard from "./walletCard";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import Grid from "@mui/material/Grid";
import OtpVerificationLayout from "component/auth/otpVerification";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import { AppStatusCode } from "config/appStatusCode";
import { setAlert } from "state/slice/alert";
import { HTTP_ERROR } from "functions/http";
import WalletRecharge from "./walletRecharge";
import WalletPinChange from "./walletPinChange";
import DialogContent from "@mui/material/DialogContent";

const MyWalletLayout = () => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<WalletDetailsProps | null>(null);

  const [otpState, setOtpState] = useState<OtpVerificationModel>(
    new OtpVerificationModel()
  );
  const [isProgress, setIsProgress] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [openCreateWallet, setOpenCreateWallet] = useState<boolean>(false);

  const [openOtpVerification, setOpenOtpVerification] =
    useState<boolean>(false);
  const [openWalletPinChange, setOpenWalletPinChange] =
    useState<boolean>(false);

  const [openWalletRecharge, setOpenWalletRecharge] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  const handleToggleCreateWallet = () => setOpenCreateWallet(!openCreateWallet);

  const handleToggleOtpVerification = () =>
    setOpenOtpVerification(!openOtpVerification);
  const handleToggleWalletPinChange = () =>
    setOpenWalletPinChange(!openWalletPinChange);

  const handleToggleWalletRecharge = () =>
    setOpenWalletRecharge(!openWalletRecharge);

  // REQUEST FOR PIN CHANGE
  const handleRequestForPinChange = () => {
    Dispatch(toggleLoading(true));

    WalletChangePinRequest()
      .then((res) => {
        if (res?.data?.statusCode === AppStatusCode.api_success) {
          const data = res?.data?.data;

          if (data && data?.token) {
            setOtpState(() => ({
              OTP: "",
              token: data?.token,
              type: "sms",
              otpSent: true,
            }));
            handleToggleOtpVerification();
          }
        } else {
          Dispatch(
            setAlert({
              type: res?.data?.level || "error",
              message: res?.data?.message,
            })
          );
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  // RESEND OTP FOR PIN CHANGE
  const handleResendOtpForPinChange = () => {
    if (otpState?.token) {
      setIsProgress(true);

      WalletResendOtpForPinChange({
        DATA: {
          token: otpState?.token,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;

            if (data) {
              setOtpState((prev) => ({
                ...prev,
                OTP: "",
                otpSent: true,
              }));
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  // VERIFY OTP FOR PIN CHANGE
  const handleVerifyOtpForPinChange = () => {
    if (otpState?.token && otpState?.OTP) {
      setIsProgress(true);

      WalletVerifyOtpForPinChange({
        DATA: {
          token: otpState?.token,
          otp: otpState.OTP,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            handleToggleWalletPinChange();
            handleToggleOtpVerification();
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  // FETCH WALLET
  useEffect(() => {
    let fetchWallet: (() => void) | null = () => {
      setIsLoaded(false);
      Dispatch(toggleLoading(true));
      GetMyWallet()
        .then((res) => {
          let DATA: WalletDetailsProps = res?.data?.data;
          if (DATA?._id) {
            setState({
              _id: DATA?._id,
              user: DATA?.user,
              userID: DATA?.userID,
              walletID: DATA?.walletID,
              balance: DATA?.balance,
              walletFor: DATA?.walletFor,
              roleId: DATA?.roleId,
              status: DATA?.status,
              isActive: DATA?.isActive,
            });
          } else {
            setState(null);
          }
        })
        .catch(() => {
          setState(null);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
          setIsLoaded(true);
        });
    };
    fetchWallet();
    return () => {
      fetchWallet = null;
    };
  }, [refresh]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <SectionTitle title="My Wallet" />
        {state ? (
          <Box
            flex="1 1 auto"
            display="flex"
            flexWrap="wrap"
            gap={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              onClick={handleRequestForPinChange}
              variant="outlined"
              sx={{
                borderRadius: "4px",
                padding: "8px 12px",
              }}
            >
              Change PIN
            </Button>
            <Button
              onClick={handleToggleWalletRecharge}
              variant="contained"
              sx={{
                borderRadius: "4px",
                padding: "8px 12px",
              }}
            >
              Add Balance
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {isLoaded ? (
        <Box pt={5} className="fm-poppins">
          {!state ? (
            <>
              <Box textAlign="center">
                <Typography pb={1} fontSize={24}>
                  Wallet Not Found
                </Typography>
                <Button variant="contained" onClick={handleToggleCreateWallet}>
                  Create Wallet
                </Button>
              </Box>
            </>
          ) : (
            <Grid container spacing={5} justifyContent="center">
              <Grid item xs={12} lg={6}>
                <WalletCard data={state} handleRefresh={handleRefresh} />
              </Grid>
            </Grid>
          )}
        </Box>
      ) : (
        <></>
      )}

      {/* CREATE WALLET */}
      <Dialog
        open={openCreateWallet}
        onClose={handleToggleCreateWallet}
        fullWidth
        maxWidth="sm"
      >
        <WalletCreate
          handleRefresh={handleRefresh}
          handleClose={handleToggleCreateWallet}
        />
      </Dialog>

      {/* OTP VERIFICATION FOR PIN CHANGE */}
      <Dialog open={openOtpVerification} fullWidth maxWidth="sm">
        <DialogContent
          sx={{
            p: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          <OtpVerificationLayout
            otpState={otpState}
            setOtpState={setOtpState}
            isProgress={isProgress}
            handleResendOtp={handleResendOtpForPinChange}
            handleOtpVerification={handleVerifyOtpForPinChange}
            handleCancel={handleToggleOtpVerification}
          />
        </DialogContent>
      </Dialog>

      {/* Wallet PIN CHANGE */}
      <Dialog open={openWalletPinChange} fullWidth maxWidth="sm">
        <WalletPinChange
          token={otpState.token}
          handleRefresh={handleRefresh}
          handleClose={handleToggleWalletPinChange}
        />
      </Dialog>

      {/* WALLET RECHARGE */}
      <Dialog
        open={openWalletRecharge}
        onClose={handleToggleWalletRecharge}
        fullWidth
        maxWidth="sm"
      >
        <WalletRecharge
          handleRefresh={handleRefresh}
          handleClose={handleToggleWalletRecharge}
        />
      </Dialog>
    </>
  );
};

export default MyWalletLayout;
