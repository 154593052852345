import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatPriceWithDelimiter } from "functions/helper";
import { GetCustomerBalanceSummary } from "functions/http-requests/customer";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { BalanceSummaryProps } from "_interfaces/myAccount/balanceSummary";

const BalanceSummaryLayout = () => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<BalanceSummaryProps | null>(null);

  useEffect(() => {
    Dispatch(toggleLoading(true));
    let fetchList: (() => void) | null = () => {
      GetCustomerBalanceSummary()
        .then((res) => {
          setState(res?.data?.data);
        })
        .catch(() => {})
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  return (
    <>
      <Grid container spacing={5} className="fc-secondary">
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
              borderRadius: 3,
              padding: 3,
            }}
          >
            {state ? (
              <>
                <Box>
                  <Typography fontWeight={600}>Current Balance</Typography>
                </Box>
                <Typography fontSize={24} fontWeight={700} className="fc-black">
                  {state.lastRechargeCurrencySymbol}
                  {state?.currentBalance
                    ? formatPriceWithDelimiter(state.currentBalance, false)
                    : 0}
                </Typography>
                <Box fontSize={14}>
                  <RenderRow
                    label="Recharge Amount"
                    value={`${state?.lastRechargeCurrencySymbol}${
                      state?.lastRecharge
                        ? formatPriceWithDelimiter(state.lastRecharge, false)
                        : 0
                    }`}
                  />
                  <RenderRow
                    label="Recharge Date"
                    value={`${moment(state.lastRechargeDate).format(
                      "Do MMM YYYY hh:mm:ss A"
                    )}`}
                  />
                  <RenderRow
                    label="Recharge Channel"
                    value={`${state.rechargeChannel}`}
                  />
                  <RenderRow
                    label="Recharge Type"
                    value={`${state.lastRechargeType}`}
                  />
                </Box>
              </>
            ) : (
              <>
                <Typography pb={1} fontSize={16} textAlign="center">
                  Summary Not Found
                </Typography>
              </>
            )}
          </Box>

          {state?.bundles?.length ? (
            <>
              <Box pt={3} pb={1}>
                <Typography fontSize={18} fontWeight={600}>
                  All bundles
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" gap={3}>
                {state?.bundles?.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      boxShadow: "0 .25rem 0.5rem rgba(0,0,0,.05)",
                      borderRadius: 3,
                      padding: 3,
                    }}
                  >
                    <Box fontSize={14}>
                      <RenderRow
                        label="Bundle Name"
                        value={`${item?.bundleName}`}
                      />
                      <RenderRow
                        label="Subscription Date"
                        value={`${moment(item?.bundleSubscriptionDate).format(
                          "Do MMM YYYY hh:mm:ss A"
                        )}`}
                      />
                      <RenderRow
                        label="Expiry Date"
                        value={`${moment(item?.bundleExpiry).format(
                          "Do MMM YYYY hh:mm:ss A"
                        )}`}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

interface RowProps {
  label: string;
  value: string | number;
}
const RenderRow: React.FC<RowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        pt={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize="inherit" className="fc-grey">
          {label}
        </Typography>
        <Typography textAlign="right">{value}</Typography>
      </Box>
    </>
  );
};

export default BalanceSummaryLayout;
