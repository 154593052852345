import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MainContainer from "parts/mainContainer";
import React from "react";

interface Props {
  bannerImage?: string;
  subTitle?: string;
  title?: string;
  upperTitle?: string;
  sx?: SxProps<Theme>;
}

const InnerPageTopBanner3: React.FC<Props> = ({
  bannerImage,
  subTitle,
  title,
  upperTitle,
  sx,
}) => {
  return (
    <>
      <section>
        <MainContainer>
          <Box
            sx={{
              borderRadius: 5,
              overflow: "hidden",
            }}
          >
            <Box
              className="fm-poppins"
              sx={{
                height: {
                  xs: 350,
                  md: 450,
                  lg: 500,
                },
                // minHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                position: "relative",
                zIndex: 10,
              }}
            >
              <Box
                pt={{ xs: 5, md: 0 }}
                px={{ xs: 3, md: 5 }}
                pb={8}
                zIndex={999}
                maxWidth={800}
              >
                <Box position="relative">
                  {upperTitle ? (
                    <Typography
                      fontSize={{
                        xs: 20,
                        md: 24,
                      }}
                      fontWeight={600}
                      lineHeight={1.5}
                      className="fm-poppins fc-black"
                    >
                      {upperTitle}
                    </Typography>
                  ) : null}
                  {title ? (
                    <Typography
                      fontSize={{ xs: "36px", md: "70px" }}
                      fontWeight={600}
                      lineHeight={1.3}
                      className="fm-poppins fc-black"
                      zIndex={-1}
                    >
                      {title}
                    </Typography>
                  ) : null}
                  {subTitle ? (
                    <Typography
                      fontSize={{
                        xs: 20,
                        md: 24,
                      }}
                      fontWeight={600}
                      lineHeight={1.5}
                      className="fm-poppins fc-black"
                    >
                      {subTitle}
                    </Typography>
                  ) : null}
                </Box>
              </Box>

              <Box
                sx={{
                  background: `url(${bannerImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top center",
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  right: 0,
                  zIndex: 1,
                }}
              />
            </Box>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default InnerPageTopBanner3;
