import {
  FormHelperTextProps,
  InputBaseProps,
  InputProps,
  SxProps,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React, { useEffect, useRef } from "react";

interface Props extends InputBaseProps {
  label?: string;
  InputProps?: InputProps;
  errorText?: string;
  active?: boolean;
  activeColor?: string;
  FormHelperTextProps?: Partial<FormHelperTextProps<"p", {}>>;
  sx?: SxProps;
  variant?: TextFieldProps["variant"];
  color?: TextFieldProps["color"];
}

const InputText: React.FC<Props> = ({
  type,
  name,
  label,
  placeholder,
  autoComplete,
  minRows,
  InputProps,
  onChange,
  onFocus,
  onBlur,
  value,
  errorText,
  disabled,
  active,
  activeColor,
  multiline,
  FormHelperTextProps,
  sx,
  variant,
  color,
  inputProps,
}) => {
  const { palette } = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  // Disable scroll and arrow keys for number inputs
  useEffect(() => {
    const inputElement = inputRef.current;

    if (type === "number" && inputElement) {
      const handleWheel = (e: WheelEvent) => e.preventDefault();
      const handleKeyDown = (e: KeyboardEvent) => {
        // Prevent arrow up and down keys from changing the value
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      };

      // Add event listeners
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
      inputElement.addEventListener("keydown", handleKeyDown);

      // Cleanup event listeners on component unmount
      return () => {
        inputElement.removeEventListener("wheel", handleWheel);
        inputElement.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [type]);

  return (
    <>
      {/* {label ? (
        <FormLabel
          htmlFor={name}
          className="tw-mb-[6px] tw-block"
          sx={{ color: palette.secondary.main }}
        >
          {label}
        </FormLabel>
      ) : null} */}
      <TextField
        inputRef={inputRef}
        color={color || "secondary"} // Set the ref for accessing the DOM element
        id={name}
        fullWidth
        variant={variant || "standard"}
        type={type}
        name={name}
        label={label || ""}
        value={value || ""}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        autoComplete={autoComplete}
        multiline={multiline || type === "textArea"}
        minRows={minRows}
        error={!!errorText}
        helperText={
          errorText ? (
            <Box
              component="span"
              dangerouslySetInnerHTML={{ __html: errorText }}
            />
          ) : null
        }
        FormHelperTextProps={FormHelperTextProps}
        disabled={disabled}
        InputProps={InputProps}
        inputProps={inputProps}
        sx={{
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor:
                active && activeColor
                  ? activeColor
                  : active && !activeColor
                  ? palette.primary.main
                  : "",
            },
          },
          ...(sx || {}),
        }}
      />
    </>
  );
};

export default InputText;
