import { LoginModel } from "_models/data/auth/data.login.model";
import { isValidPassword } from "functions/validators";

export const validateLoginRequest = (DATA: LoginModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.phone?.trim()) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  return { valid, errors };
};

export const validateLoginPasswordSubmit = (DATA: LoginModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.phone?.trim()) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  if (!DATA.password?.trim()) {
    valid = false;
    errors.push({
      name: "password",
      error: "Password is required.",
    });
  }

  return { valid, errors };
};

export const validateLoginSetPasswordSubmit = (DATA: LoginModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.phone?.trim()) {
    valid = false;
    errors.push({
      name: "phone",
      error: "Phone is required.",
    });
  }

  if (!DATA.password?.trim()) {
    valid = false;
    errors.push({
      name: "password",
      error: "Password is required.",
    });
  }

  if (!DATA.confirmPassword?.trim()) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Confirm Password is required.",
    });
  }

  if (DATA.password?.trim() && !isValidPassword(DATA.password)) {
    valid = false;
    errors.push({
      name: "password",
      error:
        "Password must contain at least 8 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character",
    });
  }

  if (
    DATA.confirmPassword?.trim() &&
    DATA.password?.trim() &&
    DATA.confirmPassword !== DATA.password
  ) {
    valid = false;
    errors.push({
      name: "confirmPassword",
      error: "Password doesn't match",
    });
  }

  return { valid, errors };
};
