import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { validateOtpVerification } from "./formValidator";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import { OtpVerificationErrorModel } from "_models/errors/auth/error.otpVerification.model";
import OtpInput from "component/_common/forms/otpInput";

interface OtpVerificationLayoutProps {
  otpState: OtpVerificationModel;
  setOtpState: React.Dispatch<React.SetStateAction<OtpVerificationModel>>;
  isProgress?: boolean;
  handleOtpVerification: () => void;
  handleResendOtp?: () => void;
  handleCancel?: () => void;
}

const OtpVerificationLayout: React.FC<OtpVerificationLayoutProps> = ({
  otpState,
  setOtpState,
  isProgress,
  handleOtpVerification,
  handleResendOtp,
  handleCancel,
}) => {
  const { palette } = useTheme();
  const [errors, setErrors] = useState<OtpVerificationErrorModel>(
    new OtpVerificationErrorModel()
  );

  const initialMinutes = 2;
  const initialSeconds = 0;
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  const [restartTimer, setRestartTimer] = useState(false);

  const handleChange = (newValue: string) => {
    setOtpState((prev) => ({ ...prev, OTP: newValue }));
  };

  const resendOTP = () => {
    if (handleResendOtp) handleResendOtp();
    setOtpState((prev) => ({ ...prev, OTP: "", otpSent: false }));
  };

  const handleSubmit = (): void => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateOtpVerification(otpState);

    if (ValidateStep?.valid) {
      handleOtpVerification();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes, restartTimer]);

  useEffect(() => {
    if (errors?.OTP && otpState?.OTP?.trim()) {
      setErrors((prev) => ({ ...prev, OTP: "" }));
    }
  }, [otpState.OTP, errors.OTP]);

  useEffect(() => {
    if (otpState.otpSent) {
      setMinutes(initialMinutes);
      setSeconds(initialSeconds);
      setRestartTimer((prev) => !prev);
      setOtpState((prev) => ({ ...prev, otpSent: false }));
    }
  }, [otpState]);

  return (
    <>
      <Box
        sx={{
          margin: "auto",
          textAlign: "center",
          maxWidth: 400,
        }}
      >
        <Typography
          variant="h1"
          fontSize={36}
          fontWeight={700}
          textAlign="center"
          sx={{ textTransform: "capitalize" }}
        >
          {otpState.type || "OTP"} Verification
        </Typography>
        {otpState.type ? (
          <Typography>Enter OTP received on {otpState.type}</Typography>
        ) : (
          <></>
        )}

        <Box>
          <OtpInput
            code={otpState.OTP || ""}
            handleChange={handleChange}
            maximumLength={6}
          />

          {errors?.OTP ? (
            <Box pt={1}>
              <FormHelperText
                sx={{ color: palette.error.main, textAlign: "center" }}
              >
                {errors?.OTP}
              </FormHelperText>
            </Box>
          ) : (
            <></>
          )}
          <Box
            pt={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            {seconds > 0 || minutes > 0 ? (
              <Typography fontSize={14}>
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
            ) : (
              <Typography fontSize={14}>Didn't receive code?</Typography>
            )}

            <Button
              variant="text"
              disabled={seconds > 0 || minutes > 0}
              sx={{
                margin: "0 10px",
                padding: "6px 10px",
                textTransform: "capitalize",
                opacity: seconds > 0 || minutes > 0 ? 0.7 : 1,
                fontFamily: "inherit",
                lineHeight: 1,
                textDecoration: "underline",
              }}
              onClick={resendOTP}
            >
              Resend code
            </Button>
          </Box>

          <Box pt={5} display="flex" flexDirection="column" gap={1}>
            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disableElevation
              disabled={
                otpState?.OTP && otpState?.OTP?.trim()?.length === 6
                  ? false
                  : true
              }
              sx={{
                textTransform: "capitalize",
                py: "12px",
              }}
            >
              {isProgress ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Verify OTP"
              )}
            </Button>
            {handleCancel ? (
              <Button
                onClick={handleCancel}
                fullWidth
                variant="text"
                disableElevation
                color="inherit"
              >
                Cancel
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OtpVerificationLayout;
