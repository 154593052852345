import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  BuildBundleProps,
  PackageBundleDetailsProps,
} from "_interfaces/phoneBooking";
import React from "react";
import BuildBundleCard from "./buildBundleCard";
import PlanCard from "./planCard";

interface Props {
  packageBundleDetails?: PackageBundleDetailsProps | null;
  buildBundleDetails?: BuildBundleProps | null;
}

const SummaryPlan: React.FC<Props> = ({
  packageBundleDetails,
  buildBundleDetails,
}) => {
  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: 4,
        }}
      >
        <CardContent>
          <Box pb={3} display="flex" justifyContent="space-between">
            <Typography variant="subtitle1" fontWeight={600}>
              Your Plan
            </Typography>
          </Box>
          {packageBundleDetails?._id ? (
            <Box>
              <PlanCard data={packageBundleDetails} previewOnly />
            </Box>
          ) : buildBundleDetails ? (
            <>
              <Box>
                <BuildBundleCard data={buildBundleDetails} />
              </Box>
            </>
          ) : (
            <Box textAlign="center" py={5}>
              <Typography>Package Not Selected</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SummaryPlan;
