import PromotionLayout from "component/promotion";

const Promotion = () => {
  return (
    <>
      <PromotionLayout />
    </>
  );
};

export default Promotion;
