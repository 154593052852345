import Box from "@mui/material/Box";
import RechargeHistoryLayout from "component/my-account/recharge-history";
import MyAccountLayout from "parts/myAccountLayout";
import SectionTitle from "parts/sectionTitle";
import React from "react";

const RechargeHistory: React.FC = () => {
  return (
    <>
      <MyAccountLayout>
        <SectionTitle title="Recharge History" />
        <Box pt={5} className="fm-poppins">
          <RechargeHistoryLayout />
        </Box>
      </MyAccountLayout>
    </>
  );
};

export default RechargeHistory;
