import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CustomStepper from "component/_common/customStepper";
import useScrollToHash from "hooks/useScrollToHash";
import CustomAlert from "parts/customAlert";
import Footer from "parts/footer";
import Header from "parts/header";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, useLocation } from "react-router-dom";
import {
  allowedPathsForBooking,
  clearPhoneBookingData,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import AuthCheckLayout from "./authCheckLayout";
import AllRoutes from "./routes";
import RoutingFunction from "./routingFunction";

const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  useScrollToHash();
  const Dispatch = useDispatch();

  const { isLoading } = useSelector((state: RootState) => state.loading);
  const alert = useSelector((state: RootState) => state.alert);

  useEffect(() => {
    const listener = () => {
      window.scrollTo(0, 0);
    };
    listener();
    return () => {
      listener();
    };
  }, [pathname]);

  useEffect(() => {
    if (!allowedPathsForBooking?.includes(pathname)) {
      Dispatch(clearPhoneBookingData());
    }
  }, [pathname, Dispatch, allowedPathsForBooking]);

  return (
    <>
      <AuthCheckLayout>
        <Header />

        <main>
          <Routes>
            {AllRoutes.map((route, index) => RoutingFunction(route, index))}
          </Routes>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 99999,
            }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {alert?.showAlert ? <CustomAlert /> : <></>}
          <CustomStepper />
        </main>
        <Footer />
      </AuthCheckLayout>
    </>
  );
};

export default Navigation;
