interface Props {
  searchNumber?: string;
}

const defaultData: Props = {
  searchNumber: "",
};

export class SearchNumberErrorModel {
  searchNumber?: string;

  constructor(data: Props = defaultData) {
    this.searchNumber = data.searchNumber || "";
  }
}
