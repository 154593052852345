import axios, { CancelTokenSource } from "axios";
import { BASE_URL } from "config/endpoint";
import { ClearDataOnLogout } from "./helper";

interface HTTPProps {
  Method: string;
  Url: string;
  Data?: object;
  isFormData?: boolean;
  baseURL?: string;
  isCancellable?: boolean; // Add this property to control if the request should be cancellable
}

// Map to store cancel tokens
const cancelTokenMap = new Map<string, CancelTokenSource>();

export const HTTP = ({
  Method,
  Url,
  Data,
  isFormData,
  baseURL,
  isCancellable = true,
}: HTTPProps) => {
  const axiosInstance = axios.create({
    baseURL: baseURL || BASE_URL, // Use provided baseURL or default BASE_URL
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  // Response Interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) {
        // console.error(`Request canceled: ${error.message}`);
      } else if (error?.response?.status === 401) {
        ClearDataOnLogout();
      }
      return Promise.reject(error);
    }
  );

  let token: string | null = localStorage.getItem("token");
  let signature: string | null = localStorage.getItem("signature");

  let _headers: any = {
    Authorization: token ? `Bearer ${token}` : null,
    "x-auth-signature": signature || null,
  };

  if (isFormData) {
    _headers["Content-Type"] = "multipart/form-data";
  }

  let cancelTokenSource: CancelTokenSource | undefined;

  if (isCancellable) {
    // Check if there is an ongoing request for the same URL
    if (cancelTokenMap.has(Url)) {
      cancelTokenSource = cancelTokenMap.get(Url);
      if (cancelTokenSource) {
        cancelTokenSource.cancel(`Cancelled request for ${Url}`);
        cancelTokenMap.delete(Url);
      }
    }

    // Create a new cancel token for the current request
    cancelTokenSource = axios.CancelToken.source();
    cancelTokenMap.set(Url, cancelTokenSource);
  }

  return axiosInstance({
    method: Method,
    url: Url,
    data: Data,
    headers: _headers,
    // cancelToken:
    //   cancelTokenSource && process.env.REACT_APP_ENVIRONMENT === "prod"
    //     ? cancelTokenSource.token
    //     : undefined,
    cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
  });
  // .finally(() => {
  //   // Remove the cancel token from the map once the request is complete
  //   cancelTokenMap.delete(Url);
  // });
};

//GENERIC ERROR FUNCTION FOR PARSING ERRORS
export const HTTP_ERROR = (ERROR: any): string => {
  if (ERROR.response) {
    if (ERROR.response.status >= 500) {
      return "An unexpected error has occurred. 500 internal server error.";
    } else {
      return ERROR.response.data
        ? RES_ERROR(ERROR.response.data)
        : "An unexpected error has occurred";
    }
  } else return RES_ERROR(ERROR);
};

const RES_ERROR = (ERROR: { message: string }) => {
  if (ERROR.message) return ERROR.message;
  else {
    return "An unexpected error has occurred";
  }
};
