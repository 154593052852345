import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import { formatDataUsage } from "functions/helper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "state/slice/alert";
import { RootState } from "state/store";
import { calculateBuildBundleUnit } from "./calculateBuildBundleCost";

interface PlanCircleContainerProps {
  value?: number;
  title?: string;
  color?: string;
  primaryColor?: boolean;
  circularColorClass?: string;
  disableButtons?: boolean;
  minValue?: number;
  maxValue: number;
  name?: string;
  setState?: React.Dispatch<React.SetStateAction<calculateBuildBundleUnit>>;
}

const PlanCircleContainer: React.FC<PlanCircleContainerProps> = ({
  value,
  title,
  minValue = 0,
  maxValue,
  name,
  setState,
  color,
  primaryColor,
  circularColorClass,
  disableButtons,
}) => {
  const Dispatch = useDispatch();

  const { bookingData } = useSelector((state: RootState) => state.phoneBooking);

  const displayValue = (val: number) =>
    name === "dataMb" ? formatDataUsage(val) : val;

  const handleIncrease = () => {
    if (bookingData?.promotionDetails?.promotionId) {
      return;
    }
    if (setState && name) {
      setState((prev) => {
        let newValue = prev[name as keyof calculateBuildBundleUnit] || 0;

        // Handle the case for dataMb separately
        if (name === "dataMb") {
          newValue += 512; // Increase by half a GB (512 MB)
        } else {
          newValue += 10; // For other fields, increase by 10
        }

        return {
          ...prev,
          [name]: maxValue > 0 ? Math.min(newValue, maxValue) : newValue,
        };
      });
    }
  };

  const handleDecrease = () => {
    if (bookingData?.promotionDetails?.promotionId) {
      return;
    }
    if (setState && name) {
      setState((prev) => {
        let prevValue = prev[name as keyof calculateBuildBundleUnit] || 0;
        let newValue = prevValue;

        // Handle the case for dataMb separately
        if (name === "dataMb") {
          newValue -= 512; // Decrease by half a GB (512 MB)
        } else {
          newValue -= 10; // For other fields, decrease by 10
        }

        if (newValue < minValue) {
          Dispatch(
            setAlert({
              message: `${title} cannot be less than ${displayValue(minValue)}`,
              type: "error",
            })
          );
          return {
            ...prev,
            [name]: minValue,
          };
        }

        return {
          ...prev,
          [name]: newValue,
        };
      });
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (bookingData?.promotionDetails?.promotionId) {
      return;
    }
    if (typeof newValue === "number" && setState && name) {
      if (newValue < minValue) {
        Dispatch(
          setAlert({
            message: `${title} cannot be less than ${displayValue(minValue)}`,
            type: "error",
          })
        );
        setState((prev) => ({
          ...prev,
          [name]: minValue,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          [name]: maxValue > 0 ? Math.min(newValue, maxValue) : newValue,
        }));
      }
    }
  };

  const getColorClass = (baseClass: string) =>
    color === "white" ? "text-white" : baseClass;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        sx={{
          border: "1px solid rgba(178, 175, 175, 0.3)",
          borderColor: color === "white" ? "white" : "",
          borderRadius: "50%",
          height: 120,
          width: 120,
          position: "relative",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={value ? (value / maxValue) * 100 : 0}
          size={120}
          className={
            circularColorClass ||
            (primaryColor ? "fc-primary" : "fc-primary-var")
          }
        />
        <Box
          className={`f-s-12 ${getColorClass("")}`}
          sx={{
            position: "absolute",
            width: "90%",
            height: "90%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            fontWeight={600}
            fontSize={18}
            className={`fm-poppins ${getColorClass("")}`}
          >
            {value && displayValue(value)}
          </Box>

          <Box className={getColorClass("text-muted")}>{title}</Box>
        </Box>
      </Box>
      <Slider
        value={value}
        onChange={handleSliderChange}
        aria-labelledby="continuous-slider"
        step={1}
        max={maxValue}
        disabled={Boolean(bookingData?.promotionDetails?.promotionId)}
      />
      {!disableButtons && (
        <Box className="d-flex">
          <IconButton
            onClick={handleDecrease}
            disabled={
              Boolean(bookingData?.promotionDetails?.promotionId) ||
              (value !== undefined && value < minValue)
            }
          >
            <RemoveCircleOutlinedIcon
              className={getColorClass("")}
              sx={{ fontSize: 42, color: "black" }}
            />
          </IconButton>
          <IconButton
            onClick={handleIncrease}
            disabled={Boolean(bookingData?.promotionDetails?.promotionId)}
          >
            <AddCircleOutlinedIcon
              className={
                circularColorClass ||
                (primaryColor ? "fc-primary" : "fc-primary-var")
              }
              sx={{ fontSize: 42 }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PlanCircleContainer;
