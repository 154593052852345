import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import FormHelperText from "@mui/material/FormHelperText";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { WalletPinChangeModel } from "_models/data/wallet/data.walletPinChange.model";
import { WalletPinChangeErrorModel } from "_models/errors/wallet/error.walletPinChange.model";
import OtpInput from "component/_common/forms/otpInput";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { ChangeWalletPin, CreateWallet } from "functions/http-requests/wallet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { RootState } from "state/store";
import { validateCreateWallet } from "./formValidator";

interface WalletPinChangeProps {
  token?: string;
  handleRefresh: () => void;
  handleClose: () => void;
}

const WalletPinChange: React.FC<WalletPinChangeProps> = ({
  token,
  handleRefresh,
  handleClose,
}) => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();

  const [state, setState] = useState<WalletPinChangeModel>(
    new WalletPinChangeModel()
  );
  const [errors, setErrors] = useState<WalletPinChangeErrorModel>(
    new WalletPinChangeErrorModel()
  );

  const handleChange = (newValue: string) => {
    setState((prev) => ({ ...prev, PIN: newValue }));
  };

  const handleWalletPinChange = () => {
    Dispatch(toggleLoading(true));
    ChangeWalletPin({
      DATA: {
        token: state.token || "",
        PIN: state.PIN || "",
      },
    })
      .then((res) => {
        Dispatch(
          setAlert({
            type: res?.data?.level || "error",
            message: res?.data?.message,
          })
        );
        if (res?.data?.statusCode === AppStatusCode.api_success) {
          handleRefresh();
          handleClose();
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => Dispatch(toggleLoading(false)));
  };

  const handleSubmit = (): void => {
    let ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateCreateWallet(state);

    if (!ValidateStep?.valid) {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
      return;
    }

    if (!state.token) {
      Dispatch(setAlert({ message: "User not found", type: "error" }));
      return;
    }

    handleWalletPinChange();
  };

  useEffect(() => {
    if (errors?.PIN && state?.PIN?.trim()) {
      setErrors((prev) => ({ ...prev, PIN: "" }));
    }
  }, [state.PIN, errors.PIN]);

  useEffect(() => {
    if (token) {
      setState((prev) => ({ ...prev, token: token }));
    }
  }, [token]);

  return (
    <>
      <DialogContent>
        <Box
          sx={{
            margin: "auto",
            textAlign: "center",
            maxWidth: 400,
            p: 3,
          }}
        >
          <Typography
            variant="h2"
            fontSize={36}
            fontWeight={700}
            textAlign="center"
            sx={{ textTransform: "capitalize" }}
          >
            Change Wallet PIN
          </Typography>

          <Typography>Reset your 6 digit PIN</Typography>

          <Box>
            <OtpInput
              code={state.PIN || ""}
              handleChange={handleChange}
              maximumLength={6}
            />

            {errors?.PIN ? (
              <Box pt={1}>
                <FormHelperText
                  sx={{ color: palette.error.main, textAlign: "center" }}
                >
                  {errors.PIN}
                </FormHelperText>
              </Box>
            ) : (
              <></>
            )}

            <Box pt={5} display="flex" flexDirection="column" gap={1}>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                  state?.PIN && state?.PIN?.trim()?.length === 6 ? false : true
                }
              >
                Change Wallet PIN
              </Button>
              <Button
                onClick={handleClose}
                fullWidth
                variant="text"
                disableElevation
                color="inherit"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};

export default WalletPinChange;
