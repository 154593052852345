import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import RouteConstant from "navigation/constant";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DispatchBookingStep } from "state/slice/phoneBooking";
import { RootState } from "state/store";

interface Props {
  handleOpen: () => void;
  setReSelectingNumber: React.Dispatch<React.SetStateAction<boolean>>;
}
const ConfirmNumberAndContinue: React.FC<Props> = ({
  handleOpen,
  setReSelectingNumber,
}) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { bookingData, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const handleContinue = () => {
    Dispatch(DispatchBookingStep(stepIndex + 1));
    const couponToken = searchParams.get("ct"); // Get couponToken from URL
    if (couponToken) {
      navigate(`${RouteConstant.CREATE_ACCOUNT}?ct=${couponToken}`);
    } else {
      navigate(RouteConstant.CREATE_ACCOUNT);
    }
  };

  return (
    <>
      {bookingData?.phoneDetails ? (
        <>
          <Typography variant="caption">Confirm the number</Typography>
          <Typography>
            You have chosen the one you{" "}
            <Box component="span" color="#FF0000" fontWeight={600}>
              love
            </Box>
          </Typography>
          <Box py={{ xs: 2, sm: 5 }}>
            <Typography
              variant="h4"
              sx={{
                backgroundColor: alpha("#FFF", 0.15),
                padding: "4px 16px",
                borderRadius: "8px",
                fontSize: {
                  xs: 30,
                  sm: 36,
                },
              }}
              className="fm-poppins"
            >
              {bookingData?.phoneDetails.phoneNumber}
            </Typography>
          </Box>
          <Box
            pb={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              id="next-step-for-account-details"
              variant="contained"
              onClick={handleContinue}
              sx={{
                mb: { xs: 1, sm: 3 },
                fontSize: { xs: 14, sm: 16 },
                paddingLeft: { xs: 2, sm: 3 },
                paddingRight: { xs: 2, sm: 3 },
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#000",
                },
              }}
            >
              yes I like this number
            </Button>

            <CustomJoyRide
              steps={steps}
              stepIndex={stepIndex}
              run={!!bookingData?.phoneDetails?._id}
            />

            <Button
              onClick={() => {
                handleOpen();
                setReSelectingNumber(true);
              }}
              variant="outlined"
              sx={{
                fontSize: { xs: 14, sm: 16 },
                paddingLeft: { xs: 2, sm: 3 },
                paddingRight: { xs: 2, sm: 3 },
                color: "#000",
                borderColor: "#000",
                "&:hover": {
                  borderColor: "#000",
                },
              }}
            >
              No Choose A Number
            </Button>
          </Box>{" "}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConfirmNumberAndContinue;
