import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import InputText from "component/_common/forms/inputText";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  PhoneBookingApplyCoupon,
  PhoneBookingRemoveCoupon,
} from "functions/http-requests/phoneBooking";
import React, { ChangeEvent, Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import {
  DispatchBillDetails,
  DispatchBookingDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { PhoneBookingApplyCouponProps } from "_interfaces/http-requests/phoneBooking";
import { ApplyCouponModel } from "_models/data/data.applyCoupon.model";
import { ApplyCouponErrorModel } from "_models/errors/error.applyCoupon.model";
import { validateApplyCoupon } from "./formValidator";

const ApplyCouponForm = () => {
  const { palette } = useTheme();
  const Dispatch = useDispatch();

  const { bookingData } = useSelector((state: RootState) => state.phoneBooking);

  const [state, setState] = useState<ApplyCouponModel>(new ApplyCouponModel());
  const [errors, setErrors] = useState<ApplyCouponErrorModel>(
    new ApplyCouponErrorModel()
  );

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setState((prev) => ({
      ...prev,
      [name]: value?.toUpperCase(),
      searchResult: null,
    }));
  };

  const handleApplyCoupon = () => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateApplyCoupon(state);

    if (ValidateStep?.valid) {
      const PAYLOAD_DATA: PhoneBookingApplyCouponProps["DATA"] = {
        coupon: state?.coupon || undefined,
        bookingId: bookingData?.bookingDetails?._id,
      };
      Dispatch(toggleLoading(true));
      PhoneBookingApplyCoupon({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          const bookingData = data?.data?.booking;
          const billData = data?.data?.bill;
          const discountedInfo = data?.data?.discountedInfo;
          if (
            data?.statusCode === AppStatusCode.api_success &&
            bookingData?._id &&
            billData?._id
          ) {
            Dispatch(
              DispatchBillDetails({
                _id: billData?._id,
                billID: billData?.billID,
                subTotalSimCharges: billData?.subTotalSimCharges,
                simChargesId: billData?.simChargesId,
                subTotalPackageCharges: billData?.subTotalPackageCharges,
                subTotalPackageChargesId: billData?.subTotalPackageChargesId,
                totalCharges: billData?.totalCharges,
                bundleBundleCharges: billData?.bundleBundleCharges,
                isCouponApplied: billData?.isCouponApplied || false,
                couponId: billData?.couponId,
                totalWithoutDiscount: billData?.totalWithoutDiscount,
                discountAmount: billData?.discountAmount,
              })
            );
          } else {
            if (!billData?.isCouponApplied && discountedInfo?.message) {
              Dispatch(
                setAlert({
                  type: data?.level,
                  message: discountedInfo?.message || "Something went wrong!",
                })
              );
            } else {
              Dispatch(
                setAlert({
                  type: data?.level,
                  message: data?.message || "Something went wrong!",
                })
              );
            }
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const handleRemoveCoupon = () => {
    if (bookingData?.bookingDetails?._id) {
      Dispatch(toggleLoading(true));
      PhoneBookingRemoveCoupon(bookingData?.bookingDetails?._id)
        .then((res) => {
          const data = res?.data;
          const bookingData = data?.data?.booking;
          const billData = data?.data?.bill;
          if (
            data?.statusCode === AppStatusCode.api_success &&
            bookingData?._id &&
            billData?._id
          ) {
            Dispatch(
              DispatchBillDetails({
                _id: billData?._id,
                billID: billData?.billID,
                subTotalSimCharges: billData?.subTotalSimCharges,
                simChargesId: billData?.simChargesId,
                subTotalPackageCharges: billData?.subTotalPackageCharges,
                subTotalPackageChargesId: billData?.subTotalPackageChargesId,
                totalCharges: billData?.totalCharges,
                bundleBundleCharges: billData?.bundleBundleCharges,
                isCouponApplied: billData?.isCouponApplied || false,
                couponId: billData?.couponId,
                totalWithoutDiscount: billData?.totalWithoutDiscount,
                discountAmount: billData?.discountAmount,
              })
            );
            setState((prev) => ({ ...prev, coupon: "" }));
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => Dispatch(toggleLoading(false)));
    }
  };

  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: 12,
            display: "inline-block",
          }}
        >
          {bookingData?.billDetails?.isCouponApplied ? (
            <Box component="span" color={palette.success.main}>
              Coupon successfully applied
            </Box>
          ) : (
            <Box component="span">Have a coupon code?</Box>
          )}
        </Typography>
      </Box>
      <Box>
        <InputText
          variant="standard"
          color="secondary"
          type="text"
          label="Enter Coupon Code"
          name="coupon"
          value={state?.coupon}
          error={!!errors?.coupon}
          errorText={errors?.coupon}
          onChange={handleChange}
          onFocus={handleFocus}
          disabled={bookingData?.billDetails?.isCouponApplied}
          InputProps={{
            endAdornment: state?.coupon?.trim() ? (
              <InputAdornment position="end">
                {bookingData?.billDetails?.isCouponApplied ? (
                  <Button
                    onClick={handleRemoveCoupon}
                    sx={{
                      py: 0.5,
                      px: 0.5,
                      fontSize: 14,
                    }}
                  >
                    Remove
                  </Button>
                ) : (
                  <Button
                    onClick={handleApplyCoupon}
                    sx={{
                      py: 0.5,
                      px: 0.5,
                      fontSize: 14,
                    }}
                  >
                    Apply
                  </Button>
                )}
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default ApplyCouponForm;
