import SimCardIcon from "@mui/icons-material/SimCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { PhoneDetailsProps } from "_interfaces/phoneBooking";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import RechargeNumberLayout from "../recharge/rechargeNumberLayout";

interface Props {
  phoneDetails?: PhoneDetailsProps | null;
  phoneNumber?: string;
  title?: string;
  showChangeButton?: boolean;
}

const SummaryNumber: React.FC<Props> = ({
  phoneDetails,
  phoneNumber,
  title,
  showChangeButton,
}) => {
  const [openRechargeNumberLayout, setOpenRechargeNumberLayout] =
    useState<boolean>(false);

  const handleToggleRechargeNumberLayout = () =>
    setOpenRechargeNumberLayout(!openRechargeNumberLayout);

  return (
    <>
      <Card
        sx={{ bgcolor: "grey.100", mb: 3, boxShadow: "none", borderRadius: 4 }}
      >
        <CardContent>
          <Box display="flex" alignItems="center">
            <SimCardIcon color="primary" sx={{ fontSize: 48 }} />
            <Box className="fm-poppins" pl={1}>
              <Typography fontSize={14} fontWeight={400}>
                {title || "Your New Number"}
              </Typography>
              <Typography
                fontSize={phoneDetails?.phoneNumber || phoneNumber ? 24 : 18}
                fontWeight={phoneDetails?.phoneNumber || phoneNumber ? 600 : ""}
                lineHeight={1.3}
              >
                {phoneDetails?.phoneNumber || phoneNumber || "Not Selected"}
              </Typography>
            </Box>
            {showChangeButton ? (
              <Box pl={3} marginLeft="auto">
                <Button
                  variant="text"
                  onClick={handleToggleRechargeNumberLayout}
                  sx={{ fontSize: 18, fontWeight: 600 }}
                >
                  Change
                </Button>
              </Box>
            ) : null}
          </Box>
        </CardContent>
      </Card>

      {/* Recharge Number */}
      <Dialog
        open={openRechargeNumberLayout}
        onClose={handleToggleRechargeNumberLayout}
        maxWidth="sm"
        fullWidth
      >
        <RechargeNumberLayout onClose={handleToggleRechargeNumberLayout} />
      </Dialog>
    </>
  );
};

export default SummaryNumber;
