import Button from "@mui/material/Button";
import RouteConstant from "navigation/constant";

const CancelPhoneBooking = () => {
  const handleCancel = () => {
    window.location.href = RouteConstant.HOME;
  };

  return (
    <>
      <Button
        variant="text"
        color="error"
        size="small"
        onClick={handleCancel}
        sx={{
          p: 0,
          fontSize: 14,
          "&:hover": {
            backgroundColor: "transparent",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        Cancel booking
      </Button>
    </>
  );
};

export default CancelPhoneBooking;
