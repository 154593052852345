import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "state/slice/alert";

const useDisableBackButton = () => {
  const Dispatch = useDispatch();

  useEffect(() => {
    // Push state to initialize the history stack
    window.history.pushState({}, "", document.URL);

    const handlePopState = () => {
      window.history.pushState({}, "", document.URL);
      // Show Alert
      Dispatch(
        setAlert({
          message: "Oops! The back button is disabled.",
          type: "error",
        })
      );
    };

    // Add event listener for popstate
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [Dispatch]);
};

export default useDisableBackButton;
