import Box from "@mui/material/Box";
import {
  PromotionDataProps,
  showInPageProps,
} from "_interfaces/_common/promotion";
import BannerImage from "assets/img/static/bg/products-banner.jpg";
import ProductsLayout from "component/products";
import { PromotionGetAll } from "functions/http-requests/promotion";
import moment from "moment";
import BuildBundleBanner from "parts/banners/buildBundleBanner";
import InnerPageTopBanner3 from "parts/banners/innerPageTopBanner/innerPageTopBanner3";
import LimitedTimeOfferBanner from "parts/banners/limitedTimeofferBanner";
import FrequentlyQuestions from "parts/frequentlyQuestions";
import MainContainer from "parts/mainContainer";
import { useEffect, useState } from "react";

const Products = () => {
  const [promotions, setPromotions] = useState<PromotionDataProps[] | null>(
    null
  );

  // GET PROMOTIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      PromotionGetAll({
        DATA: {
          showInPage: showInPageProps.products,
        },
      })
        .then((res) => {
          let DATA: PromotionDataProps[] = res?.data?.data;

          const itemCondition = (item: PromotionDataProps): boolean =>
            item?.showInPage.includes(showInPageProps.products) &&
            moment(item.expiryDate).isAfter(moment()) &&
            item?.isActive;

          if (
            Array.isArray(DATA) &&
            DATA?.length &&
            DATA?.some((item) => itemCondition(item))
          ) {
            DATA = DATA?.filter((item) => itemCondition(item));
            setPromotions(DATA);
          }
        })
        .catch((e) => {});
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <>
      <InnerPageTopBanner3
        bannerImage={BannerImage}
        title="Product"
        upperTitle="Choose a"
        subTitle="That Fits Your Needs"
      />

      <section>
        <MainContainer>
          <Box py={5}>
            <ProductsLayout />
          </Box>
        </MainContainer>
      </section>

      {promotions?.some((item) => item.UI.theme === 1) ? (
        <section>
          <MainContainer>
            <LimitedTimeOfferBanner
              data={promotions?.find((item) => item.UI.theme === 1)}
            />
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      {promotions?.some((item) => item.UI.theme === 2) ? (
        <section>
          <MainContainer>
            <Box pt={5}>
              <BuildBundleBanner
                isImageFirst
                data={promotions?.find((item) => item.UI.theme === 2)}
              />
            </Box>
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      <section>
        <MainContainer>
          <Box py={5}>
            <FrequentlyQuestions />
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default Products;
