import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatDataUsage, formatPriceWithDelimiter } from "functions/helper";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { minimumPriceForBuildBundle } from "_interfaces/http-requests/packageBundle";
import {
  calculateBuildBundleReturn,
  calculateBuildBundleUnit,
} from "./calculateBuildBundleCost";

interface Props {
  bundleCalculation: calculateBuildBundleReturn;
  bundleUnitState: calculateBuildBundleUnit;
  handleContinueBuildBundle: () => void;
  isRecharge?: boolean;
}

const BuildBundleCalculation: React.FC<Props> = ({
  bundleCalculation,
  bundleUnitState,
  handleContinueBuildBundle,
  isRecharge,
}) => {
  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );

  let row = [
    { field: "dataPrice", unitField: "dataMb", headerName: "DATA" },
    {
      field: "onNetPrice",
      unitField: "onNetVoice",
      headerName: "On Net Voice",
    },
    {
      field: "offNetPrice",
      unitField: "offNetVoice",
      headerName: "All Net Voice",
    },
    { field: "smsPrice", unitField: "smsOffNet", headerName: "SMS Off Net" },
    {
      field: "totalPrice",
      unitField: "",
      headerName: "Total",
      renderCell: () => {},
    },
  ];

  return (
    <>
      <Box maxWidth={1200} margin="auto">
        {!isRecharge &&
        bundleCalculation?.totalPrice > 0 &&
        bundleCalculation?.totalPrice < minimumPriceForBuildBundle ? (
          <>
            <Box className="fm-poppins" pt={2}>
              <Alert severity="info" sx={{ fontFamily: "inherit" }}>
                Add more items worth{" "}
                <Box
                  component="span"
                  fontSize={16}
                  fontWeight={600}
                  sx={{ fontFamily: "inherit" }}
                >
                  {formatPriceWithDelimiter(
                    minimumPriceForBuildBundle - bundleCalculation.totalPrice
                  )}
                </Box>{" "}
                to proceed with your booking.
              </Alert>
            </Box>
          </>
        ) : (
          <></>
        )}

        {bookingData?.promotionDetails?.promotionId ? (
          <Box className="fm-poppins" fontSize={24} pt={2}>
            <Alert
              severity="success"
              sx={{
                fontFamily: "inherit",
                fontSize: 18,
                fontWeight: 600,
                "& .MuiAlert-icon": {
                  fontSize: 28,
                },
              }}
            >
              Promotion Successfully Applied!
            </Alert>
          </Box>
        ) : (
          <></>
        )}

        <Box pt={3}>
          <Grid container spacing={3} justifyContent="center">
            {row?.map((item, i, arr) => {
              const priceValue =
                bundleCalculation?.[
                  item?.field as keyof calculateBuildBundleReturn
                ];
              const originalPriceValue =
                bundleCalculation?.[
                  `original_${item?.field}` as keyof calculateBuildBundleReturn
                ];
              const unitValue =
                bundleUnitState?.[
                  item?.unitField as keyof calculateBuildBundleUnit
                ];
              return (
                <Grid item key={i} xs={6} sm={4} md={2.4}>
                  <Box
                    sx={{
                      height: "100%",
                      borderRight:
                        i !== arr.length - 1 ? `1px solid #EAEAEA` : "0",
                      py: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#7A7474",
                        fontSize: 16,
                        lineHeight: 1,
                      }}
                    >
                      {item.headerName}
                    </Typography>
                    {bookingData?.promotionDetails?.promotionId &&
                    originalPriceValue ? (
                      <Box color="error.main">
                        <Typography
                          fontWeight={400}
                          color="inherit"
                          fontSize={14}
                        >
                          <Box
                            component="span"
                            sx={{
                              textDecoration: "line-through",
                            }}
                          >
                            UGX
                          </Box>{" "}
                          <Box
                            component="span"
                            fontSize={{ xs: 20, sm: 24 }}
                            fontWeight={600}
                            sx={{
                              textDecoration: "line-through",
                              lineHeight: 1.3,
                            }}
                          >
                            {formatPriceWithDelimiter(originalPriceValue)}
                          </Box>
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    <Typography
                      fontWeight={400}
                      className="fc-primary-var"
                      fontSize={{ xs: 16, sm: 18 }}
                    >
                      UGX{" "}
                      <Box
                        component="span"
                        fontSize={{ xs: 22, sm: 30 }}
                        fontWeight={600}
                        sx={{
                          lineHeight: 1.3,
                        }}
                      >
                        {formatPriceWithDelimiter(priceValue)}
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#7A7474",
                        fontSize: 14,
                        lineHeight: 1,
                      }}
                    >
                      {unitValue ? (
                        <>
                          {item?.unitField === "dataMb"
                            ? formatDataUsage(unitValue)
                            : item?.unitField === "smsOffNet"
                            ? `${unitValue} SMS`
                            : `${unitValue} Mins`}
                        </>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Box pt={3} textAlign="center">
          {(!isRecharge &&
            bundleCalculation?.totalPrice > 0 &&
            bundleCalculation?.totalPrice >= minimumPriceForBuildBundle) ||
          (isRecharge && bundleCalculation?.totalPrice > 0) ? (
            <>
              <Box pt={2}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleContinueBuildBundle}
                  sx={{
                    maxWidth: 280,
                  }}
                >
                  Continue
                </Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default BuildBundleCalculation;
