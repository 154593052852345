import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import { GetCustomerByPhone } from "functions/http-requests/recharge";
import SectionTitle from "parts/sectionTitle";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { DispatchRechargeData } from "state/slice/rechargePlan";
import { RootState } from "state/store";
import { RechargeNumberModel } from "_models/data/recharge/data.rechargeNumber.model";
import { RechargeNumberErrorModel } from "_models/errors/recharge/error.rechargeNumber.model";
import InputText from "../forms/inputText";
import { validateRechargeNumber } from "./formValidator";

interface Props {
  onClose?: () => void;
  handleContinue?: () => void;
}

const RechargeNumberLayout: React.FC<Props> = ({ onClose, handleContinue }) => {
  const Dispatch = useDispatch();

  const { rechargeData } = useSelector(
    (state: RootState) => state.rechargePlan
  );

  const [state, setState] = useState<RechargeNumberModel>(
    new RechargeNumberModel()
  );
  const [errors, setErrors] = useState<RechargeNumberErrorModel>(
    new RechargeNumberErrorModel()
  );

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    if (errors?.[name as keyof RechargeNumberErrorModel]) {
      handleFocus(name);
    }

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleGetCustomer = () => {
    if (state?.phoneNumber) {
      Dispatch(toggleLoading(true));
      GetCustomerByPhone(state.phoneNumber)
        .then((res) => {
          const DATA = res?.data;

          if (
            DATA?.statusCode === AppStatusCode.api_success &&
            DATA?.data?._id &&
            DATA?.data?.MSISDN
          ) {
            Dispatch(
              DispatchRechargeData({
                phoneNumber: DATA.data.MSISDN,
              })
            );
            if (onClose) onClose();
            if (handleContinue) handleContinue();
          } else {
            setErrors((prev) => ({
              ...prev,
              phoneNumber:
                "The number you have entered is not a registered number. Please enter a valid number and try again.",
            }));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validateRechargeNumber(state);

    if (ValidateStep?.valid) {
      handleGetCustomer();
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  useEffect(() => {
    if (rechargeData?.phoneNumber) {
      setState((prev) => ({ ...prev, phoneNumber: rechargeData.phoneNumber }));
    }
  }, [rechargeData?.phoneNumber]);

  const inputList = [
    {
      type: "number",
      name: "phoneNumber",
      label: "Enter Phone Number",
    },
  ];

  return (
    <>
      <DialogContent>
        <Box margin="auto" py={{ xs: 1, sm: 3 }} px={{ xs: 1, sm: 3, lg: 5 }}>
          <SectionTitle
            title="Mobile Recharge"
            subTitle="Enter your details to proceed"
            sx={{
              fontSize: {
                xs: 18,
                sm: 30,
              },
              fontWeight: 600,
            }}
          />

          <Box pt={5}>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {inputList?.map((item, i) => (
                  <Fragment key={i}>
                    <Grid item xs={12}>
                      <InputText
                        type={item?.type}
                        label={item?.label}
                        name={item?.name}
                        value={state?.[item?.name as keyof RechargeNumberModel]}
                        error={
                          !!errors?.[
                            item?.name as keyof RechargeNumberErrorModel
                          ]
                        }
                        errorText={
                          errors?.[item?.name as keyof RechargeNumberErrorModel]
                        }
                        onChange={handleChange}
                        onFocus={() => handleFocus(item?.name)}
                      />
                    </Grid>
                  </Fragment>
                ))}

                <Grid item xs={12}>
                  <Box pt={5}>
                    <Button fullWidth variant="contained" type="submit">
                      Continue
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="inherit"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};

export default RechargeNumberLayout;
