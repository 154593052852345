import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FC } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const CustomExpandIcon: FC = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <Box className="expandIconWrapper">
        <RemoveIcon />
      </Box>
      <Box className="collapsIconWrapper">
        <AddIcon />
      </Box>
    </Box>
  );
};

interface Props {
  index: number;
  item: {
    title: string;
    desc: string;
  };
  expandedIndexes?: number[];
  handleAccordionChange?: (index: number, isExpanded: boolean) => void;
}

const FrequentlyQuestionItem: React.FC<Props> = ({
  index,
  item,
  expandedIndexes,
  handleAccordionChange,
}) => {
  return (
    <>
      <Accordion
        expanded={expandedIndexes ? expandedIndexes.includes(index) : undefined}
        onChange={
          handleAccordionChange
            ? (_, isExpanded) => handleAccordionChange(index, isExpanded)
            : undefined
        }
        sx={{
          "&.MuiAccordion-root": {
            background: "transparent",
            borderLeft: 0,
            borderRight: 0,
            "&:first-of-type": {
              borderTop: 0,
            },
            px: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<CustomExpandIcon />}
          sx={{ fontWeight: 500 }}
        >
          <Box display="flex" gap={2}>
            <Typography
              className="fc-black"
              fontSize={{ xs: 18, md: 22 }}
              fontWeight={500}
            >
              {index + 1 < 10 ? `0${index + 1}` : index + 1}
            </Typography>
            <Typography
              className="fc-black"
              fontSize={{ xs: 18, md: 22 }}
              fontWeight={500}
            >
              {item?.title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            pt={0.5}
            fontSize={{ xs: 14, xl: 16 }}
            fontWeight={400}
            sx={{
              color: "#545454",
              lineHeight: 1.6,
              "table td": {
                border: "1px solid #ccc",
                padding: "6px 12px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: item.desc }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FrequentlyQuestionItem;
