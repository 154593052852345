import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BannerImage from "assets/img/static/bg/premium-banner-2.jpeg";
import MainContainer from "parts/mainContainer";

const PremiumInfo = () => {
  const sliderList = [
    {
      title: "Choose Your Premium Plan",
      desc: "Experience the best of Talkio with our Premium Plans, tailored to provide ultimate convenience, value, and flexibility for all types of users. Whether you need more data, unlimited calls, or international minutes, there's a plan designed for you.",
      buttonText: "Read Now",
      link: "",
    },
  ];
  return (
    <>
      <section>
        <MainContainer disablePadding>
          <Box
            py={10}
            sx={{
              background: `linear-gradient(#01010175, #01010175), url(${BannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  className="fc-white fm-poppins"
                  p={{ xs: 2, sm: 8 }}
                  sx={{
                    background: "#221E1E4D",
                    position: "relative",
                  }}
                >
                  {sliderList.map(({ title, desc, buttonText, link }, i) => (
                    <Box key={i}>
                      <Typography
                        fontSize={{ xs: 24, sm: 30 }}
                        fontWeight={600}
                        lineHeight={1}
                      >
                        {title}
                      </Typography>
                      <Typography
                        fontSize={{ xs: 14, sm: 16 }}
                        fontWeight={300}
                        pt={2}
                        letterSpacing="1.1px"
                        lineHeight={1.7}
                      >
                        {desc}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default PremiumInfo;
