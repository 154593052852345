import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import role from "config/role";
import { AuthCheck } from "functions/http-requests/auth";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { setLogout, setUser } from "state/slice/user";
import { RootState } from "state/store";

interface Props {
  children: any;
}

const AuthCheckLayout: React.FC<Props> = ({ children }) => {
  const Dispatch = useDispatch();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { userInfo } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let AuthenticateToken: (() => void) | null = async () => {
      let signature = localStorage.getItem("signature") || userInfo?.signature;
      let token = localStorage.getItem("token") || userInfo.token;
      let userId = localStorage.getItem("userId") || userInfo.userId;

      Dispatch(toggleLoading(true));
      if (signature && token) {
        await AuthCheck({
          DATA: {
            signature,
            accessToken: token,
            userId,
          },
        })
          .then((res) => {
            const DATA = res?.data?.data;
            if (DATA.role === role.CUSTOMER) {
              if (DATA?.refreshToken) {
                localStorage.setItem("token", DATA.refreshToken);
              }
              Dispatch(setUser(DATA));
              setIsAuthenticating(false);
              Dispatch(toggleLoading(false));
            } else {
              Dispatch(setLogout());
              setIsAuthenticating(false);
              Dispatch(toggleLoading(false));
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.error("Auth check request was canceled:", error.message);
            } else {
              Dispatch(setLogout());
              setIsAuthenticating(false);
              Dispatch(toggleLoading(false));
            }
          });
        // .finally(() => {
        //   Dispatch(toggleLoading(false));
        //   setIsAuthenticating(false);
        // });
      } else {
        Dispatch(toggleLoading(false));
        setIsAuthenticating(false);
        Dispatch(setLogout());
      }
    };
    AuthenticateToken();

    return () => {
      AuthenticateToken = null;
    };
  }, [Dispatch, userInfo.signature, userInfo.token]);

  if (isAuthenticating)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 99999 }}
        open={isAuthenticating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return <>{children}</>;
};

export default AuthCheckLayout;
