import TalkioImage from "assets/img/static/bg/talkio-sim.png";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import Logo from "assets/img/static/logo-original-without-bg.png";
import {
  fontSizeMap,
  formatFontSize,
  isValidFontSize,
  PromotionDataProps,
} from "_interfaces/_common/promotion";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "state/store";
import { BuildBundleProps } from "_interfaces/phoneBooking";
import {
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import RouteConstant from "navigation/constant";

interface Props {
  data?: PromotionDataProps;
}
const PromotionalBanner: React.FC<Props> = ({ data }) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();

  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );

  const handleButtonClick = () => {
    if (data?._id) {
      let intervalId = data?.buildBundleData?.intervalId || undefined;

      let dataMb = data?.buildBundleData?.dataMb || 0;
      let onNetVoice = data?.buildBundleData?.onNetVoice || 0;
      let offNetVoice = data.buildBundleData?.offNetVoice || 0;
      let smsOffNet = data.buildBundleData?.smsOffNet || 0;

      if (dataMb) {
        dataMb = data?.rechargeDataUnit === "gb" ? dataMb * 1024 : dataMb;
      }

      let newData: BuildBundleProps = {
        ...bookingData.buildBundleMinimumRequirement,
        onNetVoice,
        offNetVoice,
        dataMb,
        smsOffNet,
      };

      if (bookingData?.buildBundleDetails) {
        newData = {
          ...bookingData.buildBundleDetails,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        };
      }

      Dispatch(DispatchBuildBundleDetails(newData));

      if (intervalId) {
        Dispatch(DispatchBuildBundleIntervalId(intervalId));
      }

      Dispatch(
        DispatchPromotionDetails({
          promotionId: data._id,
          amount: data.amount || 0,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );
      Dispatch(
        DispatchBuildBundleMinimumRequirement({
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );

      navigate(`${RouteConstant.PRODUCT_BUILD_BUNDLE}?promotion=${data?._id}`);
    }
  };

  return (
    <>
      {data?._id ? (
        <Box
          sx={{
            backgroundColor: "#1F1E1E",
            color: "#fff",
            borderRadius: 8,
            px: {
              xs: 3,
              sm: 5,
              lg: 10,
            },
            pt: 5,
            pb: 3,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6} lg={7}>
              <Box>
                {data?.UI?.title2?.show ? (
                  <Box
                    sx={{
                      fontSize: {
                        xs: 24,
                        sm: formatFontSize(
                          isValidFontSize(data?.UI?.title2?.size)
                            ? fontSizeMap[data?.UI?.title2?.size]
                            : "36"
                        ),
                      },
                      fontWeight: 400,
                      color: data?.UI?.title2?.color || "inherit",
                    }}
                    dangerouslySetInnerHTML={{ __html: data?.UI?.title2?.text }}
                  />
                ) : (
                  <></>
                )}

                {data?.UI?.title3?.show ? (
                  <Box
                    sx={{
                      fontSize: { xs: 48, sm: 80, lg: 166 },
                      fontWeight: 700,
                      lineHeight: 0.9,
                      color: data?.UI?.title3?.color || palette.primary.main,
                    }}
                    dangerouslySetInnerHTML={{ __html: data?.UI?.title3?.text }}
                  />
                ) : (
                  <></>
                )}

                {data?.UI?.title4?.show ? (
                  <Box
                    sx={{
                      fontSize: {
                        xs: 30,
                        sm: formatFontSize(
                          isValidFontSize(data?.UI?.title4?.size)
                            ? fontSizeMap[data?.UI?.title4?.size]
                            : "36"
                        ),
                      },
                      fontWeight: 400,
                      lineHeight: 1.2,
                    }}
                    dangerouslySetInnerHTML={{ __html: data?.UI?.title4?.text }}
                  />
                ) : (
                  <></>
                )}
              </Box>

              <Box pt={5} sx={{ display: "flex", gap: 2, maxWidth: 200 }}>
                <Button
                  onClick={handleButtonClick}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    flex: 1,
                    borderRadius: 1.2,
                    py: 2,
                  }}
                >
                  Book Now
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Box display="flex" justifyContent="center" pb={3}>
                <Box
                  component="img"
                  src={Logo}
                  alt="Talkio Mobile"
                  sx={{ width: 120 }}
                />
              </Box>

              <Box position="relative">
                <Box
                  sx={{
                    width: {
                      xs: 240,
                      sm: 360,
                    },
                    height: {
                      xs: 240,
                      sm: 360,
                    },
                    mx: "auto",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      background: palette.primary.main,
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      position: "absolute",
                      left: "-2%",
                      zIndex: -1,
                    }}
                  />
                  <Box
                    component="img"
                    src={TalkioImage}
                    alt="Book Ypour Talkio No"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(31,30,30,1) 40%)",
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    height: "40%",
                    zIndex: 1,
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "100%",
                    bottom: "15%",
                    zIndex: 1,
                  }}
                >
                  <Typography
                    sx={{ color: "#fff", fontSize: 14, fontWeight: 400 }}
                  >
                    #BookYourTalkioNo
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box pt={3} fontSize={10} fontWeight={400} color="gray">
            T&Cs Apply
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default PromotionalBanner;
