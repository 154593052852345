import { Box, Button, Grid } from "@mui/material";
import { AppStatusCode } from "config/appStatusCode";
import { generateAutocompleteOptions } from "functions/helper";
import { PackageBundleGetBuildBundleRates } from "functions/http-requests/packageBundle";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { DispatchBuildBundleIntervalId } from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { BuildBundleInterval } from "_interfaces/phoneBooking";
import { AutoCompleteOptionsProps } from "_interfaces/_common/forms";
import {
  BundleRatesProps,
  calculateBuildBundle,
  calculateBuildBundleReturn,
  calculateBuildBundleUnit,
  getDataMbRate,
  getOffNetVoiceRate,
  getOnNetVoiceRate,
  getSmsOffNetRate,
  unitRate,
} from "./calculateBuildBundleCost";
import PlanCircleContainer from "./planCircleContainer";

interface Props {
  bundleUnitState: calculateBuildBundleUnit;
  setBundleUnitState: React.Dispatch<
    React.SetStateAction<calculateBuildBundleUnit>
  >;
  setBundleCalculation: React.Dispatch<
    React.SetStateAction<calculateBuildBundleReturn>
  >;
}
const BuildBundle: React.FC<Props> = ({
  bundleUnitState,
  setBundleUnitState,
  setBundleCalculation,
}) => {
  const Dispatch = useDispatch();

  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );
  const [bundleRates, setBundleRates] = useState<BundleRatesProps[]>([]);

  const handleBuildBundleInterval = (id: BuildBundleInterval) => {
    if (id) {
      Dispatch(DispatchBuildBundleIntervalId(id));
      setBundleUnitState((prev) => ({
        onNetVoice: prev?.onNetVoice || 0,
        offNetVoice: prev?.offNetVoice || 0,
        dataMb: prev?.dataMb || 0,
        smsOffNet: prev?.smsOffNet || 0,
      }));
    }
  };

  // Fetch bundle rates
  useEffect(() => {
    let fetchRates: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      PackageBundleGetBuildBundleRates({
        PARAMS: {
          intervalId: bookingData?.buildBundleIntervalId?.toString(),
        },
      })
        .then((res) => {
          const DATA = res?.data;
          if (
            DATA?.statusCode === AppStatusCode.api_success &&
            DATA?.data?.length
          ) {
            const ratesList = DATA?.data
              ?.filter(
                (item: any) =>
                  item?.intervalId === bookingData?.buildBundleIntervalId
              )
              ?.map((item: BundleRatesProps) => ({
                data: item?.data?.counterId
                  ? {
                      counterId: item?.data?.counterId,
                      rate: item?.data?.rate?.map((r) => ({
                        rate: r?.rate,
                        valueFrom: r?.valueFrom,
                        valueTo: r?.valueTo,
                      })),
                    }
                  : undefined,
                voice: item?.voice?.length
                  ? item?.voice?.map((v) => ({
                      counterId: v?.counterId,
                      type: v?.type,
                      rate: v?.rate?.map((r) => ({
                        rate: r?.rate,
                        valueFrom: r?.valueFrom,
                        valueTo: r?.valueTo,
                      })),
                    }))
                  : undefined,
                sms: item?.sms?.length
                  ? item?.sms?.map((s) => ({
                      counterId: s?.counterId,
                      type: s?.type,
                      rate: s?.rate?.map((r) => ({
                        rate: r?.rate,
                        valueFrom: r?.valueFrom,
                        valueTo: r?.valueTo,
                      })),
                    }))
                  : undefined,
              }));
            setBundleRates(ratesList);
          }
        })
        .catch(() => {})
        .finally(() => Dispatch(toggleLoading(false)));
    };

    fetchRates();

    return () => {
      fetchRates = null;
    };
  }, [Dispatch, bookingData?.buildBundleIntervalId]);

  useEffect(() => {
    if (bundleRates?.length) {
      let bundleCalculationRates = calculateBuildBundle(
        bundleUnitState,
        bundleRates,
        bookingData?.promotionDetails || undefined
      );
      setBundleCalculation(bundleCalculationRates);
    }
  }, [bundleUnitState, bundleRates, bookingData.promotionDetails]);

  const getMaxValueTo = (
    data: BundleRatesProps[],
    counterId: string
  ): number => {
    let rates: unitRate[] = [];
    if (counterId == "84") rates = getDataMbRate(data);
    if (counterId == "85") rates = getOnNetVoiceRate(data);
    if (counterId == "86") rates = getOffNetVoiceRate(data);
    if (counterId == "87") rates = getSmsOffNetRate(data);
    if (!rates?.length) return 0;
    return rates?.reduce(
      (max, rate) => (rate.valueTo > max ? rate.valueTo : max),
      0
    );
  };

  const planList = useMemo(
    () => [
      {
        name: "dataMb",
        title: "DATA",
        minValue: bookingData.buildBundleMinimumRequirement.dataMb,
        maxValue: getMaxValueTo(bundleRates, "84"),
      },
      {
        name: "onNetVoice",
        title: "On Net Voice",
        minValue: bookingData.buildBundleMinimumRequirement.onNetVoice,
        maxValue: getMaxValueTo(bundleRates, "85"),
      },
      {
        name: "offNetVoice",
        title: "All Net Voice",
        minValue: bookingData.buildBundleMinimumRequirement.offNetVoice,
        maxValue: getMaxValueTo(bundleRates, "86"),
      },
      {
        name: "smsOffNet",
        title: "SMS",
        minValue: bookingData.buildBundleMinimumRequirement.smsOffNet,
        maxValue: getMaxValueTo(bundleRates, "87"),
      },
    ],
    [bundleRates, bookingData.buildBundleMinimumRequirement]
  );

  useEffect(() => {
    const { dataMb, onNetVoice, offNetVoice, smsOffNet } =
      bookingData.buildBundleMinimumRequirement;

    setBundleUnitState((prev) => {
      const updatedState = {
        dataMb: dataMb ?? 0,
        onNetVoice: onNetVoice ?? 0,
        offNetVoice: offNetVoice ?? 0,
        smsOffNet: smsOffNet ?? 0,
      };

      // Return the new state only if any of the values have changed
      return JSON.stringify(prev) !== JSON.stringify(updatedState)
        ? updatedState
        : prev;
    });
  }, [bookingData.buildBundleMinimumRequirement]);

  const buildBundleIntervalList =
    generateAutocompleteOptions(BuildBundleInterval);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gap={1}
        pt={3}
      >
        {buildBundleIntervalList?.map(
          (item: AutoCompleteOptionsProps, i: number) => (
            <Button
              key={i}
              color="secondary"
              variant={
                bookingData?.buildBundleIntervalId?.toString() ===
                item?.id?.toString()
                  ? "contained"
                  : "text"
              }
              disabled={Boolean(
                bookingData?.promotionDetails?.promotionId &&
                  bookingData?.buildBundleIntervalId?.toString() !==
                    item?.id?.toString()
              )}
              onClick={() => {
                if (item?.id) handleBuildBundleInterval(Number(item.id));
              }}
            >
              {item?.title}
            </Button>
          )
        )}
      </Box>
      <Box pt={5}>
        <Grid
          container
          spacing={{
            xs: 3,
            md: 5,
            lg: 8,
          }}
          justifyContent="center"
        >
          {planList?.map((item, i) => (
            <Grid key={i} item xs="auto">
              <PlanCircleContainer
                name={item.name}
                setState={setBundleUnitState}
                minValue={item.minValue}
                maxValue={item.maxValue}
                value={
                  bundleUnitState?.[
                    item?.name as keyof calculateBuildBundleUnit
                  ]
                }
                title={item.title}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default BuildBundle;
