import { useEffect } from "react";

const usePreventPageRefresh = (): void => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
      const warningMessage = "Changes you made may not be saved.";
      event.returnValue = warningMessage; // For most browsers
      return warningMessage; // For some other browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
};

export default usePreventPageRefresh;
