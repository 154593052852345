import React, { FC } from "react";
import { Route } from "react-router-dom";
import AuthCheckLayout from "./authCheckLayout";
import SecuredRoute from "./securedRoute";

interface RouteProps {
  exact?: boolean;
  path: string;
  index?: boolean;
  name?: string;
  element: React.ElementType;
  isSecured?: boolean;
  children?: React.ReactNode;
  role?: string | string[];
}

const RoutingFunction: FC<RouteProps> = (route, index) => {
  const { element: Element } = route;

  return (
    <Route
      path={route.path}
      element={
        route.isSecured ? (
          <SecuredRoute role={route.role}>
            <Element />
          </SecuredRoute>
        ) : (
          <Element />
        )
      }
      key={route.name || index}
    />
  );
};

export default RoutingFunction;
