import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import MobileWithManPic from "assets/img/static/bg/mobile_with_man.png";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  PaymentByProps,
  PaymentMethodDataProps,
  PaymentMethodsProps,
} from "_interfaces/_common/payment";
import { PaymentMethodModel } from "_models/data/data.paymentMethod.model";
import { PaymentMethodErrorModel } from "_models/errors/error.paymentMethod.model";
import InputText from "../forms/inputText";
import { validatePaymentMethod } from "./formValidator";
import {
  paymentOptionsList,
  PaymentOptionsListProps,
} from "./paymentOptionsList";

interface Props {
  handleMakePaymentRequest: (DATA: PaymentMethodDataProps) => void;
  togglePaymentOption: () => void;
  paymentOptions?: PaymentOptionsListProps[];
}
const PaymentMethod: React.FC<Props> = ({
  handleMakePaymentRequest,
  togglePaymentOption,
  paymentOptions = paymentOptionsList,
}) => {
  const [activeOption, setActiveOption] = useState<{
    paymentMethod: PaymentMethodsProps | "";
    paymentBy: PaymentByProps | "";
  }>({ paymentMethod: "", paymentBy: "" });

  const [state, setState] = useState<PaymentMethodModel>(
    new PaymentMethodModel()
  );
  const [errors, setErrors] = useState<PaymentMethodErrorModel>(
    new PaymentMethodErrorModel()
  );

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setState((prev) => ({
      ...prev,
      [name]: value,
      searchResult: null,
    }));
  };

  const handleContinuePayment = () => {
    const ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    } = validatePaymentMethod(state, activeOption.paymentBy);

    if (ValidateStep?.valid) {
      if (
        handleMakePaymentRequest &&
        activeOption?.paymentBy &&
        activeOption?.paymentMethod
      ) {
        handleMakePaymentRequest({
          paymentBy: activeOption.paymentBy,
          paymentMethod: activeOption.paymentMethod,
          momoPhoneNumber:
            activeOption.paymentBy === "momo" && state?.momoPhoneNumber?.trim()
              ? `256${state.momoPhoneNumber.trim()}`
              : undefined,
          airtelMOMOPhone:
            activeOption.paymentBy === "airtelMoney" &&
            state?.airtelMOMOPhone?.trim()
              ? state.airtelMOMOPhone.trim()
              : undefined,
        });
      }
    } else {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
    }
  };

  const inputList: {
    type: string;
    name: "momoPhoneNumber" | "airtelMOMOPhone";
    label: string;
    paymentBy: PaymentByProps;
    InputProps?: InputProps;
  }[] = [
    {
      type: "number",
      name: "momoPhoneNumber",
      label: "Enter Momo Phone Number",
      paymentBy: "momo",
      InputProps: {
        startAdornment: <InputAdornment position="start">256</InputAdornment>,
      },
    },
    {
      type: "number",
      name: "airtelMOMOPhone",
      label: "Enter Airtel Momo Phone Number",
      paymentBy: "airtelMoney",
    },
  ];

  useEffect(() => {
    const paymentByMobileMoney = paymentOptions.find(
      (item) => item.paymentMethod === "mobileMoney"
    );
    if (
      paymentByMobileMoney &&
      !activeOption?.paymentMethod &&
      !activeOption?.paymentBy
    ) {
      setActiveOption({
        paymentMethod: paymentByMobileMoney.paymentMethod,
        paymentBy: paymentByMobileMoney.paymentBy,
      });
    }
  }, [activeOption?.paymentMethod, activeOption?.paymentBy, paymentOptions]);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} bgcolor="primaryVar.main">
            <Box px={2} py={3}>
              <Typography textAlign="center" variant="body2" mb={3}>
                Just few steps, then <br /> you can connect with <br /> your
                loved ones
              </Typography>
              <div className="resImg">
                <img src={MobileWithManPic} alt="MobileWithManPic" />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} boxShadow="-4px 0px 9px 1px #2828285c">
            <Box p={{ xs: 2, sm: 3 }}>
              <Typography fontSize={18} fontWeight={600} mb={3}>
                How would you want to pay?
              </Typography>
              <Box
                sx={{
                  boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
                  borderRadius: 3,
                  padding: 2,
                }}
              >
                <Typography fontSize={14} fontWeight={600} pb={1}>
                  Mobile Money
                </Typography>
                <Box pl={2}>
                  <RadioGroup
                    aria-labelledby="select-payment-method"
                    name="payment-method"
                  >
                    {paymentOptions
                      ?.filter((item) => item?.paymentMethod === "mobileMoney")
                      ?.map(
                        (
                          { label, Icon, paymentBy, paymentMethod, disabled },
                          i
                        ) => (
                          <Fragment key={i}>
                            <FormControlLabel
                              key={i}
                              value={activeOption}
                              onChange={() => {
                                setState((prev) => ({
                                  ...prev,
                                  momoPhoneNumber: "",
                                  airtelMOMOPhone: "",
                                }));
                                setActiveOption({ paymentBy, paymentMethod });
                              }}
                              control={
                                <Radio
                                  checked={
                                    activeOption?.paymentBy === paymentBy
                                  }
                                />
                              }
                              disabled={disabled}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {Icon ? (
                                    <Icon sx={{ mr: 1, color: "black" }} />
                                  ) : (
                                    <></>
                                  )}
                                  <Typography variant="body2">
                                    {label}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Fragment>
                        )
                      )}
                  </RadioGroup>
                </Box>
              </Box>

              <Box pt={5}>
                <Grid container spacing={2}>
                  {inputList?.map((item, i) => (
                    <Fragment key={i}>
                      {item?.paymentBy === activeOption?.paymentBy ? (
                        <Grid item xs={12}>
                          <InputText
                            type={item?.type}
                            label={item?.label}
                            name={item?.name}
                            value={
                              state?.[item?.name as keyof PaymentMethodModel]
                            }
                            error={
                              !!errors?.[
                                item?.name as keyof PaymentMethodErrorModel
                              ]
                            }
                            errorText={
                              errors?.[
                                item?.name as keyof PaymentMethodErrorModel
                              ]
                            }
                            onChange={handleChange}
                            onFocus={() => handleFocus(item?.name)}
                            InputProps={item?.InputProps}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}

                  {activeOption?.paymentBy ? (
                    <>
                      <Grid item xs={12}>
                        <Box pt={5}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleContinuePayment}
                          >
                            Continue
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="inherit"
                      onClick={togglePaymentOption}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default PaymentMethod;
