import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputCheckbox from "component/_common/forms/inputCheckbox";
import InputText from "component/_common/forms/inputText";
import Password from "component/_common/forms/password";
import { getClientTimeZone } from "functions/helper";
import RouteConstant from "navigation/constant";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LoginFormProps } from "_interfaces/auth";
import { FormInputProps } from "_interfaces/_common/forms";
import { LoginModel } from "_models/data/auth/data.login.model";
import { LoginErrorModel } from "_models/errors/auth/error.login.model";
import {
  validateLoginPasswordSubmit,
  validateLoginRequest,
  validateLoginSetPasswordSubmit,
} from "./formValidator";

interface InputListProps extends FormInputProps {
  name: "loginAs" | "phone" | "password" | "confirmPassword" | "stayLogin";
}

const LoginForm: React.FC<LoginFormProps> = ({
  handleLogin,
  handleLoginPasswordSubmit,
  isProgress,
  state,
  setState,
  passwordRequired,
  setPassword,
}) => {
  const [errors, setErrors] = useState<LoginErrorModel>(new LoginErrorModel());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    let ValidateStep: {
      valid: boolean;
      errors: { name: string; error: string }[];
    };

    if (passwordRequired) {
      ValidateStep = validateLoginPasswordSubmit(state);
    } else if (setPassword) {
      ValidateStep = validateLoginSetPasswordSubmit(state);
    } else {
      ValidateStep = validateLoginRequest(state);
    }

    if (!ValidateStep?.valid) {
      for (
        let i = 0, item: { name: string; error: string };
        !!(item = ValidateStep.errors[i++]);

      ) {
        setErrors((prevState) => ({ ...prevState, [item.name]: item.error }));
      }
      return;
    }

    // if (!state.location) {
    //   getLocation()
    //     .then((res: GeolocationPosition) => {
    //       if (res.coords.latitude && res.coords.longitude) {
    //         setState((prev) => ({
    //           ...prev,
    //           location: `${res.coords.latitude},${res.coords.longitude}`,
    //         }));
    //         if (passwordRequired || setPassword) {
    //           handleLoginPasswordSubmit();
    //         } else {
    //           handleLogin();
    //         }
    //       }
    //     })
    //     .catch((er) => {
    //       Dispatch(
    //         setAlert({
    //           type: "error",
    //           message: `${HTTP_ERROR(er)}. Location is must to Login.`,
    //         })
    //       );
    //     });
    // } else {
    if (passwordRequired || setPassword) {
      handleLoginPasswordSubmit();
    } else {
      handleLogin();
    }
    // }
  };

  // useEffect(() => {
  //   getLocation()
  //     .then((res: GeolocationPosition) => {
  //       if (res.coords.latitude && res.coords.longitude) {
  //         setState((prev) => ({
  //           ...prev,
  //           location: `${res.coords.latitude},${res.coords.longitude}`,
  //         }));
  //       }
  //     })
  //     .catch((er) => {
  //       Dispatch(
  //         setAlert({
  //           type: "error",
  //           message: `${HTTP_ERROR(er)}. Location is must to Login.`,
  //         })
  //       );
  //     });
  // }, []);

  useEffect(() => {
    if (navigator && navigator.userAgent) {
      setState((prev) => ({ ...prev, userAgent: navigator.userAgent }));
    }
  }, []);

  useEffect(() => {
    const timeZone = getClientTimeZone();
    if (timeZone) setState((prev) => ({ ...prev, timeZone }));
  }, []);

  const inputList: InputListProps[] = [
    {
      type: "number",
      name: "phone",
      label: "Phone",
      placeholder: "Enter Phone",
      enabled: true,
      disabled: passwordRequired, // Disable the field while entering password
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter Password",
      enabled: passwordRequired || setPassword, // Show while entering password or setting password
    },
    {
      type: "password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Enter Confirm Password",
      enabled: setPassword, // Show only while setting password
    },
    {
      type: "checkbox",
      name: "stayLogin",
      label: "Remember me?",
      enabled: true,
    },
  ];

  return (
    <>
      <Typography
        variant="h1"
        fontSize={36}
        fontWeight={700}
        textAlign="center"
      >
        Login
      </Typography>
      <Typography textAlign="center">
        {passwordRequired
          ? "Enter your password"
          : setPassword
          ? "Set your password"
          : "Enter phone number"}
      </Typography>

      <Box pt={5}>
        <Box component="form" name="auth_login_form" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {inputList.map(
              (
                {
                  type,
                  name,
                  label,
                  placeholder,
                  autoComplete,
                  enabled,
                  disabled,
                  options,
                  multiple,
                },
                index
              ) => (
                <Fragment key={index}>
                  {enabled ? (
                    <>
                      {type === "number" ? (
                        <Grid item xs={12}>
                          <InputText
                            variant="outlined"
                            type={type}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            autoComplete={autoComplete || "off"}
                            value={state[name as keyof LoginModel]}
                            error={!!errors[name as keyof LoginErrorModel]}
                            errorText={errors[name as keyof LoginErrorModel]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={disabled}
                          />
                        </Grid>
                      ) : type === "password" ? (
                        <Grid item xs={12}>
                          <Password
                            type={type}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            autoComplete={autoComplete || "off"}
                            value={state[name as keyof LoginModel]}
                            errorText={errors[name as keyof LoginErrorModel]}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={disabled}
                            isHideShow
                          />
                        </Grid>
                      ) : type === "checkbox" && name === "stayLogin" ? (
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="space-between">
                            <InputCheckbox
                              name={name}
                              label={label}
                              value={state?.stayLogin}
                              onChange={handleChange}
                            />
                            <Typography display="flex" alignItems="center">
                              <Link to={RouteConstant.FORGOT_PASSWORD}>
                                Forgot Password ?
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Fragment>
              )
            )}

            <Grid item xs={12} mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disableElevation
                sx={{
                  textTransform: "capitalize",
                  py: "12px",
                }}
              >
                {isProgress ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Login"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
