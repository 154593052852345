import { ThemeProvider } from "@mui/material";
import Theme from "assets/theme";
import Navigation from "navigation";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "state/store";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

function App(): JSX.Element {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <StyledThemeProvider theme={Theme}>
            <BrowserRouter>
              <Navigation />
            </BrowserRouter>
          </StyledThemeProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
