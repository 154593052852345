import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface InfoCardProps extends BoxProps {
  Icon: React.ElementType;
  heading?: string | number;
  desc?: string;
  link?: string;
  light?: boolean;
  multipleHeadingDesc?: { heading?: string | number; desc?: string }[];
}

const InfoCard: React.FC<InfoCardProps> = ({
  Icon,
  heading,
  desc,
  link,
  light,
  multipleHeadingDesc,
}) => {
  return (
    <Box
      sx={{
        p: 1,
        mb: 1.5,
        display: "flex",
        border: "1px solid #dedbdb",
        borderRadius: 1,
        alignItems: "center",
        gap: 1,
        position: "relative",
        svg: {
          width: 22,
        },
      }}
    >
      <Icon color={light ? "white" : "black"} />
      <Box>
        {heading ? (
          <Typography
            fontSize={14}
            lineHeight={1.2}
            fontWeight={600}
            textTransform="capitalize"
          >
            {heading}
          </Typography>
        ) : (
          <></>
        )}
        {desc ? (
          <Box
            component="span"
            textTransform="capitalize"
            fontSize={12}
            lineHeight={1}
            sx={{ color: "rgb(195, 195, 195)" }}
          >
            {desc}
          </Box>
        ) : (
          <></>
        )}
        {multipleHeadingDesc?.length ? (
          <Box flex="1" display="flex" flexWrap="wrap" gap={1.5}>
            {multipleHeadingDesc?.map((item, i, arr) => (
              <Box
                key={i}
                sx={{
                  pr: i + 1 !== arr?.length ? 1.5 : 0,
                  position: "relative",
                  "&::after": {
                    content: "''",
                    display: i + 1 !== arr?.length ? "block" : "none",
                    width: "1px",
                    height: "70%",
                    background: "rgb(195, 195, 195)",
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                  },
                }}
              >
                {item.heading ? (
                  <Typography
                    fontSize={14}
                    lineHeight={1.2}
                    fontWeight={600}
                    textTransform="capitalize"
                  >
                    {item.heading}
                  </Typography>
                ) : (
                  <></>
                )}
                {item?.desc ? (
                  <Box
                    component="span"
                    fontSize={12}
                    lineHeight={1}
                    textTransform="capitalize"
                    sx={{ color: "rgb(195, 195, 195)" }}
                  >
                    {item?.desc}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {link && <Link to={link} className="stretched-link" />}
    </Box>
  );
};

export default InfoCard;
