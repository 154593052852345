import MyWalletLayout from "component/my-account/my-wallet";
import MyAccountLayout from "parts/myAccountLayout";

const MyWallet = () => {
  return (
    <>
      <MyAccountLayout>
        <MyWalletLayout />
      </MyAccountLayout>
    </>
  );
};

export default MyWallet;
