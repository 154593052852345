import Checkout from "pages/checkout";

import GetStarted from "pages/get-started";
import FAQ from "pages/help/faq";
import HowToPay from "pages/help/how-to-pay";
import Home from "pages/home";
import NearestAgent from "pages/nearest-agent";
import NotFound from "pages/notFound";
import OrderStatus from "pages/order-status";
import RouteConstant from "./constant";

import role from "config/role";
import Login from "pages/auth/login";
import ConfirmNumber from "pages/confirm-number";
import CreateAccount from "pages/create-account";

import AppDownload from "pages/app-download";
import ChangePassword from "pages/auth/change-password";
import ForgotPassword from "pages/auth/forgot-password";
import MyAccount from "pages/my-account";
import BalanceSummary from "pages/my-account/balance-summary";
import MyProfile from "pages/my-account/my-profile";
import MyWallet from "pages/my-account/my-wallet";
import RechargeHistory from "pages/my-account/recharge-history";
import PrivacyPolicy from "pages/privacy-policy";
import Products from "pages/products";
import ProductBuildBundle from "pages/products/build-bundle";
import ProductBundlePlans from "pages/products/bundle-plans";
import ProductPremiumPlans from "pages/products/premium-plans";
import ProductTouristPlans from "pages/products/tourist-plans";
import RechargePlans from "pages/recharge/plans";
import SelectService from "pages/select-service";
import TermsConditions from "pages/terms-conditions";
import Promotion from "pages/promotion";

interface Route {
  path: string;
  element: React.ElementType;
  isSecured: boolean;
  name: string;
  exact?: boolean;
  role?: string | string[];
}

const AllRoutes: Route[] = [
  {
    path: "/",
    element: Home,
    isSecured: false,
    name: "Home",
    exact: true,
  },
  {
    path: RouteConstant.HOME,
    element: Home,
    isSecured: false,
    name: "HomePage",
    exact: true,
  },
  {
    path: RouteConstant.PRIVACY_POLICY,
    element: PrivacyPolicy,
    isSecured: false,
    name: "PrivacyPolicy",
    exact: true,
  },
  {
    path: RouteConstant.TERMS_CONDITIONS,
    element: TermsConditions,
    isSecured: false,
    name: "TermsConditions",
    exact: true,
  },

  {
    path: RouteConstant.SELECT_SERVICE,
    element: SelectService,
    isSecured: false,
    name: "SelectService",
  },
  {
    path: RouteConstant.CONFIRM_NUMBER,
    element: ConfirmNumber,
    isSecured: false,
    name: "ConfirmNumber",
  },
  {
    path: RouteConstant.CREATE_ACCOUNT,
    element: CreateAccount,
    isSecured: false,
    name: "CreateAccount",
  },
  {
    path: RouteConstant.CHECKOUT,
    element: Checkout,
    isSecured: false,
    name: "Checkout",
  },
  {
    path: RouteConstant.ORDER_STATUS,
    element: OrderStatus,
    isSecured: false,
    name: "OrderStatus",
  },
  {
    path: RouteConstant.NEAREST_AGENT,
    element: NearestAgent,
    isSecured: false,
    name: "NearestAgent",
  },

  {
    path: RouteConstant.FAQ,
    element: FAQ,
    isSecured: false,
    name: "FAQ",
  },

  {
    path: RouteConstant.GET_STARTED,
    element: GetStarted,
    isSecured: false,
    name: "GetStarted",
  },

  {
    path: RouteConstant.HOW_TO_PAY,
    element: HowToPay,
    isSecured: false,
    name: "HowToPay",
  },

  // Product
  {
    path: RouteConstant.PRODUCTS,
    element: Products,
    name: "Products",
    isSecured: false,
  },
  {
    path: RouteConstant.PRODUCT_BUILD_BUNDLE,
    element: ProductBuildBundle,
    name: "ProductBuildBundle",
    isSecured: false,
  },
  {
    path: RouteConstant.PRODUCT_PREMIUM_PLANS,
    element: ProductPremiumPlans,
    name: "ProductPremiumPlans",
    isSecured: false,
  },
  {
    path: RouteConstant.PRODUCT_TOURIST_PLANS,
    element: ProductTouristPlans,
    name: "ProductTouristPlans",
    isSecured: false,
  },
  {
    path: RouteConstant.PRODUCT_BUNDLE_PLANS,
    element: ProductBundlePlans,
    name: "ProductBundlePlans",
    isSecured: false,
  },

  {
    path: RouteConstant.PROMOTION,
    element: Promotion,
    name: "Promotion",
    isSecured: false,
  },

  // Auth
  {
    path: RouteConstant.LOGIN,
    element: Login,
    name: "Login",
    isSecured: false,
    exact: true,
  },
  {
    path: RouteConstant.FORGOT_PASSWORD,
    element: ForgotPassword,
    isSecured: false,
    name: "ForgotPassword",
    exact: true,
  },
  {
    path: RouteConstant.CHANGE_PASSWORD,
    element: ChangePassword,
    isSecured: false,
    name: "ChangePassword",
    exact: true,
  },

  // Logged in pages
  {
    path: RouteConstant.RECHARGE_PLANS,
    element: RechargePlans,
    name: "RechargePlans",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },

  /// MY ACCOUNT
  {
    path: RouteConstant.MY_ACCOUNT,
    element: MyAccount,
    name: "MyAccount",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },
  {
    path: RouteConstant.MY_PROFILE,
    element: MyProfile,
    name: "MyProfile",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },
  {
    path: RouteConstant.MY_WALLET,
    element: MyWallet,
    name: "MyWallet",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },
  {
    path: RouteConstant.BALANCE_SUMMARY,
    element: BalanceSummary,
    name: "BalanceSummary",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },
  {
    path: RouteConstant.RECHARGE_HISTORY,
    element: RechargeHistory,
    name: "RechargeHistory",
    isSecured: true,
    exact: true,
    role: role.CUSTOMER,
  },
  {
    path: RouteConstant.APP_DOWNLOAD,
    element: AppDownload,
    name: "AppDownload",
    isSecured: false,
  },
  {
    path: "*",
    element: NotFound,
    isSecured: false,
    name: "NotFound",
  },
];

export default AllRoutes;
