import { CreateAccountModel } from "_models/data/data.createAccount.model";
import { isValidEmail } from "functions/validators";

export const validateGetStartedAccountDetails = (DATA: CreateAccountModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.email?.trim()) {
    valid = false;
    errors.push({
      name: "email",
      error: "Enter your email.",
    });
  }

  if (DATA?.email?.trim() && !isValidEmail(DATA.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  return { valid, errors };
};
