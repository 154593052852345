import React, { ReactNode } from "react";

interface SubMenuProps {
  right?: boolean;
  mobile?: boolean;
  show?: boolean;
  fullWidth?: boolean;
  megaMenu?: boolean;
  children: ReactNode;
}

const SubMenu: React.FC<SubMenuProps> = ({
  right = false,
  mobile = false,
  show,
  children,
  fullWidth,
  megaMenu = false,
}) => {
  let className = "nav-sub-menu shadow ";
  if (right) className += " right ";
  if (mobile) className += " mobile ";
  if (show) className += " show ";
  if (fullWidth) className += " fullWidth ";
  if (megaMenu) className += " mega-menu ";
  return <div className={className}>{children}</div>;
};

export default SubMenu;
