import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatPriceWithDelimiter } from "functions/helper";
import { GetCustomerRechargeHistory } from "functions/http-requests/customer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { RechargeHistoryProps } from "_interfaces/myAccount/rechargeHistory";

const RechargeHistoryLayout = () => {
  const Dispatch = useDispatch();

  const [state, setState] = useState<RechargeHistoryProps | null>(null);

  useEffect(() => {
    Dispatch(toggleLoading(true));
    let fetchList: (() => void) | null = () => {
      GetCustomerRechargeHistory({})
        .then((res) => {
          setState(res?.data?.data);
        })
        .catch(() => {})
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  return (
    <>
      <Grid container spacing={5} className="fc-secondary">
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
              borderRadius: 3,
              padding: 3,
            }}
          >
            {state ? (
              <></>
            ) : (
              <>
                <Typography pb={1} fontSize={16} textAlign="center">
                  Recharge History Not Found
                </Typography>
              </>
            )}
          </Box>

          {state?.rechargeSummary?.length ? (
            <>
              <Box display="flex" flexDirection="column" gap={3}>
                {state?.rechargeSummary?.map((item, i) => (
                  <Box
                    key={i}
                    sx={{
                      boxShadow: "0 .25rem 0.5rem rgba(0,0,0,.05)",
                      borderRadius: 3,
                      padding: 3,
                    }}
                  >
                    <Box fontSize={14}>
                      <RenderRow
                        label="Recharge Type"
                        value={`${item?.rechargeType}`}
                      />
                      <RenderRow
                        label="Total Recharge Amount"
                        value={
                          item?.sumOfRechargeAmounts
                            ? formatPriceWithDelimiter(
                                item?.sumOfRechargeAmounts,
                                false
                              )
                            : 0
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

interface RowProps {
  label: string;
  value: string | number;
}
const RenderRow: React.FC<RowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        pt={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize="inherit" className="fc-grey">
          {label}
        </Typography>
        <Typography textAlign="right">{value}</Typography>
      </Box>
    </>
  );
};

export default RechargeHistoryLayout;
