import ArrowOutward from "@mui/icons-material/ArrowOutward";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import BannerImage from "assets/img/static/bg/revolution.jpeg";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";

interface Props {
  handleOpenSearchNumber: () => void;
}
const JoinRevolutionBanner: React.FC<Props> = ({ handleOpenSearchNumber }) => {
  const list = [
    {
      title: "Visit Your Nearest Agent",
      desc: "Purchase your sim card from any of our agent locations country-wide",
      link: "",
    },
    {
      title: "Customize Your Number",
      desc: "Pick any number that works for you",
      handleClick: handleOpenSearchNumber,
    },
    {
      title: "Build your bundle",
      desc: "Mix & match to create the perfect bundle",
      link: RouteConstant.PRODUCT_BUILD_BUNDLE,
    },
  ];
  return (
    <>
      <Box
        pt={{ xs: 5, sm: 10 }}
        pb={5}
        px={{ xs: 3, sm: 5 }}
        sx={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.11) 58.83%, rgba(0, 0, 0, 0.8) 100%), url(${BannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Typography
          className="fc-black"
          textTransform="uppercase"
          fontSize={{ xs: 24, sm: 42 }}
          fontWeight={500}
        >
          join the
        </Typography>
        <Typography
          className="fc-white"
          textTransform="uppercase"
          fontSize={{ xs: 30, sm: 48, md: 76 }}
          lineHeight={1}
          fontWeight={900}
        >
          REVOLUTION
        </Typography>
        <Box pt={5}>
          <Grid container spacing={5}>
            {list?.map((item, i, arr) => (
              <Grid
                item
                xs={12}
                md={4}
                key={i}
                sx={{
                  position: "relative",
                  "&::after": {
                    content: '""',
                    display: {
                      xs: "none",
                      md: i + 1 !== arr.length ? "block" : "none",
                    },
                    width: "1px",
                    height: "70%",
                    background: "#C0C0C0",
                    position: "absolute",
                    right: "-20px",
                    top: "50%",
                    transform: "translateY(calc(-50% + 20px))",
                  },
                }}
              >
                <Box
                  className="fc-black"
                  sx={{
                    background: "#FFFFFF75",
                    borderRadius: "10px",
                    p: 2,
                    height: "100%",
                  }}
                >
                  <Typography
                    fontSize={{ xs: 18, md: 26 }}
                    fontWeight={800}
                    textTransform="capitalize"
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    py={1.5}
                    fontSize={{ xs: 16, md: 18 }}
                    fontWeight={400}
                  >
                    {item.desc}
                  </Typography>
                  {item?.link ? (
                    <Link to={item.link}>
                      <IconButton color="inherit">
                        <ArrowOutward />
                      </IconButton>
                    </Link>
                  ) : item?.handleClick ? (
                    <IconButton onClick={item.handleClick} color="inherit">
                      <ArrowOutward />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default JoinRevolutionBanner;
