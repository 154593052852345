import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SectionTitle from "parts/sectionTitle";
import SocialLinks from "parts/socialLinks";
import { FC, useState } from "react";
import { FaqData } from "./faqData";
import FrequentlyQuestionItem from "./frequentlyQuestionItem";

interface FrequentlyQuestionsProps {}

const FrequentlyQuestions: FC<FrequentlyQuestionsProps> = ({}) => {
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([
    0, 1, 2, 3,
  ]);

  const handleAccordionChange = (index: number, isExpanded: boolean) => {
    setExpandedIndexes((prevExpandedIndexes) =>
      isExpanded
        ? [...prevExpandedIndexes, index]
        : prevExpandedIndexes.filter((i) => i !== index)
    );
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Typography
            className="fc-black"
            fontSize={{ xs: 18, sm: 22 }}
            fontWeight={500}
          >
            Still have questions left?
          </Typography>
          <Box pt={3}>
            <SectionTitle
              title="Frequently Asked Question"
              className="fc-primary"
              sx={{
                fontSize: { xs: 30, md: 36 },
                fontWeight: 900,
                lineHeight: 1.2,
              }}
            />
          </Box>
          <Box
            my={3}
            sx={{
              width: "50%",
              height: "1px",
              background: "#222222",
            }}
          />
          <Box className="fc-black" pb={5}>
            <SocialLinks color="inherit" justifyContent="flex-start" />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {FaqData?.slice(0, 4)?.map((item, i) => (
            <FrequentlyQuestionItem
              key={i}
              index={i}
              item={item}
              expandedIndexes={expandedIndexes}
              handleAccordionChange={handleAccordionChange}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FrequentlyQuestions;
