import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";

const NoticeSec = () => {
  const { palette } = useTheme();

  const noticeChart = [
    {
      desc: "Booking your Talkio Number is quick, easy, and takes just a few minutes. Simply follow these steps:",
    },
    {
      desc: `Visit our <a href="${RouteConstant.SELECT_SERVICE}">Book Your Number</a> page.`,
    },
    {
      desc: "Choose Your Number – Browse and select your preferred Talkio Number. Find a number that’s uniquely you!",
    },
    {
      desc: "Select Your Plan – Build a customized bundle or choose from a range of other Talkio Mobile products that suit your needs. We offer affordable, flexible plans for everyone",
    },
  ];

  return (
    <Box py={10}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Box pr={{ md: 5 }}>
            <SectionTitle
              title="Important Note"
              sx={{ fontSize: 32, fontWeight: 600, color: "#f98e42" }}
            />
            <Box pt={3}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: {
                    xs: 20,
                    sm: 32,
                  },
                }}
              >
                Here’s everything you need to know to participate and boost your
                chances of winning.
              </Typography>
            </Box>
            <Box pt={5}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  flex: 1,
                  borderRadius: 1.2,
                  py: 2,
                  maxWidth: 200,
                }}
              >
                Book Now
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            {noticeChart.map((item, index, arr) => (
              <Box
                key={index}
                sx={{
                  mb: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,

                    fontSize: {
                      xs: 16,
                      sm: 18,
                    },
                  }}
                >
                  <Box
                    sx={{
                      color: palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    {index + 1 < 10 ? `0${index + 1}` : index}
                  </Box>
                  <Box
                    sx={{
                      wordBreak: "break-word",
                      a: {
                        color: palette.primary.main,
                      },
                      "&:after": {
                        content: '""',
                        width: "1px",
                        height: 30,
                        background: "gray",
                        display: index + 1 !== arr.length ? "block" : "none",
                        mt: 2,
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                </Box>
              </Box>
            ))}
            {/* <Box color="gray">
              <Button variant="text" color="inherit">
                Show More
              </Button>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoticeSec;
