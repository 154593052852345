import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomJoyRide from "component/_common/customJoyRide";
import PlanCard from "component/_common/plans/planCard";
import { filterNonNullValues } from "functions/helper";
import { PackageBundleGetAll } from "functions/http-requests/packageBundle";
import RouteConstant from "navigation/constant";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleLoading } from "state/slice/loading";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
  DispatchIdentityType,
  DispatchPackageBundleDetails,
  DispatchSetPreviousPath,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetPackageBundleProps } from "_interfaces/http-requests/packageBundle";
import { PackageBundleDetailsProps } from "_interfaces/phoneBooking";

const AllTouristPlans = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { pathname } = useLocation();
  const paginationRef = useRef<HTMLDivElement | null>(null);

  const { bookingData, steps, stepIndex } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [state, setState] = useState<PackageBundleDetailsProps[]>([]);

  const handleSelectPlan = (item: any) => {
    if (item?._id) {
      Dispatch(DispatchIdentityType(item?.packageFor));
      Dispatch(DispatchPackageBundleDetails(item));
      Dispatch(DispatchBookingStep(stepIndex + 1));
      Dispatch(DispatchSetPreviousPath(pathname));
      navigate(RouteConstant.CONFIRM_NUMBER);
    }
  };

  useEffect(() => {
    if (bookingData?.phoneDetails?._id) {
      Dispatch(DispatchBookingStep(1));
    } else {
      Dispatch(clearPhoneBookingData());
    }
  }, [Dispatch, bookingData]);

  useEffect(() => {
    let fetchPlans: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      let PAYLOAD_DATA: GetPackageBundleProps["DATA"] = {
        pageNumber: 1,
        pageSize: 100,
        packageFor: "tourist",
      };
      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      PackageBundleGetAll({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const DATA = res?.data?.data;
          if (DATA?.length) {
            const categoryOrder = ["bronze", "silver", "gold"];
            setState(
              DATA?.map((item: any) => ({
                _id: item?._id || "",
                retailPrice: item?.retailPrice || "",
                // packageName: item?.packageName || "",
                category: item?.category || "",
                // packageType: item?.packageType || "",
                validity: item?.validity || "",
                allNetMinutes: item?.allNetMinutes || "",
                onNetMinutes: item?.onNetMinutes || "",
                offNetMinutes: item?.offNetMinutes || "",
                allNetSMS: item?.allNetSMS || "",
                onNetSMS: item?.onNetSMS || "",
                offNetSMS: item?.offNetSMS || "",
                internationalCallMinutes: item?.internationalCallMinutes || "",
                packageFor: item?.packageFor || "",
                packageID: item?.packageID || "",
                dataPerDay: item?.dataPerDay || null,
                dataVolume: item?.dataVolume || null,
              }))?.sort((a: any, b: any) => {
                const indexA =
                  categoryOrder.indexOf(a.category) !== -1
                    ? categoryOrder.indexOf(a.category)
                    : categoryOrder.length;
                const indexB =
                  categoryOrder.indexOf(b.category) !== -1
                    ? categoryOrder.indexOf(b.category)
                    : categoryOrder.length;
                return indexA - indexB;
              })
            );
          } else {
            setState([]);
          }
        })
        .catch(() => {
          setState([]);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchPlans();

    return () => {
      fetchPlans = null;
    };
  }, []);

  return (
    <>
      <Box>
        <CustomJoyRide
          steps={steps}
          stepIndex={stepIndex}
          run={!!state?.length}
        />
        <Box position="relative">
          {state?.length ? (
            <>
              <Swiper
                spaceBetween={30}
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination",
                }}
                modules={[Pagination]}
                className="custom-pagination-swiper"
                centerInsufficientSlides
                freeMode
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                  },
                  600: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                  },
                  900: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                  1100: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                  },
                }}
                style={{
                  paddingTop: "24px",
                  paddingBottom: "50px",
                  paddingLeft: "24px",
                  paddingRight: "24px",
                }}
                onSlideChange={() => {
                  const activeBullet = document.querySelector(
                    ".swiper-pagination-bullet-active"
                  ) as HTMLElement | null;

                  if (paginationRef.current && activeBullet) {
                    paginationRef.current.scrollLeft =
                      activeBullet.offsetLeft -
                      paginationRef.current.offsetWidth / 2 +
                      activeBullet.offsetWidth / 2;
                  }
                }}
              >
                {state.map((item: any, i: number) => (
                  <SwiperSlide key={i}>
                    <PlanCard
                      data={item}
                      handleClick={() => handleSelectPlan(item)}
                      showValidityAsChip={true}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Box className="swiper-pagination" ref={paginationRef} />
            </>
          ) : (
            <Box pt={3}>
              <Typography fontSize={{ xs: 16, md: 24 }} textAlign="center">
                No Plan Found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AllTouristPlans;
