export const FaqData = [
  {
    title: `What is Talkio Mobile?`,
    desc: `Talkio Mobile is your gateway to a smarter, faster, and fully customizable mobile experience. With affordable, reliable plans for individuals and businesses, we ensure you get exactly what you need, wherever you are. Let’s Connect.`,
  },
  {
    title: `Who can use Talkio?`,
    desc: `Anyone can purchase and use a Talkio SIM card. All you need to do is visit any of our agent points country-wide to get started.`,
  },
  {
    title: `How to register for a Talkio SIM card?`,
    desc: `You can purchase a Talkio SIM from any of our service centers, website or mobile app. Requirements are as below;
<ul>
<li>An original National ID (for Ugandans)</li>
<li>Or a valid Passport and VISA (for foreigners)</li>
<li>Or a Refugee ID (for refugees)</li>
</ul>`,
  },
  {
    title: `How can I get an e-SIM?`,
    desc: `You can purchase a Talkio e-SIM from any of our service centers, website or mobile app. Requirements are as below;
<ul>
<li>An e-sim-enabled smartphone</li>
<li>An original National ID (for Ugandans)</li>
<li>Or a valid Passport and VISA (for foreigners)</li>
<li>Or a Refugee ID (for refugees)</li>
</ul>`,
  },
  {
    title: `How many SIM cards can I activate?`,
    desc: `One SIM card every 24hrs.`,
  },
  {
    title: `How can I replace a physical SIM with an e-SIM?`,
    desc: `Please visit a Talkio service center with your e-sim-enabled smartphone.`,
  },
  {
    title: `How do I purchase bundles?`,
    desc: `Dial *150#, or *100#, or *160# to view and purchase available bundle plans`,
  },
  {
    title: `How to contact Talkio Mobile Customer Care?`,
    desc: `Please dial 0203100100 for customer care assistance.`,
  },
  {
    title: `What is the validity of the Tourist SIM?`,
    desc: `Tourist SIM is valid for the duration of your Visa.`,
  },
  {
    title: `How do I check my balance?`,
    desc: `You can check your balance by dialing the USSD code *131# or *100*6*1#`,
  },
  {
    title: `How do I recharge my account?`,
    desc: `You can recharge your account through MoMo by dialing *100*7#`,
  },
  {
    title: `What should I do if I can't make calls?`,
    desc: `Ensure your phone is not in airplane mode and that your sim card has sufficient balance. If the problem persists, please contact customer support for further assistance.`,
  },
  {
    title: `Why is my internet connection slow?`,
    desc: `Slow internet can be due to network congestion, poor signal strength, or data plan limitations. Try restarting your device, checking your data balance, and moving to an area with better coverage.`,
  },
  {
    title: `How do I configure my phone for internet access?`,
    desc: `		<p>Most modern phones automatically configure settings for internet access.</p>

<p>To Configure manually, go to settings &gt; Mobile networks&gt; Access Point Names and fill in according to the table below.</p>
<table>
<tbody>
<tr>
<td>Name</td>
<td>Talkio</td>
</tr>
<tr>
<td>APN</td>
<td>Talkio</td>
</tr>
<tr>
<td>Proxy</td>
<td>
</td>
</tr>
<tr>
<td>Port</td>
<td>
</td>
</tr>
<tr>
<td>Username</td>
<td>
</td>
</tr>
<tr>
<td>Password</td>
<td>
</td>
</tr>
<tr>
<td>Server</td>
<td>
</td>
</tr>

<tr>
<td>MMSC</td>
<td>
  
</td>
</tr>
<tr>
<td>MMS Proxy</td>
<td>
  
</td>
</tr>
<tr>
<td>MMS Port</td>
<td>
  
</td>
</tr>
<tr>
<td>MCC</td>
<td>
641
</td>
</tr>
<tr>
<td>MNC</td>
<td>
08
</td>
</tr>
<tr>
<td>Authentication Type</td>
<td>
</td>
</tr>
<tr>
<td>APN Type</td>
<td>
default
</td>
</tr>
<tr>
<td>APN Protocol</td>
<td>
IPV4
</td>
</tr>
<tr>
<td>APN roaming protocol</td>
<td>
IPV4
</td>
</tr>
<tr>
<td>Bearer</td>
<td>
Unspecified
</td>
</tr>
<tr>
<td>Mobile Virtual Network Operator type</td>
<td>
None
</td>
</tr>
</tbody>
</table>`,
  },
  {
    title: `What should I do if I have been overcharged?`,
    desc: `Contact customer support immediately with details of the overcharge. We will investigate and rectify the issue as quickly as possible.`,
  },
  {
    title: `Why is my SIM card not working?`,
    desc: `Try putting the phone in airplane mode and ensuring the SIM card is properly inserted. Next, try inserting the SIM card in another phone. If it still doesn't work, visit the nearest Talkio service center for a replacement.`,
  },
  {
    title: `How can I block my SIM card if it's lost or stolen?`,
    desc: `<p>&nbsp;Contact customer support immediately to block your SIM card and prevent unauthorized use. You can also request a replacement SIM card at any of our Talkio service centers.</p>
    <p><strong>Codes to remember.</strong></p>
    <div>
      <table>
        <tbody>
          <tr>
            <td style="background-color:#FD9D0E; color:#ffffff; text-align: center;" colspan="2">TALKIO USEFUL SHORT CODES TO ACCESS OUR SERVICES
            </td>
          </tr>
          <tr>
            <td style="background-color:#FD9D0E; color:#ffffff;">
              CODE
            </td>
            <td style="background-color:#FD9D0E; color:#ffffff;">
              SERVICE
            </td>
          </tr>
          <tr>
            <td>*100#</td>
            <td>All Services/Products</td>
          </tr>
          <tr>
            <td>*160#</td>
            <td>All Services/Products</td>
          </tr>
          <tr>
            <td>*150#</td>
            <td>Data Services</td>
          </tr>
          <tr>
            <td>*100*6#</td>
            <td>Buying Airtime off MTN Mobile Money and Airtel Mobile Money</td>
          </tr>
          <tr>
            <td>*131#</td>
            <td>My Balances</td>
          </tr>
          <tr>
            <td>*100*1#</td>
            <td>Bundle Plans</td>
          </tr>
          <tr>
            <td>*100*2#</td>
            <td>Non Expiry Data Plans</td>
          </tr>
          <tr>
            <td>*100*8#</td>
            <td>Non Expiry Voice Plans</td>
          </tr>
          <tr>
            <td>*100*3#</td>
            <td>Tourist Plans</td>
          </tr>
          <tr>
            <td>*100*4#</td>
            <td>Premium Plans</td>
          </tr>
          <tr>
            <td>*100*5#</td>
            <td>Build Your Bundle</td>
          </tr>
          <tr>
            <td>*100*7#</td>
            <td>Manage Account</td>
          </tr>
          <tr>
            <td>*100*1*2#</td>
            <td>Voice Bundle</td>
          </tr>
          <tr>
            <td>*100*1*1#</td>
            <td>Data bundle</td>
          </tr>
          <tr>
            <td>*100*1*4#</td>
            <td>SMS Bundle</td>
          </tr>
          <tr>
            <td>*100*1*3#</td>
            <td>Combo Bundle</td>
          </tr>
          <tr>
            <td>*100*1*2*2#</td>
            <td>Buy for Another Voice Bundle</td>
          </tr>
          <tr>
            <td>*100*1*1*2#</td>
            <td>Buy for Another Data Bundle</td>
          </tr>
          <tr>
            <td>*100*7*1#</td>
            <td>My Balances</td>
          </tr>
          <tr>
            <td>*100*7*2#</td>
            <td>My Talkio Number</td>
          </tr>
          <tr>
            <td>*100*7*3#</td>
            <td>View My tarrif plan</td>
          </tr>
          <tr>
            <td>*100*7*4#</td>
            <td>Change tarrif plan</td>
          </tr>
        </tbody>
      </table>
    </div>
`,
  },
];
