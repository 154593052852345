import {
  Box,
  Button,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import BundlePlanImage from "assets/img/static/bg/bundle-plans-banner.jpg";
import HomeBanner20GBImage from "assets/img/static/bg/home-banner-20gb.jpg";
import PremiumPlanImage from "assets/img/static/bg/premium-banner.jpg";
import TouristPlanImage from "assets/img/static/bg/tourist-banner.jpg";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface LabelProps {
  text: string;
  sx?: SxProps;
}

interface ButtonProps {
  text: string;
  link: string;
  sx?: SxProps;
}

interface SliderListProps {
  label1?: LabelProps;
  label2?: LabelProps;
  label3?: LabelProps;
  button1?: ButtonProps;
  button2?: ButtonProps;
  image?: {
    url?: string;
    sx?: SxProps;
  };
}

const HomeHeader: React.FC = () => {
  const { palette } = useTheme();

  const sliderList: SliderListProps[] = [
    {
      label1: {
        text: "Experience Better And Faster",
      },
      label2: {
        text: "Connectivity",
      },
      button1: {
        text: "Get Started",
        link: RouteConstant.PRODUCTS,
      },
      button2: {
        text: "Explore Products",
        link: `${RouteConstant.HOME}#explore-products`,
      },
      image: { url: BundlePlanImage },
    },
    {
      label1: {
        text: "Premium plans",
      },
      label2: {
        text: "Crafted Just For You!",
      },
      button1: {
        text: "Explore Plans",
        link: RouteConstant.PRODUCT_PREMIUM_PLANS,
      },
      image: { url: PremiumPlanImage },
    },
    {
      label1: {
        text: "Stay Connected with our competitive Tourist Plans",
      },
      label2: {
        text: "Traveling to Uganda Soon?",
      },
      button1: {
        text: "Explore Plans",
        link: RouteConstant.PRODUCT_TOURIST_PLANS,
      },
      image: { url: TouristPlanImage },
    },
    {
      label1: {
        text: "Book your SIM & get",
        sx: {
          fontSize: {
            xs: 30,
            lg: 48,
          },
        },
      },
      label2: {
        text: "20GB",
        sx: {
          fontSize: { xs: 48, sm: 80, lg: 166 },
          color: palette.primaryVar.main,
          lineHeight: 0.9,
          fontWeight: 700,
        },
      },
      label3: {
        text: "at only UGX 35,000",
        sx: {
          fontSize: {
            xs: 30,
            lg: 48,
          },
        },
      },
      button1: {
        text: "Click to learn more",
        link: RouteConstant.PROMOTION,
        sx: {
          backgroundColor: palette.primaryVar.main,
          "&:hover": {
            backgroundColor: palette.primaryVar.main,
          },
        },
      },
      image: {
        url: HomeBanner20GBImage,
        sx: {
          backgroundPosition: "center right",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.05)",
            zIndex: 2,
          },
        },
      },
    },
  ];
  return (
    <section style={{ position: "relative" }}>
      <MainContainer>
        <Box
          sx={{
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Swiper
            slidesPerView={1}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="custom-pagination-swiper normal"
          >
            {sliderList.map(
              ({ label1, label2, label3, button1, button2, image }, i) => (
                <SwiperSlide key={i}>
                  <Box
                    className="fm-poppins"
                    sx={{
                      height: {
                        xs: 450,
                        md: 450,
                        lg: 500,
                      },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      position: "relative",
                      zIndex: 10,
                    }}
                  >
                    <Box
                      pt={{ xs: 5, md: 0 }}
                      px={{ xs: 3, md: 5 }}
                      pb={8}
                      zIndex={999}
                      maxWidth={800}
                    >
                      {label1?.text ? (
                        <Typography
                          sx={{
                            fontSize: {
                              xs: 20,
                              md: 24,
                            },
                            fontWeight: 600,
                            lineHeight: 1.5,
                            ...(label1?.sx || {}),
                          }}
                        >
                          {label1?.text}
                        </Typography>
                      ) : null}

                      {label2?.text ? (
                        <Box position="relative">
                          <Typography
                            sx={{
                              fontSize: { xs: 36, md: 54 },
                              fontWeight: 600,
                              lineHeight: 1.3,
                              ...(label2?.sx || {}),
                            }}
                          >
                            {label2?.text}
                          </Typography>
                        </Box>
                      ) : null}

                      {label3?.text ? (
                        <Box position="relative">
                          <Typography
                            sx={{
                              fontSize: {
                                xs: 20,
                                md: 24,
                              },
                              fontWeight: 600,
                              lineHeight: 1.5,
                              ...(label3?.sx || {}),
                            }}
                          >
                            {label3?.text}
                          </Typography>
                        </Box>
                      ) : null}

                      <Box
                        pt={3}
                        maxWidth={button2?.link && button2?.text ? 400 : 220}
                      >
                        <Stack gap={2} direction={{ xs: "column", sm: "row" }}>
                          {button1?.link ? (
                            <Link to={button1?.link} style={{ flex: 1 }}>
                              <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                  borderRadius: "6px",
                                  fontSize: { md: "16px" },
                                  backgroundColor: "#1F1F1F",
                                  color: "#FFFFFF",
                                  width: "100%",
                                  minWidth: "auto",
                                  "&:hover": {
                                    backgroundColor: "#1F1F1F",
                                  },
                                  ...(button1?.sx || {}),
                                }}
                              >
                                {button1?.text}
                              </Button>
                            </Link>
                          ) : null}
                          {button2?.link && button2?.text ? (
                            <Link to={button2?.link} style={{ flex: 1 }}>
                              <Button
                                disableElevation
                                variant="outlined"
                                color="inherit"
                                sx={{
                                  borderRadius: "6px",
                                  fontSize: { md: "16px" },
                                  borderColor: "#1F1F1F",
                                  color: "#1F1F1F",
                                  width: "100%",
                                  minWidth: "auto",
                                  ...(button2?.sx || {}),
                                }}
                                onClick={(e) => {
                                  const elementId =
                                    button2?.link?.split("#")[1];
                                  const element =
                                    document.getElementById(elementId);
                                  if (element) {
                                    element.scrollIntoView({
                                      behavior: "smooth",
                                    });
                                  }
                                }}
                              >
                                {button2?.text}
                              </Button>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Box>
                    </Box>

                    {image?.url ? (
                      <Box
                        sx={{
                          background: `url(${image?.url})`,
                          backgroundSize: "cover",
                          backgroundPosition: "top center",
                          backgroundRepeat: "no-repeat",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          right: 0,
                          zIndex: 1,
                          ...(image?.sx || {}),
                        }}
                      />
                    ) : null}
                  </Box>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Box>
      </MainContainer>
    </section>
  );
};

export default HomeHeader;
