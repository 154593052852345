import {
  ChangeWalletPinProps,
  CreateWalletProps,
  WalletPaymentSubmitProps,
  WalletResendOtpForPinChangeProps,
  WalletVerifyOtpForPinChangeProps,
} from "_interfaces/http-requests/wallet";
import {
  CHANGE_WALLET_PIN,
  CREATE_WALLET,
  GET_MY_WALLET,
  WALLET_CHANGE_PIN_REQUEST,
  WALLET_PAYMENT_SUBMIT,
  WALLET_RESEND_OTP_FOR_PIN_CHANGE,
  WALLET_VERIFY_OTP_FOR_PIN_CHANGE,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetMyWallet = () => {
  return HTTP({
    Method: "GET",
    Url: GET_MY_WALLET,
  });
};

export const CreateWallet = ({ DATA }: CreateWalletProps) => {
  return HTTP({
    Method: "POST",
    Url: CREATE_WALLET,
    Data: DATA,
  });
};

export const WalletChangePinRequest = () => {
  return HTTP({
    Method: "POST",
    Url: WALLET_CHANGE_PIN_REQUEST,
  });
};

export const WalletResendOtpForPinChange = ({
  DATA,
}: WalletResendOtpForPinChangeProps) => {
  return HTTP({
    Method: "POST",
    Url: WALLET_RESEND_OTP_FOR_PIN_CHANGE,
    Data: DATA,
  });
};

export const WalletVerifyOtpForPinChange = ({
  DATA,
}: WalletVerifyOtpForPinChangeProps) => {
  return HTTP({
    Method: "POST",
    Url: WALLET_VERIFY_OTP_FOR_PIN_CHANGE,
    Data: DATA,
  });
};

export const ChangeWalletPin = ({ DATA }: ChangeWalletPinProps) => {
  return HTTP({
    Method: "POST",
    Url: CHANGE_WALLET_PIN,
    Data: DATA,
  });
};

export const WalletPaymentSubmit = ({ DATA }: WalletPaymentSubmitProps) => {
  return HTTP({
    Method: "POST",
    Url: WALLET_PAYMENT_SUBMIT,
    Data: DATA,
  });
};
