import { CreateAccountModel } from "_models/data/data.createAccount.model";
import { isValidEmail, isValidPhone } from "functions/validators";

export const validateAccountDetails = (DATA: CreateAccountModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA?.identityType?.trim()) {
    valid = false;
    errors.push({
      name: "identityType",
      error: "Select identity type.",
    });
  }

  if (!DATA?.name?.trim()) {
    valid = false;
    errors.push({
      name: "name",
      error: "Enter your name.",
    });
  }

  if (!DATA?.email?.trim()) {
    valid = false;
    errors.push({
      name: "email",
      error: "Enter your email.",
    });
  }

  if (DATA?.email?.trim() && !isValidEmail(DATA.email)) {
    valid = false;
    errors.push({
      name: "email",
      error: "Invalid email.",
    });
  }

  if (!DATA.phoneNumber) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Enter your phone number.",
    });
  }

  if (DATA?.phoneNumber?.trim() && !isValidPhone(DATA.phoneNumber)) {
    valid = false;
    errors.push({
      name: "phoneNumber",
      error: "Invalid phone number.",
    });
  }

  if (DATA?.identityType === "citizen" && !DATA.nationalIdIdNumber?.trim()) {
    valid = false;
    errors.push({
      name: "nationalIdIdNumber",
      error: "Enter your National ID number.",
    });
  }

  if (DATA?.identityType === "refugee" && !DATA.refugeeDocumentNumber?.trim()) {
    valid = false;
    errors.push({
      name: "refugeeDocumentNumber",
      error: "Enter your Refugee Document number.",
    });
  }

  if (DATA?.identityType === "tourist" && !DATA.passportNumber?.trim()) {
    valid = false;
    errors.push({
      name: "passportNumber",
      error: "Enter your Passport number.",
    });
  }

  return { valid, errors };
};
