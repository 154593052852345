import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import React from "react";

interface Props {
  status?: string | boolean | null;
  statusColor?: string;
  children?: React.ReactNode;
}

export const IsActiveCell: React.FC<Props> = ({
  status,
  statusColor,
  children,
}) => {
  const { palette } = useTheme();

  let displayText;
  let color;
  if (typeof status === "string") {
    displayText = status; // Display the string value directly
    if (displayText?.toLowerCase() === "idle") {
      color = palette.secondary.main;
    } else if (
      displayText?.toLowerCase() === "success" ||
      displayText?.toLowerCase() === "active"
    ) {
      color = palette.success.main;
    } else if (
      displayText?.toLowerCase() === "pending" ||
      displayText?.toLowerCase() === "inprogress"
    ) {
      color = palette.warning.main;
    } else {
      color = palette.error.main;
    }
  } else {
    // Handle boolean values
    displayText = status ? "Active" : "In Active";
    color = status ? palette.success.main : palette.error.main;
  }

  return (
    <>
      <Box
        component="span"
        color={color}
        sx={{
          color: statusColor || color,
          textTransform: "capitalize",
        }}
      >
        {children ? children : displayText}
      </Box>
    </>
  );
};

export const IsDeletedCell: React.FC<Props> = ({ status }) => {
  const { palette } = useTheme();
  return (
    <>
      <Box
        component="span"
        color={status ? palette.error.main : palette.secondary.main}
      >
        {status ? "Deleted" : ""}
      </Box>
    </>
  );
};
