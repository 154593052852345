import React, { SVGProps } from "react";

const ExploreIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={props?.width || "20"}
      height={props?.height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5C0.5 7.48528 2.51472 9.5 5 9.5C7.48528 9.5 9.5 7.48528 9.5 5C9.5 2.51472 7.48528 0.5 5 0.5C2.51472 0.5 0.5 2.51472 0.5 5ZM0.5 15C0.5 17.4853 2.51472 19.5 5 19.5C7.48528 19.5 9.5 17.4853 9.5 15C9.5 12.5147 7.48528 10.5 5 10.5C2.51472 10.5 0.5 12.5147 0.5 15ZM10.5 15C10.5 17.4853 12.5147 19.5 15 19.5C17.4853 19.5 19.5 17.4853 19.5 15C19.5 12.5147 17.4853 10.5 15 10.5C12.5147 10.5 10.5 12.5147 10.5 15ZM7.5 5C7.5 6.38071 6.38071 7.5 5 7.5C3.61929 7.5 2.5 6.38071 2.5 5C2.5 3.61929 3.61929 2.5 5 2.5C6.38071 2.5 7.5 3.61929 7.5 5ZM7.5 15C7.5 16.3807 6.38071 17.5 5 17.5C3.61929 17.5 2.5 16.3807 2.5 15C2.5 13.6193 3.61929 12.5 5 12.5C6.38071 12.5 7.5 13.6193 7.5 15ZM17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C13.6193 17.5 12.5 16.3807 12.5 15C12.5 13.6193 13.6193 12.5 15 12.5C16.3807 12.5 17.5 13.6193 17.5 15ZM14 9V6H11V4H14V1H16V4H19V6H16V9H14Z"
        fill={props?.fill || props?.color || "#FD9D0E"}
      />
    </svg>
  );
};

export default ExploreIcon;
