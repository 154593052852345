import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "parts/footer";
import MainContainer from "parts/mainContainer";
import Navbar from "parts/navbar";
import React from "react";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <Typography
              textAlign="center"
              fontSize={{ xs: 30, md: 48 }}
              fontWeight={800}
            >
              Not Found
            </Typography>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default NotFound;
