import { useMediaQuery } from "@mui/material";
import DesktopNavbar from "parts/navbar/component/desktopNavbar";
import React from "react";
import { useSelector } from "react-redux";
import MobileNavbar from "./component/mobileNavbar";

interface NavbarProps {
  transparent?: boolean;
  transparentBgOnly?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ transparent, transparentBgOnly }) => {
  const { isLoggedIn } = useSelector((state: any) => state.user);

  const mobileSize = useMediaQuery("(max-width:1100px)");
  return (
    <>
      {mobileSize ? (
        <>
          <MobileNavbar />
          {/* {isLoggedIn ? <MobileLoggedinNavbar /> : <MobileNavbar />} */}
        </>
      ) : (
        <>
          <DesktopNavbar />
          {/* {isLoggedIn ? <DesktopLoggedinNavbar /> : <DesktopNavbar />} */}
        </>
      )}
    </>
  );
};

export default Navbar;
