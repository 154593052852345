import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Logo from "assets/img/static/logo.png";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "state/store";
import HelpsNavMenu from "./help/helpNavMenu";
import ProductNavMenu from "./product";
import Submenu from "./subMenu";

const MobileNavbar: React.FC = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState(true);

  const handleClose = () => {
    setActiveItem(null);
    setCollapsed(!collapsed);
  };

  const listItems = [
    {
      id: "product",
      label: "Product",
      link: RouteConstant.PRODUCTS,
      component: <ProductNavMenu handleClose={handleClose} />,
    },
    // {
    //   id: "promotion",
    //   label: "Promotion",
    //   component: <PromotionNavMenu />,
    // },
    {
      id: "help",
      label: "Help",
      component: <HelpsNavMenu handleClose={handleClose} />,
    },
    {
      // id: isLoggedIn ? "my-account" : "",
      label: isLoggedIn ? "My Account" : "Login",
      // component: isLoggedIn ? <MyAccountNavMenu /> : "",
      link: isLoggedIn ? RouteConstant.MY_ACCOUNT : `${RouteConstant.LOGIN}`,
    },
  ];

  const isActiveLink = useCallback(
    (link: string) => {
      let [_, firstPath] = pathname?.split("/");
      if (link.includes("#")) {
        const [path, linkHash] = link.split("#");
        return (
          (firstPath === path || pathname === path) && `#${linkHash}` === hash
        );
      }
      return !hash && (firstPath === link || pathname === link);
    },
    [pathname, hash]
  );

  return (
    <>
      <MainContainer disablePadding>
        <Box
          className="mobile-nav-wrapper"
          sx={{
            px: {
              xs: 2,
              sm: 5,
              md: 8,
            },
            py: 2,
            boxShadow: collapsed
              ? " 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)"
              : "none",
          }}
        >
          {activeItem ? (
            <Box
              className={
                collapsed
                  ? " mobile-nav-container collapsed"
                  : "mobile-nav-container"
              }
            >
              <span className="fw-bold fs-3">{activeItem}</span>
              <IconButton onClick={() => setActiveItem(null)}>
                <KeyboardArrowLeftIcon
                  sx={{ color: "primary", size: "large" }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box
              className={
                collapsed
                  ? " mobile-nav-container collapsed"
                  : "mobile-nav-container"
              }
            >
              <Box className="nav-brand">
                <Link to={RouteConstant.HOME}>
                  <img src={Logo} alt="logo" />
                </Link>
              </Box>
              <Box className="fc-grey">
                <IconButton
                  onClick={() => setCollapsed(!collapsed)}
                  color="inherit"
                >
                  {collapsed ? <MenuRoundedIcon /> : <CloseRoundedIcon />}
                </IconButton>
              </Box>
            </Box>
          )}
          <Box
            className={
              collapsed
                ? "mobile-menu-container collapsed"
                : "mobile-menu-container"
            }
          >
            {listItems?.map((item, i) => (
              <Fragment key={i}>
                {item?.id ? (
                  <Box
                    className="mobile-nav-item"
                    onClick={() => {
                      if (item?.link) {
                        navigate(item.link);
                      }
                      setActiveItem(item.id);
                    }}
                  >
                    <Box
                      className={`mobile-nav-link ${
                        isActiveLink(item.id) ? "active" : ""
                      }`}
                    >
                      {item?.label}
                    </Box>
                    <Submenu mobile show={activeItem === item.id}>
                      {item?.component}
                    </Submenu>
                  </Box>
                ) : item?.link ? (
                  <Box className="mobile-nav-item">
                    <Box
                      className={`mobile-nav-link ${
                        isActiveLink(item.link) ? "active" : ""
                      }`}
                    >
                      <NavLink to={item.link} onClick={handleClose}>
                        <span
                          className={"item-name " + isActiveLink(item.link)}
                        >
                          {item?.label}
                        </span>
                      </NavLink>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Fragment>
            ))}

            <Box className="text-center mt-3">
              <Link
                className={"mobile-nav-link"}
                to={RouteConstant.GET_STARTED}
                onClick={handleClose}
              >
                <Chip
                  variant={
                    isActiveLink(RouteConstant?.GET_STARTED)
                      ? "filled"
                      : "outlined"
                  }
                  label="Get started"
                  color="primary"
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </MainContainer>
    </>
  );
};

export default MobileNavbar;
