import TuneIcon from "@mui/icons-material/Tune";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FilterPlansProps } from "_interfaces/_common/filterPlans";
import { GetPackageBundleProps } from "_interfaces/http-requests/packageBundle";
import { PackageBundleDetailsProps } from "_interfaces/phoneBooking";
import PlanCardFour from "component/_common/plans/planCardFour";
import {
  filterNonNullValues,
  removeSpecialCharsAndCamelCase,
} from "functions/helper";
import { PackageBundleGetAll } from "functions/http-requests/packageBundle";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleLoading } from "state/slice/loading";
import { DispatchRechargeData } from "state/slice/rechargePlan";
import Dialog from "@mui/material/Dialog";
import FilterPlans from "./filterPlans";
import Drawer from "@mui/material/Drawer";

interface Props {
  handleSelectPlan?: () => void;
  filters: FilterPlansProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterPlansProps>>;
}

const AllPlans: React.FC<Props> = ({
  handleSelectPlan,
  filters,
  setFilters,
}) => {
  const Dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isMediumDevice = useMediaQuery(breakpoints.up("md"));

  const [state, setState] = useState<PackageBundleDetailsProps[]>([]);

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleToggleFilter = () => setOpenFilter(!openFilter);

  useEffect(() => {
    let fetchPlans: (() => void) | null = () => {
      Dispatch(toggleLoading(true));

      let PAYLOAD_DATA: GetPackageBundleProps["DATA"] = {
        pageNumber: 1,
        pageSize: 100,
        packageFor:
          filters?.packageFor && filters?.packageFor?.toLowerCase() !== "all"
            ? filters?.packageFor
            : undefined,
        category:
          filters?.category && filters?.category?.toLowerCase() !== "all"
            ? filters?.category
            : undefined,
        packageType: filters?.packageType?.length
          ? filters?.packageType
          : undefined,
      };
      PAYLOAD_DATA = filterNonNullValues(PAYLOAD_DATA);

      PackageBundleGetAll({
        DATA: PAYLOAD_DATA,
      })
        .then((res) => {
          const DATA = res?.data?.data;
          if (DATA?.length) {
            setState(
              DATA?.map((item: any) => ({
                _id: item?._id || "",
                retailPrice: item?.retailPrice || "",
                packageName: item?.packageName || "",
                category: item?.category || "",
                packageType: item?.packageType || "",
                validity: item?.validity || "",
                allNetMinutes: item?.allNetMinutes || "",
                onNetMinutes: item?.onNetMinutes || "",
                offNetMinutes: item?.offNetMinutes || "",
                allNetSMS: item?.allNetSMS || "",
                onNetSMS: item?.onNetSMS || "",
                offNetSMS: item?.offNetSMS || "",
                internationalCallMinutes: item?.internationalCallMinutes || "",
                packageFor: item?.packageFor || "",
                packageID: item?.packageID || "",
                dataPerDay: item?.dataPerDay || null,
                dataVolume: item?.dataVolume || null,
              }))
            );
          } else {
            setState([]);
          }
        })
        .catch(() => {
          setState([]);
        })
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchPlans();

    return () => {
      fetchPlans = null;
    };
  }, [filters]);

  return (
    <>
      <Box pb={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h2"
            fontSize={{ xs: 24, sm: 30, md: 36 }}
            fontWeight={700}
            className="fc-dark-secondary"
          >
            Choose Your Plan
          </Typography>
          {!isMediumDevice ? (
            <Button
              onClick={handleToggleFilter}
              color="inherit"
              variant="outlined"
              size="small"
              startIcon={<TuneIcon />}
              sx={{
                fontSize: 14,
                p: "6px 12px",
              }}
            >
              Filter
            </Button>
          ) : (
            <></>
          )}
        </Box>
        <Box pt={1}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            gap={1}
          >
            {Object.keys(filters)?.map((item, i) => {
              const filteredItem = filters?.[item as keyof FilterPlansProps];

              return (
                <Fragment key={i}>
                  {filteredItem &&
                  (typeof filteredItem === "string" ||
                    (Array.isArray(filteredItem) && filteredItem?.length)) ? (
                    <>
                      <Box
                        sx={{
                          py: 0.5,
                          px: 1,
                          textTransform: "capitalize",
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography fontSize={12} className="fc-grey">
                          {removeSpecialCharsAndCamelCase(item)}:
                        </Typography>

                        {Array.isArray(filteredItem) ? (
                          filteredItem.map((subItem, subIndex) => (
                            <Fragment key={subIndex}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: 3,
                                  px: 1,
                                  py: 0.5,
                                  background: "rgba(0,0,0,0.05)",
                                  borderRadius: "30px",
                                }}
                              >
                                <Typography fontSize={14} lineHeight={1}>
                                  {removeSpecialCharsAndCamelCase(subItem)}
                                </Typography>
                                <Button
                                  onClick={() =>
                                    setFilters((prev) => ({
                                      ...prev,
                                      [item]: filteredItem.filter(
                                        (_, idx) => idx !== subIndex
                                      ), // Remove individual array item
                                    }))
                                  }
                                  color="error"
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    minWidth: "unset",
                                    borderRadius: "50%",
                                    padding: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <ClearIcon sx={{ fontSize: 16 }} />
                                </Button>
                              </Box>
                            </Fragment>
                          ))
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: 3,
                              px: 1,
                              py: 0.5,
                              background: "rgba(0,0,0,0.05)",
                              borderRadius: "30px",
                            }}
                          >
                            <Typography fontSize={14} lineHeight={1}>
                              {removeSpecialCharsAndCamelCase(filteredItem)}
                            </Typography>
                            <Button
                              onClick={() =>
                                setFilters((prev) => ({
                                  ...prev,
                                  [item]: "", // Reset the string value
                                }))
                              }
                              color="error"
                              sx={{
                                width: "20px",
                                height: "20px",
                                minWidth: "unset",
                                borderRadius: "50%",
                                padding: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ClearIcon sx={{ fontSize: 16 }} />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Fragment>
              );
            })}
          </Box>
        </Box>
      </Box>

      {state?.length ? (
        <>
          <Grid container spacing={3}>
            {state.map((item: PackageBundleDetailsProps, i: number) => (
              <Grid item key={i} xs={12} sm={6} lg={4}>
                <PlanCardFour
                  data={item}
                  handleClick={() => {
                    Dispatch(DispatchRechargeData({ bundleId: item._id }));
                    if (handleSelectPlan) handleSelectPlan();
                  }}
                  buttonText="Recharge"
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box>
          <Typography fontSize={{ xs: 16, md: 24 }} textAlign="center">
            No Plan Found
          </Typography>
        </Box>
      )}

      {/* Filter  ----- Mobile Device */}
      <Drawer
        variant="temporary"
        open={openFilter}
        onClose={handleToggleFilter}
        ModalProps={{ keepMounted: true }}
        sx={{
          "& .MuiDrawer-paper": {
            minWidth: 240, // Minimum width
          },
        }}
      >
        <FilterPlans filters={filters} setFilters={setFilters} />
      </Drawer>
    </>
  );
};

export default AllPlans;
