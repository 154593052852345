import { configureStore } from "@reduxjs/toolkit";
import progressBarReducer from "./slice/progressBar";
import userReducer from "./slice/user";
import loadingReducer from "./slice/loading";
import alertReducer from "./slice/alert";
import phoneBookingReducer from "./slice/phoneBooking";
import rechargePlanReducer from "./slice/rechargePlan";

const store = configureStore({
  reducer: {
    progressBar: progressBarReducer,
    user: userReducer,
    loading: loadingReducer,
    alert: alertReducer,
    phoneBooking: phoneBookingReducer,
    rechargePlan: rechargePlanReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== "prod",
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
