import React from "react";
import TopHeader from "./topHeader";
import MiddleHeader from "./middleHeader";
import Navbar from "parts/navbar";

const Header = () => {
  return (
    <>
      <TopHeader />
      <MiddleHeader />
      <header>
        <Navbar />
      </header>
    </>
  );
};

export default Header;
