import Box from "@mui/material/Box";
import BalanceSummaryLayout from "component/my-account/balance-summary";
import MyAccountLayout from "parts/myAccountLayout";
import SectionTitle from "parts/sectionTitle";
import React from "react";

const BalanceSummary: React.FC = () => {
  return (
    <>
      <MyAccountLayout>
        <SectionTitle title="Balance Summary" />
        <Box pt={5} className="fm-poppins">
          <BalanceSummaryLayout />
        </Box>
      </MyAccountLayout>
    </>
  );
};

export default BalanceSummary;
