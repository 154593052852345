import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LoginLayout from "component/auth/login";
import PaymentMethod from "component/_common/payment/paymentMethod";
import { paymentOptionsList } from "component/_common/payment/paymentOptionsList";
import WalletPaymentMethod from "component/_common/payment/walletPaymentMethod";
import RechargeNumberLayout from "component/_common/recharge/rechargeNumberLayout";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  BundleRechargeRequest,
  RechargeRequestUpdate,
} from "functions/http-requests/recharge";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { DispatchRechargeData } from "state/slice/rechargePlan";
import { RootState } from "state/store";
import { BundleRechargeRequestProps } from "_interfaces/http-requests/recharge";
import { BundlePlansFiltersProps } from "_interfaces/products/bundle-plans";
import { PaymentMethodDataProps } from "_interfaces/_common/payment";
import { WalletPaymentModel } from "_models/data/wallet/data.walletPayment.model";
import AllBundlePlans from "./allBundlePlans";
import AllBundlePlansFilter from "./allBundlePlansFilter";

const BundlePlansLayout = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, userInfo } = useSelector(
    (state: RootState) => state.user
  );
  const { rechargeData } = useSelector(
    (state: RootState) => state.rechargePlan
  );

  const [filters, setFilters] = useState<BundlePlansFiltersProps>({
    packageFor: "normal",
    packageType: undefined,
    packageValidity: undefined,
    unlimited: true,
  });

  const [openRechargeNumberLayout, setOpenRechargeNumberLayout] =
    useState<boolean>(false);

  const [openPaymentOption, setOpenPaymentOption] = useState<boolean>(false);
  const [openWalletPaymentMethod, setOpenWalletPaymentMethod] =
    useState<boolean>(false);

  const [walletPaymentState, setWalletPaymentState] =
    useState<WalletPaymentModel>(new WalletPaymentModel());

  const handleToggleRechargeNumberLayout = () =>
    setOpenRechargeNumberLayout(!openRechargeNumberLayout);

  const handleTogglePaymentOption = () =>
    setOpenPaymentOption(!openPaymentOption);

  const handleToggleWalletPaymentMethod = () =>
    setOpenWalletPaymentMethod(!openWalletPaymentMethod);

  let handleRechargeRequest = (DATA: PaymentMethodDataProps) => {
    if (
      DATA?.paymentBy &&
      DATA?.paymentMethod &&
      rechargeData?.bundleId &&
      rechargeData?.phoneNumber
    ) {
      let PAYLOAD_DATA: BundleRechargeRequestProps["DATA"] = {
        bundleId: rechargeData.bundleId,
        phoneNumber: rechargeData.phoneNumber,
        paymentMethod: DATA.paymentMethod,
        paymentBy: DATA.paymentBy,
        momoPhoneNumber: DATA.momoPhoneNumber || undefined,
        airtelMOMOPhone: DATA.airtelMOMOPhone || undefined,
      };

      Dispatch(toggleLoading(true));
      BundleRechargeRequest({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          const rechargeId = data?.data?.rechargeId;

          if (data?.statusCode === AppStatusCode.api_success && rechargeId) {
            if (DATA.paymentBy === "wallet") {
              const walletPaymentId = data?.data?.walletRequestId;
              setWalletPaymentState((prev) => ({
                ...prev,
                walletPaymentId: walletPaymentId,
                rechargeId: rechargeId,
              }));
              handleToggleWalletPaymentMethod();
              Dispatch(toggleLoading(false));
            } else {
              handleRechargeRequestUpdate(rechargeId);
            }
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          Dispatch(toggleLoading(false));
        });
    }
  };

  const handleCancelProcess = () => {
    Dispatch(toggleLoading(false));
  };

  const handleRechargeRequestUpdate = (ID: string) => {
    RechargeRequestUpdate(ID)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const STATUS = data?.data?.status?.toLowerCase();

          if (STATUS === "success") {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message,
              })
            );
            handleCancelProcess();
            if (openPaymentOption) setOpenPaymentOption(false);
            if (openWalletPaymentMethod) setOpenWalletPaymentMethod(false);
            navigate(RouteConstant.MY_ACCOUNT);
          } else if (STATUS === "pending") {
            // Call the function again after 4 seconds if status is pending
            setTimeout(() => {
              handleRechargeRequestUpdate(ID);
            }, 4000);
          }
        } else {
          Dispatch(
            setAlert({
              type: data?.level,
              message: data?.message || "Something went wrong!",
            })
          );
          handleCancelProcess();
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        handleCancelProcess();
      });
  };

  useEffect(() => {
    if (userInfo.phone) {
      Dispatch(DispatchRechargeData({ phoneNumber: userInfo.phone }));
    }
  }, [userInfo?.phone]);

  return (
    <>
      <Box maxWidth={600} id="next-step-for-package-bundle-selection">
        <SectionTitle
          title="Bundle Plans"
          subTitle="Choose from daily, weekly, or monthly periods of validity and
                enjoy both voice and data"
        />
      </Box>

      <Box pt={3}>
        <AllBundlePlansFilter filters={filters} setFilters={setFilters} />
      </Box>

      <AllBundlePlans
        filters={filters}
        handleSelectPlan={() => {
          handleToggleRechargeNumberLayout();
        }}
      />

      {/* PAYMENT METHOD */}
      <Dialog
        open={openPaymentOption}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            ">.MuiDialogContent-root": {
              msOverflowStyle: "none" /* Internet Explorer 10+ */,
              scrollbarWidth: "none" /* Firefox */,
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
          }}
        >
          <PaymentMethod
            handleMakePaymentRequest={handleRechargeRequest}
            togglePaymentOption={handleTogglePaymentOption}
            paymentOptions={
              isLoggedIn
                ? paymentOptionsList
                : paymentOptionsList.filter(
                    (item) => item.paymentBy !== "wallet"
                  )
            }
          />
        </DialogContent>
      </Dialog>

      {/* WALLET PAYMENT METHOD */}
      <Dialog open={openWalletPaymentMethod} fullWidth maxWidth="sm">
        <WalletPaymentMethod
          handleClose={handleToggleWalletPaymentMethod}
          walletPaymentState={walletPaymentState}
          handleContinue={handleRechargeRequestUpdate}
        />
      </Dialog>

      {/* Recharge Number */}
      <Dialog
        open={openRechargeNumberLayout}
        onClose={handleToggleRechargeNumberLayout}
        maxWidth="sm"
        fullWidth
      >
        <RechargeNumberLayout
          onClose={handleToggleRechargeNumberLayout}
          handleContinue={() => {
            if (rechargeData?.bundleId) {
              handleTogglePaymentOption();
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default BundlePlansLayout;
