import Box from "@mui/material/Box";
import {
  PromotionDataProps,
  showInPageProps,
} from "_interfaces/_common/promotion";
import { BuildBundleProps } from "_interfaces/phoneBooking";
import { PromotionGetAll } from "functions/http-requests/promotion";
import moment from "moment";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { Marquee } from "styles/marquee";

interface RenderItemProps {
  data?: PromotionDataProps;
  handleButtonClick: (data: PromotionDataProps) => void;
}

const RenderItem: React.FC<RenderItemProps> = ({ data, handleButtonClick }) => {
  return (
    <>
      <Box
        sx={{
          mr: 5,
          display: "flex",
          alignItems: "center",
          position: "relative",
          cursor: "pointer",
          "&::after": {
            //   content: i !== arr?.length - 1 ? '"•"' : '""',
            content: '"•"',
            ml: 5,
            fontSize: 24,
          },
        }}
        onClick={() => {
          if (data) handleButtonClick(data);
        }}
      >
        {data?.UI?.heading?.text}
      </Box>
    </>
  );
};

const MiddleHeader = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );

  const [promotions, setPromotions] = useState<PromotionDataProps[] | null>(
    null
  );

  const handleButtonClick = (data: PromotionDataProps) => {
    if (data?._id) {
      let intervalId = data?.buildBundleData?.intervalId || undefined;

      let dataMb = data?.buildBundleData?.dataMb || 0;
      let onNetVoice = data?.buildBundleData?.onNetVoice || 0;
      let offNetVoice = data.buildBundleData?.offNetVoice || 0;
      let smsOffNet = data.buildBundleData?.smsOffNet || 0;

      if (dataMb) {
        dataMb = data?.rechargeDataUnit === "gb" ? dataMb * 1024 : dataMb;
      }

      let newData: BuildBundleProps = {
        ...bookingData.buildBundleMinimumRequirement,
        onNetVoice,
        offNetVoice,
        dataMb,
        smsOffNet,
      };

      if (bookingData?.buildBundleDetails) {
        newData = {
          ...bookingData.buildBundleDetails,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        };
      }

      Dispatch(DispatchBuildBundleDetails(newData));

      if (intervalId) {
        Dispatch(DispatchBuildBundleIntervalId(intervalId));
      }

      Dispatch(
        DispatchPromotionDetails({
          promotionId: data._id,
          amount: data.amount || 0,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );
      Dispatch(
        DispatchBuildBundleMinimumRequirement({
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );

      navigate(`${RouteConstant.PRODUCT_BUILD_BUNDLE}?promotion=${data?._id}`);
    }
  };

  // GET PROMOTIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      PromotionGetAll({
        DATA: {
          showInPage: showInPageProps["home_top_carousel"],
        },
        isCancellable: false,
      })
        .then((res) => {
          let DATA: PromotionDataProps[] = res?.data?.data;

          const itemCondition = (item: PromotionDataProps): boolean =>
            item?.showInPage?.includes(showInPageProps["home_top_carousel"]) &&
            moment(item?.expiryDate).isAfter(moment()) &&
            item?.isActive;

          if (
            Array.isArray(DATA) &&
            DATA?.length &&
            DATA?.some((item) => itemCondition(item))
          ) {
            DATA = DATA?.filter((item) => itemCondition(item));
            setPromotions(DATA);
          }
        })
        .catch((e) => {});
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <>
      {promotions?.some(
        (item) =>
          item?.UI?.theme === 1 &&
          item?.UI?.heading?.show &&
          item?.UI?.heading?.text
      ) ? (
        <section>
          <MainContainer maxWidth="xl" bgColor="#434343">
            <Box
              sx={{
                color: "#FFFFFF",
                overflow: "hidden",
              }}
              className="fm-poppins"
            >
              <Marquee speed={20}>
                <Box py={1}>
                  {[...Array(16)].map((_, index) => (
                    <RenderItem
                      key={index}
                      data={promotions?.find((item) => item.UI.theme === 1)}
                      handleButtonClick={handleButtonClick}
                    />
                  ))}
                </Box>
              </Marquee>
            </Box>
          </MainContainer>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default MiddleHeader;
