import axios from "axios";
import { AppStatusCode } from "config/appStatusCode";
import role from "config/role";
import { HTTP_ERROR } from "functions/http";
import {
  UserLoginOtpSubmit,
  UserLoginPasswordSubmit,
  UserLoginRequest,
} from "functions/http-requests/auth";
import RouteConstant from "navigation/constant";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { setUser } from "state/slice/user";
import { LoginModel } from "_models/data/auth/data.login.model";
import { OtpVerificationModel } from "_models/data/auth/data.otpVerification.model";
import OtpVerificationLayout from "../otpVerification";
import LoginForm from "./loginForm";

interface Props {
  onClose?: () => void;
  handleContinue?: () => void;
}

const LoginLayout: React.FC<Props> = ({ onClose, handleContinue }) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isProgress, setIsProgress] = useState<boolean>(false);

  const [loginSteps, setLoginSteps] = useState<{
    needVerification: "both" | "email" | "sms" | "none";
    passwordRequired: boolean;
    setPassword: boolean;
  }>({
    needVerification: "none",
    passwordRequired: false,
    setPassword: false,
  });

  const [loginState, setLoginState] = useState<LoginModel>(new LoginModel());
  const [otpState, setOtpState] = useState<OtpVerificationModel>(
    new OtpVerificationModel()
  );

  const handleLoginComplete = (DATA: any) => {
    if (DATA.role === role.CUSTOMER) {
      localStorage.setItem("token", DATA.token);
      localStorage.setItem("signature", DATA.signature);
      localStorage.setItem("userId", DATA._id);
      Dispatch(setUser(DATA));

      if (pathname === RouteConstant.LOGIN) {
        navigate(RouteConstant.MY_ACCOUNT);
      }

      if (onClose) {
        onClose();
      }
      if (handleContinue) {
        handleContinue();
      }
    } else {
      setLoginSteps((prev) => ({
        ...prev,
        passwordRequired: false,
        setPassword: false,
        needVerification: "none",
      }));
      setLoginState((prev) => ({ ...prev, phone: "", password: "" }));
      setOtpState(new OtpVerificationModel());
      Dispatch(
        setAlert({
          type: "error",
          message: `Invalid role`,
        })
      );
    }
  };

  const handleLoginRequest = () => {
    if (loginState?.phone) {
      setIsProgress(true);

      UserLoginRequest({
        DATA: {
          phone: loginState?.phone,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;

            if (data && data?.otpToken) {
              setLoginSteps((prev) => ({
                ...prev,
                needVerification: "sms",
              }));
              setOtpState(() => ({
                OTP: "",
                token: data?.otpToken,
                type: "sms",
                otpSent: true,
              }));
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  const handleLoginOtpSubmit = () => {
    if (loginState?.phone && otpState?.OTP && otpState?.token) {
      setIsProgress(true);

      UserLoginOtpSubmit({
        DATA: {
          phone: loginState?.phone,
          otp: otpState?.OTP,
          otpToken: otpState?.token,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;

            if (data) {
              setLoginSteps((prev) => ({
                ...prev,
                needVerification: "none",
                passwordRequired: data?.passwordRequired,
                setPassword: data?.setPassword,
              }));
              setOtpState((prev) => ({
                ...prev,
                OTP: "",
                type: "",
                otpSent: false,
              }));
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  const handleLoginPasswordSubmit = () => {
    if (loginState?.phone && otpState?.token && loginState?.password) {
      setIsProgress(true);

      UserLoginPasswordSubmit({
        DATA: {
          phone: loginState?.phone,
          password: loginState.password,
          otpToken: otpState?.token,
          userAgent: loginState?.userAgent,
          location: loginState?.location,
          timeZone: loginState?.timeZone,
          stayLogin: loginState?.stayLogin,
        },
      })
        .then((res) => {
          if (res?.data?.statusCode === AppStatusCode.api_success) {
            const data = res?.data?.data;

            if (data && data?.signature && data?.token) {
              handleLoginComplete(data);
            }
          } else {
            Dispatch(
              setAlert({
                type: res?.data?.level || "error",
                message: res?.data?.message,
              })
            );
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error))
            Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        })
        .finally(() => setIsProgress(false));
    }
  };

  return (
    <>
      {loginSteps?.needVerification === "none" ||
      loginSteps?.passwordRequired ||
      loginSteps?.setPassword ? (
        <LoginForm
          handleLogin={handleLoginRequest}
          handleLoginPasswordSubmit={handleLoginPasswordSubmit}
          isProgress={isProgress}
          state={loginState}
          setState={setLoginState}
          passwordRequired={loginSteps?.passwordRequired}
          setPassword={loginSteps?.setPassword}
        />
      ) : (
        <>
          <OtpVerificationLayout
            otpState={otpState}
            setOtpState={setOtpState}
            isProgress={isProgress}
            handleResendOtp={handleLoginRequest}
            handleOtpVerification={handleLoginOtpSubmit}
          />
        </>
      )}
    </>
  );
};

export default LoginLayout;
