type Props = {
  roleId?: string;
  PIN?: string;
};

const defaultData: Props = {
  roleId: "",
  PIN: "",
};

export class CreateWalletModel {
  roleId?: string;
  PIN?: string;

  constructor(data: Props = defaultData) {
    this.roleId = data.roleId || "";
    this.PIN = data.PIN || "";
  }
}
