import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Logo from "assets/img/static/logo.png";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RootState } from "state/store";
import HelpsNavMenu from "./help/helpNavMenu";
import ProductNavMenu from "./product";
import Submenu from "./subMenu";

const DesktopNavbar = () => {
  const { pathname } = useLocation();

  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const isActive = useCallback(
    (path: string) => {
      if (path === "/") {
        return pathname === "/" ? "active" : "";
      } else {
        return pathname.startsWith(path) ? "active" : "";
      }
    },
    [pathname]
  );

  const listItems = [
    {
      label: "Home",
      link: RouteConstant.HOME,
    },
    {
      id: "product",
      label: "Products",
      link: RouteConstant.PRODUCTS,
      icon: <KeyboardArrowDownIcon />,
      component: <ProductNavMenu />,
    },
    // {
    //   id: "promotion",
    //   label: "Promotion",
    //   icon: <KeyboardArrowDownIcon />,
    //   component: <PromotionNavMenu />,
    // },
    {
      id: "help",
      label: "Help",
      icon: <KeyboardArrowDownIcon />,
      component: <HelpsNavMenu />,
    },
    {
      // id: isLoggedIn ? "my-account" : "",
      label: isLoggedIn ? "My Account" : "Login",
      icon: isLoggedIn ? <KeyboardArrowDownIcon /> : "",
      // component: isLoggedIn ? <MyAccountNavMenu /> : "",
      link: isLoggedIn ? RouteConstant.MY_ACCOUNT : `${RouteConstant.LOGIN}`,
    },
  ];
  return (
    <MainContainer className="desktop-nav-container">
      {/* <Box className="desktop-nav-container"> */}
      <Box className=" desktop-navbar ">
        <Box className="nav-brand">
          <Link to={RouteConstant.HOME}>
            <img src={Logo} alt="Talkio Mobile" />
          </Link>
        </Box>
        <Box className="desktop-nav-items-container">
          {listItems?.map((item, i) => (
            <Box className="desk-nav-item" key={i}>
              {item?.id ? (
                <>
                  <span className={"item-name " + isActive(item?.id)}>
                    {item?.link ? (
                      <NavLink to={item.link}>{item?.label}</NavLink>
                    ) : (
                      item?.label
                    )}

                    <span className="item-icon">{item?.icon}</span>
                  </span>
                  <Submenu right>{item?.component}</Submenu>
                </>
              ) : item?.link ? (
                <>
                  <NavLink to={item.link} end>
                    <span className={"item-name " + isActive(item.link)}>
                      {item?.label}
                    </span>
                  </NavLink>
                </>
              ) : (
                <></>
              )}
            </Box>
          ))}

          <Box className="desk-nav-item" pl={2}>
            <Link to={RouteConstant.GET_STARTED}>
              <Chip
                variant={
                  isActive(RouteConstant?.GET_STARTED) ? "filled" : "outlined"
                }
                label="Get started"
                color="primary"
              />
            </Link>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
    </MainContainer>
  );
};

export default DesktopNavbar;
