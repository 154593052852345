import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BannerImage from "assets/img/static/bg/build-bundle.png";
import RouteConstant from "navigation/constant";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { BuildBundleProps } from "_interfaces/phoneBooking";
import {
  fontSizeMap,
  formatFontSize,
  isValidFontSize,
  PromotionDataProps,
} from "_interfaces/_common/promotion";

interface Props {
  isImageFirst?: boolean;
  data?: PromotionDataProps;
}

const BuildBundleBanner: React.FC<Props> = ({ isImageFirst, data }) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );

  const handleButtonClick = () => {
    if (data?._id) {
      let intervalId = data?.buildBundleData?.intervalId || undefined;

      let dataMb = data?.buildBundleData?.dataMb || 0;
      let onNetVoice = data?.buildBundleData?.onNetVoice || 0;
      let offNetVoice = data.buildBundleData?.offNetVoice || 0;
      let smsOffNet = data.buildBundleData?.smsOffNet || 0;

      if (dataMb) {
        dataMb = data?.rechargeDataUnit === "gb" ? dataMb * 1024 : dataMb;
      }

      let newData: BuildBundleProps = {
        ...bookingData.buildBundleMinimumRequirement,
        onNetVoice,
        offNetVoice,
        dataMb,
        smsOffNet,
      };

      if (bookingData?.buildBundleDetails) {
        newData = {
          ...bookingData.buildBundleDetails,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        };
      }

      Dispatch(DispatchBuildBundleDetails(newData));

      if (intervalId) {
        Dispatch(DispatchBuildBundleIntervalId(intervalId));
      }

      Dispatch(
        DispatchPromotionDetails({
          promotionId: data._id,
          amount: data.amount || 0,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );
      Dispatch(
        DispatchBuildBundleMinimumRequirement({
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );

      navigate(`${RouteConstant.PRODUCT_BUILD_BUNDLE}?promotion=${data?._id}`);

      if (location.pathname === RouteConstant.PRODUCT_BUILD_BUNDLE) {
        // Manually handle scroll since route is the same
        document
          .getElementById("build-own-bundle")
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {data?._id ? (
        <Box
          pb={10}
          pl={{
            lg: isImageFirst ? "15%" : 0,
          }}
        >
          <Box
            p={{ xs: 3, sm: 5 }}
            className="bg-black fc-white"
            sx={{
              background:
                "radial-gradient(88.66% 88.66% at 7.05% 18.8%, #1D1D1D 0%, #000000 100%)",
              borderRadius: 4,
            }}
          >
            <Grid container spacing={5}>
              <Grid item xs={12} lg={4} order={isImageFirst ? 2 : 1}>
                {data?.UI?.title1?.show ? (
                  <Box
                    sx={{
                      fontSize: {
                        xs: 24,
                        sm: formatFontSize(
                          isValidFontSize(data?.UI?.title1?.size)
                            ? fontSizeMap[data?.UI?.title1?.size]
                            : "36"
                        ),
                      },
                      fontWeight: 500,
                      color: data?.UI?.title1?.color || "inherit",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data?.UI?.title1?.text,
                    }}
                  />
                ) : (
                  <></>
                )}

                {data?.UI?.title2?.show ? (
                  <Box
                    sx={{
                      fontSize: { xs: 42, lg: 70 },
                      fontWeight: 700,
                      lineHeight: 1,
                      color: data?.UI?.title2?.color || "inherit",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data?.UI?.title2?.text,
                    }}
                  />
                ) : (
                  <></>
                )}

                {data?.UI?.title3?.show ? (
                  <Box
                    sx={{
                      pt: 1,
                      fontSize: {
                        xs: 18,
                        lg: formatFontSize(
                          isValidFontSize(data?.UI?.title3?.size)
                            ? fontSizeMap[data?.UI?.title3?.size]
                            : "36"
                        ),
                      },
                      fontWeight: 400,
                      color: data?.UI?.title3?.color || "#9C9C9C",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: data?.UI?.title3?.text,
                    }}
                  />
                ) : (
                  <></>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                order={isImageFirst ? 1 : 2}
                sx={{
                  display: {
                    xs: "none",
                    lg: "block",
                  },
                }}
              >
                <Box
                  sx={{
                    background: `url(${BannerImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%",
                    transform: `scale(2) translateX(${
                      isImageFirst ? "-25%" : 0
                    })`,
                  }}
                />
              </Grid>

              <Grid item xs={12} lg={4} order={3}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    pl: { lg: 5 },
                    pt: { xs: 5, lg: 0 },
                    position: "relative",
                    "&::before": {
                      content: '""',
                      display: "block",
                      width: { xs: "70%", lg: "1px" },
                      height: { xs: "1px", lg: "70%" },
                      background: "#474646",
                      position: "absolute",
                      left: {
                        xs: "50%",
                        lg: 0,
                      },
                      top: {
                        xs: 0,
                        lg: "50%",
                      },
                      transform: {
                        xs: "translateX(-50%)",
                        lg: "translateY(-50%)",
                      },
                    },
                  }}
                >
                  {data?.UI?.title4?.show ? (
                    <Box
                      sx={{
                        fontSize: formatFontSize(
                          isValidFontSize(data?.UI?.title4?.size)
                            ? fontSizeMap[data?.UI?.title4?.size]
                            : "12"
                        ),
                        fontWeight: 700,
                        color: data?.UI?.title4?.color || "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.UI?.title4?.text,
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  {data?.UI?.description?.show ? (
                    <Box
                      sx={{
                        fontSize: formatFontSize(
                          isValidFontSize(data?.UI?.description?.size)
                            ? fontSizeMap[data?.UI?.description?.size]
                            : "22"
                        ),
                        fontWeight: 400,
                        color: data?.UI?.description?.color || "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.UI?.description?.text,
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <Box pt={5}>
                    <Button
                      onClick={handleButtonClick}
                      variant="contained"
                      className="c-bg-primary fc-black"
                      sx={{
                        borderRadius: "6px",
                        borderColor: "inherit",
                      }}
                    >
                      Book Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default BuildBundleBanner;
