import {
  AIRTIME_RECHARGE_REQUEST,
  BUILD_BUNDLE_RECHARGE_REQUEST,
  BUNDLE_RECHARGE_REQUEST,
  BUNDLE_RECHARGE_REQUEST_SECURE,
  GET_CUSTOMER_BY_PHONE,
  RECHARGE_REQUEST_UPDATE,
  WALLET_RECHARGE_REQUEST,
} from "config/endpoint";
import { HTTP } from "functions/http";
import {
  AirtimeRechargeRequestProps,
  BuildBundleRechargeRequestProps,
  BundleRechargeRequestProps,
  WalletRechargeRequestProps,
} from "_interfaces/http-requests/recharge";

export const GetCustomerByPhone = (phoneNumber: string) => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_BY_PHONE(phoneNumber),
  });
};

export const WalletRechargeRequest = ({ DATA }: WalletRechargeRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: WALLET_RECHARGE_REQUEST,
    Data: DATA,
  });
};

export const AirtimeRechargeRequest = ({
  DATA,
}: AirtimeRechargeRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: AIRTIME_RECHARGE_REQUEST,
    Data: DATA,
  });
};

export const BundleRechargeRequest = ({ DATA }: BundleRechargeRequestProps) => {
  let token: string | null = localStorage.getItem("token");

  return HTTP({
    Method: "POST",
    Url: token ? BUNDLE_RECHARGE_REQUEST_SECURE : BUNDLE_RECHARGE_REQUEST,
    Data: DATA,
  });
};

export const RechargeRequestUpdate = (ID: string) => {
  return HTTP({
    Method: "POST",
    Url: RECHARGE_REQUEST_UPDATE(ID),
  });
};

export const BuildBundleRechargeRequest = ({
  DATA,
}: BuildBundleRechargeRequestProps) => {
  return HTTP({
    Method: "POST",
    Url: BUILD_BUNDLE_RECHARGE_REQUEST,
    Data: DATA,
  });
};
