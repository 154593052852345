import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Grid, Typography } from "@mui/material";
import CustomizeImage from "assets/img/static/bg/customize-connectivity.jpeg";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { Link } from "react-router-dom";
import BannerImage from "assets/img/static/bg/choose-us.jpg";

const WhyChooseUs = () => {
  const list = [
    { title: "Freedom", subtitle: "Create the plan that works for you" },
    {
      title: "Transparency",
      subtitle: "No hidden fees",
    },
    { title: "Nationwide coverage", subtitle: "We’re everywhere you go" },
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box
            height="100%"
            borderRadius={5}
            p={{ xs: 2, md: 3 }}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 1)",
              backgroundImage: `url(${BannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <SectionTitle
              title="3 Reasons to choose us"
              className="fc-primary-var"
              sx={{
                fontWeight: 600,
                fontSize: {
                  xs: 24,
                  md: 40,
                },
              }}
            />
            <Box pt={3}>
              <Grid container spacing={2}>
                {list?.map((item, i) => (
                  <Grid item xs={12} sm={4} key={i}>
                    <Box
                      p={2}
                      height="100%"
                      sx={{
                        border: "1px solid #363636",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        className="fc-primary"
                        sx={{
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                      >
                        0{i + 1}
                      </Typography>
                      <Typography
                        py={2}
                        className="fc-white"
                        fontSize={16}
                        fontWeight={700}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        fontSize={15}
                        fontWeight={400}
                        sx={{ color: "#c9c9c9" }}
                      >
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box pr={{ xs: 3, md: 0 }}>
            <Box borderRadius={5} height="100%" position="relative">
              <Box
                overflow="hidden"
                height="100%"
                width="100%"
                borderRadius={5}
              >
                <img
                  src={CustomizeImage}
                  alt="Customize your connectivity"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "bottom center",
                  }}
                />
              </Box>
              <Link to={RouteConstant.PRODUCTS}>
                <Box
                  className="fc-white bg-black"
                  width={80}
                  height={80}
                  borderRadius="100%"
                  position="absolute"
                  right="-15px"
                  bottom="-15px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    "&:after": {
                      content: '""',
                      display: "block",
                      width: "100%",
                      height: "100%",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      transform: "scale(1.15)",
                      position: "absolute",
                    },
                  }}
                >
                  <ArrowOutwardIcon fontSize="large" />
                </Box>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default WhyChooseUs;
