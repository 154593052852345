import ArrowOutward from "@mui/icons-material/ArrowOutward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";

const HomeGetStarted = () => {
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={7} md={8}>
          <Box
            sx={{
              borderRight: {
                sm: "1px solid #B4ABAB",
              },
              pr: {
                sm: 2,
              },
            }}
          >
            <Typography>
              Choose Your Product that Fits Your Need
              <br />
              Explore and select from a variety of mobile plans, mobile money
              services, and home internet options. Plus, shop online for your
              favorite products — all in one place
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <Box textAlign={{ xs: "center", sm: "left" }}>
            <Link to={RouteConstant.PRODUCTS}>
              <Button
                fullWidth
                variant="text"
                className="fc-primary"
                endIcon={<ArrowOutward fontSize="large" />}
                sx={{
                  fontSize: { xs: 18, md: 24 },
                  fontWeight: 600,
                }}
              >
                Get Started
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeGetStarted;
