import RechargePlansLayout from "component/recharge/plans";
import React from "react";

const RechargePlans = () => {
  return (
    <>
      <RechargePlansLayout />
    </>
  );
};

export default RechargePlans;
