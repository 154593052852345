import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FlareImage from "assets/img/static/icon/flare.png";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  GetRandomNumberForBooking,
  SearchPhoneNumber,
} from "functions/http-requests/MSISDN";
import RouteConstant from "navigation/constant";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import {
  DispatchBookingStep,
  DispatchPhoneDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { RandomNumberTypesProps } from "_interfaces/http-requests/MSISDN";
import {
  SearchNumberModel,
  SearchResultProps,
} from "_models/data/data.searchNumber.model";
import { SearchNumberErrorModel } from "_models/errors/error.searchNumber.model";
import InputText from "../forms/inputText";

interface Props {
  onClose: () => void;
  reSelectingNumber?: boolean;
  setReSelectingNumber?: React.Dispatch<React.SetStateAction<boolean>>;
}
const SearchNumber: React.FC<Props> = ({
  onClose,
  reSelectingNumber,
  setReSelectingNumber,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const NUMBER_PREFIX = "07280";

  const { stepIndex } = useSelector((state: RootState) => state.phoneBooking);

  const [state, setState] = useState<SearchNumberModel>(
    new SearchNumberModel()
  );
  const [errors, setErrors] = useState<SearchNumberErrorModel>(
    new SearchNumberErrorModel()
  );

  interface AllowedRandomNumberTypesProps {
    id: RandomNumberTypesProps;
    label: string;
  }
  const allowedRandomNumberTypes: AllowedRandomNumberTypesProps[] = [
    {
      id: "normal",
      label: "Normal",
    },
    {
      id: "premium",
      label: "Premium",
    },
  ];
  const [randomNumberType, setRandomNumberType] = useState<
    AllowedRandomNumberTypesProps["id"] | ""
  >("");

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleRefresh = () => setRefresh(!refresh);

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event:
      | SelectChangeEvent
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    setState((prev) => ({
      ...prev,
      [name]: value,
      searchResult: null,
    }));
  };

  const resetNumber = () => {
    setState((prev) => ({ ...prev, searchNumber: "", searchResult: null }));
  };

  const onSelectNumber = (item: SearchResultProps) => {
    if (item?._id) {
      Dispatch(DispatchPhoneDetails(item));
      if (!reSelectingNumber) {
        Dispatch(DispatchBookingStep(stepIndex + 1));
      }
      if (setReSelectingNumber) {
        setReSelectingNumber(false);
      }
      onClose();
      if (pathname !== RouteConstant.CONFIRM_NUMBER) {
        const couponToken = searchParams.get("ct"); // Get couponToken from URL
        if (couponToken) {
          navigate(`${RouteConstant.PRODUCT_BUILD_BUNDLE}?ct=${couponToken}`);
        } else {
          navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
        }
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!state.searchNumber) {
      setErrors((prev) => ({
        ...prev,
        searchNumber: "Please enter phone number",
      }));
      return;
    }
    Dispatch(toggleLoading(true));
    SearchPhoneNumber(`${NUMBER_PREFIX}${state.searchNumber}`)
      .then((res) => {
        const data = res?.data;
        if (data?.statusCode === AppStatusCode.api_success) {
          setState((prev) => ({
            ...prev,
            searchResult: data?.data,
          }));
        } else {
          Dispatch(
            setAlert({ type: data?.level, message: "Number not available!" })
          );
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
      })
      .finally(() => {
        Dispatch(toggleLoading(false));
      });
  };

  useEffect(() => {
    let fetchRandomNumber: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      GetRandomNumberForBooking({
        PARAMS: {
          type: randomNumberType || undefined,
        },
      })
        .then((res) => {
          const data = res?.data;
          if (data?.statusCode === AppStatusCode.api_success) {
            setState((prev) => ({
              ...prev,
              randomResults: data?.data,
            }));
          }
        })
        .catch(() => {})
        .finally(() => {
          Dispatch(toggleLoading(false));
        });
    };
    fetchRandomNumber();

    return () => {
      fetchRandomNumber = null;
    };
  }, [Dispatch, randomNumberType, refresh]);

  const planList = [
    { id: "normal", label: "Normal" },
    { id: "red-vip", label: "Red VIP Only" },
  ];

  return (
    <>
      <DialogContent
        sx={{
          padding: {
            xs: 2,
            sm: 3,
            md: 5,
          },
          minHeight: "400px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { xs: 10, sm: 20, md: 50 },
            top: { xs: 5, sm: 15, md: 30 },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box textAlign="center" pt={{ xs: 3, sm: 0 }}>
          <Typography
            textTransform="uppercase"
            className="fm-poppins"
            fontSize={{ xs: 12, sm: 14, md: 16 }}
          >
            choose a number
          </Typography>
          <Typography
            textTransform="uppercase"
            fontSize={{ xs: 16, sm: 24, md: 36 }}
          >
            find a number you love
          </Typography>
          <Typography
            className="fm-poppins"
            fontSize={{ xs: 12, sm: 14, md: 16 }}
          >
            Search for any digits you want in your number.
          </Typography>
        </Box>
        <Box pt={5} maxWidth={300} margin="auto" className="fm-poppins">
          <Box component="form" onSubmit={handleSubmit}>
            <InputText
              type="text"
              placeholder="XXXXX"
              id="searchNumber"
              name="searchNumber"
              value={state?.searchNumber || ""}
              error={!!errors?.["searchNumber"]}
              errorText={
                errors?.["searchNumber"]
                  ? errors?.["searchNumber"]
                  : state?.searchResult?._id
                  ? "Number Available"
                  : ""
              }
              FormHelperTextProps={{
                style: {
                  color: state?.searchResult?._id
                    ? palette.success.main
                    : undefined,
                  textAlign: state?.searchResult?._id ? "right" : "left",
                },
              }}
              onChange={handleChange}
              onFocus={() => handleFocus("searchNumber")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {NUMBER_PREFIX}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 5, // Restrict input to 5 digits
                inputMode: "numeric",
                pattern: "[0-9]*", // Ensures only numeric input
              }}
            />

            <Box pt={4} textAlign="center">
              {state?.searchResult?._id ? (
                <>
                  {state?.searchResult?.type?.toLowerCase() !== "normal" ? (
                    <Box color="red" fontSize={12} mb={1}>
                      This is a{" "}
                      <Box
                        component="span"
                        textTransform="capitalize"
                        fontWeight={500}
                      >
                        {state?.searchResult?.type}
                      </Box>{" "}
                      phone number.
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() =>
                      state.searchResult && onSelectNumber(state.searchResult)
                    }
                    sx={{
                      mb: 1,
                      backgroundColor: "#000",
                      "&:hover": {
                        backgroundColor: "#000",
                      },
                    }}
                  >
                    Continue with this number
                  </Button>
                  <Button
                    fullWidth
                    variant="text"
                    color="secondary"
                    onClick={resetNumber}
                  >
                    Search another number
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={!state?.searchNumber}
                >
                  SEARCH
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {state?.randomResults?.length ? (
          <>
            <Box py={3} textAlign="center">
              <Typography>OR</Typography>
            </Box>
            <Box textAlign="center" className="fm-poppins">
              <Typography variant="body2" pb={3}>
                Choose a number from the list
              </Typography>
              <Grid
                container
                spacing={2}
                alignItems={{
                  xs: "center",
                  md: "flex-end",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  order={{ xs: 3, sm: 2, md: 1 }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    alignItems={{
                      xs: "center",
                      sm: "flex-start",
                    }}
                  >
                    <Box
                      display="flex"
                      gap={1}
                      color="gray"
                      flexWrap="wrap"
                      alignItems="center"
                    >
                      <Typography textAlign="left" fontSize={12}>
                        Filter:
                      </Typography>
                      {allowedRandomNumberTypes.map((item, i) => (
                        <Button
                          key={i}
                          onClick={() => {
                            if (randomNumberType !== item.id) {
                              setRandomNumberType(item.id);
                            } else {
                              setRandomNumberType("");
                            }
                          }}
                          variant={
                            item.id === randomNumberType
                              ? "contained"
                              : "outlined"
                          }
                          color="inherit"
                          size="small"
                          sx={{
                            fontSize: 12,
                            border: "1px solid gray",
                          }}
                        >
                          {item.label}
                        </Button>
                      ))}
                    </Box>

                    <Typography
                      fontSize={10}
                      color="red"
                      textAlign={{ xs: "center", sm: "left" }}
                    >
                      * The charge for a premium number is 100K.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                  <Box pb={1}>
                    <img src={FlareImage} alt="Hurrah!" width={30} />
                  </Box>
                  <Typography>Hurrah!</Typography>
                  <Typography variant="body2">
                    Here are some cool numbers we found for you:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  order={{ xs: 2, sm: 3, md: 3 }}
                  display="flex"
                  justifyContent={{ xs: "center", sm: "flex-end" }}
                  color="gray"
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    startIcon={<RefreshIcon fontSize="small" />}
                    onClick={handleRefresh}
                    sx={{
                      fontSize: 12,
                    }}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box py={3}>
              <Grid container spacing={2} justifyContent="center">
                {state?.randomResults?.map((item, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      endIcon={<KeyboardArrowRightOutlinedIcon />}
                      sx={{
                        fontSize: { xs: 14, sm: 20 },
                      }}
                      className="fm-poppins"
                      onClick={() => item && onSelectNumber(item)}
                    >
                      {item?.phoneNumber}
                      {item?.type?.toLowerCase() !== "normal" ? (
                        <Box
                          component="small"
                          color="red"
                          fontSize={10}
                          pl={1}
                          position="relative"
                          top="2px"
                          textTransform="capitalize"
                        >
                          {item?.type}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <></>
        )}
      </DialogContent>
    </>
  );
};

export default SearchNumber;
