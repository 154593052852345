import Box from "@mui/material/Box";
import GetStartedDesc from "component/get-started/desc";
import GetStaredHeader from "component/get-started/header";
import MidSection from "component/get-started/mid";
import MainContainer from "parts/mainContainer";
import React from "react";

const GetStarted: React.FC = () => {
  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <GetStaredHeader />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer bgColor="#3c3a3c">
          <Box py={5}>
            <MidSection />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box py={5}>
            <GetStartedDesc />
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default GetStarted;
