export interface SearchResultProps {
  _id: string;
  MSISDN: string;
  phoneLocal: string;
  phoneNumber: string;
  type: string;
}

interface Props {
  activePlan?: string;
  searchNumber?: string;
  searchResult?: null | SearchResultProps;
  randomResults?: SearchResultProps[];
}

const defaultData: Props = {
  activePlan: "",
  searchNumber: "",
  searchResult: null,
  randomResults: [],
};

export class SearchNumberModel {
  activePlan?: string;
  searchNumber?: string;
  searchResult?: null | SearchResultProps;
  randomResults?: SearchResultProps[];

  constructor(data: Props = defaultData) {
    this.activePlan = data.activePlan || "";
    this.searchNumber = data.searchNumber || "";
    this.searchResult = data.searchResult || null;
    this.randomResults = data.randomResults || [];
  }
}
