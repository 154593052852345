import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "state/store";
import RouteConstant from "./constant";

interface Props {
  children: React.ReactNode;
  role?: string | string[];
}
const SecuredRoute: React.FC<Props> = ({ children, role }) => {
  const { isLoggedIn, userInfo } = useSelector(
    (state: RootState) => state.user
  );

  if (!isLoggedIn) return <Navigate to={RouteConstant.LOGIN} />;

  if (
    role &&
    ((!Array.isArray(role) && role !== userInfo?.role) ||
      (Array.isArray(role) && !role.includes(userInfo?.role)))
  ) {
    return <Navigate to={RouteConstant.UNAUTHORIZED} />;
  }

  return <>{children}</>;
};

export default SecuredRoute;
