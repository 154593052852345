interface Props {
  agreeTerms?: boolean;
}

const defaultData: Props = {
  agreeTerms: false,
};

export class CheckoutModel {
  agreeTerms?: boolean;

  constructor(data: Props = defaultData) {
    this.agreeTerms = data.agreeTerms || false;
  }
}
