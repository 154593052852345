import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import {
  AutoCompleteOptionsProps,
  FormInputProps,
} from "_interfaces/_common/forms";
import React from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props extends FormInputProps {
  options: AutoCompleteOptionsProps[];
  errorText?: string;
  tagList?: string[];
  onChange: (
    event: React.SyntheticEvent,
    value: any,
    multiple?: boolean
  ) => void;
  value?: any;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  multiple?: boolean;
}

const InputAutoComplete: React.FC<Props> = ({
  label,
  name,
  options,
  placeholder,
  onChange,
  value,
  errorText,
  onFocus,
  multiple,
  disabled,
}) => {
  const { palette } = useTheme();

  return (
    <>
      {/* {label ? (
        <FormLabel
          htmlFor={name}
          className="tw-mb-[6px] tw-block"
          sx={{ color: palette.secondary.main }}
        >
          {label}
        </FormLabel>
      ) : (
        <></>
      )} */}
      <Autocomplete
        fullWidth
        multiple={multiple}
        limitTags={3}
        id={name}
        options={options}
        getOptionDisabled={(option) => (option?.isActive ? false : true)}
        value={
          value
            ? multiple
              ? options?.filter((item) =>
                  value?.includes(item?.value || item?.id)
                )
              : options?.find(
                  (item) => value === item?.value || value === item?.id
                )
            : multiple
            ? []
            : null
        }
        disabled={disabled}
        disableCloseOnSelect={multiple}
        getOptionLabel={(option: AutoCompleteOptionsProps) => option.title}
        onChange={(e, v) => onChange(e, v, multiple)}
        renderOption={(props, option: any, { selected }) => (
          <li {...props} key={option?.id || option?.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.title}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            color="secondary"
            autoComplete="off"
            name={name}
            label={label}
            placeholder={placeholder}
            error={errorText ? true : false}
            helperText={errorText}
            onFocus={onFocus}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default InputAutoComplete;
