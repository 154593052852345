import RouteConstant from "navigation/constant";
import { AutoCompleteOptionsProps } from "_interfaces/_common/forms";

export const Channel = new BroadcastChannel("logout");

export const ClearDataOnLogout = () => {
  localStorage.removeItem("token");
  window.location.href = RouteConstant.LOGIN;
};

export const getLocation = (): Promise<GeolocationPosition> => {
  return new Promise((res, rej) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res, rej);
    } else {
      return rej;
    }
  });
};

export const filterNonNullValues = (obj: any): any => {
  const filteredObj: any = {};
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== ""
    ) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
};

export const camelCaseToFormattedString = (camelCase: string): string => {
  if (!camelCase) return "";
  return camelCase.replace(/([A-Z])/g, " $1").toLowerCase();
};

export const removeSpecialCharsAndCamelCase = (str?: string): string => {
  if (!str) return "";

  // Replace special characters with space if not already preceded or followed by a space
  const spacedStr = str.replace(
    /([^a-zA-Z0-9])(?=\S)|(?<=\S)([^a-zA-Z0-9])/g,
    " "
  );

  // Convert camel case to normal text by adding space before uppercase letters
  const normalText = spacedStr.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Convert to lowercase and trim any extra spaces
  return normalText.toLowerCase().trim();
};

export const toTitleCase = (str: string): string => {
  if (!str) return "";
  return (
    str
      .toString()
      // Insert spaces before uppercase letters (for camelCase)
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      // Replace underscores and hyphens with spaces
      .replace(/[_-]/g, " ")
      // Convert each word to title case
      .replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
      )
  );
};

export const getClientTimeZone = (): string | null => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone) return timeZone;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getInitialName = (name: string) => {
  if (!name) return "";

  const words = name.trim().split(" ");
  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase();
  }

  return words
    .map((word) => word.charAt(0).toUpperCase())
    .join("")
    .substring(0, 2);
};

export const formatDataUsage = (value: number): string => {
  const formatValue = (val: number) => {
    return val % 1 === 0 ? val.toFixed(0) : val.toFixed(2);
  };

  if (value >= 1024 * 1024) {
    return formatValue(value / (1024 * 1024)) + " TB";
  } else if (value >= 1024) {
    return formatValue(value / 1024) + " GB";
  } else {
    return formatValue(value) + " MB";
  }
};

export const removeLeadingSlash = (path: string): string =>
  path.startsWith("/") ? path.slice(1) : path;

export const removeAllSlashes = (path: string): string =>
  path.replace(/\//g, "");

export const formatPathToHyphen = (path: string): string =>
  path.replace(/^\/|\/$/g, "").replace(/\//g, "-");

export const formatPriceWithDelimiter = (
  price: string | number,
  shouldRound: boolean = true // Default to rounding
): string => {
  try {
    const parsedPrice = Number(price);

    if (isNaN(parsedPrice)) {
      return "";
    }

    if (shouldRound) {
      // Round the number to the nearest integer
      return Math.round(parsedPrice).toLocaleString("en-UG", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } else {
      // Check if the decimal part is zero
      const hasDecimal = parsedPrice % 1 !== 0;

      return parsedPrice.toLocaleString("en-UG", {
        minimumFractionDigits: hasDecimal ? 2 : 0,
        maximumFractionDigits: hasDecimal ? 2 : 0,
      });
    }
  } catch (error) {
    return "";
  }
};

export const generateEndpoint = (
  endpoint: string,
  params?: Record<string, any>
): string => {
  // Remove leading slash if present
  let url: string = endpoint.replace(/^\//, "");

  // Check if params are provided and not empty
  if (params && Object.keys(params).length > 0) {
    const queryString = Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null) // Filter out null/undefined
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
      ) // Encode values
      .join("&");
    if (queryString) {
      url += `?${queryString}`;
    }
  }

  return url;
};

// Convert enum or record keys to an array of strings
const getEnumKeys = <T>(enumObj: T): string[] => {
  if (enumObj) {
    return Object.keys(enumObj).filter((key) => isNaN(Number(key)));
  }
  return [];
};

// Generate autocomplete options with consistent string values
export const generateAutocompleteOptions = (
  values: string[] | Record<string, string | number>
): AutoCompleteOptionsProps[] => {
  const keys = Array.isArray(values) ? values : getEnumKeys(values); // Extract keys for enums or objects

  return keys.map((key) => {
    const rawValue = Array.isArray(values)
      ? key
      : values[key as keyof typeof values]; // Get the value from record or use the key directly

    const value = String(rawValue); // Ensure all values are strings

    return {
      id: value, // Stringified ID
      value: value, // Stringified value
      title: toTitleCase(key), // Convert key to title case for display
      isActive: true,
    };
  });
};
