import { ApplyCouponModel } from "_models/data/data.applyCoupon.model";

export const validateApplyCoupon = (DATA: ApplyCouponModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  const couponRegex = /^[a-zA-Z0-9-]+$/;

  if (!DATA?.coupon?.trim()) {
    valid = false;
    errors.push({
      name: "coupon",
      error: "Enter coupon code",
    });
  } else if (DATA?.coupon.length < 4 || DATA.coupon.length > 10) {
    valid = false;
    errors.push({
      name: "coupon",
      error: "Invalid coupon code",
    });
  } else if (!couponRegex.test(DATA?.coupon)) {
    valid = false;
    errors.push({
      name: "coupon",
      error: "Invalid coupon code",
    });
  }

  return { valid, errors };
};
