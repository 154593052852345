import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MyAccountDetailsProps } from "_interfaces/myAccount";
import AirtimeRecharge from "component/my-account/my-wallet/airtimeRecharge";
import ProfileCard from "component/my-account/myAccountLayout/profileCard";
import { GetCustomerInfo } from "functions/http-requests/customer";
import moment from "moment";
import RouteConstant from "navigation/constant";
import MyAccountLayout from "parts/myAccountLayout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleLoading } from "state/slice/loading";

const MyAccount: React.FC = () => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<MyAccountDetailsProps | null>(null);

  const [openAirtimeRecharge, setOpenAirtimeRecharge] =
    useState<boolean>(false);

  const handleToggleAirtimeRecharge = () =>
    setOpenAirtimeRecharge(!openAirtimeRecharge);

  useEffect(() => {
    setIsLoaded(false);
    Dispatch(toggleLoading(true));
    let fetchInfo: (() => void) | null = () => {
      GetCustomerInfo()
        .then((res) => {
          const DATA = res?.data?.data?.[0];
          if (DATA) {
            setState(DATA);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoaded(true);
          Dispatch(toggleLoading(false));
        });
    };

    fetchInfo();

    return () => {
      fetchInfo = null;
    };
  }, [Dispatch]);

  return (
    <>
      <MyAccountLayout>
        {isLoaded ? (
          <Grid container spacing={5} className="fc-secondary">
            {state ? (
              <Grid item xs={12} lg={8}>
                <ProfileCard data={state} />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} lg={4}>
              <Box
                sx={{
                  boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
                  borderRadius: 3,
                  padding: 3,
                  height: "100%",
                }}
              >
                <Typography fontSize={12} pb={2}>
                  Features:
                </Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Link to={RouteConstant.MY_WALLET}>
                    <Button
                      onClick={handleToggleAirtimeRecharge}
                      fullWidth
                      variant="text"
                      color="inherit"
                    >
                      Wallet Balance
                    </Button>
                  </Link>
                  <Button
                    onClick={handleToggleAirtimeRecharge}
                    fullWidth
                    variant="outlined"
                    color="inherit"
                  >
                    Recharge Airtime
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </MyAccountLayout>

      {/* Airtime RECHARGE */}
      <Dialog
        open={openAirtimeRecharge}
        onClose={handleToggleAirtimeRecharge}
        fullWidth
        maxWidth="sm"
      >
        <AirtimeRecharge
          // handleRefresh={handleRefresh}
          handleClose={handleToggleAirtimeRecharge}
        />
      </Dialog>
    </>
  );
};

export default MyAccount;
