import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useState } from "react";
import { CheckStatusModel } from "_models/data/data.checkStatus.model";
import { CheckStatusErrorModel } from "_models/errors/error.checkStatus.model";
import InputText from "../forms/inputText";

interface Props {
  onClose: () => void;
}

const CheckStatus: React.FC<Props> = ({ onClose }) => {
  const [state, setState] = useState<CheckStatusModel>(new CheckStatusModel());
  const [errors, setErrors] = useState<CheckStatusErrorModel>(
    new CheckStatusErrorModel()
  );

  const handleFocus = (name: string) => {
    if (name) setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    setState((prev) => ({
      ...prev,
      [name]: value,
      searchResult: null,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!state.statusId) {
      setErrors((prev) => ({
        ...prev,
        searchNumber: "Please enter id",
      }));
      return;
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          padding: {
            xs: 2,
            sm: 3,
            md: 5,
          },
          minHeight: "400px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { xs: 10, sm: 20, md: 50 },
            top: { xs: 5, sm: 15, md: 30 },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box textAlign="center" pt={{ xs: 3, sm: 0 }}>
          <Typography
            textTransform="uppercase"
            className="fm-poppins"
            fontSize={{ xs: 12, sm: 14, md: 16 }}
          >
            Booking Overview
          </Typography>
          <Typography
            textTransform="uppercase"
            fontSize={{ xs: 16, sm: 24, md: 36 }}
          >
            Check Your Booking Status
          </Typography>
          <Typography
            className="fm-poppins"
            fontSize={{ xs: 12, sm: 14, md: 16 }}
          >
            Stay updated on the current progress of your booking
          </Typography>
        </Box>
        <Box pt={5} maxWidth={300} margin="auto" className="fm-poppins">
          <Box component="form" onSubmit={handleSubmit}>
            <InputText
              type="text"
              placeholder="Enter Id"
              id="statusId"
              name="statusId"
              value={state?.statusId || ""}
              error={!!errors?.["statusId"]}
              errorText={errors?.["statusId"] ? errors?.["statusId"] : ""}
              onChange={handleChange}
              onFocus={() => handleFocus("searchNumber")}
            />

            <Box
              pt={4}
              textAlign="center"
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={!state?.statusId}
              >
                Check Status
              </Button>

              <Button
                fullWidth
                variant="text"
                color="inherit"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </>
  );
};

export default CheckStatus;
