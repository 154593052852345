import { calculateBuildBundleReturn } from "component/_common/plans/calculateBuildBundleCost";

export type IdentityTypeProps = "citizen" | "refugee" | "tourist";

export enum BuildBundleInterval {
  NonExpiry = 18,
  Hourly = 19,
  Daily = 1,
  Weekly = 3,
}

export type PhoneDetailsProps = {
  _id: string;
  MSISDN: string;
  phoneLocal: string;
  phoneNumber: string;
  type: string;
};

export type PackageBundleDetailsProps = {
  _id: string;
  retailPrice: string | number;
  packageName: string;
  category: string;
  packageType: string;
  validity: string | number;
  isValidityUnlimited?: boolean;
  allNetMinutes: string | number;
  onNetMinutes: string | number;
  offNetMinutes: string | number;
  allNetSMS: string | number;
  onNetSMS: string | number;
  offNetSMS: string | number;
  internationalCallMinutes: string | number;
  packageFor: string;
  packageID: string;
  dataVolume?: {
    quantity: string | number;
    dataUnit: string;
    isUnlimited: boolean;
  } | null;
  dataPerDay?: {
    quantity: string | number;
    dataUnit: string;
    isUnlimited: boolean;
  } | null;
};

export type AccountDetailsProps = {
  name: string;
  email: string;
  phoneNumber: string;
  identityType?: IdentityTypeProps;
  nationalIdIdNumber?: string;
  refugeeDocumentNumber?: string;
  passportNumber?: string;
};

export type BookingDetailsProps = {
  _id: string;
  bookingID: string;
  promotionId?: string;
};

export type BundleBundleChargesProps = {
  onNetPrice: number;
  offNetPrice: number;
  dataPrice: number;
  smsPrice: number;
  totalPrice: number;
};

export type BillDetailsProps = {
  _id: string;
  billID: string;
  subTotalSimCharges?: string | number;
  simChargesId?: string;
  subTotalPackageCharges?: string | number;
  subTotalPackageChargesId?: string;
  totalCharges?: string | number;
  bundleBundleCharges?: BundleBundleChargesProps;
  isCouponApplied?: boolean;
  couponId?: string;
  totalWithoutDiscount?: string | number;
  discountAmount?: string | number;
};

export type BuildBundleProps = {
  onNetVoice?: number;
  offNetVoice?: number;
  dataMb?: number;
  smsOffNet?: number;
};

export type PromotionDetailsProps = {
  promotionId: string;
  amount: number;
  onNetVoice?: number;
  offNetVoice?: number;
  dataMb?: number;
  smsOffNet?: number;
  charges?: null | calculateBuildBundleReturn;
};
