import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MyAccountDetailsProps } from "_interfaces/myAccount";
import { camelCaseToFormattedString } from "functions/helper";
import RouteConstant from "navigation/constant";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "state/store";

interface Props {
  data: MyAccountDetailsProps;
}
const ProfileCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: RootState) => state.user);

  return (
    <>
      <Box
        sx={{
          boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
          borderRadius: 3,
          padding: 3,
        }}
      >
        <Typography
          fontSize={22}
          fontWeight={600}
          className="fc-black-secondary"
        >
          {data.nameAsDocument}
        </Typography>
        <Box pt={2} fontSize={14}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              lineHeight: 1,
              "p:not(:last-child)": {
                pr: 2,
                borderRight: "1px solid rgba(0,0,0,0.3)",
              },
            }}
          >
            <Typography
              textTransform="capitalize"
              fontSize="inherit"
              lineHeight="inherit"
            >
              {data?.serviceType
                ? camelCaseToFormattedString(data.serviceType)
                : ""}
            </Typography>
            <Typography
              textTransform="capitalize"
              fontSize="inherit"
              lineHeight="inherit"
            >
              {data?.SIM_type ? camelCaseToFormattedString(data.SIM_type) : ""}
            </Typography>
            <Typography
              textTransform="capitalize"
              fontSize="inherit"
              lineHeight="inherit"
            >
              {data?.identityType
                ? camelCaseToFormattedString(data.identityType)
                : ""}
            </Typography>
          </Box>

          <Typography
            fontSize={18}
            fontWeight={600}
            className="fc-black-secondary"
          >
            {userInfo.phone}
          </Typography>

          <Box pt={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => navigate(RouteConstant.RECHARGE_PLANS)}
                >
                  Recharge
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileCard;
