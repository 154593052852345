import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GetAPPLatestVersion } from "functions/http-requests/talkio-app";
import MainContainer from "parts/mainContainer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleLoading } from "state/slice/loading";
import { TalkioAppProps } from "_interfaces/talkio-app";

const AppDownload = () => {
  const Dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [state, setState] = useState<TalkioAppProps | null>(null);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      Dispatch(toggleLoading(true));
      setIsLoaded(false);
      GetAPPLatestVersion()
        .then((res) => {
          setState(res?.data?.data);
        })
        .catch(() => {})
        .finally(() => {
          Dispatch(toggleLoading(false));
          setIsLoaded(true);
        });
    };
    fetchList();
    return () => {
      fetchList = null;
    };
  }, [Dispatch]);

  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            {isLoaded ? (
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                  {state?._id ? (
                    <>
                      <Box
                        sx={{
                          boxShadow: "0 .25rem 1rem rgba(0,0,0,.08)",
                          borderRadius: 3,
                          padding: 3,
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Typography
                            textAlign="center"
                            fontSize={{ xs: 24, md: 36 }}
                            fontWeight={600}
                          >
                            Talkio APP
                          </Typography>
                        </Box>

                        <Box fontSize={14} pt={2}>
                          <RenderRow
                            label="App Name"
                            value={`${state?.appName}`}
                          />
                          <RenderRow
                            label="Current Version"
                            value={`${state?.currentVersion}`}
                          />
                        </Box>
                        {state?.appFile?.Url ? (
                          <Box pt={5}>
                            <Link to={state?.appFile?.Url} target="_blank">
                              <Button fullWidth variant="contained">
                                Download
                              </Button>
                            </Link>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography
                        textAlign="center"
                        fontSize={{ xs: 24, md: 36 }}
                        fontWeight={600}
                      >
                        App Not Found
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

interface RowProps {
  label: string;
  value: string | number;
}
const RenderRow: React.FC<RowProps> = ({ label, value }) => {
  return (
    <>
      <Box
        pt={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize="inherit" className="fc-grey">
          {label}
        </Typography>
        <Typography textAlign="right">{value}</Typography>
      </Box>
    </>
  );
};

export default AppDownload;
