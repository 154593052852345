import React, { SVGProps } from "react";

const Sim2Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={props?.width || "35"}
      height={props?.height || "47"}
      viewBox="0 0 35 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9094 46.446H2.77193C1.24092 46.446 0 45.205 0 43.674V2.77193C0 1.24092 1.24092 0 2.77193 0H21.4664C22.2077 0 22.9007 0.290086 23.4325 0.805794L33.8756 11.2489C34.3913 11.7646 34.6814 12.4737 34.6814 13.215V43.6901C34.6814 45.205 33.4404 46.446 31.9094 46.446Z"
        fill="white"
      />
      <path
        d="M31.9094 46.446H2.77193C1.24092 46.446 0 45.205 0 43.674V2.77193C0 1.24092 1.24092 0 2.77193 0H21.4664C22.2077 0 22.9007 0.290086 23.4325 0.805794L33.8756 11.2489C34.3913 11.7646 34.6814 12.4737 34.6814 13.215V43.6901C34.6814 45.205 33.4404 46.446 31.9094 46.446Z"
        stroke="#656565"
      />
      <path
        d="M26.5751 25.4308H8.10632C7.70342 25.4308 7.36499 25.7531 7.36499 26.1721V39.645C7.36499 40.0479 7.68731 40.3863 8.10632 40.3863H26.5912C26.9941 40.3863 27.3326 40.064 27.3326 39.645V26.1721C27.3164 25.7692 26.9941 25.4308 26.5751 25.4308Z"
        fill="#FFD05B"
      />
      <path
        d="M21.9499 29.766V25.4308H20.9024V29.766H13.7791V25.4308H12.7316V29.766H7.36502V30.8296H27.3165V29.766H21.9499Z"
        fill="black"
      />
      <path
        d="M7.36496 36.035H12.7315V40.3702H13.7791V36.035H20.9023V40.3702H21.9498V36.035H27.3164V34.9714H7.36496V36.035Z"
        fill="black"
      />
      <g clipPath="url(#clip0_1_15)">
        <path
          d="M19.8321 9.10514H22.0467C22.3524 9.10514 22.6003 9.35301 22.6003 9.65878V18.517C22.6003 18.8228 22.3524 19.0707 22.0467 19.0707H12.0811C11.7754 19.0707 11.5275 18.8228 11.5275 18.517V9.65878C11.5275 9.35301 11.7754 9.10514 12.0811 9.10514H14.2957V7.99786H15.403V9.10514H18.7248V7.99786H19.8321V9.10514ZM12.6348 12.427V17.9634H21.493V12.427H12.6348ZM13.7421 14.6415H16.5103V16.8561H13.7421V14.6415Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_15">
          <rect
            width="13.2874"
            height="13.2874"
            fill="white"
            transform="translate(10.4202 7.44421)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sim2Icon;
