import { Box, Grid } from "@mui/material";
import MainContainer from "parts/mainContainer";
import MyAccountNavbar from "parts/myAccountNavbar";
import React, { ReactNode } from "react";

interface MyAccountLayoutProps {
  children: ReactNode;
}

const MyAccountLayout: React.FC<MyAccountLayoutProps> = ({ children }) => {
  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4} lg={3}>
                <Box
                  sx={{
                    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.15)",
                    borderRadius: 4,
                    position: "sticky",
                    top: 0,
                    py: { xs: 1, md: 3 },
                    px: { xs: 1, md: 0 },
                    display: "flex",
                    flexDirection: {
                      xs: "row",
                      md: "column",
                    },
                    justifyContent: {
                      xs: "space-between",
                      md: "center",
                    },
                    alignItems: "center",
                  }}
                >
                  <MyAccountNavbar />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default MyAccountLayout;
