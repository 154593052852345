import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TalkioImage from "assets/img/static/bg/talkio-sim.png";
import PatternImage from "assets/img/static/shape/pattern-1.png";
import RouteConstant from "navigation/constant";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DispatchBuildBundleDetails,
  DispatchBuildBundleIntervalId,
  DispatchBuildBundleMinimumRequirement,
  DispatchPromotionDetails,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";
import { BuildBundleProps } from "_interfaces/phoneBooking";
import {
  fontSizeMap,
  formatFontSize,
  isValidFontSize,
  PromotionDataProps,
} from "_interfaces/_common/promotion";

interface Props {
  data?: PromotionDataProps;
}
const LimitedTimeOfferBanner: React.FC<Props> = ({ data }) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const bookingData = useSelector(
    (state: RootState) => state.phoneBooking.bookingData
  );

  const handleButtonClick = () => {
    if (data?._id) {
      let intervalId = data?.buildBundleData?.intervalId || undefined;

      let dataMb = data?.buildBundleData?.dataMb || 0;
      let onNetVoice = data?.buildBundleData?.onNetVoice || 0;
      let offNetVoice = data.buildBundleData?.offNetVoice || 0;
      let smsOffNet = data.buildBundleData?.smsOffNet || 0;

      if (dataMb) {
        dataMb = data?.rechargeDataUnit === "gb" ? dataMb * 1024 : dataMb;
      }

      let newData: BuildBundleProps = {
        ...bookingData.buildBundleMinimumRequirement,
        onNetVoice,
        offNetVoice,
        dataMb,
        smsOffNet,
      };

      if (bookingData?.buildBundleDetails) {
        newData = {
          ...bookingData.buildBundleDetails,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        };
      }

      Dispatch(DispatchBuildBundleDetails(newData));

      if (intervalId) {
        Dispatch(DispatchBuildBundleIntervalId(intervalId));
      }

      Dispatch(
        DispatchPromotionDetails({
          promotionId: data._id,
          amount: data.amount || 0,
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );
      Dispatch(
        DispatchBuildBundleMinimumRequirement({
          onNetVoice,
          offNetVoice,
          dataMb,
          smsOffNet,
        })
      );

      navigate(`${RouteConstant.PRODUCT_BUILD_BUNDLE}?promotion=${data?._id}`);

      if (location.pathname === RouteConstant.PRODUCT_BUILD_BUNDLE) {
        // Manually handle scroll since route is the same
        document
          .getElementById("build-own-bundle")
          ?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      {data?._id ? (
        <Box>
          {data?.UI?.heading?.show ? (
            <Box
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: 24,
                  md: formatFontSize(
                    isValidFontSize(data?.UI?.heading?.size)
                      ? fontSizeMap[data?.UI?.heading?.size]
                      : "46"
                  ),
                },
                maxWidth: 1100,
                lineHeight: 1.3,
                color: data?.UI?.heading?.color || "#1F1F1F",
              }}
              dangerouslySetInnerHTML={{ __html: data?.UI?.heading?.text }}
            />
          ) : (
            <></>
          )}
          <Box pt={2} pb={3}>
            <Box
              width={{ xs: "100%", md: "80%" }}
              className="bg-black fc-white"
              py={5}
              px={5}
              borderRadius={5}
              position="relative"
              pb={{ xs: "200px", md: 5 }}
            >
              <Box>
                {data?.UI?.title1?.show ? (
                  <Box
                    sx={{
                      fontSize: formatFontSize(
                        isValidFontSize(data?.UI?.title1?.size)
                          ? fontSizeMap[data?.UI?.title1?.size]
                          : "18"
                      ),
                      fontWeight: 500,
                      color: data?.UI?.title1?.color || "inherit",
                    }}
                    dangerouslySetInnerHTML={{ __html: data?.UI?.title1?.text }}
                  />
                ) : (
                  <></>
                )}
                <Box pt={3}>
                  {data?.UI?.title2?.show ? (
                    <Box
                      sx={{
                        fontSize: {
                          xs: 24,
                          sm: formatFontSize(
                            isValidFontSize(data?.UI?.title2?.size)
                              ? fontSizeMap[data?.UI?.title2?.size]
                              : "36"
                          ),
                        },
                        fontWeight: 400,
                        color: data?.UI?.title2?.color || "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.UI?.title2?.text,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {data?.UI?.title3?.show ? (
                    <Box
                      sx={{
                        fontSize: { xs: 48, sm: 80, lg: 166 },
                        fontWeight: 700,
                        lineHeight: 0.9,
                        color: data?.UI?.title3?.color || "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.UI?.title3?.text,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {data?.UI?.title4?.show ? (
                    <Box
                      sx={{
                        fontSize: {
                          xs: 30,
                          sm: formatFontSize(
                            isValidFontSize(data?.UI?.title4?.size)
                              ? fontSizeMap[data?.UI?.title4?.size]
                              : "36"
                          ),
                        },
                        fontWeight: 400,
                        lineHeight: 1.2,
                        color: data?.UI?.title4?.color || "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: data?.UI?.title4?.text,
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Box pt={5}>
                  <Button
                    onClick={handleButtonClick}
                    variant="contained"
                    sx={{
                      borderRadius: "6px",
                      borderColor: "inherit",
                      minWidth: 220,
                    }}
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  background: `linear-gradient(230.24deg, rgba(0, 0, 0, 0) 18.18%, rgba(0, 0, 0, 0.85) 69.65%, #000000 85.85%), url(${PatternImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: {
                    xs: 180,
                    sm: 280,
                  },
                  height: {
                    xs: 180,
                    sm: 280,
                  },
                  position: "absolute",
                  right: { xs: "30px", md: "10%" },
                  top: { xs: "unset", md: "50%" },
                  bottom: { xs: "2%", md: "unset" },
                  transform: {
                    xs: "none",
                    md: "translateY(-50%)",
                  },
                  zIndex: 1,
                }}
              />

              <Box
                sx={{
                  background: `url(${TalkioImage})`,
                  backgroundSize: "contain",
                  backgroundPosition: "bottom right",
                  backgroundRepeat: "no-repeat",
                  width: {
                    xs: "100%",
                    sm: 550,
                  },
                  height: { xs: 180, md: "100%" },
                  position: "absolute",
                  right: {
                    xs: "10px",
                    md: "-25%",
                  },
                  bottom: {
                    xs: "10px",
                    md: "-8%",
                  },
                  zIndex: 1,
                }}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default LimitedTimeOfferBanner;
