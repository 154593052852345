type Props = {
  walletPaymentId?: string;
  rechargeId?: string;
  PIN?: string;
};

const defaultData: Props = {
  walletPaymentId: "",
  rechargeId: "",
  PIN: "",
};

export class WalletPaymentModel {
  walletPaymentId?: string;
  rechargeId?: string;
  PIN?: string;

  constructor(data: Props = defaultData) {
    this.walletPaymentId = data.walletPaymentId || "";
    this.rechargeId = data.rechargeId || "";
    this.PIN = data.PIN || "";
  }
}
