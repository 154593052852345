import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

interface Props {
  data?: {
    chipLabel?: string;
    planName?: string;
    list?: {
      heading?: string;
      desc?: string;
      component?: React.ReactNode;
    }[];
  };
  handleClick?: () => void;
  handleShowMore?: () => void;
}

const PlanCardThree: React.FC<Props> = ({
  data,
  handleClick,
  handleShowMore,
}) => {
  const { palette } = useTheme();

  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: hovered
            ? alpha(palette.primary.main, 0.08)
            : "#FFFFFF",
          transition: "all .3s ease",
          boxShadow: "none",
          border: "1px solid #EEEEEE",
          borderRadius: "20px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          p: 2,
          transform: hovered ? `scale(1.08)` : "none",
          overflow: "hidden",
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        className="fm-poppins"
      >
        <Box>
          {data?.chipLabel ? (
            <Chip
              label={data?.chipLabel}
              sx={{
                minWidth: 80,
                backgroundColor: palette.primary3.main,
                color: palette.warning.dark,
                fontWeight: 400,
                overflow: "hidden",
                whiteSpace: "nowrap",
                px: 1.5,
                py: 1.5,
                fontSize: 12,
                lineHeight: 1.5,
                borderRadius: "4px",
              }}
            />
          ) : (
            <></>
          )}

          <Box py={1}>
            <Typography
              fontSize={{ xs: 18, sm: 20, xl: 24 }}
              fontWeight={600}
              textTransform="capitalize"
              className="fc-black"
            >
              {data?.planName}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" gap={1.5}>
            {data?.list?.map((item, i) => (
              <Box
                key={i}
                p={1.5}
                sx={{
                  border: "1px solid #EEEEEE",
                  borderRadius: "10px",
                }}
              >
                <Box className="info-des">
                  <Typography
                    fontSize={{ xs: 16, xl: 18 }}
                    fontWeight={500}
                    className="fc-black"
                    textTransform="capitalize"
                  >
                    {item?.heading}
                  </Typography>

                  {item?.desc ? (
                    <Typography
                      pt={0.5}
                      fontSize={{ xs: 14, xl: 16 }}
                      fontWeight={400}
                      sx={{
                        color: "#545454",
                      }}
                    >
                      {item?.desc}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {item?.component ? item.component : <></>}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {handleClick || handleShowMore ? (
          <Box
            pt={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop="auto"
          >
            {handleClick ? (
              <Button
                variant="contained"
                onClick={handleClick}
                className="c-bg-primary-var"
                size="small"
                sx={{
                  borderRadius: "6px",
                  borderColor: "inherit",
                  minWidth: 180,
                  fontSize: 14,
                }}
              >
                Get Started
              </Button>
            ) : (
              <></>
            )}
            {handleShowMore ? (
              <Box
                pt={2}
                sx={{
                  color: palette.primaryVar.main,
                  opacity: hovered ? 1 : 0,
                  position: "relative",
                  transform: hovered ? "translateY(0)" : "translateY(100%)",
                  transition: "all 0.3s",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleShowMore}
                  sx={{
                    borderRadius: "6px",
                    borderColor: "inherit",
                    color: "inherit",
                    minWidth: 180,
                    "&:hover": {
                      borderColor: "inherit",
                      color: "inherit",
                    },
                  }}
                >
                  Show More
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default PlanCardThree;
