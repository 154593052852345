import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import ConfirmNumberLayout from "component/confirm-number";
import useDisableBackButton from "hooks/useDisableBackButton";
import usePreventPageRefresh from "hooks/usePreventPageRefresh";
import RouteConstant from "navigation/constant";
import Footer from "parts/footer";
import MainContainer from "parts/mainContainer";
import Navbar from "parts/navbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "state/store";

const ConfirmNumber = () => {
  useDisableBackButton();
  usePreventPageRefresh();

  const navigate = useNavigate();

  const { bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  useEffect(() => {
    if (
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails
    ) {
      setIsLoaded(true);
      setCounter(3);
      return;
    }

    setIsLoaded(true);
  }, [bookingStatus]);

  useEffect(() => {
    if (
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails &&
      counter > 0
    ) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            clearInterval(timer);
            return prevCounter - 1;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [bookingStatus, counter]);

  useEffect(() => {
    if (
      counter === 0 &&
      isLoaded &&
      !bookingStatus?.packageBundleDetails &&
      !bookingStatus?.buildBundleDetails
    ) {
      navigate(RouteConstant.PRODUCT_BUILD_BUNDLE);
    }
  }, [counter, navigate, isLoaded, bookingStatus]);

  return (
    <>
      <section>
        <MainContainer>
          <Box pt={5}>
            {isLoaded ? (
              <>
                {!bookingStatus?.packageBundleDetails &&
                !bookingStatus?.buildBundleDetails ? (
                  <>
                    {counter > 0 ? (
                      <Box pb={5}>
                        <Alert severity="error" className="fm-poppins">
                          <AlertTitle>Package Not Selected</AlertTitle>
                          You will be automatically redirected to the package
                          bundle selection page in {counter} seconds.
                          <br /> Alternatively, you can click{" "}
                          <Link
                            to={RouteConstant.PRODUCT_BUILD_BUNDLE}
                            style={{ textDecoration: "underline" }}
                          >
                            here
                          </Link>{" "}
                          to go to the page immediately.
                        </Alert>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <ConfirmNumberLayout />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default ConfirmNumber;
