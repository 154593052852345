import { NavLink } from "react-router-dom";
import RouteConstant from "navigation/constant";
import { Grid } from "@mui/material";
import React from "react";

interface Props {
  handleClose?: () => void;
}
const HelpsNavMenu: React.FC<Props> = ({ handleClose }) => {
  const navList = [
    {
      title: "FAQs",
      desc: "Get your doubts solved here",
      link: RouteConstant.FAQ,
    },
    {
      title: "How to Pay ?",
      desc: "Switch to us to enjoy more",
      link: RouteConstant.HOW_TO_PAY,
    },
    // {
    //   title: "Stores",
    //   desc: "",
    //   link: RouteConstant.STORE_MAP,
    // },
    // {
    //   title: "Blog",
    //   desc: "",
    //   link: RouteConstant.BLOG,
    // },
    // {
    //   title: "Press Release",
    //   desc: "",
    //   link: RouteConstant.PRESS_RELEASE,
    // },
    // {
    //   title: "Our partners",
    //   desc: "",
    //   link: RouteConstant.PARTNERS,
    // },
  ];

  return (
    <Grid container spacing={2}>
      {navList.map(({ title, desc, link }, i) => (
        <Grid item xs={12} md={6} key={i}>
          <NavLink
            to={link}
            onClick={(e) => {
              e.stopPropagation();
              if (handleClose) handleClose();
            }}
            className={({ isActive }) =>
              isActive ? "submenu-link active" : "submenu-link"
            }
          >
            <h5 className="nav-submenu-link-header">{title}</h5>
            {desc ? <p className="nav-submenu-link-des">{desc}</p> : ""}
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default HelpsNavMenu;
