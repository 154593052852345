import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    primaryVar: Palette["primary"];
    primary3: Palette["primary"];
    secondary1: Palette["primary"];
    secondary2: Palette["primary"];
    secondary3: Palette["primary"];
    // white:Palette['white']
  }

  interface PaletteOptions {
    primaryVar?: PaletteOptions["primary"];
    primary3?: PaletteOptions["primary"];
    secondary1?: PaletteOptions["primary"];
    secondary2?: PaletteOptions["primary"];
    secondary3?: PaletteOptions["primary"];
  }
}
const Theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          borderRadius: "30px",
          lineHeight: 1.3,
          padding: "10px 20px",
          textTransform: "capitalize",
          fontSize: "1rem",
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: "8px",
          paddingBottom: "8px",
          fontFamily: "inherit",
          fontWeight: "inherit",
          color: "inherit",
        },
      },
    },
  },
  palette: {
    primary: {
      // main: "#E7B521",
      // main: "#E39725",
      main: "#FD9D0E",
      contrastText: "#fff",
    },
    primaryVar: {
      main: "#E39725",
      contrastText: "#fff",
    },
    primary3: {
      main: "#F9F6D7",
      contrastText: "#fff",
    },
    secondary: {
      dark: "#333333",
      main: "#414042",
      contrastText: "#fff",
    },
    secondary1: {
      dark: "#333333",
      main: "#3C3A3C",
      contrastText: "#fff",
    },
    secondary2: {
      dark: "#F0EFE9",
      main: "#F0EFE9",
      contrastText: "#555353",
    },
    secondary3: {
      dark: "#656565",
      main: "#656565",
      contrastText: "#FFFFFF",
    },
    // white: {
    //   main: "#fff",
    //   contrastText: "#000",
    // },
    // black: {
    //   main: "#000000",
    //   contrastText: "#fff",
    // },
    warning: {
      main: "#ed6c02",
      light: "#F9F6D7",
      dark: "#EC712C",
      contrastText: "#fff",
    },
    // grey: {
    //   main: "#999999",

    // },
  },
});

export default Theme;
