interface Props {
  coupon?: string;
}

const defaultData: Props = {
  coupon: "",
};

export class ApplyCouponModel {
  coupon?: string;

  constructor(data: Props = defaultData) {
    this.coupon = data.coupon || "";
  }
}
