import { Box, Grid, Typography, useTheme } from "@mui/material";
import WorldMap from "assets/img/static/bg/footerWorld.png";
import Logo from "assets/img/static/logo.png";
import { GetAPPLatestVersion } from "functions/http-requests/talkio-app";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import SocialLinks from "parts/socialLinks";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TalkioAppProps } from "_interfaces/talkio-app";

interface FooterProps {
  socialMedia?: boolean;
  light?: boolean;
  mb?: number;
}

interface LinkItem {
  link: string;
  label: string;
  target?: string;
}

interface FooterLinksWidgetProps {
  title: string;
  links: LinkItem[];
}

const FooterLinksWidget: React.FC<FooterLinksWidgetProps> = ({
  title,
  links,
}) => {
  const { palette } = useTheme();
  return (
    <Grid xs={6} sm={4} lg={3} item>
      <Box
        className="footer-list"
        sx={{
          display: "flex",
          flexDirection: "column",
          a: {
            color: "rgba(255, 255, 255, 0.85)",
            py: 1,
            "&:hover": {
              color: palette.primary.main,
            },
          },
        }}
      >
        <Typography
          fontSize={14}
          className="fc-white"
          textTransform="uppercase"
          mb={2}
        >
          {title}
        </Typography>
        {links?.map((item, i) => (
          <Link
            to={item.link}
            key={i}
            target={item?.target || "_self"}
            rel={item?.target === "_blank" ? "noopener noreferrer" : undefined}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 300,
                textTransform: "capitalize",
              }}
            >
              {item.label}
            </Typography>
          </Link>
        ))}
      </Box>
    </Grid>
  );
};

const Footer: React.FC<FooterProps> = () => {
  const quickLinksList: LinkItem[] = [
    { link: RouteConstant.HOME, label: "Home" },
    { link: RouteConstant.TERMS_CONDITIONS, label: "Terms & Conditions" },
    { link: RouteConstant.PRIVACY_POLICY, label: "Privacy Policy" },
    { link: RouteConstant.GET_STARTED, label: "Get Started" },
  ];

  const productsLinksList: LinkItem[] = [
    { link: RouteConstant.PRODUCT_BUILD_BUNDLE, label: "Build Your Bundle" },
    { link: RouteConstant.PRODUCT_BUNDLE_PLANS, label: "Bundle Plans" },
    { link: RouteConstant.PRODUCT_PREMIUM_PLANS, label: "Premium Plans" },
    { link: RouteConstant.PRODUCT_TOURIST_PLANS, label: "Tourist Plans" },
  ];

  const [helpLinksList, setHelpLinksList] = useState<LinkItem[]>([
    { link: RouteConstant.HOW_TO_PAY, label: "How To Pay?" },
    { link: RouteConstant.FAQ, label: "FAQ" },
    { link: RouteConstant.SELECT_SERVICE, label: "Join Now" },
  ]);

  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      GetAPPLatestVersion()
        .then((res) => {
          const DATA: TalkioAppProps = res?.data?.data;
          if (DATA?.appFile?.Url) {
            setHelpLinksList((prev) => {
              const alreadyExists = prev.some(
                (item) => item.label === "Download Talkio APP"
              );

              if (!alreadyExists) {
                return [
                  ...prev,
                  {
                    link: DATA?.appFile?.Url || "",
                    label: "Download Talkio APP",
                    target: "_blank",
                  },
                ];
              }

              return prev;
            });
          }
        })
        .catch(() => {});
    };

    fetchList();

    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <footer>
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <MainContainer bgColor="#1C1A1A">
          <Box
            sx={{
              background: `#1C1A1A url(${WorldMap}) no-repeat center center`,
              backgroundSize: "contain",
            }}
          >
            <Box py={5}>
              <Grid
                container
                justifyContent={{ xs: "flex-start", sm: "center" }}
                spacing={3}
              >
                <FooterLinksWidget title="Quick Links" links={quickLinksList} />
                <FooterLinksWidget title="Products" links={productsLinksList} />
                <FooterLinksWidget title="Help" links={helpLinksList} />
              </Grid>
            </Box>
            <Box
              py={2}
              sx={{
                borderTop: "1.5px solid #898989",
              }}
            >
              <Grid
                container
                spacing={3}
                justifyContent={{
                  xs: "center",
                  md: "space-between",
                }}
                alignItems="center"
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    className="resImg"
                    sx={{
                      width: "120px",
                      margin: {
                        xs: "auto",
                        md: 0,
                      },
                    }}
                  >
                    <img src={Logo} alt="logo" />
                  </Box>
                  <Typography
                    color="white"
                    sx={{
                      textTransform: "uppercase",
                      textAlign: {
                        xs: "center",
                        md: "left",
                      },
                    }}
                    className="fm-poppins"
                    fontSize={12}
                    fontWeight={300}
                  >
                    COPYRIGHT &copy; {new Date().getFullYear()} TALKIO MOBILE
                    LTD &bull; All rights reserved
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className="fc-white">
                    <SocialLinks />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </MainContainer>
      </Box>
    </footer>
  );
};

export default Footer;
