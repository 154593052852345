import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import SearchNumber from "component/_common/searchNumber";
import RouteConstant from "navigation/constant";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { clearPhoneBookingData } from "state/slice/phoneBooking";
import { RootState } from "state/store";

interface Props {
  handleClose?: () => void;
}
const ProductNavMenu: React.FC<Props> = ({ handleClose }) => {
  const { hash, pathname } = useLocation();
  const Dispatch = useDispatch();

  const { bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  const [openSearchNumber, setOpenSearchNumber] = useState<boolean>(false);

  const [openCancelAlert, setOpenCancelAlert] = useState<boolean>(false);

  const handleOpenSearchNumber = () => setOpenSearchNumber(true);
  const handleCloseSearchNumber = () => setOpenSearchNumber(false);

  const handleOpenCancelAlert = () => setOpenCancelAlert(true);
  const handleCloseCancelAlert = () => setOpenCancelAlert(false);

  const navList = [
    {
      title: "Build Your Bundle",
      desc: "Choose what works for you",
      link: RouteConstant.PRODUCT_BUILD_BUNDLE,
    },
    {
      title: "Bundle Plans",
      desc: "Voice, Data, all in one subscription",
      link: RouteConstant.PRODUCT_BUNDLE_PLANS,
    },
    {
      title: "Premium Plans",
      desc: "Get the full Talkio experience",
      link: `${RouteConstant.PRODUCT_PREMIUM_PLANS}`,
    },
    {
      title: "Tourist Plans",
      desc: "Stay connected wherever you are",
      link: `${RouteConstant.PRODUCT_TOURIST_PLANS}`,
    },
    {
      title: "Promotion",
      desc: "Win an iPhone 16 with Talkio Mobile",
      link: `${RouteConstant.PROMOTION}`,
    },
    {
      title: "",
      desc: "",
      handleClick: () => {
        if (
          bookingStatus?.phoneDetails ||
          bookingStatus?.packageBundleDetails ||
          bookingStatus?.buildBundleDetails ||
          bookingStatus?.accountDetails ||
          bookingStatus?.billDetails ||
          bookingStatus?.bookingDetails
        ) {
          handleOpenCancelAlert();
        } else {
          Dispatch(clearPhoneBookingData());
          handleOpenSearchNumber();
        }
      },
      buttonText: "Book Your Number",
    },
  ];

  const isActiveLink = (link: string) => {
    if (link.includes("#")) {
      const [path, linkHash] = link.split("#");
      return pathname === path && `#${linkHash}` === hash;
    }
    return !hash && pathname === link;
  };

  return (
    <>
      <Grid container spacing={2}>
        {navList.map(({ title, desc, link, buttonText, handleClick }, i) => (
          <Grid
            item
            xs={12}
            md={4}
            key={i}
            sx={{
              a: {
                height: "100%",
                display: "flex",
                justifyContent: buttonText ? "center" : "unset",
              },
            }}
          >
            {handleClick && buttonText ? (
              <>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    onClick={handleClick}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      // flex: 1,
                      borderRadius: 1.2,
                      py: 2,
                      px: 0,
                    }}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </>
            ) : link ? (
              <>
                <NavLink
                  to={link}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (handleClose) handleClose();
                  }}
                  className={() => {
                    if (buttonText) return "";
                    return isActiveLink(link)
                      ? "submenu-link active"
                      : "submenu-link";
                  }}
                >
                  {title ? (
                    <h5 className="nav-submenu-link-header">{title}</h5>
                  ) : null}{" "}
                  {desc ? <p className="nav-submenu-link-des">{desc}</p> : null}
                </NavLink>{" "}
              </>
            ) : null}
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openSearchNumber}
        onClose={handleCloseSearchNumber}
        maxWidth="lg"
        fullWidth
      >
        <SearchNumber onClose={handleCloseSearchNumber} />
      </Dialog>

      <Dialog open={openCancelAlert} onClose={handleOpenCancelAlert}>
        <DialogContent>
          <Typography fontSize={24}>Are You Sure?</Typography>
          <Typography>
            The current phone booking process will be canceled, and a new
            process will begin.
          </Typography>

          <Box pt={3} display="flex" justifyContent="flex-end" gap={2}>
            <Button
              onClick={handleCloseCancelAlert}
              variant="text"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                Dispatch(clearPhoneBookingData());
                handleOpenSearchNumber();
                handleCloseCancelAlert();
              }}
              variant="contained"
              color="primary"
            >
              Yes, I'm sure
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductNavMenu;
