import styled, { keyframes, css } from "styled-components";

interface MarqueeProps {
  speed?: number;
}

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const Marquee = styled.div<MarqueeProps>`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  & > div {
    display: flex;
    align-items: center;
    animation: ${(props) =>
      css`
        ${marqueeAnimation} ${props.speed || 20}s linear infinite
      `};
  }

  &:hover > div {
    animation-play-state: paused;
  }
`;
