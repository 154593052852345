import { BuildBundleInterval } from "_interfaces/phoneBooking";

export type PromotionTypeProps = "simBooking" | "registration" | "recharge";
export type PromotionConsumerProps = "citizen" | "tourist" | "refugee";
export type promotionApplyOnProps = "recharge" | "totalAmount";
export enum showInPageProps {
  "home" = "home",
  "products" = "products",
  "products-build-bundle" = "products-build-bundle",
  "products-premium-plans" = "products-premium-plans",
  "home_top_carousel" = "home_top_carousel",
  "promotionPage" = "promotionPage",
}
export type rechargeTypeProps = "airtime" | "buildBundle" | "bundle"; //if promotion on recharge
export type rechargeDataUnitProps = "gb" | "mb";
export type UIHeadingPositionProps =
  | "left"
  | "right"
  | "bottom"
  | "top"
  | "middle";
export type UIHeadingTitleProps = {
  text: string;
  size: string;
  color: string;
  position: UIHeadingPositionProps;
  linePosition: number;
  show: boolean;
};

export interface PromotionDataProps {
  buildBundleData: {
    onNetVoice: number;
    offNetVoice: number;
    dataMb: number;
    smsOffNet: number;
    intervalId?: BuildBundleInterval;
  };
  UI: {
    heading?: UIHeadingTitleProps;
    title1?: UIHeadingTitleProps;
    title2?: UIHeadingTitleProps;
    title3?: UIHeadingTitleProps;
    title4?: UIHeadingTitleProps;
    description?: UIHeadingTitleProps;
    theme: number;
  };
  _id: string;
  promotionType: PromotionTypeProps;
  promotionConsumer: PromotionConsumerProps[];
  amount: number;
  promotionApplyOn: promotionApplyOnProps;
  rechargeType: rechargeTypeProps;
  rechargeDataUnit: rechargeDataUnitProps;
  promotionId: string;
  assignDate: Date;
  expiryDate: Date;
  maxUse: number;
  isActive: boolean;
  showInPage: (showInPageProps | string)[];
}

export const fontSizeMap = {
  "1": "42",
  "2": "36",
  "3": "24",
  "4": "18",
  "5": "14",
};

export const isValidFontSize = (
  size: string
): size is "1" | "2" | "3" | "4" | "5" => {
  return ["1", "2", "3", "4", "5"].includes(size);
};

export const formatFontSize = (size?: string, defaultSize?: string) =>
  `${size || defaultSize || "16"}px`;
