import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import { useEffect, useRef, useState } from "react";
import { termsConditionsData } from "./data";

const TermsConditions: React.FC = () => {
  const { breakpoints, palette } = useTheme();
  const isSmallDevice = useMediaQuery(breakpoints.down("md"));

  const [activeStep, setActiveStep] = useState<number>(0);
  const stepRefs = useRef<(HTMLDivElement | null)[]>([]);
  const stepperRef = useRef<HTMLDivElement | null>(null);

  const HEADER_HEIGHT = 100;

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;

    const activeStepIndex = stepRefs.current.findIndex(
      (step) => step && step.offsetTop > scrollPosition
    );

    if (activeStepIndex === -1) {
      setActiveStep(termsConditionsData.length - 1);
    } else {
      setActiveStep(activeStepIndex - 1 >= 0 ? activeStepIndex - 1 : 0);
    }
  };

  const handleStepClick = (index: number) => {
    if (stepRefs?.current?.[index]) {
      const offsetPosition = stepRefs.current[index]!.offsetTop - HEADER_HEIGHT;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setActiveStep(index);
    }
  };

  useEffect(() => {
    if (isSmallDevice && stepperRef?.current) {
      const activeStepElement = stepperRef.current.querySelector(
        `.MuiStep-root:nth-child(${activeStep + 1})`
      );

      if (activeStepElement) {
        const stepperWidth = stepperRef.current.offsetWidth;
        const stepWidth = (activeStepElement as HTMLElement).offsetWidth;
        const stepLeft = (activeStepElement as HTMLElement).offsetLeft;
        const scrollPosition = stepLeft - stepperWidth / 2 + stepWidth / 2;

        stepperRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [activeStep, isSmallDevice]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section>
      <MainContainer>
        <Box py={5} className="fm-poppins">
          <SectionTitle title="Terms and Conditions of Use for Talkio Mobile Services" />

          <Box pt={3}>
            <Grid container spacing={2}>
              <Grid
                item
                md={3}
                sx={{
                  maxWidth: "-webkit-fill-available",
                  position: isSmallDevice ? "sticky" : "unset",
                  bottom: isSmallDevice ? 0 : "unset",
                  order: {
                    xs: 2,
                    md: 1,
                  },
                }}
              >
                <Stepper
                  ref={stepperRef} // Attach the ref to the stepper container
                  activeStep={activeStep}
                  orientation={isSmallDevice ? "horizontal" : "vertical"}
                  connector={null}
                  sx={{
                    fontFamily: "inherit",
                    background: "white",
                    p: { xs: 1, md: 3 },
                    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.15)",
                    borderRadius: 4,
                    position: isSmallDevice ? "unset" : "sticky",
                    top: isSmallDevice ? "unset" : HEADER_HEIGHT,
                    overflowX: isSmallDevice ? "auto" : "unset",
                    whiteSpace: isSmallDevice ? "nowrap" : "normal",
                  }}
                >
                  {termsConditionsData?.map((item, i) => (
                    <Step key={i} onClick={() => handleStepClick(i)}>
                      <StepLabel
                        sx={{
                          py: 1,
                          px: 0,
                          "& .MuiStepLabel-label": {
                            fontFamily: "inherit",
                            fontSize: 16,
                            fontWeight: 500,
                            cursor: "pointer",
                            color:
                              activeStep === i
                                ? palette.primary.main
                                : "text.secondary",
                            "&.Mui-active": {
                              color: palette.primary.main,
                            },
                          },
                          "& .MuiStepLabel-iconContainer": {
                            display: "none",
                          },
                        }}
                      >
                        {item.title}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid
                item
                md={9}
                sx={{
                  order: {
                    xs: 1,
                    md: 2,
                  },
                }}
              >
                <Box flex={1} padding={2}>
                  {termsConditionsData.map((item, index) => (
                    <Box
                      key={index}
                      ref={(el: HTMLDivElement | null) =>
                        (stepRefs.current[index] = el)
                      }
                      pb={5}
                      sx={{
                        li: {
                          lineHeight: 1.5,
                          py: 0.8,
                        },
                        ol: {
                          counterReset: "item",
                          li: {
                            display: "block",
                            "&:before": {
                              counterIncrement: "item",
                              content: "counters(item, '.') '. '",
                            },
                          },
                        },
                        "ol[start='2'], ol[start='3']": {
                          counterReset: "item 1",
                        },
                        "ol[start='3']": {
                          counterReset: "item 2",
                        },
                        "ol[start='4']": {
                          counterReset: "item 3",
                        },
                        "ol[start='5']": {
                          counterReset: "item 4",
                        },
                        "ol[start='6']": {
                          counterReset: "item 5",
                        },
                        "ol[start='7']": {
                          counterReset: "item 6",
                        },
                      }}
                    >
                      <Box dangerouslySetInnerHTML={{ __html: item.content }} />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </MainContainer>
    </section>
  );
};

export default TermsConditions;
