import React from "react";
import Navbar from "parts/navbar";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
  CardMedia,
  TextField,
} from "@mui/material";
import DeliverMapPic from "assets/img/dynamic/bg/deliver_map.png";
import InfoCard from "component/_common/cards/infoCards";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import SimCardIcon from "@mui/icons-material/SimCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Link } from "react-router-dom";
import RouteConstant from "navigation/constant";
import Footer from "parts/footer";

const NearestAgent: React.FC = () => {
  return (
    <>
      <section>
        <Box py={8} className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  mb: 3,
                  boxShadow: "none",
                  border: "1px solid rgba(0,0,0,0.1)",
                }}
              >
                <CardContent>
                  <h1 className="heading-3">Summary</h1>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="baseline"
                  >
                    <Typography gutterBottom variant="subtitle1">
                      Your New Number
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    textAlign={"center"}
                    className="fm-poppins"
                  >
                    <SimCardIcon />
                    &nbsp; 058 589 3567
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  boxShadow: "none",
                  border: "1px solid rgba(0,0,0,0.1)",
                }}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="baseline"
                  >
                    <Typography gutterBottom variant="subtitle1">
                      Your Plan
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <InfoCard
                      Icon={ImportExportIcon}
                      heading="7GB"
                      desc="DATA"
                      my={1}
                    />
                    <InfoCard
                      Icon={LocationOnIcon}
                      heading="500"
                      desc="Local min/SMS"
                      my={1}
                    />
                    <InfoCard
                      Icon={LanguageIcon}
                      heading="100"
                      desc="International min/SMS"
                      my={1}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                fontFamily={"Raleway"}
                variant="body1"
                textAlign="center"
              >
                We deliver in 60 minutes
              </Typography>
              <h1 className="heading-2 text-center my-3">
                We'll deliver your SIM <br />
                to you, for free.
              </h1>
              <Card
                sx={{
                  boxShadow: "none",
                  border: "1px solid rgba(0,0,0,0.1)",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Region"
                              variant="standard"
                              color="secondary"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="County"
                              variant="standard"
                              color="secondary"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="District"
                              variant="standard"
                              color="secondary"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button variant="contained" sx={{ mt: 2 }}>
                              Search Agent
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CardMedia
                        component="img"
                        height="250"
                        image={DeliverMapPic}
                        alt="map"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
};

export default NearestAgent;
