interface Props {
  statusId?: string;
}

const defaultData: Props = {
  statusId: "",
};

export class CheckStatusModel {
  statusId?: string;

  constructor(data: Props = defaultData) {
    this.statusId = data.statusId || "";
  }
}
