import {
  PACKAGE_BUNDLE_BUILD_BUNDLE_CALCULATION,
  PACKAGE_BUNDLE_GET_ALL,
  PACKAGE_BUNDLE_GET_BUILD_BUNDLE_RATES,
  PACKAGE_BUNDLE_GET_BY_ID,
} from "config/endpoint";
import { HTTP } from "functions/http";
import {
  GetPackageBundleProps,
  PackageBundleGetBuildBundleCalculationProps,
  PackageBundleGetBuildBundleRatesProps,
} from "_interfaces/http-requests/packageBundle";

export const PackageBundleGetAll = ({
  DATA,
  isCancellable = true,
}: GetPackageBundleProps) => {
  return HTTP({
    Method: "POST",
    Url: PACKAGE_BUNDLE_GET_ALL,
    Data: DATA,
    isCancellable,
  });
};

export const PackageBundleGetById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: PACKAGE_BUNDLE_GET_BY_ID(ID),
  });
};

export const PackageBundleGetBuildBundleRates = ({
  PARAMS,
}: PackageBundleGetBuildBundleRatesProps) => {
  return HTTP({
    Method: "GET",
    Url: PACKAGE_BUNDLE_GET_BUILD_BUNDLE_RATES(PARAMS),
  });
};

export const PackageBundleGetBuildBundleCalculation = ({
  DATA,
}: PackageBundleGetBuildBundleCalculationProps) => {
  return HTTP({
    Method: "POST",
    Url: PACKAGE_BUNDLE_BUILD_BUNDLE_CALCULATION,
    Data: DATA,
  });
};
