import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PaymentMethod from "component/_common/payment/paymentMethod";
import { paymentOptionsList } from "component/_common/payment/paymentOptionsList";
import WalletPaymentMethod from "component/_common/payment/walletPaymentMethod";
import SummaryNumber from "component/_common/plans/summaryNumber";
import { AppStatusCode } from "config/appStatusCode";
import { HTTP_ERROR } from "functions/http";
import {
  BuildBundleRechargeRequest,
  BundleRechargeRequest,
  RechargeRequestUpdate,
} from "functions/http-requests/recharge";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "state/slice/alert";
import { toggleLoading } from "state/slice/loading";
import { DispatchRechargeData } from "state/slice/rechargePlan";
import { RootState } from "state/store";
import {
  BuildBundleRechargeRequestProps,
  BundleRechargeRequestProps,
} from "_interfaces/http-requests/recharge";
import { FilterPlansProps } from "_interfaces/_common/filterPlans";
import { PaymentMethodDataProps } from "_interfaces/_common/payment";
import { WalletPaymentModel } from "_models/data/wallet/data.walletPayment.model";
import AllPlans from "./allPlans";
import BuildBundleRecharge from "./buildBundleRecharge";
import FilterPlans from "./filterPlans";

const RechargePlansLayout = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isMediumDevice = useMediaQuery(breakpoints.up("md"));

  const { userInfo } = useSelector((state: RootState) => state.user);
  const { rechargeData } = useSelector(
    (state: RootState) => state.rechargePlan
  );
  const { bookingData } = useSelector((state: RootState) => state.phoneBooking);

  const [filters, setFilters] = useState<FilterPlansProps>({
    packageFor: "",
    category: "",
    packageType: undefined,
  });

  const [buildBundleRechargeOpen, setBuildBundleRechargeOpen] =
    useState<boolean>(false);

  const [openPaymentOption, setOpenPaymentOption] = useState<boolean>(false);
  const [openWalletPaymentMethod, setOpenWalletPaymentMethod] =
    useState<boolean>(false);

  const [walletPaymentState, setWalletPaymentState] =
    useState<WalletPaymentModel>(new WalletPaymentModel());

  const handleToggleBuildBundleRechargeOpen = () =>
    setBuildBundleRechargeOpen(!buildBundleRechargeOpen);

  const handleTogglePaymentOption = () =>
    setOpenPaymentOption(!openPaymentOption);

  const handleToggleWalletPaymentMethod = () =>
    setOpenWalletPaymentMethod(!openWalletPaymentMethod);

  let handleRechargeRequest = (DATA: PaymentMethodDataProps) => {
    if (
      DATA?.paymentBy &&
      DATA?.paymentMethod &&
      rechargeData?.bundleId &&
      rechargeData?.phoneNumber
    ) {
      let PAYLOAD_DATA: BundleRechargeRequestProps["DATA"] = {
        bundleId: rechargeData.bundleId,
        phoneNumber: rechargeData.phoneNumber,
        paymentMethod: DATA.paymentMethod,
        paymentBy: DATA.paymentBy,
        momoPhoneNumber: DATA.momoPhoneNumber || undefined,
        airtelMOMOPhone: DATA.airtelMOMOPhone || undefined,
      };

      Dispatch(toggleLoading(true));
      BundleRechargeRequest({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          const rechargeId = data?.data?.rechargeId;

          if (data?.statusCode === AppStatusCode.api_success && rechargeId) {
            if (DATA.paymentBy === "wallet") {
              const walletPaymentId = data?.data?.walletRequestId;
              setWalletPaymentState((prev) => ({
                ...prev,
                walletPaymentId: walletPaymentId,
                rechargeId: rechargeId,
              }));
              handleToggleWalletPaymentMethod();
              Dispatch(toggleLoading(false));
            } else {
              handleRechargeRequestUpdate(rechargeId);
            }
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          Dispatch(toggleLoading(false));
        });
    }
  };

  let handleBuildBundleRechargeRequest = (DATA: PaymentMethodDataProps) => {
    if (DATA?.paymentBy && DATA?.paymentMethod && rechargeData?.phoneNumber) {
      let PAYLOAD_DATA: BuildBundleRechargeRequestProps["DATA"] = {
        // bundleId: rechargeData.bundleId,
        onNetVoice: bookingData?.buildBundleDetails?.onNetVoice || 0,
        offNetVoice: bookingData?.buildBundleDetails?.offNetVoice || 0,
        dataMb: bookingData?.buildBundleDetails?.dataMb || 0,
        smsOffNet: bookingData?.buildBundleDetails?.smsOffNet || 0,
        intervalId: bookingData?.buildBundleIntervalId || undefined,
        phoneNumber: rechargeData.phoneNumber,
        paymentMethod: DATA.paymentMethod,
        paymentBy: DATA.paymentBy,
        momoPhoneNumber: DATA.momoPhoneNumber || undefined,
        airtelMOMOPhone: DATA.airtelMOMOPhone || undefined,
      };

      Dispatch(toggleLoading(true));
      BuildBundleRechargeRequest({ DATA: PAYLOAD_DATA })
        .then((res) => {
          const data = res?.data;
          const rechargeId = data?.data?.rechargeId;

          if (data?.statusCode === AppStatusCode.api_success && rechargeId) {
            if (DATA.paymentBy === "wallet") {
              const walletPaymentId = data?.data?.walletRequestId;
              setWalletPaymentState((prev) => ({
                ...prev,
                walletPaymentId: walletPaymentId,
                rechargeId: rechargeId,
              }));
              handleToggleWalletPaymentMethod();
              Dispatch(toggleLoading(false));
            } else {
              handleRechargeRequestUpdate(rechargeId);
            }
          } else {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message || "Something went wrong!",
              })
            );
            Dispatch(toggleLoading(false));
          }
        })
        .catch((error) => {
          Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
          Dispatch(toggleLoading(false));
        });
    }
  };

  const handleCancelProcess = () => {
    Dispatch(toggleLoading(false));
    if (buildBundleRechargeOpen) handleToggleBuildBundleRechargeOpen();
  };

  const handleRechargeRequestUpdate = (ID: string) => {
    RechargeRequestUpdate(ID)
      .then((res) => {
        const data = res?.data;

        if (data?.statusCode === AppStatusCode.api_success) {
          const STATUS = data?.data?.status?.toLowerCase();

          if (STATUS === "success") {
            Dispatch(
              setAlert({
                type: data?.level,
                message: data?.message,
              })
            );
            handleCancelProcess();
            if (openPaymentOption) setOpenPaymentOption(false);
            if (openWalletPaymentMethod) setOpenWalletPaymentMethod(false);
            navigate(RouteConstant.MY_ACCOUNT);
          } else if (STATUS === "pending") {
            // Call the function again after 4 seconds if status is pending
            setTimeout(() => {
              handleRechargeRequestUpdate(ID);
            }, 4000);
          }
        } else {
          Dispatch(
            setAlert({
              type: data?.level,
              message: data?.message || "Something went wrong!",
            })
          );
          handleCancelProcess();
        }
      })
      .catch((error) => {
        Dispatch(setAlert({ type: "error", message: HTTP_ERROR(error) }));
        handleCancelProcess();
      });
  };

  useEffect(() => {
    if (userInfo.phone) {
      Dispatch(DispatchRechargeData({ phoneNumber: userInfo.phone }));
    }
  }, [userInfo?.phone]);

  return (
    <>
      <section>
        <MainContainer>
          <Box py={5}>
            <Box maxWidth={400}>
              <SummaryNumber
                phoneNumber={rechargeData?.phoneNumber}
                title="Recharging for"
                showChangeButton
              />
            </Box>
            <Grid container spacing={3}>
              {isMediumDevice ? (
                <Grid item xs={12} md={4} lg={3}>
                  <FilterPlans filters={filters} setFilters={setFilters} />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} md={8} lg={9}>
                <BuildBundleRecharge
                  handleSelectPlan={handleTogglePaymentOption}
                  handleToggleBuildBundleRechargeOpen={
                    handleToggleBuildBundleRechargeOpen
                  }
                />
                <AllPlans
                  filters={filters}
                  setFilters={setFilters}
                  handleSelectPlan={handleTogglePaymentOption}
                />
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
      </section>

      {/* PAYMENT METHOD */}
      <Dialog
        open={openPaymentOption}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiPaper-root.MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            ">.MuiDialogContent-root": {
              msOverflowStyle: "none" /* Internet Explorer 10+ */,
              scrollbarWidth: "none" /* Firefox */,
            },
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
          }}
        >
          <PaymentMethod
            handleMakePaymentRequest={
              buildBundleRechargeOpen
                ? handleBuildBundleRechargeRequest
                : handleRechargeRequest
            }
            togglePaymentOption={handleTogglePaymentOption}
            paymentOptions={paymentOptionsList}
          />
        </DialogContent>
      </Dialog>

      {/* WALLET PAYMENT METHOD */}
      <Dialog open={openWalletPaymentMethod} fullWidth maxWidth="sm">
        <WalletPaymentMethod
          handleClose={handleToggleWalletPaymentMethod}
          walletPaymentState={walletPaymentState}
          handleContinue={handleRechargeRequestUpdate}
        />
      </Dialog>
    </>
  );
};

export default RechargePlansLayout;
