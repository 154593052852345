import { PromotionGetAllProps } from "_interfaces/http-requests/promotion";
import {
  GET_COUNT_FOR_IPHONE_LOTTERY,
  PROMOTION_GET_ALL,
  PROMOTION_GET_BY_ID,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const PromotionGetAll = ({
  DATA,
  isCancellable = true,
}: PromotionGetAllProps) => {
  return HTTP({
    Method: "POST",
    Url: PROMOTION_GET_ALL,
    Data: DATA,
    isCancellable: isCancellable,
  });
};

export const PromotionGetById = (ID: string) => {
  return HTTP({
    Method: "GET",
    Url: PROMOTION_GET_BY_ID(ID),
  });
};

export const GetCountForIphoneLottery = () => {
  return HTTP({
    Method: "GET",
    Url: GET_COUNT_FOR_IPHONE_LOTTERY,
  });
};
