import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import {
  PromotionDataProps,
  showInPageProps,
} from "_interfaces/_common/promotion";
import CheckStatus from "component/_common/checkStatus";
import SearchNumber from "component/_common/searchNumber";
import BookingSteps from "component/home/bookingSteps";
import ChooseUs from "component/home/chooseUs";
import ExcitingPlans from "component/home/excitingPlans";
import HomeHeader from "component/home/Header";
import HomeGetStarted from "component/home/homeGetStarted";
import SwitchToTalkio from "component/home/switchToTalkio";
import { PromotionGetAll } from "functions/http-requests/promotion";
import moment from "moment";
import BuildBundleBanner from "parts/banners/buildBundleBanner";
import JoinRevolutionBanner from "parts/banners/joinRevolutionBanner";
import LimitedTimeOfferBanner from "parts/banners/limitedTimeofferBanner";
import FrequentlyQuestions from "parts/frequentlyQuestions";
import MainContainer from "parts/mainContainer";
import { FC, useEffect, useState } from "react";

const Home: FC = () => {
  const [promotions, setPromotions] = useState<PromotionDataProps[] | null>(
    null
  );

  const [openSearchNumber, setOpenSearchNumber] = useState<boolean>(false);
  const [openCheckStatus, setOpenCheckStatus] = useState<boolean>(false);

  const handleOpenSearchNumber = () => setOpenSearchNumber(true);
  const handleCloseSearchNumber = () => setOpenSearchNumber(false);

  const handleOpenCheckStatus = () => setOpenCheckStatus(true);
  const handleCloseCheckStatus = () => setOpenCheckStatus(false);

  // GET PROMOTIONS
  useEffect(() => {
    let fetchList: (() => void) | null = () => {
      PromotionGetAll({
        DATA: {
          showInPage: showInPageProps.home,
        },
        isCancellable: false,
      })
        .then((res) => {
          let DATA: PromotionDataProps[] = res?.data?.data;

          const itemCondition = (item: PromotionDataProps): boolean =>
            item?.showInPage?.includes(showInPageProps.home) &&
            moment(item?.expiryDate).isAfter(moment()) &&
            item?.isActive;

          if (
            Array.isArray(DATA) &&
            DATA?.length &&
            DATA?.some((item) => itemCondition(item))
          ) {
            DATA = DATA?.filter((item) => itemCondition(item));
            setPromotions(DATA);
          }
        })
        .catch((e) => {});
    };

    fetchList();
    return () => {
      fetchList = null;
    };
  }, []);

  return (
    <>
      <HomeHeader />

      <section>
        <MainContainer>
          <Box py={5}>
            <HomeGetStarted />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <BookingSteps
                handleOpenSearchNumber={handleOpenSearchNumber}
                handleOpenCheckStatus={handleOpenCheckStatus}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SwitchToTalkio />
            </Grid>
          </Grid>
        </MainContainer>
      </section>

      <section id="explore-products">
        <MainContainer>
          <Box py={5}>
            <ExcitingPlans />
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box pb={5}>
            <ChooseUs />
          </Box>
        </MainContainer>
      </section>

      {promotions?.some((item) => item?.UI?.theme === 1) ? (
        <section>
          <MainContainer>
            <LimitedTimeOfferBanner
              data={promotions?.find((item) => item.UI.theme === 1)}
            />
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      <section>
        <MainContainer>
          <Box pt={3}>
            <JoinRevolutionBanner
              handleOpenSearchNumber={handleOpenSearchNumber}
            />
          </Box>
        </MainContainer>
      </section>

      {promotions?.some((item) => item?.UI?.theme === 2) ? (
        <section>
          <MainContainer>
            <Box pt={3}>
              <BuildBundleBanner
                data={promotions?.find((item) => item.UI.theme === 2)}
              />
            </Box>
          </MainContainer>
        </section>
      ) : (
        <></>
      )}

      <section>
        <MainContainer>
          <Box py={3}>
            <FrequentlyQuestions />
          </Box>
        </MainContainer>
      </section>

      <Dialog
        open={openSearchNumber}
        onClose={handleCloseSearchNumber}
        maxWidth="lg"
        fullWidth
      >
        <SearchNumber onClose={handleCloseSearchNumber} />
      </Dialog>

      <Dialog
        open={openCheckStatus}
        onClose={handleCloseCheckStatus}
        maxWidth="md"
        fullWidth
      >
        <CheckStatus onClose={handleCloseCheckStatus} />
      </Dialog>
    </>
  );
};

export default Home;
