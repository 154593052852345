import { BuildBundleInterval } from "_interfaces/phoneBooking";

export interface unitRate {
  valueFrom: number;
  valueTo: number;
  rate: number;
}

interface BundleUnitRate {
  counterId: string;
  rate: unitRate[];
  type?: "onNet" | "offNet";
}

export interface BundleRatesProps {
  data?: BundleUnitRate;
  voice?: BundleUnitRate[];
  sms?: BundleUnitRate[];
}

export type calculateBuildBundleReturn = {
  onNetPrice: number;
  offNetPrice: number;
  dataPrice: number;
  smsPrice: number;
  totalPrice: number; // Total after promotion applied
  original_totalPrice: number; // Total before promotion
};

export type calculateBuildBundleUnit = {
  onNetVoice?: number;
  offNetVoice?: number;
  dataMb?: number;
  smsOffNet?: number;
};

// Helper function to calculate cost for a given value and rates
const calculateCost = (value: number, rates: unitRate[]): number => {
  let totalCost = 0;

  for (const rate of rates) {
    if (value >= rate.valueFrom && value <= rate.valueTo) {
      totalCost = value * rate.rate;
      return Number(totalCost.toFixed(2));
    }
  }
  return 0;
};

export const getDataMbRate = (rate?: BundleRatesProps[]) => {
  if (rate?.length) {
    return rate?.find((p) => p?.data?.counterId == "84")?.data?.rate || [];
  }
  return [];
};

export const getOnNetVoiceRate = (rate?: BundleRatesProps[]) => {
  if (rate?.length) {
    return (
      rate
        ?.find((p) => p?.voice?.find((v) => v?.counterId == "85"))
        ?.voice?.find((p) => p?.type === "onNet")?.rate || []
    );
  }
  return [];
};

export const getOffNetVoiceRate = (rate?: BundleRatesProps[]) => {
  if (rate?.length) {
    return (
      rate
        ?.find((p) => p?.voice?.find((v) => v?.counterId == "86"))
        ?.voice?.find((p) => p?.type === "offNet")?.rate || []
    );
  }
  return [];
};
export const getSmsOffNetRate = (rate?: BundleRatesProps[]) => {
  if (rate?.length) {
    return (
      rate
        ?.find((p) => p?.sms?.find((v) => v?.counterId == "87"))
        ?.sms?.find((p) => p?.type === "offNet")?.rate || []
    );
  }
  return [];
};

// Main function to calculate the bundle prices
export const calculateBuildBundle = (
  payload?: calculateBuildBundleUnit,
  rate?: BundleRatesProps[],
  promotion?: {
    promotionId: string;
    amount: number; // Fixed cost for promotion
    intervalId?: BuildBundleInterval;
    onNetVoice?: number;
    offNetVoice?: number;
    dataMb?: number;
    smsOffNet?: number;
  }
): calculateBuildBundleReturn => {
  // Calculate the original prices for each unit
  const originalDataPrice =
    payload?.dataMb && rate
      ? calculateCost(payload.dataMb, getDataMbRate(rate))
      : 0;
  const originalOnNetPrice =
    payload?.onNetVoice && rate
      ? calculateCost(payload.onNetVoice, getOnNetVoiceRate(rate))
      : 0;
  const originalOffNetPrice =
    payload?.offNetVoice && rate
      ? calculateCost(payload.offNetVoice, getOffNetVoiceRate(rate))
      : 0;
  const originalSmsPrice =
    payload?.smsOffNet && rate
      ? calculateCost(payload.smsOffNet, getSmsOffNetRate(rate))
      : 0;

  const originalPrice =
    originalDataPrice +
    originalOnNetPrice +
    originalOffNetPrice +
    originalSmsPrice;

  let totalPrice = 0;

  if (promotion) {
    // Calculate costs after applying the promotion
    const dataPriceAfterPromotion =
      promotion?.dataMb && payload?.dataMb && rate
        ? calculateCost(
            Math.max(0, payload.dataMb - promotion.dataMb),
            getDataMbRate(rate)
          )
        : 0;
    const onNetPriceAfterPromotion =
      promotion?.onNetVoice && payload?.onNetVoice && rate
        ? calculateCost(
            Math.max(0, payload.onNetVoice - promotion.onNetVoice),
            getOnNetVoiceRate(rate)
          )
        : 0;
    const offNetPriceAfterPromotion =
      promotion?.offNetVoice && payload?.offNetVoice && rate
        ? calculateCost(
            Math.max(0, payload.offNetVoice - promotion.offNetVoice),
            getOffNetVoiceRate(rate)
          )
        : 0;
    const smsPriceAfterPromotion =
      promotion?.smsOffNet && payload?.smsOffNet && rate
        ? calculateCost(
            Math.max(0, payload.smsOffNet - promotion.smsOffNet),
            getSmsOffNetRate(rate)
          )
        : 0;

    // Apply the fixed promotion amount
    totalPrice =
      promotion.amount +
      dataPriceAfterPromotion +
      onNetPriceAfterPromotion +
      offNetPriceAfterPromotion +
      smsPriceAfterPromotion;
  } else {
    // No promotion, use original prices
    totalPrice = originalPrice;
  }

  return {
    onNetPrice: Number(originalOnNetPrice.toFixed(2)),
    offNetPrice: Number(originalOffNetPrice.toFixed(2)),
    dataPrice: Number(originalDataPrice.toFixed(2)),
    smsPrice: Number(originalSmsPrice.toFixed(2)),
    totalPrice: Number(totalPrice.toFixed(2)), // Price after promotion applied
    original_totalPrice: Number(originalPrice.toFixed(2)), // Price before promotion
  };
};
