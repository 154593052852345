import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import RouteConstant from "navigation/constant";
import { Link } from "react-router-dom";

const Offer = () => {
  const { palette } = useTheme();
  return (
    <>
      <Box sx={{ maxWidth: 1000, mx: "auto", textAlign: "center" }}>
        <Typography
          sx={{ fontSize: { xs: 30, sm: 42 }, fontWeight: 700, pb: 2 }}
        >
          Win an
          <Box component="span" sx={{ color: palette.primary.main }}>
            {" "}
            iPhone 16 Pro Max{" "}
          </Box>
          with Talkio Mobile!
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: 16,
              sm: 22,
            },
            fontWeight: 400,
          }}
        >
          Are you ready to elevate your mobile experience? Talkio Mobile is
          thrilled to offer you an exciting chance to win the latest iPhone 16
          Pro Max, along with other amazing prizes like free data, smartphones,
          and MiFi devices. All you have to do is book your Talkio Number
          through our website and you’re automatically entered into the draw!
        </Typography>
        <Box pt={3}>
          <Link to={RouteConstant.SELECT_SERVICE}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: 1.2,
                py: 2,
                minWidth: 180,
              }}
            >
              Book Now
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Offer;
