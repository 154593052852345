import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import MobileImage from "assets/img/static/bg/mobileBg.png";
import SearchNumber from "component/_common/searchNumber";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import ConfirmNumberAndContinue from "./confirmNumberAndContinue";
import ConfirmNumberSummary from "./confirmNumberSummary";
import ReselectNumber from "./reselectNumber";

const ConfirmNumberLayout = () => {
  const { bookingData } = useSelector((state: RootState) => state.phoneBooking);

  const [openSearchNumber, setOpenSearchNumber] = useState<boolean>(false);
  const [reSelectingNumber, setReSelectingNumber] = useState<boolean>(false);

  const handleOpen = () => setOpenSearchNumber(true);
  const handleClose = () => setOpenSearchNumber(false);

  return (
    <>
      <Grid container spacing={{ xs: 3, md: 5 }} justifyContent="center">
        <Grid item xs={12} lg={5}>
          <ConfirmNumberSummary />
        </Grid>
        <Grid item xs={12} lg={7}>
          <Box
            pt={{ xs: 12, sm: 15 }}
            width="100%"
            minHeight={{ xs: "330px", sm: "500px" }}
            height="100%"
            className="text-white"
            textAlign="center"
            display="flex"
            sx={{
              backgroundImage: `url(${MobileImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "bottom center",
            }}
          >
            <Box
              width={{ xs: 220, sm: "70%" }}
              margin="auto"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {bookingData?.phoneDetails?._id ? (
                <>
                  <ConfirmNumberAndContinue
                    handleOpen={handleOpen}
                    setReSelectingNumber={setReSelectingNumber}
                  />
                </>
              ) : (
                <>
                  <ReselectNumber
                    handleOpen={handleOpen}
                    setReSelectingNumber={setReSelectingNumber}
                  />
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openSearchNumber}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <SearchNumber
          onClose={handleClose}
          reSelectingNumber={reSelectingNumber}
          setReSelectingNumber={setReSelectingNumber}
        />
      </Dialog>
    </>
  );
};

export default ConfirmNumberLayout;
