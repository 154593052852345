import { useTheme } from "@mui/material/styles";
import React from "react";
import ReactJoyride from "react-joyride";

interface Props {
  steps: any[];
  stepIndex: number;
  run?: boolean;
}
const CustomJoyRide: React.FC<Props> = ({ steps, stepIndex, run }) => {
  const { palette } = useTheme();

  return (
    <ReactJoyride
      steps={steps}
      stepIndex={stepIndex}
      hideBackButton
      disableOverlay
      scrollToFirstStep
      spotlightClicks
      scrollOffset={200}
      run={run}
      styles={{
        options: {
          primaryColor: palette.primary.main,
        },
        buttonClose: {
          display: "none",
        },
      }}
    />
  );
};

export default CustomJoyRide;
