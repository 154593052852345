import { PaymentMethodModel } from "_models/data/data.paymentMethod.model";
import { WalletPaymentModel } from "_models/data/wallet/data.walletPayment.model";
import { isValidPhone, isValidPin } from "functions/validators";
import { PaymentByProps } from "_interfaces/_common/payment";

export const validatePaymentMethod = (
  DATA: PaymentMethodModel,
  paymentBy: PaymentByProps | ""
) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (paymentBy === "momo") {
    if (!DATA?.momoPhoneNumber?.trim()) {
      valid = false;
      errors.push({
        name: "momoPhoneNumber",
        error: "Enter your Momo phone number",
      });
    }

    if (DATA?.momoPhoneNumber?.trim() && !isValidPhone(DATA.momoPhoneNumber)) {
      valid = false;
      errors.push({
        name: "momoPhoneNumber",
        error: "Invalid phone number.",
      });
    }
  }

  if (paymentBy === "airtelMoney") {
    if (!DATA?.airtelMOMOPhone?.trim()) {
      valid = false;
      errors.push({
        name: "airtelMOMOPhone",
        error: "Enter your Airtel Momo phone number",
      });
    }

    if (DATA?.airtelMOMOPhone?.trim() && !isValidPhone(DATA.airtelMOMOPhone)) {
      valid = false;
      errors.push({
        name: "airtelMOMOPhone",
        error: "Invalid phone number.",
      });
    }
  }

  return { valid, errors };
};

export const validateWalletPaymentSubmit = (DATA: WalletPaymentModel) => {
  let valid = true;
  let errors = [{ name: "", error: "" }];

  if (!DATA.PIN?.trim()) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "PIN is required",
    });
  }

  if (DATA.PIN?.trim() && !isValidPin(DATA.PIN)) {
    valid = false;
    errors.push({
      name: "PIN",
      error: "Invalid PIN",
    });
  }

  return { valid, errors };
};
