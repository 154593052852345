import { GetCustomerRechargeHistoryProps } from "_interfaces/http-requests/customer";
import {
  GET_CUSTOMER_BALANCE_SUMMARY,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_RECHARGE_HISTORY,
} from "config/endpoint";
import { HTTP } from "functions/http";

export const GetCustomerInfo = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_INFO,
  });
};

export const GetCustomerBalanceSummary = () => {
  return HTTP({
    Method: "GET",
    Url: GET_CUSTOMER_BALANCE_SUMMARY,
  });
};

export const GetCustomerRechargeHistory = ({
  DATA,
}: GetCustomerRechargeHistoryProps) => {
  return HTTP({
    Method: "POST",
    Url: GET_CUSTOMER_RECHARGE_HISTORY,
    Data: DATA,
  });
};
